import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

// import { VehicleService } from "../../service/vehicle.service";
import { TruckManagerLayoutService } from "src/app/service/truck-manager-layout.service";
import { WebConnectivityService } from 'src/app/service/web.connectivity.service';
import { WebsocketService } from "src/app/service/websocket.service";
import { ObligationService } from "src/app/service/obligation.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { TmAgendaService } from "src/app/service/tm-agenda.service";
import { WarehouseService } from "src/app/service/warehouse.service";
import { MessageService } from "src/app/service/message.service";
import { ItineraryDairyDayCollection } from "../../model/itinerary-dairy-day-collection.object";
import { ServiceEventObject } from "src/app/model/service-event.object";
import { WarehouseEvent } from "src/app/model/warehouse-event.object";
import { Itinerary } from "../../model/itinerary.object";
import { Attachment } from "src/app/model/attachment.object";
import { Agenda } from "src/app/model/agenda.object";
import { Vehicle } from "src/app/model/vehicle.object";
import { Email } from "src/app/model/email.object";

import { Ta1ObligationEmailingComponent } from "../ta1-obligation-emailing/ta1-obligation-emailing.component";
import { WebsocketResponse } from "src/app/interface/websocket-response.interface";
import { DateTools } from 'src/app/tools/DateTools';
import { Haversine } from "src/app/tools/Haversine";
import { IS_DEMO, ItineraryType, ObligationStatus, ServiceType } from "src/app/config";

// declare let gtag: Function;
declare var $: any;

@Component({
  selector: '<itinerary-diary>',
  templateUrl: './r-itinerary-diary.component.html',
  styleUrls: ['./r-itinerary-diary.component.css'],
  providers: [
    TmAgendaService,
    ObligationService,
    VehicleNewService,
    WarehouseService
  ]
})
export class RItineraryDiaryComponent implements OnInit, OnDestroy {
  
  private _subs: Array<Subscription> = [];

  // switch flag for sorting according to hour/vehicle of number_plate
  private _sortByHour: boolean = true;
  public get sortByHour(): boolean {
    return this._sortByHour;
  }
  public set sortByHour(value: boolean) {
    this._sortByHour = value;
    if (this._sortByHour) {
      this.itinerary.markByHour();
    }
    else {
      this.itinerary.markByVehicle();
    }
  }

  // main collection of itinerary
  private _sortedItinerary: ItineraryDairyDayCollection = new ItineraryDairyDayCollection([]);
  get itinerary(): ItineraryDairyDayCollection {
    return this._sortedItinerary;
  }
  
  // vehicles stuff
  private _vehicles: Array<Vehicle> = [];
  
  get loadingVehicles(): boolean {
    return this._vehicleService.loadingVehicles;
  }

  // reload all vehicles
  private _reloadVehicles: boolean = false;
  public get reloadVehicles(): boolean {
    return this._reloadVehicles;
  }

  // reload just one vehicle (agenda)
  private _reloadAgenda: boolean = false;
  public get reloadAgenda(): boolean {
    return this._reloadAgenda;
  }

  // possible details of itinerary
  private _currentPositionDetailItinerary: Itinerary;
  get currentPositionDetailItinerary(): Itinerary {
    return this._currentPositionDetailItinerary;
  }

  private _itineraryAgendaDetail: Itinerary;
  get itineraryAgendaDetail(): Itinerary {
    return this._itineraryAgendaDetail;
  }

  private _itineraryMessages: Itinerary;
  public get itineraryMessages(): Itinerary {
    return this._itineraryMessages;
  }

  private _itineraryMessagePatterns: Array<string> = [];
  public get itineraryMessagePatterns(): Array<string> {
    return this._itineraryMessagePatterns;
  }

  private readonly _messagesPatterns: Array<string> = [
    $localize`Prosím o zaslání dokumentů k zakázce č. <ORDER_NUMBER_STANDARD>.`,
    $localize`Dokument k zakázce č. <ORDER_NUMBER_STANDARD> je špatné kvality. Pošlete prosím znovu.`
  ];


  // connection stuff
  private _firstTimeConnectionUp: boolean = false;

  private _notConnected: boolean = false;
  get notConnected(): boolean {
    return this._notConnected;
  }
  
  private TODAY: Date = new Date();

  // get flag if user has valid access = true, expired access = false
  get validAccess(): boolean {
    if (IS_DEMO) return true;
    if (!this._layout.user || !this._layout.user.admittanceDateExtended) return false;
    return this._layout.user.admittanceDateExtended > this.TODAY;
  }
  
  get formattedLastVehicleLoad(): string {
    if (!this._vehicleService.lastLoadDate) {
        return '-';
    }
    return DateTools.formatLocaleString(this._vehicleService.lastLoadDate, '%hours:%minutes');
  }

  // yesterday/today/tomorrow management
  private _visibleToday: number = 0;
  private _visibleTomorrow: number = 1;
  private _visibleYesterday: number = -1;
  private _visible: number = this._visibleToday;
  
  get isYesterdayVisible(): boolean {
    return this._visible === this._visibleYesterday;
  }

  get isTomorrowVisible(): boolean {
    return this._visible === this._visibleTomorrow;
  }

  get isTodayVisible(): boolean {
    return this._visible === this._visibleToday;
  }

  get current_selected_text(): string {
    switch (true) {
      case this.isTodayVisible:
        return $localize`Dnes`;
      case this.isTomorrowVisible:
        return $localize`Zítra`;
      case this.isYesterdayVisible:
        return $localize`Včera`;
      default:
        return '';
    }
  }
  
  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }

  public selectedVehicle:Vehicle = null;
  public selectedDriver:any = null;

    
  constructor(
    private _router: Router,
    private _layout: TruckManagerLayoutService,
    private _vehicleService: VehicleNewService, 
    private _tmAgendaServ: TmAgendaService,
    private _webConn: WebConnectivityService,
    private _webSocketServ: WebsocketService,
    private _obligationServ: ObligationService,
    private _warehouseServ: WarehouseService,
    private _messageServ: MessageService,
    private _changeDetector: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    // if (!window.location.href.includes('app2') && !window.location.href.includes('localhost')) {
    //   gtag('config', 'UA-116483982-1', { 'page_path': 'diar_nakladek_vykladek' });
    // }

    this._subs.push(
      this._vehicleService.getFilteredVehiclesCache().subscribe(
        vehicles => {
          if (vehicles && vehicles.length) {
            let new_itineraries: Array<Itinerary> = [];
            // console.log(vehicles);
            this._vehicles = vehicles;

            this._vehicles.forEach(
              vehicle => {
                if (vehicle.agenda && vehicle.agenda.length) {
                  vehicle.agenda.forEach(
                    agenda => {
                      console.log(agenda);
                      if (agenda.itinerary && agenda.itinerary.length) {
                        console.log(agenda.order_number_standard);
                        // warehousing
                        if (agenda.piece_shipment) {
                          // same car for collection and delivery
                          if (agenda.car_key == agenda.car2_key && agenda.car_key == vehicle.car_key) {
                            // push itinerary and warehouse
                            agenda.itinerary.forEach(
                              it => {
                                if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                                  it.car_key = vehicle.car_key;
                                  it.number_plate = vehicle.number_plate;
                                  new_itineraries.push(it);
                                }
                                else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                                  let service_events: Array<ServiceEventObject> = [];
                                  if (it.service_events) {
                                    service_events = it.service_events.slice();
                                  } 

                                  // colection warehouse itinerary
                                  it.warehouse_in_itinerary = true;
                                  it.car_key = vehicle.car_key;
                                  it.number_plate = vehicle.number_plate;
                                  new_itineraries.push(it);
                                  
                                  // delivery warehouse itinerary
                                  let warehouse_it2: Itinerary = new Itinerary();
                                  for (let key in it) {
                                    warehouse_it2[key] = it[key];
                                  }
                                  warehouse_it2.warehouse_in_itinerary = false;
                                  warehouse_it2.warehouse_out_itinerary = true;
                                  new_itineraries.push(warehouse_it2);

                                  console.log(warehouse_it2);

                                  // filtering events
                                  if (service_events) {
                                    // filter warehouse collection service events
                                    it.service_events = service_events.filter(
                                      e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                    );
                                    // filter warehouse delivery service events
                                    warehouse_it2.service_events = service_events.filter(
                                      e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                    );
                                  }

                                  // load info about warehouse events
                                  let time_from_date: Date = it.arrival_time;
                                  // resize tf interval (without that some events could be missing)
                                  let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                                  let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                                  let filterObj: any = {
                                    tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                    tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                    on: agenda.order_number_standard
                                  };
                                  // load info about warehouse events
                                  this._subs.push(
                                    this._warehouseServ.getEvents(filterObj).subscribe(
                                      response => {
                                        if (response && response.length) {
                                          // find unloading (naskladneni)
                                          let collection: WarehouseEvent = response.find(
                                            e => e.type == ItineraryType.UNLOADING
                                          );
                                          if (collection) {
                                            it.warehouse_in_event = collection;
                                            it.arrival_time = collection.timeDate;
                                          }

                                          // find loading (vyskladneni)
                                          let delivery: WarehouseEvent = response.find(
                                            e => e.type == ItineraryType.LOADING
                                          );
                                          if (delivery) {
                                            it.warehouse_out_event = delivery;
                                            it.arrival_time = delivery.timeDate;
                                          }
  
                                          // build diary collection
                                          this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                          console.log(this._sortedItinerary);
                                        }
                                      }
                                    )
                                  );
                                }
                              }
                            );
                          }
                          else {
                            // collection
                            if (agenda.car_key == vehicle.car_key) {
                              // push loadings and warehouse
                              agenda.itinerary.forEach(
                                it => {
                                  if (it.type == ItineraryType.LOADING) {
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    new_itineraries.push(it);
                                  }
                                  else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                                    it.warehouse_in_itinerary = true;
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    if (it.service_events) {
                                      // filter warehouse collection service events
                                      it.service_events = it.service_events.filter(
                                        e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                      );
                                    }
                                    new_itineraries.push(it);

                                    // load info about warehouse events
                                    let time_from_date: Date = it.arrival_time;
                                    // resize tf interval (without that some events could be missing)
                                    let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                                    let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                                    let filterObj: any = {
                                      tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                      tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                      on: agenda.order_number_standard
                                    };
                                    this._subs.push(
                                      this._warehouseServ.getEvents(filterObj).subscribe(
                                        response => {
                                          if (response && response.length) {
                                            // find unloading (naskladneni)
                                            let collection: WarehouseEvent = response.find(
                                              e => e.type == ItineraryType.UNLOADING
                                            );
                                            if (collection) {
                                              it.warehouse_in_event = collection;
                                              it.arrival_time = collection.timeDate;
                                            }

                                            // build diary collection
                                            this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                            console.log(this._sortedItinerary);
                                          }
                                        }
                                      )
                                    );
                                  }
                                }
                              );
                            }
                            // delivery
                            if (agenda.car2_key == vehicle.car_key) {
                              // push warehouse and unloadings
                              agenda.itinerary.forEach(
                                it => {
                                  if (it.type == ItineraryType.UNLOADING) {
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    new_itineraries.push(it);
                                  }
                                  else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                                    it.warehouse_out_itinerary = true;
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    if (it.service_events) {
                                      // filter warehouse delivery service events
                                      it.service_events = it.service_events.filter(
                                        e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                      );
                                    }

                                    new_itineraries.push(it);

                                    // load info about warehouse events
                                    let time_from_date: Date = it.arrival_time;
                                    // resize tf interval (without that some events could be missing)
                                    let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                                    let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                                    let filterObj: any = {
                                      tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                      tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                      on: agenda.order_number_standard
                                    };
                                    // load info about warehouse events
                                    this._subs.push(
                                      this._warehouseServ.getEvents(filterObj).subscribe(
                                        response => {
                                          if (response && response.length) {
                                            // find loading (vyskladneni)
                                            let delivery: WarehouseEvent = response.find(
                                              e => e.type == ItineraryType.LOADING
                                            );
                                            console.log(response);
                                            if (delivery) {
                                              it.warehouse_out_event = delivery;
                                              it.arrival_time = delivery.timeDate;
                                            }

                                            // build diary collection
                                            this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                          }
                                        }
                                      )
                                    );
                                  }
                                }
                              );
                            }
                          }
                        }
                        // transshipment
                        else if (agenda.transshipment) {
                          // same car for collection and delivery
                          if (agenda.car_key == agenda.car2_key && agenda.car_key == vehicle.car_key) {
                            // push itinerary and warehouse
                            agenda.itinerary.forEach(
                              it => {
                                if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                                  it.car_key = vehicle.car_key;
                                  it.number_plate = vehicle.number_plate;
                                  new_itineraries.push(it);
                                }
                                else if (it.type == ItineraryType.TRANSSHIPMENT) {
                                  let service_events: Array<ServiceEventObject> = [];
                                  if (it.service_events) {
                                    service_events = it.service_events.slice();
                                  } 

                                  // transshipment first part
                                  it.warehouse_in_itinerary = true;
                                  it.car_key = vehicle.car_key;
                                  it.number_plate = vehicle.number_plate;
                                  new_itineraries.push(it);
                                  
                                  // transshipment second part
                                  let warehouse_it2: Itinerary = new Itinerary();
                                  for (let key in it) {
                                    warehouse_it2[key] = it[key];
                                  }
                                  warehouse_it2.warehouse_in_itinerary = false;
                                  warehouse_it2.warehouse_out_itinerary = true;
                                  new_itineraries.push(warehouse_it2);

                                  // filtering events
                                  if (service_events) {
                                    // filter warehouse collection service events
                                    it.service_events = service_events.filter(
                                      e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                    );
                                    // filter warehouse delivery service events
                                    warehouse_it2.service_events = service_events.filter(
                                      e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                    );
                                  }

                                  // load info about warehouse events
                                  let time_from_date: Date = it.arrival_time;
                                  // resize tf interval (without that some events could be missing)
                                  let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                                  let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                                  let filterObj: any = {
                                    tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                    tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                    on: agenda.order_number_standard
                                  };
                                  // load info about warehouse events
                                  this._subs.push(
                                    this._warehouseServ.getEvents(filterObj).subscribe(
                                      response => {
                                        if (response && response.length) {
                                          // find unloading (vylozeni)
                                          let collection: WarehouseEvent = response.find(
                                            e => e.type == ItineraryType.UNLOADING
                                          );
                                          if (collection) {
                                            it.warehouse_in_event = collection;
                                            it.arrival_time = collection.timeDate;
                                          }

                                          // find loading (nalozeni)
                                          let delivery: WarehouseEvent = response.find(
                                            e => e.type == ItineraryType.LOADING
                                          );
                                          if (delivery) {
                                            it.warehouse_out_event = delivery;
                                            it.arrival_time = delivery.timeDate;
                                          }
  
                                          // build diary collection
                                          this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                          console.log(this._sortedItinerary);
                                        }
                                      }
                                    )
                                  );
                                }
                              }
                            );
                          }
                          else {
                            // first part of transshipment
                            if (agenda.car_key == vehicle.car_key) {
                              // push loadings and warehouse
                              agenda.itinerary.forEach(
                                it => {
                                  if (it.type == ItineraryType.LOADING) {
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    new_itineraries.push(it);
                                  }
                                  else if (it.type == ItineraryType.TRANSSHIPMENT) {
                                    it.warehouse_in_itinerary = true;
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    if (it.service_events) {
                                      // filter transshipment unloading service events
                                      it.service_events = it.service_events.filter(
                                        e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                      );
                                    }
                                    new_itineraries.push(it);

                                    // load info about warehouse events
                                    let time_from_date: Date = it.arrival_time;
                                    // resize tf interval (without that some events could be missing)
                                    let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                                    let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                                    let filterObj: any = {
                                      tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                      tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                      on: agenda.order_number_standard
                                    };
                                    this._subs.push(
                                      this._warehouseServ.getEvents(filterObj).subscribe(
                                        response => {
                                          if (response && response.length) {
                                            // find unloading (naskladneni)
                                            let collection: WarehouseEvent = response.find(
                                              e => e.type == ItineraryType.UNLOADING
                                            );
                                            if (collection) {
                                              it.warehouse_in_event = collection;
                                              it.arrival_time = collection.timeDate;
                                            }

                                            // build diary collection
                                            this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                            console.log(this._sortedItinerary);
                                          }
                                        }
                                      )
                                    );
                                  }
                                }
                              );
                            }
                            // second part of transshipment
                            if (agenda.car2_key == vehicle.car_key) {
                              // push warehouse and unloadings
                              agenda.itinerary.forEach(
                                it => {
                                  if (it.type == ItineraryType.UNLOADING) {
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    new_itineraries.push(it);
                                  }
                                  else if (it.type == ItineraryType.TRANSSHIPMENT) {
                                    it.warehouse_out_itinerary = true;
                                    it.car_key = vehicle.car_key;
                                    it.number_plate = vehicle.number_plate;
                                    if (it.service_events) {
                                      // filter transshipment loading service events
                                      it.service_events = it.service_events.filter(
                                        e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                      );
                                    }

                                    new_itineraries.push(it);

                                    // load info about warehouse events
                                    let time_from_date: Date = it.arrival_time;
                                    // resize tf interval (without that some events could be missing)
                                    let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                                    let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                                    let filterObj: any = {
                                      tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                      tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                      on: agenda.order_number_standard
                                    };
                                    // load info about warehouse events
                                    this._subs.push(
                                      this._warehouseServ.getEvents(filterObj).subscribe(
                                        response => {
                                          if (response && response.length) {
                                            // find loading (vyskladneni)
                                            let delivery: WarehouseEvent = response.find(
                                              e => e.type == ItineraryType.LOADING
                                            );
                                            console.log(response);
                                            if (delivery) {
                                              it.warehouse_out_event = delivery;
                                              it.arrival_time = delivery.timeDate;
                                            }

                                            // build diary collection
                                            this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                          }
                                        }
                                      )
                                    );
                                  }
                                }
                              );
                            }
                          }
                        }
                        else {
                          // standard obligations - push all itinerary
                          agenda.itinerary.forEach(
                            itinerary => {
                              itinerary.car_key = vehicle.car_key;
                              itinerary.number_plate = vehicle.number_plate;
                              new_itineraries.push(itinerary);
                            }
                          );
                        }
                      }
                    }
                  );
                }
              }
            );

            // little more delay for loading destination routes
            let TIMEOUT_DELAY: number = 1000 + this._vehicles.length * 30;
            window.setTimeout(
              () => {
                this._vehicles.forEach(
                  v => {
                    this._vehicleService.loadDestinationRoute(v);
                  }
                );
              }, TIMEOUT_DELAY
            );

            // build diary collection
            this._sortedItinerary = new ItineraryDairyDayCollection(new_itineraries);
            console.log(this._sortedItinerary);
            
            // load obligations with files and emails
            this.loadObligationsFilesEmails();
          }
          else {
            this._vehicles = [];
          }
        }
      ),
      // changes when already running
      // this._vehicleService.vehicleDataChanged.subscribe(
      this._vehicleService.vehicleServiceEventsChanged.subscribe(
        vehicle => {
          if (vehicle) {
            // update vehicles array with current agenda data
            let idx: number = this._vehicles.findIndex(v => v.number_plate == vehicle.number_plate);
            if (idx > -1) {
              this._vehicles[idx] = vehicle;
            }

            // this stuff is here because of service events (from websockets)
            if (vehicle.agenda && vehicle.agenda.length) {
              vehicle.agenda.forEach(
                agenda => {
                  if (agenda.itinerary && agenda.itinerary.length) {
                    // console.log(agenda.order_number_standard);
                    // piece_shipment
                    if (agenda.piece_shipment) {
                      // same car for collection and delivery
                      if (agenda.car_key == agenda.car2_key && agenda.car_key == vehicle.car_key) {
                        // push itinerary and warehouse
                        agenda.itinerary.forEach(
                          it => {
                            // loadings/unloadings
                            if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key 
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                            }
                            else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                              let service_events: Array<ServiceEventObject> = [];
                              if (it.service_events) {
                                service_events = it.service_events.slice();
                              } 

                              // colection warehouse itinerary
                              it.warehouse_in_itinerary = true;
                              it.warehouse_out_itinerary = false;
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key && i.warehouse_in_itinerary
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                              
                              // delivery warehouse itinerary
                              let warehouse_it2: Itinerary = new Itinerary();
                              for (let key in it) {
                                warehouse_it2[key] = it[key];
                              }
                              warehouse_it2.warehouse_in_itinerary = false;
                              warehouse_it2.warehouse_out_itinerary = true;
                              let found2 = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == warehouse_it2.itinerary_key && i.warehouse_out_itinerary
                              );
                              if (found2) { found2 = it; }
                              else { this._sortedItinerary.itineraries.push(warehouse_it2); }

                              // service_events filtering
                              if (service_events) {
                                // filter warehouse collection service events
                                it.service_events = service_events.filter(
                                  e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                );
                                // filter warehouse delivery service events
                                warehouse_it2.service_events = service_events.filter(
                                  e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                );
                              }

                              // load info about warehouse events
                              let time_from_date: Date = it.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                on: agenda.order_number_standard
                              };
                              // load info about warehouse events
                              this._subs.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response && response.length) {
                                      // find unloading (naskladneni)
                                      let collection: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.UNLOADING
                                      );
                                      if (collection) {
                                        it.warehouse_in_event = collection;
                                        it.arrival_time = collection.timeDate;
                                      }

                                      // find loading (vyskladneni)
                                      let delivery: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.LOADING
                                      );
                                      if (delivery) {
                                        warehouse_it2.warehouse_out_event = delivery;
                                        warehouse_it2.arrival_time = delivery.timeDate;
                                      }

                                      // build diary collection
                                      this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                      console.log(this._sortedItinerary);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        );
                      }
                      else if (vehicle.car_key == agenda.car_key) {
                        // push itinerary and warehouse
                        agenda.itinerary.forEach(
                          it => {
                            if (it.type == ItineraryType.LOADING) {
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;

                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key 
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                            }
                            else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                              // colection warehouse itinerary
                              it.warehouse_in_itinerary = true;
                              it.warehouse_out_itinerary = false;
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              if (it.service_events) {
                                // filter warehouse collection service events
                                it.service_events = it.service_events.filter(
                                  e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                );
                              }

                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key && i.warehouse_in_itinerary
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                              
                              // load info about warehouse events
                              let time_from_date: Date = it.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                on: agenda.order_number_standard
                              };
                              // load info about warehouse events
                              this._subs.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response && response.length) {
                                      // find unloading (naskladneni)
                                      let collection: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.UNLOADING
                                      );
                                      if (collection) {
                                        it.warehouse_in_event = collection;
                                        it.arrival_time = collection.timeDate;
                                      }

                                      // build diary collection
                                      this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                      console.log(this._sortedItinerary);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        );
                      }
                      else if (vehicle.car_key == agenda.car2_key) {
                        // push itinerary and warehouse
                        agenda.itinerary.forEach(
                          it => {
                            if (it.type == ItineraryType.UNLOADING) {
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key 
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                            }
                            else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                              // colection warehouse itinerary
                              it.warehouse_in_itinerary = false;
                              it.warehouse_out_itinerary = true;
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              if (it.service_events) {
                                // filter warehouse delivery service events
                                it.service_events = it.service_events.filter(
                                  e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                );
                              }
                              
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key && i.warehouse_out_itinerary
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }

                              // load info about warehouse events
                              let time_from_date: Date = it.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                on: agenda.order_number_standard
                              };
                              // load info about warehouse events
                              this._subs.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response && response.length) {
                                      // find loading (vyskladneni)
                                      let delivery: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.LOADING
                                      );
                                      if (delivery) {
                                        it.warehouse_out_event = delivery;
                                        it.arrival_time = delivery.timeDate;
                                      }

                                      // build diary collection
                                      this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                      console.log(this._sortedItinerary);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        );
                      }
                    }
                    // transshipment
                    else if (agenda.transshipment) {
                      // same car for collection and delivery
                      if (agenda.car_key == agenda.car2_key && agenda.car_key == vehicle.car_key) {
                        // push itinerary and transshipment
                        agenda.itinerary.forEach(
                          it => {
                            // loadings/unloadings
                            if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key 
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                            }
                            else if (it.type == ItineraryType.TRANSSHIPMENT || it.type == ItineraryType.TRANSSHIPMENT) {
                              let service_events: Array<ServiceEventObject> = [];
                              if (it.service_events) {
                                service_events = it.service_events.slice();
                              } 

                              // first part of transshipment itinerary
                              it.warehouse_in_itinerary = true;
                              it.warehouse_out_itinerary = false;
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key && i.warehouse_in_itinerary
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                              
                              // delivery warehouse itinerary
                              let warehouse_it2: Itinerary = new Itinerary();
                              for (let key in it) {
                                warehouse_it2[key] = it[key];
                              }
                              warehouse_it2.warehouse_in_itinerary = false;
                              warehouse_it2.warehouse_out_itinerary = true;
                              let found2 = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == warehouse_it2.itinerary_key && i.warehouse_out_itinerary
                              );
                              if (found2) { found2 = it; }
                              else { this._sortedItinerary.itineraries.push(warehouse_it2); }

                              // service_events filtering
                              if (service_events) {
                                // filter transhipment unloading service events
                                it.service_events = service_events.filter(
                                  e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                );
                                // filter transhipment loading service events
                                warehouse_it2.service_events = service_events.filter(
                                  e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                );
                              }

                              // load info about warehouse~transshipment events
                              let time_from_date: Date = it.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                on: agenda.order_number_standard
                              };
                              this._subs.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response && response.length) {
                                      // find unloading (vylozeni)
                                      let collection: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.UNLOADING
                                      );
                                      if (collection) {
                                        it.warehouse_in_event = collection;
                                        it.arrival_time = collection.timeDate;
                                      }

                                      // find loading (nalozeni)
                                      let delivery: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.LOADING
                                      );
                                      if (delivery) {
                                        warehouse_it2.warehouse_out_event = delivery;
                                        warehouse_it2.arrival_time = delivery.timeDate;
                                      }

                                      // build diary collection
                                      this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                      console.log(this._sortedItinerary);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        );
                      }
                      else if (vehicle.car_key == agenda.car_key) {
                        // push itinerary and warehouse
                        agenda.itinerary.forEach(
                          it => {
                            if (it.type == ItineraryType.LOADING) {
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;

                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key 
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                            }
                            else if (it.type == ItineraryType.TRANSSHIPMENT) {
                              // colection warehouse itinerary
                              it.warehouse_in_itinerary = true;
                              it.warehouse_out_itinerary = false;
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              if (it.service_events) {
                                // filter warehouse collection service events
                                it.service_events = it.service_events.filter(
                                  e => e.type == ServiceType.UNLOADING_START || e.type == ServiceType.UNLOADING_END
                                );
                              }

                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key && i.warehouse_in_itinerary
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                              
                              // load info about warehouse events
                              let time_from_date: Date = it.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                on: agenda.order_number_standard
                              };
                              // load info about warehouse events
                              this._subs.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response && response.length) {
                                      // find unloading (naskladneni)
                                      let collection: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.UNLOADING
                                      );
                                      if (collection) {
                                        it.warehouse_in_event = collection;
                                        it.arrival_time = collection.timeDate;
                                      }

                                      // build diary collection
                                      this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                      console.log(this._sortedItinerary);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        );
                      }
                      else if (vehicle.car_key == agenda.car2_key) {
                        // push itinerary and warehouse
                        agenda.itinerary.forEach(
                          it => {
                            if (it.type == ItineraryType.UNLOADING) {
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key 
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }
                            }
                            else if (it.type == ItineraryType.TRANSSHIPMENT) {
                              // colection warehouse itinerary
                              it.warehouse_in_itinerary = false;
                              it.warehouse_out_itinerary = true;
                              it.car_key = vehicle.car_key;
                              it.number_plate = vehicle.number_plate;
                              if (it.service_events) {
                                // filter warehouse delivery service events
                                it.service_events = it.service_events.filter(
                                  e => e.type == ServiceType.LOADING_START || e.type == ServiceType.LOADING_END
                                );
                              }
                              
                              let found = this._sortedItinerary.itineraries.find(
                                i => i.itinerary_key == it.itinerary_key && i.warehouse_out_itinerary
                              );
                              if (found) { found = it; }
                              else { this._sortedItinerary.itineraries.push(it); }

                              // load info about warehouse events
                              let time_from_date: Date = it.arrival_time;
                              // resize tf interval (without that some events could be missing)
                              let sevenDaysLess: Date = new Date(time_from_date.getTime() - 7*24*60*60*1000);
                              let sevenDaysMore: Date = new Date(time_from_date.getTime() + 7*24*60*60*1000);
                              let filterObj: any = {
                                tf: DateTools.toCustomIsoApiFormat(sevenDaysLess),
                                tt: DateTools.toCustomIsoApiFormat(sevenDaysMore),
                                on: agenda.order_number_standard
                              };
                              // load info about warehouse events
                              this._subs.push(
                                this._warehouseServ.getEvents(filterObj).subscribe(
                                  response => {
                                    if (response && response.length) {
                                      // find loading (vyskladneni)
                                      let delivery: WarehouseEvent = response.find(
                                        e => e.type == ItineraryType.LOADING
                                      );
                                      if (delivery) {
                                        it.warehouse_out_event = delivery;
                                        it.arrival_time = delivery.timeDate;
                                      }

                                      // build diary collection
                                      this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
                                      console.log(this._sortedItinerary);
                                    }
                                  }
                                )
                              );
                            }
                          }
                        );
                      }
                    }
                    else {
                      agenda.itinerary.forEach(
                        it => {
                          if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                            it.car_key = vehicle.car_key;
                            it.number_plate = vehicle.number_plate;
                            let found: Itinerary = this._sortedItinerary.itineraries.find(
                              i => i.itinerary_key == it.itinerary_key
                            );
  
                            // standard obligations
                            if (found) {
                              // already exist - update data
                              found = it;
                            }
                            else {
                              // new itinerary - add
                              this._sortedItinerary.itineraries.push(it);
                            }
                          }
                        }
                      );
                    }
                  }
                }
              );

              this._sortedItinerary = new ItineraryDairyDayCollection(this._sortedItinerary.itineraries);
              console.log(this._sortedItinerary);

              // resort and remark
              this._sortedItinerary.sortCollection();
              if (!this._sortByHour) {
                this.itinerary.markByVehicle();
              }
            }
            this._changeDetector.detectChanges();
          }
        }
      ),
      this._webSocketServ.companyMessage.subscribe(
        (wsMessage: WebsocketResponse) => {
          // obligation created -> load vehicle with agenda
          if (wsMessage.relation == WebsocketService.RELATIONS.obligation && wsMessage.operation == WebsocketService.OPERATIONS.insert) {
            if (wsMessage.record.obligation_key) {
              if (wsMessage.record.car_key) {
                this._reloadAgenda = true;
                window.setTimeout(
                  () => {
                    this._vehicleService.reloadVehicleAgenda(wsMessage.record.car_key, wsMessage.record.obligation_key);
                    this._reloadAgenda = false;
                  }, 3000
                );
              }

              // warehouse delivery car
              if (wsMessage.record.car2_key) {
                this._reloadAgenda = true;
                window.setTimeout(
                  () => {
                    this._vehicleService.reloadVehicleAgenda(wsMessage.record.car2_key, wsMessage.record.obligation_key);
                    this._reloadAgenda = false;
                  }, 3000
                );
              }
            }
          }
          // obligation update -> load vehicle with agenda
          else if (wsMessage.relation == WebsocketService.RELATIONS.obligation && wsMessage.operation == WebsocketService.OPERATIONS.update) {
            if (wsMessage.record.obligation_key) {
              console.log(wsMessage.record.obligation_key);

              // find updated obligation/agenda
              let agendas_in_diary: Array<Agenda> = [];
              let agenda_car_key: number = null;
              let agenda_obligation_key: number = null;

              this._vehicles.forEach(
                v => {
                  if (v.agenda) {
                    // filter all agendas (warehouse obligation may be in two cars)
                    let tmp_agenda: Array<Agenda> = v.agenda.filter(
                      a => a.obligation_key == wsMessage.record.obligation_key
                    );
                    if (tmp_agenda.length) {
                      agendas_in_diary = agendas_in_diary.concat(tmp_agenda);
                      agenda_car_key = tmp_agenda[0].car_key;
                      agenda_obligation_key = tmp_agenda[0].obligation_key;
                    }
                  }
                }
              );

              if (agendas_in_diary.length) {
                // agenda has been shown in diary - remove and reload
                // filtering
                agendas_in_diary.forEach(
                  agenda => {
                    agenda.itinerary.forEach(
                      it => {
                        this._sortedItinerary.itineraries = this._sortedItinerary.itineraries.filter(
                          it2 => it2.itinerary_key != it.itinerary_key
                        );
                      }
                    );
                  }
                );

                if (wsMessage.record.status != ObligationStatus.STORNOVANA) {
                  // reloading vehicle
                  this._reloadAgenda = true;
                  window.setTimeout(
                    () => {
                      this._vehicleService.reloadVehicleAgenda(agenda_car_key, agenda_obligation_key);
                      this._reloadAgenda = false;
                    }, 3000
                  );

                  // car_key has been changed - reload vehicle with current agenda car_key
                  if (agenda_car_key != wsMessage.record.car_key) {
                    // reloading vehicle
                    this._reloadAgenda = true;
                    window.setTimeout(
                      () => {
                        this._vehicleService.reloadVehicleAgenda(wsMessage.record.car_key, wsMessage.record.obligation_key);
                        this._reloadAgenda = false;
                      }, 3000
                    );
                  }

                  // reloading vehicle - warehouse delivery part
                  if (wsMessage.record.car2_key && wsMessage.record.car_key != wsMessage.record.car2_key) {
                    this._reloadAgenda = true;
                    window.setTimeout(
                      () => {
                        this._vehicleService.reloadVehicleAgenda(wsMessage.record.car2_key, wsMessage.record.obligation_key);
                        this._reloadAgenda = false;
                      }, 3000
                    );
                  }
                }
              }
              else {
                // agenda has not been shown in diary
                if (wsMessage.record.car_key) {
                  // reloading vehicle
                  this._reloadAgenda = true;
                  window.setTimeout(
                    () => {
                      this._vehicleService.reloadVehicleAgenda(wsMessage.record.car_key, wsMessage.record.obligation_key);
                      this._reloadAgenda = false;
                    }, 3000
                  );
                }

                // reloading vehicle - warehouse delivery part
                if (wsMessage.record.car2_key) {
                  this._reloadAgenda = true;
                  window.setTimeout(
                    () => {
                      this._vehicleService.reloadVehicleAgenda(wsMessage.record.car2_key, wsMessage.record.obligation_key);
                      this._reloadAgenda = false;
                    }, 3000
                  );
                }
              }
            }
          }
          // obligation delete -> load vehicle with agenda
          else if (wsMessage.relation == WebsocketService.RELATIONS.obligation && wsMessage.operation == WebsocketService.OPERATIONS.delete) {
            if (wsMessage.record.obligation_key) {
              // find updated obligation/agenda
              let agendas_in_diary: Array<Agenda> = [];
              let agenda_car_key: number = null;
              let agenda_car2_key: number = null;
              let agenda_obligation_key: number = null;

              this._vehicles.forEach(
                v => {
                  if (v.agenda) {
                    // filter all agendas (warehouse obligation may be in two cars)
                    let tmp_agenda: Array<Agenda> = v.agenda.filter(
                      a => a.obligation_key == wsMessage.record.obligation_key
                    );
                    if (tmp_agenda.length) {
                      agendas_in_diary = agendas_in_diary.concat(tmp_agenda);
                      agenda_car_key = tmp_agenda[0].car_key;
                      agenda_car2_key = tmp_agenda[0].car2_key;
                      agenda_obligation_key = tmp_agenda[0].obligation_key;
                    }
                  }
                }
              );

              if (agendas_in_diary.length) {
                // agenda has been shown in diary - remove and reload
                // filtering
                agendas_in_diary.forEach(
                  agenda => {
                    agenda.itinerary.forEach(
                      it => {
                        this._sortedItinerary.itineraries = this._sortedItinerary.itineraries.filter(
                          it2 => it2.itinerary_key != it.itinerary_key
                        );
                      }
                    );
                  }
                );

                if (agenda_car_key) {
                  // reloading vehicle
                  this._reloadAgenda = true;
                  window.setTimeout(
                    () => {
                      this._vehicleService.reloadVehicleAgenda(agenda_car_key, agenda_obligation_key);
                      this._reloadAgenda = false;
                    }, 3000
                  );
                }
                
                if (agenda_car2_key) {
                  // reloading vehicle
                  this._reloadAgenda = true;
                  window.setTimeout(
                    () => {
                      this._vehicleService.reloadVehicleAgenda(agenda_car2_key, agenda_obligation_key);
                      this._reloadAgenda = false;
                    }, 3000
                  );
                }
              }
              
              // find deleted obligation/agenda
              // let deleted_agenda: Agenda = null;
              // this._vehicles.forEach(
              //   v => {
              //     if (!deleted_agenda && v.agenda) {
              //       deleted_agenda = v.agenda.find(
              //         a => a.obligation_key == wsMessage.record.obligation_key
              //       );
              //     }
              //   }
              // );

              // agenda has been shown in diary - delete and reload
              // if (deleted_agenda) {
              //   // filtering
              //   deleted_agenda.itinerary.forEach(
              //     it => {
              //       this._sortedItinerary.itineraries = this._sortedItinerary.itineraries.filter(
              //         it2 => it2.itinerary_key != it.itinerary_key
              //       );
              //     }
              //   );

              //   // reloading vehicle
              //   this._reloadAgenda = true;
              //   window.setTimeout(
              //     () => {
              //       this._vehicleService.reloadVehicleAgenda(deleted_agenda.car_key, deleted_agenda.obligation_key);
              //       this._reloadAgenda = false;
              //     }, 3000
              //   );
              // }
            }
          }
          // attachment via truckagenda
          else if (wsMessage.relation == WebsocketService.RELATIONS.obligation && wsMessage.operation == WebsocketService.OPERATIONS.update) {
            this.reloadObligation(wsMessage.record.obligation_key);
          }
          // file as attachment of message
          else if (wsMessage.relation == WebsocketService.RELATIONS.message && wsMessage.operation == WebsocketService.OPERATIONS.insert) {
            if (wsMessage.record.attachment && wsMessage.record.attachment != "") {
              // reload vehicles - agenda with new itinerary will be up to date
              this._vehicleService.getFilteredVehiclesCache(true).subscribe();
            }
          }
          // file from scanning app (authored by Tom V.)
          else if (wsMessage.relation == WebsocketService.RELATIONS.obligation_msg && wsMessage.operation == WebsocketService.OPERATIONS.update) {
            this.reloadObligation(wsMessage.record.obligation_key);
          }
        }
      ),
      // check connection
      this._webConn.connectionDown.subscribe(
        notConnected => {
          this._notConnected = notConnected;
        }
      ),
      this._webConn.isConnectionUp().subscribe(
        lastUpTime => {
          // first connection ignore...
          if (!this._firstTimeConnectionUp) {
            this._firstTimeConnectionUp = true;
          }
          else if (this._notConnected) {
            // console.log(lastUpTime);
            // reload vehicles - agenda with new itinerary will be up to date
            this._vehicleService.getFilteredVehiclesCache(true).subscribe();
          }
          
          // this._lastUpTime = lastUpTime;
          this._notConnected = false;
        }
      )
    );
  }

  ngOnDestroy() {
    this._subs.forEach(
      subscriber => subscriber.unsubscribe()
    );
    this._subs = [];
  }

  get loadingObligations(): boolean {
    return this._obligationServ.loadingObligations2;
  }

  get selectedDayItinerary(): any {
    if (this._sortByHour) {
      if (this.isYesterdayVisible) {
        return this.itinerary.byDay.yesterday;
      }
      else if (this.isTodayVisible) {
        return this.itinerary.byDay.today;
      }
      else {
        return this.itinerary.byDay.tomorrow;
      } 
    }
    else {
      if (this.isYesterdayVisible) {
        return this.itinerary.byDayVehicle.yesterday;
      }
      else if (this.isTodayVisible) {
        return this.itinerary.byDayVehicle.today;
      }
      else {
        return this.itinerary.byDayVehicle.tomorrow;
      } 
    }
  }

  moveToPast() {
    if (this.isTomorrowVisible) {
      this._visible = this._visibleToday;
    } 
    else if (this.isTodayVisible) {
      this._visible = this._visibleYesterday;
    }
    this.hideMapDetail();
    this.loadObligationsFilesEmails();
  }

  moveToFuture() {
    if (this.isYesterdayVisible) {
      this._visible = this._visibleToday;
    } 
    else if (this.isTodayVisible) {
      this._visible = this._visibleTomorrow;
    }
    this.hideMapDetail();
    this.loadObligationsFilesEmails();
  }

  isCurrentHour(hour: number): boolean {
    let now = new Date();
    return now.getHours() === hour;
  }


  /**********************************************************************/
  /* Details opening */
  /**********************************************************************/
  openCurrentPositionDetail(itinerary: Itinerary) {
    // close possible previous
    this._itineraryAgendaDetail = null;
    this._itineraryMessages = null;
    // close
    if (this.currentPositionDetailItinerary === itinerary) {
      this._currentPositionDetailItinerary.manualTrackingMode = false;
      this._currentPositionDetailItinerary = null;
    } 
    // open
    else {
      if (this._currentPositionDetailItinerary)
        this._currentPositionDetailItinerary.manualTrackingMode = false;
      this._currentPositionDetailItinerary = itinerary;
    }
  }

  openItineraryAgendaDetail(itinerary: Itinerary) {
    // close possible previous
    this._itineraryMessages = null;
    this._currentPositionDetailItinerary = null;
    // close
    if (this.itineraryAgendaDetail === itinerary) {
      this._itineraryAgendaDetail = null;
    } 
    // open
    else {
      this._itineraryAgendaDetail = itinerary;
    }
  }

  openMessages(itinerary: Itinerary) {
    // close possible previous
    this._itineraryAgendaDetail = null;
    this._currentPositionDetailItinerary = null;
    // close
    if (this._itineraryMessages == itinerary) {
      this._itineraryMessages = null;
    }
    // open
    else {
      this._itineraryMessages = itinerary;
      
      this._itineraryMessagePatterns = this._messagesPatterns.slice();
      this._itineraryMessagePatterns.forEach(
        (pattern, idx) => {
          if (itinerary && itinerary.agenda) {
            // replace obligation order_number
            this._itineraryMessagePatterns[idx] = pattern.replace(
              '<ORDER_NUMBER_STANDARD>', itinerary.agenda.order_number_standard
            );
          }
        }
      );
    }
  }

  // universal method for hiding map/agenda/messages
  private hideMapDetail() {
    if (this._currentPositionDetailItinerary) {
      this.openCurrentPositionDetail(this._currentPositionDetailItinerary);
    }
    if (this._itineraryAgendaDetail) {
      this.openItineraryAgendaDetail(this._itineraryAgendaDetail);
    }
    if (this._itineraryMessages) {
      this.openMessages(this._itineraryMessages);
    }
  }

  // handler for event from r-vehicle-manual-tracking-itinerary component
  hideMapEvent(ev: boolean) {
    if (ev == true) {
      this._currentPositionDetailItinerary = null;
    }
  }

  // method for determinng if itinerary is the same as destination route target
  isTargetForItinerary(itinerary: Itinerary): boolean {
    if (itinerary && itinerary.gps_coord && itinerary.agenda && itinerary.agenda.vehicle) {
      if (itinerary.agenda.vehicle.temporaryManualTrackingTarget) {
        let dist = Haversine.haversine(
          {
            latitude: itinerary.agenda.vehicle.temporaryManualTrackingTarget.lat,
            longitude: itinerary.agenda.vehicle.temporaryManualTrackingTarget.lng
          },
          {
            latitude: itinerary.gps_coord.googleLatLang.lat,
            longitude: itinerary.gps_coord.googleLatLang.lng
          }
        );
        // distance closer than 1 km
        if (dist < 1) {
          return true;
        }
      }
    }
    return false;
  }


  /***********************************************************/
  /* Necessary files and loading management methods */
  /***********************************************************/
  private loadObligationsFilesEmails(): void {
    if (this.isTodayVisible) {
      if (this.itinerary.byDay.today) {
        let tmp_keys: Array<number> = []; 
        this.itinerary.byDay.today.forEach(
          hourGroup => {
            if (hourGroup.itineraries) {
              hourGroup.itineraries.forEach(
                it => {
                  if (it.type == ItineraryType.UNLOADING && it.agenda && !tmp_keys.includes(it.agenda.obligation_key)) {
                    // save obligation_key to not be loaded again
                    tmp_keys.push(it.agenda.obligation_key);
                    // set flag of downloading obligation files
                    it.agenda.loadingFiles = true;
                    // download obligation FULL with files - TO BE OPTIMIZED
                    this.initFilesToAgenda(it.agenda.obligation_key);
                    this._subs.push(
                      this._obligationServ.getEmails(it.agenda.obligation_key).subscribe(
                        response => {
                          if (response && response.length) {
                            it.agenda.email_headers = response;
                          }
                        }
                      )
                    );
                  }
                }
              )
            }
          }
        );
      }
    }
    else if (this.isYesterdayVisible) {
      if (this.itinerary.byDay.yesterday) {
        let tmp_keys: Array<number> = []; 
        this.itinerary.byDay.yesterday.forEach(
          hourGroup => {
            if (hourGroup.itineraries) {
              hourGroup.itineraries.forEach(
                it => {
                  if (it.type == ItineraryType.UNLOADING && it.agenda && !tmp_keys.includes(it.agenda.obligation_key)) {
                    // save obligation_key to not be loaded again
                    tmp_keys.push(it.agenda.obligation_key);
                    // download obligation FULL with files - TO BE OPTIMIZED
                    this.initFilesToAgenda(it.agenda.obligation_key);
                    // download obligation emails
                    this._subs.push(
                      this._obligationServ.getEmails(it.agenda.obligation_key).subscribe(
                        response => {
                          if (response && response.length) {
                            it.agenda.email_headers = response;
                          }
                        }
                      )
                    );
                  }
                }
              )
            }
          }
        );
      }
    }
    else if (this.isTomorrowVisible) {
      if (this.itinerary.byDay.tomorrow) {
        let tmp_keys: Array<number> = []; 
        this.itinerary.byDay.tomorrow.forEach(
          hourGroup => {
            if (hourGroup.itineraries) {
              hourGroup.itineraries.forEach(
                it => {
                  if (it.type == ItineraryType.UNLOADING && it.agenda && !tmp_keys.includes(it.agenda.obligation_key)) {
                    // save obligation_key to not be loaded again
                    tmp_keys.push(it.agenda.obligation_key);
                    // download obligation FULL with files - TO BE OPTIMIZED
                    this.initFilesToAgenda(it.agenda.obligation_key);
                    // download obligation emails
                    this._subs.push(
                      this._obligationServ.getEmails(it.agenda.obligation_key).subscribe(
                        response => {
                          if (response && response.length) {
                            it.agenda.email_headers = response;
                          }
                        }
                      )
                    );
                  }
                }
              )
            }
          }
        );
      }
    }
  }

  // private loadObligations(): void {
  //   this._sortedItinerary.obligation_keys.forEach(
  //     key => {
  //       this.initFilesToAgenda(key);
  //     }
  //   );
  // }

  // method for reloading obligation (using after new file/attachment/update)
  reloadObligation(obligation_key: number): void {
    window.setTimeout(
      () => {
        let index: number = this._sortedItinerary.obligation_keys.findIndex(
          key => key == obligation_key
        );
        if (index > -1) {
          this.initFilesToAgenda(obligation_key);
        }
      },
      1000
    );
  }

  // method for initializing files array of agenda
  initFilesToAgenda(obligation_key: number): void {
    this._subs.push(
      // download just light version of obligation - with files + attachments arrays
      this._obligationServ.getObligationFiles(obligation_key).subscribe(
        obligation => {
          if (obligation) {
            // save files to agenda 
            let agenda: Agenda = this.getAgenda(obligation_key);

            // build files array
            if (obligation.files && obligation.files.length) {
              let filesArr = new Array<Attachment>();
              obligation.files.forEach(
                f => {
                  let file = new Attachment();
                  for (let key in f) {
                    file[key] = f[key];
                  }
                  filesArr.push(file);
                }
              );

              // remove demo files (starting with prefix out-qqq)
              filesArr = filesArr.filter(f => f.name && !f.name.includes('out-qqq'));

              if (agenda) {
                agenda.files = filesArr;
              }
            }
            
            // unset flag of downloading obligation files
            if (agenda) {
              agenda.loadingFiles = false;
            }
          }
        }
      )
      // OBSOLETE - loading full obligation objects
      // this._obligationServ.getObligationToComponent(obligation_key).subscribe(
      //   o => {
      //     if (o) {
      //       // save files to agenda 
      //       let agenda: Agenda = this.getAgenda(obligation_key);

      //       // build files array
      //       if (o.files && o.files.length) {
      //         let filesArr = new Array<Attachment>();
      //         o.files.forEach(
      //           f => {
      //             let file = new Attachment();
      //             for (let key in f) {
      //               file[key] = f[key];
      //             }
      //             filesArr.push(file);
      //           }
      //         );

      //         // remove demo files (starting with prefix out-qqq)
      //         filesArr = filesArr.filter(f => f.name && !f.name.includes('out-qqq'));

      //         if (agenda) {
      //           agenda.files = filesArr;
      //         }
      //       }
            
      //       // unset flag of downloading obligation files
      //       if (agenda) {
      //         agenda.loadingFiles = false;
      //       }
      //     }
      //   }
      // )
    );
  }

  // method for getting agenda object according to obligation_key
  getAgenda(obligation_key: number) {
    let agenda: Agenda = null;
    for (let i = 0; i < this._vehicles.length; i++) {
      let v: Vehicle = this._vehicles[i];
      agenda = v.agenda.find(a => a.obligation_key == obligation_key);
      if (agenda) break;
    }
    return agenda;
  }

  // method for customizing width of popover div
  widthPopoverOfFiles(agenda: Agenda): string {
    let len: number = agenda.files.length;
    if (len < 2) return '140px';
    else if (len < 3) return '280px';
    else if (len < 4) return '420px';
    else return '550px';
  }

  // download all thumbnails of files
  downloadThumbnails(agenda: Agenda): void {
    if (agenda.files) {
      agenda.files.forEach(
        f => {
          this._obligationServ.downloadThumbnail(f);
        }
      );
    }
  }
  
  // flag for detecting if file/attachment is currently downloading
  public get downloadingFile(): boolean {
    return this._obligationServ.downloadingAttachment;
  }

  // method for updating attributes of specified obligation
  downloadAttachment(attachment: Attachment): void {
    this._obligationServ.openAttachmentNewTab(attachment);
  }


  /************************************************************/
  /* Methods for sending email of obligation */
  /************************************************************/
  @ViewChild('myEmailingChild', {static: false}) private _myEmailingChild: Ta1ObligationEmailingComponent;

  private _agendaToSend: Agenda = null;
  public get agendaToSend(): Agenda {
    return this._agendaToSend;
  }
  public set agendaToSend(value: Agenda) {
    this._agendaToSend = value;
    if (this._agendaToSend) {
      if (this._agendaToSend.edoc_url && this._agendaToSend.order_comp_book.edoc_required_documents) {
        window.open(this._agendaToSend.edoc_url, '_blank');
      }
      else {
        (<any>$('#sendAttachmentsModal')).modal('show');
      }
    }

    // init also attachments for emailing
    this.initAttachments();
    // reinit child component
    if (this._myEmailingChild) {
      this._myEmailingChild.reinitComponent();
    }
  }

  private _attachmentsToSend: Array<Attachment> = [];
  public get attachmentsToSend(): Array<Attachment> {
    return this._attachmentsToSend;
  }

  /* Thumbnails part */
  initAttachments(): void {
    this._attachmentsToSend = [];
    if (this._agendaToSend.files) {
      this._attachmentsToSend = this._agendaToSend.files;
    }

    this._attachmentsToSend.forEach(
      att => {
        this._obligationServ.downloadThumbnail(att);
      }
    );
  }

  // method for loading detail html page about email sent
  showEmailDetail(email_msg_id: string): void {
    this._obligationServ.getEmailShow(email_msg_id).subscribe(
      response => {
        if (response) {
          // using this solution now: https://stackoverflow.com/a/10573430
          let newWindow = window.open();
          newWindow.document.write(response);
        }
      }
    );
  }

  // output event binding method
  eventAfterEmailSent(event) {
    if (event) {
      // init if any email has not been sent yet
      if (!this._agendaToSend.email_headers) this._agendaToSend.email_headers = [];
      
      this._subs.push(
        this._obligationServ.getEmailHeader(event).subscribe(
          (header: Email) => {
            if (header) {
              header.email_msg_id = event;
              this._agendaToSend.email_headers.push(header);
            }
          }
        )
      );
    }
  }
  
  openSubsNewTab() {
    let url: any = null;
    let queryParams: any = {
      reloadPossible: true
    }
    url = this._router.serializeUrl(
      this._router.createUrlTree([
        {outlets: {left: 'general/subscription', right: 'itinerary-dairy'}}], 
        {queryParams: queryParams}
      )
    );
    this._router.navigateByUrl(url);
  }

  
  /************************************************************/
  /* Methods for creating service event without TM */
  /************************************************************/
  public newServiceEvent: ServiceEventObject = new ServiceEventObject();

  private _itineraryToEvent: Itinerary = null;
  public get itineraryToEvent(): Itinerary {
    return this._itineraryToEvent;
  }
  public set itineraryToEvent(value: Itinerary) {
    this._itineraryToEvent = value;
    if (this._itineraryToEvent) {
      this.newServiceEvent = new ServiceEventObject();
      if (this._itineraryToEvent.agenda) {
        this.newServiceEvent.order_number = this._itineraryToEvent.agenda.order_number_standard;
        this.newServiceEvent.car_key = this._itineraryToEvent.agenda.car_key;
      }
      this.newServiceEvent.itinerary_key = this._itineraryToEvent.itinerary_key; 
      this.newServiceEvent.time_custom = this._itineraryToEvent.arrival_time_custom;
      this.newServiceEvent.type = this._itineraryToEvent.type;
      this.newServiceEvent.weight = this._itineraryToEvent.weight ? this._itineraryToEvent.weight : 0;
      this.newServiceEvent.length = this._itineraryToEvent.length ? this._itineraryToEvent.length : 0;
      this.newServiceEvent.containers_in = 0;
      this.newServiceEvent.containers_out = 0;
      this.newServiceEvent.descr = '';
    }
  }

  createNewServiceEvent(): void {
    if (this._itineraryToEvent.type == ItineraryType.WAREHOUSE || this._itineraryToEvent.type == ItineraryType.WAREHOUSE_AUTO) {
      // creating warehouse event + service event
      // let wevent: WarehouseEvent = new WarehouseEvent();
      // wevent.ware_pcs = this._itineraryToEvent.ware_pcs;
      // wevent.ware_type = this._itineraryToEvent.ware_type;
      // wevent.weight = this._itineraryToEvent.weight;
      // wevent.order_number = this._itineraryToEvent.agenda.order_number_standard;
      // if (this._itineraryToEvent.car_key) {
      //   wevent.car_key = this._itineraryToEvent.car_key;
      // }
      // wevent.pos_gps = this._itineraryToEvent.gps_coord.gps_coord;
      // wevent.timeDate = new Date();
      // wevent.timeInput = DateTools.isoForDatetimeInput(wevent.timeDate);

      if (this._itineraryToEvent.warehouse_in_itinerary) {
        // warehouse collection -> unloading
        // wevent.type = ItineraryType.UNLOADING;

        this.newServiceEvent.type = ItineraryType.UNLOADING;
        // service event of loading/unloading
        this._tmAgendaServ.createServiceEvent(this.newServiceEvent).subscribe(
          ev => {
            if (ev) {
              console.log(ev);
              // send also message to vehicle to dispose order
              if (this.newServiceEvent && this.newServiceEvent.type == ServiceType.UNLOADING_END && this.newServiceEvent.car_key && this.newServiceEvent.order_number) {
                let msg: string = 'Dispečer dokončil položku ze zakázky ' + this.newServiceEvent.order_number; 
                msg += '<#ord:' + this.newServiceEvent.order_number + '|||D>';
                this._subs.push(
                  this._messageServ.sendMessageToVehicle2(msg, this.newServiceEvent.car_key).subscribe(
                    message => {
                      console.log(message);
                    },
                    error => {
                      console.log(error);
                    }
                  )
                );
              }
              // this._itineraryToEvent.service_events.push(ev);
            }
          }
        );
      }
      else {
        // warehouse delivery -> loading
        // wevent.type = ItineraryType.LOADING;
        
        this.newServiceEvent.type = ItineraryType.LOADING;
        // service event of loading/unloading
        this._tmAgendaServ.createServiceEvent(this.newServiceEvent).subscribe(
          ev => {
            if (ev) {
              console.log(ev);
              // send also message to vehicle to dispose order
              if (this.newServiceEvent && this.newServiceEvent.type == ServiceType.UNLOADING_END && this.newServiceEvent.car_key && this.newServiceEvent.order_number) {
                let msg: string = 'Dispečer dokončil položku ze zakázky ' + this.newServiceEvent.order_number; 
                msg += ' <#ord:' + this.newServiceEvent.order_number + '|||D>';
                this._subs.push(
                  this._messageServ.sendMessageToVehicle2(msg, this.newServiceEvent.car_key).subscribe(
                    message => {
                      console.log(message);
                    },
                    error => {
                      console.log(error);
                    }
                  )
                );
              }
              // this._itineraryToEvent.service_events.push(ev);
            }
          }
        );
      }

      // this._subs.push(
      //   this._warehouseServ.createEvent(wevent).subscribe()
      // );
    }
    else {
      // standard service event of loading/unloading
      this._tmAgendaServ.createServiceEvent(this.newServiceEvent).subscribe(
        ev => {
          if (ev) {
            console.log(ev);
            // send also message to vehicle to dispose order
              if (this.newServiceEvent && this.newServiceEvent.type == ServiceType.UNLOADING_END && this.newServiceEvent.car_key && this.newServiceEvent.order_number) {
              let msg: string = 'Dispečer dokončil položku ze zakázky ' + this.newServiceEvent.order_number; 
              msg += ' <#ord:' + this.newServiceEvent.order_number + '|||D>';
              this._subs.push(
                this._messageServ.sendMessageToVehicle2(msg, this.newServiceEvent.car_key).subscribe(
                  message => {
                    console.log(message);
                  },
                  error => {
                    console.log(error);
                  }
                )
              );
            }
            // this._itineraryToEvent.service_events.push(ev);
          }
        }
      );
    }

    (<any>$('#agendaServiceModal')).modal('hide');
  }

  
  /************************************************************/
  /* Methods for sending report to VISCOFAN */
  /************************************************************/
  public agendaToViscofan: Agenda = null;
  public viscofanMessage: string = '';
  public viscofanReportType: string = null;

  public readonly VISCOFAN_REPORT_TYPES: Array<string> = [
    'VehicleFault', 'Accident', 'TrafficComplication', 'DelayedLoading', 'HumanError'
  ];

  sendReportToViscofan(): void {
    let data: any = {
      action: 'incident',
      type: this.viscofanReportType,
      msg: this.viscofanMessage
    };

    this._subs.push(
      this._obligationServ.reportObligationToViscofan(
        this.agendaToViscofan.obligation_key, data
      ).subscribe(
        response => {
          console.log(response);
        }
      )
    );
  }
}
