import { GoogleLatLng } from "./google-lat-lng.object";
import { Vehicle } from "./vehicle.object";

declare var google: any;

export class GoogleMapAdvMarker {

  private _googleMarker: any;
  private events: Array<any> = [];

  private static _icons = {
    l: {
      url: "assets/icon/icon-car-state/loading.svg",
      size: '32px'
    },
    L: {
      url: "assets/icon/icon-car-state/loading.svg",
      size: '32px'
    },
    u: {
      url: "assets/icon/icon-car-state/unloading.svg",
      size: '32px'
    },
    U: {
      url: "assets/icon/icon-car-state/unloading.svg",
      size: '32px'
    },
    X: {
      url: "assets/icon/icon-car-state/interruption.svg",  // interruption vs. transit!
      size: '25px'
    },
    M_left: {
      url: "assets/icon/icon-car-state/load.svg",
      size: '25px'
    },
    M: {
      url: "assets/icon/icon-car-state/load.svg",
      size: '25px'
    },
    m: {
      url: "assets/icon/icon-car-state/unload.svg",
      size: '25px'
    },
    m_left: {
      url: "assets/icon/icon-car-state/unload.svg",
      size: '25px'
    },
    M_right: {
      url: "assets/icon/icon-car-state/load_right.svg",
      size: '25px'
    },
    m_right: {
      url: "assets/icon/icon-car-state/unload_right.svg",
      size: '25px'
    },
    '*': { //start
      url: "assets/icon/icon-car-state/sleep.svg",
      size: '25px'
    },
    '#': { //end
      url: "assets/icon/icon-car-state/sleep.svg",
      size: '25px'
    },
    F: {
      url: "assets/icon/icon-car-state/ferry.svg", // ferry vs. fuel!
      size: '25px'
    },
    _: {
      url: "assets/icon/icon-car-state/stop.svg",
      size: '25px'
    },
    '=': {
      url: "assets/icon/icon-car-state/stop.svg",
      size: '25px'
    },
    A: {
      url: "assets/icon/icon-car-state/loading.svg",
      size: '32px'
    },
    B: {
      url: "assets/icon/icon-car-state/unloading.svg",
      size: '32px'
    },
    C: {
      url: "assets/icon/icon-car-state/start.svg",
      size: '32px'
    },
    D: {
      url: "assets/icon/icon-car-state/start.svg",
      size: '32px'
    },
    E: {
      url: "assets/icon/icon-car-state/unloading.svg",
      size: '32px'
    },
    W: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      size: '32px'
    },
    V: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      size: '32px'
    },
    T: {
      url: "assets/icon/icon-car-state/forklift.svg",
      size: '32px'
    },
    Z: {
      url: "assets/icon/icon-car-state/zolldouane.svg",
      size: '32px'
    },
    serviceEvent_F: {
      url: "assets/icon/icon-car-state/fuel-new.svg",
      size: '32px'
    },
    serviceEvent_X: {
      url: "assets/icon/icon-car-state/transit.svg",
      size: '32px'
    },
    serviceEvent_M: {
      url: "assets/icon/icon-car-state/unload.svg",
      size: '32px'
    },
    serviceEvent_L: {
      url: "assets/icon/icon-car-state/loading.svg",
      size: '32px'
    },
    serviceEvent_U: {
      url: "assets/icon/icon-car-state/unloading.svg",
      size: '32px'
    },
    serviceEvent_l: {
      url: "assets/icon/icon-car-state/loading.svg",
      size: '32px'
    },
    serviceEvent_u: {
      url: "assets/icon/icon-car-state/unloading.svg",
      size: '32px'
    },
    serviceEvent_W: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      size: '32px'
    },
    serviceEvent_V: {
      url: "assets/icon/icon-car-state/warehouse1.svg",
      size: '32px'
    },
    serviceEvent_T: {
      url: "assets/icon/icon-car-state/forklift.svg", // using service event for itinerary.type = TRANSSHIPMENT
      size: '32px'
    },
    serviceEvent_Z: {
      url: "assets/icon/icon-car-state/zolldouane.svg", // using service event for itinerary.type = CUSTOMS
      size: '32px'
    },
    grayscale_L: {
      url: "assets/icon/icon-car-state/loading-grayscale.svg",
      size: '32px'
    },
    grayscale_U: {
      url: "assets/icon/icon-car-state/unloading-grayscale.svg",
      size: '32px'
    },
    grayscale_F: {
      url: "assets/icon/icon-car-state/fuel-grayscale.svg",
      size: '32px'
    },
    grayscale_W: {
      url: "assets/icon/icon-car-state/warehouse-grayscale.svg",
      size: '32px'
    },
    grayscale_V: {
      url: "assets/icon/icon-car-state/warehouse-grayscale.svg",
      size: '32px'
    },
    grayscale_X: {
      url: "assets/icon/icon-car-state/transit-grayscale.svg",
      size: '32px'
    },
    grayscale_T: {
      url: "assets/icon/icon-car-state/forklift-grayscale.svg",
      size: '32px'
    },
    grayscale_Z: {
      url: "assets/icon/icon-car-state/zolldouane.svg",
      size: '32px'
    },
    sm_F: {
      url: "assets/icon/icon-car-state/fuel-new-sm.svg",
      size: '24px'
    },
    sm_L: {
      url: "assets/icon/icon-car-state/loading-sm.svg",
      size: '24px'
    },
    sm_U: {
      url: "assets/icon/icon-car-state/unloading-sm.svg",
      size: '24px'
    },
    sm_X: {
      url: "assets/icon/icon-car-state/transit-sm.svg",
      size: '24px'
    },
    sm_W: {
      url: "assets/icon/icon-car-state/warehouse1-sm.svg",
      size: '24px'
    },
    sm_V: {
      url: "assets/icon/icon-car-state/warehouse1-sm.svg",
      size: '24px'
    },
    sm_T: {
      url: "assets/icon/icon-car-state/forklift-sm.svg",
      size: '24px'
    },
    sm_Z: {
      url: "assets/icon/icon-car-state/transit-sm.svg",
      size: '24px'
    },
    grayscale_sm_L: {
      url: "assets/icon/icon-car-state/loading-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_U: {
      url: "assets/icon/icon-car-state/unloading-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_F: {
      url: "assets/icon/icon-car-state/fuel-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_X: {
      url: "assets/icon/icon-car-state/transit-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_W: {
      url: "assets/icon/icon-car-state/warehouse-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_V: {
      url: "assets/icon/icon-car-state/warehouse-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_T: {
      url: "assets/icon/icon-car-state/forklift-grayscale-sm.svg",
      size: '24px'
    },
    grayscale_sm_Z: {
      url: "assets/icon/icon-car-state/transit-grayscale-sm.svg",
      size: '24px'
    },
  };

  static get icons(): any {
    return this._icons
  }

  private _anchorPoint: any;
  get anchorPoint(): any {
    return this.getGoogleMarker().getAnchorPoint();
  }
  set anchorPoint(value: any) {
    this.getGoogleMarker().setAnchorPoint(value)
  }

  // The offset from the marker's position to the tip of an InfoWindow that has been opened with the marker as anchor.
  get animation(): any {
    return this.getGoogleMarker().getAnimation();
  }
  set animation(value: any) {
    this.getGoogleMarker().setAnimation(value)
  }

  //Which animation to play when marker is added to a map.
  private _attribution: any;
  get attribution(): any {
    return this.getGoogleMarker().getAttribution();
  }
  set attribution(value: any) {
    this.getGoogleMarker().setAttribution(value)
  }

  // Contains all the information needed to identify your application as the source of a save.
  get clickable(): boolean {
    return this.getGoogleMarker().getClickable();
  }
  set clickable(value: boolean) {
    this.getGoogleMarker().setClickable(value)
  }

  // If true, the marker receives mouse and touch events. Default value is true.
  get crossOnDrag(): boolean {
    return this.getGoogleMarker().getCrossOnDrag();
  }
  set crossOnDrag(value: boolean) {
    this.getGoogleMarker().setCrossOnDrag(value)
  }

  // If false, disables cross that appears beneath the marker when dragging. This option is true by default.
  get cursor(): string {
    return this.getGoogleMarker().getCursor();
  }
  set cursor(value: string) {
    this.getGoogleMarker().setCursor(value)
  }

  // Mouse cursor to show on hover
  get draggable(): boolean {
    // return this.getGoogleMarker().getDraggable();
    return this.getGoogleMarker().gmpDraggable;
  }
  set draggable(value: boolean) {
    // this.getGoogleMarker().setDraggable(value)
    this.getGoogleMarker().gmpDraggable = value;
  }

  // If true, the marker can be dragged. Default value is false.
  /*private _icon: string;//|Icon|Symbol;
  get icon(): string {
    return this.getGoogleMarker().getIcon();
  }
  set icon(value: string) {
    this.getGoogleMarker().setIcon(value)
  }*/
  private _icon: string; // Store the icon string
  get icon(): string {
    return this._icon;
  }
  set icon(value: string) {
    this._icon = value;
    const googleMarker = this.getGoogleMarker();
    if (googleMarker) { 
      // Update the marker's content
      googleMarker.content = this.createIconElement(value);
    }
  }
  
  // Helper method to create an HTML element for the icon
  private createIconElement(icon: any): HTMLElement {
    const img = document.createElement('img');
    img.src = (icon && icon.url) ? icon.url : icon;
    img.style.width = '32px';
    img.style.height = '32px';
    return img;
  }

  // Icon for the foreground. If a string is provided, it is treated as though it were an Icon with the string as url.
  private _label: string;//|MarkerLabel;
  get label(): string {
    return this.getGoogleMarker().getLabel();
  }
  set label(value: string) {
    this.getGoogleMarker().setLabel(value)
  }

  // Adds a label to the marker. The label can either be a string, or a MarkerLabel object. Only the first character of the string will be displayed.
  private _map: any;//Map|StreetViewPanorama;
  get map(): any {
    return this.getGoogleMarker().getMap();
  }
  set map(value: any) {
    this.getGoogleMarker().setMap(value);
  }

  // Map on which to display Marker.
  get opacity(): number {
    return this.getGoogleMarker().getOpacity();
  }
  set opacity(value: number) {
    this.getGoogleMarker().setOpacity(value)
  }

  // The marker's opacity between 0.0 and 1.0.
  get optimized(): boolean {
    return this.getGoogleMarker().getOptimized();
  }
  set optimized(value: boolean) {
    this.getGoogleMarker().setOptimized(value)
  }

  // Optimization renders many markers as a single static element.
  get place(): any {
    return this.getGoogleMarker().getPlace();
  }
  set place(value: any) {
    this.getGoogleMarker().setPlace(value)
  }

  set position(value: any) {
    let lat, lng: number;
    if (!value) return;

    if (value instanceof GoogleLatLng) {
      lat = value.lat;
      lng = value.lng;
    } 
    else {
      if (value.lat instanceof Function) {
        lat = value.lat();
      } 
      else {
        lat = value.lat;
      }

      if (value.lng instanceof Function) {
        lng = value.lng();
      } 
      else {
        lng = value.lng;
      }
    }
    // this.getGoogleMarker().setPosition(new google.maps.LatLng(lat, lng));
    this.getGoogleMarker().position = new google.maps.LatLng(lat, lng);
  }
  get position(): any {  // google.maps.LatLngLiteral | google.maps.LatLng
    return this.getGoogleMarker().position;
  }

  get latitude(): number {
    const position = this.position;
    return typeof position.lat === "function" ? position.lat() : position.lat;
  }

  get longitude(): number {
    const position = this.position;
    return typeof position.lng === "function" ? position.lng() : position.lng;
  }

  // Marker position. Required.
  get shape(): any {
    return this.getGoogleMarker().getShape();
  }
  set shape(value: any) {
    this.getGoogleMarker().setShape(value)
  }

  // Image map region definition used for drag/click.
  get title(): string {
    return this.getGoogleMarker().title;
  }
  set title(value: string) {
    this.getGoogleMarker().title = value;
  }

  // Rollover text
  get visible(): boolean {
    return this.getGoogleMarker().getVisible();
  }
  set visible(value: boolean) {
    this.getGoogleMarker().setVisible(value)
  }

  // If true, the marker is visible
  get zIndex(): number {
    return this.getGoogleMarker().zIndex;
  }
  set zIndex(value: number) {
    this.getGoogleMarker().zIndex = value;
  }

  // All markers are displayed on the map in order of their zIndex, with higher values displaying in front of markers with lower values. By default, markers are displayed according to their vertical position on screen, with lower markers appearing in front of markers further up the screen.
  private _iwText: string;
  get iwText() {
    return this._map.getIwText();
  }
  set iwText(value: string) {
    this._iwText = value;
  }

  // This will be rendered as infowindow for this marker on map
  private _infoWindowContent;
  get infoWindowContent() {
    return this._infoWindowContent;
  }
  set infoWindowContent(value) {
    this._infoWindowContent = value;
  }

  private _infoWindow;
  get infoWindow() {
    return this._infoWindow;
  }
  set infoWindow(value) {
    this._infoWindow = value;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }
  
  // flag if itinerary marker has been already drawn
  private _itinerary_key: number;
  public get itinerary_key(): number {
    return this._itinerary_key;
  }
  public set itinerary_key(value: number) {
    this._itinerary_key = value;
  }

  private _data: any = {};
  get data(): any {
    return this._data;
  }

  private _autoInfoWindowOpen: boolean = false;
  get autoInfoWindowOpen(): boolean {
    return this._autoInfoWindowOpen;
  }
  set autoInfoWindowOpen(value: boolean) {
    this._autoInfoWindowOpen = value;
  }

  setData(key: string, value: any) {
    this._data[key] = value;
  }

  getData(key: string): any {
    return this._data[key];
  }

  public getGoogleMarker(): any {
    /*
    if (!this._googleMarker) {
      // this._googleMarker = new google.maps.Marker();
      this._googleMarker = new google.maps.marker.AdvancedMarkerElement();
    }
    return this._googleMarker;
    */
    if (!this._googleMarker) {
      this._googleMarker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: 0, lng: 0 }, // Set default position if needed
        content: this._icon ? this.createIconElement(this._icon) : undefined, // Handle content creation for icon
      });
    }
    return this._googleMarker;
  }

  addListener(any, any2) {
    this.events.push(this.getGoogleMarker().addListener(any, any2));
  }

  clearEvents(): void {
    this.events.forEach(
      event => {
        if (event) event.remove()
      }
    );
  }

  // flag for marker, if it has been clicked
  private _isActive: boolean = false;
  public get isActive(): boolean {
    return this._isActive;
  }
  public set isActive(value: boolean) {
    this._isActive = value;
  }
  
  // flag for marker, that is part of current obligation itinerary
  private _isCurrentObligation: boolean = false;
  public get isCurrentObligation(): boolean {
    return this._isCurrentObligation;
  }
  public set isCurrentObligation(value: boolean) {
    this._isCurrentObligation = value;
  }

  // e.g. number of itinerary (could be displayed in info window)
  private _number: number = null;
  public get number(): number {
    return this._number;
  }
  public set number(value: number) {
    this._number = value;
  }
  // hiperling for timocom 
  private _hipperling: string;
  public get hipperling(): string {
    return this._hipperling;
  }
  public set hipperling(value: string) {
    this._hipperling = value;
  }

  private _tonage: string;
  public get tonage(): string {
    return this._tonage;
  }
  public set tonage(value: string) {
    this._tonage = value;
  }
}
