import { TrackingEventDataInterface } from "../interface/tracking-event-data.interface";
import { DateTools } from "../tools/DateTools";
import { GeoPosition } from "./geo-position.object";
import { GoogleMapAdvMarker } from "./google-map-adv-marker.object";
// import {GoogleMapMarker} from "./google-map-marker.object";

export class TrackingEventDataObject implements TrackingEventDataInterface {

  readonly pos_key: number;
  readonly car_key: number;
  readonly driver_key: number;
  readonly driver2_key: number;
  readonly actual_pos: boolean;
  readonly time: string;
  readonly cargo_status: string;
  readonly truck_status: string;
  readonly pos_gps: string;
  readonly distance: number;//km
  readonly speed: number;
  readonly cargo_weight: number;
  readonly accelerate_mark: number;
  readonly braking_mark: number;
  readonly speeding_mark: number;
  readonly settled_mark: number;
  readonly asc_meter: number;
  readonly desc_meter: number;
  readonly speed_accum: number;
  readonly max_speed_accum: number;
  readonly secs_braking: number;
  readonly driving_time: number;
  readonly city_key: string;
  readonly city: string;

  constructor(data: TrackingEventDataInterface) {
    for (let key in data) {
      this[key] = data[key];
    }
    this._date = DateTools.isoFix(this.time);
    this._geoPos = new GeoPosition();
    this._geoPos.pos_gps = this.pos_gps;
  }

  private _date: Date;
  get date(): Date {
    return this._date;
  }

  private _geoPos;
  get geoPos(): GeoPosition {
    return this._geoPos;
  }

  public static create(data: TrackingEventDataInterface): TrackingEventDataObject {
    return new TrackingEventDataObject(data);
  }

  toMarker(icon): GoogleMapAdvMarker {
    let marker: GoogleMapAdvMarker = new GoogleMapAdvMarker();
    if (icon) {
      marker.icon = GoogleMapAdvMarker.icons[icon];
    }
    marker.position = this.geoPos.googleLatLang.googleLtLn;
    return marker;
  }

}