import { DateTools } from '../tools/DateTools';
import { GeoPosition } from './geo-position.object';
import { GoogleMapAdvMarker } from './google-map-adv-marker.object';
// import { GoogleMapMarker } from './google-map-marker.object';

export class TrackingEvent {
  public readonly isTrackingEvent: boolean = true;
  
  private _pos_key: number;
  get pos_key(): number {
    return this._pos_key;
  }
  set pos_key(value: number) {
    this._pos_key = value;
  }

  private _car_key: number;
  get car_key(): number {
    return this._car_key;
  }
  set car_key(value: number) {
    this._car_key = value;
  }

  private _driver_key: number;
  get driver_key(): number {
    return this._driver_key;
  }
  set driver_key(value: number) {
    this._driver_key = value;
  }

  // custom
  private _driver_name: string = null;
  public get driver_name(): string {
    return this._driver_name;
  }
  public set driver_name(value: string) {
    this._driver_name = value;
  }

  private _driver2_key: number;
  get driver2_key(): number {
    return this._driver2_key;
  }
  set driver2_key(value: number) {
    this._driver2_key = value;
  }

  private _settled_mark: number;
  public get settled_mark(): number {
    return this._settled_mark;
  }
  public set settled_mark(value: number) {
    this._settled_mark = value;
  }
  
  private _actual_pos: boolean;
  public get actual_pos(): boolean {
    return this._actual_pos;
  }
  public set actual_pos(value: boolean) {
    this._actual_pos = value;
  }
  
  private _accelerate_mark: number;
  public get accelerate_mark(): number {
    return this._accelerate_mark;
  }
  public set accelerate_mark(value: number) {
    this._accelerate_mark = value;
  }
  
  private _braking_mark: number;
  public get braking_mark(): number {
    return this._braking_mark;
  }
  public set braking_mark(value: number) {
    this._braking_mark = value;
  }
  
  private _speeding_mark: number;
  public get speeding_mark(): number {
    return this._speeding_mark;
  }
  public set speeding_mark(value: number) {
    this._speeding_mark = value;
  }
  
  private _asc_meter: number;
  public get asc_meter(): number {
    return this._asc_meter;
  }
  public set asc_meter(value: number) {
    this._asc_meter = value;
  }
  
  private _desc_meter: number;
  public get desc_meter(): number {
    return this._desc_meter;
  }
  public set desc_meter(value: number) {
    this._desc_meter = value;
  }
  
  private _speed_accum: number;
  public get speed_accum(): number {
    return this._speed_accum;
  }
  public set speed_accum(value: number) {
    this._speed_accum = value;
  }
  
  private _max_speed_accum: number;
  public get max_speed_accum(): number {
    return this._max_speed_accum;
  }
  public set max_speed_accum(value: number) {
    this._max_speed_accum = value;
  }
  
  private _secs_braking: number;
  public get secs_braking(): number {
    return this._secs_braking;
  }
  public set secs_braking(value: number) {
    this._secs_braking = value;
  }
  
  private _distance: number;
  public get distance(): number {
    return this._distance;
  }
  public set distance(value: number) {
    this._distance = value;
  }
  
  private _pos_gps: string;
  public get pos_gps(): string {
    return this._pos_gps;
  }
  public set pos_gps(value: string) {
    this._pos_gps = value;
  }
  
  // custom
  private _geoPos: GeoPosition;
  public get geoPos(): GeoPosition {
    return this._geoPos;
  }
  public set geoPos(value: GeoPosition) {
    this._geoPos = value;
  }
  
  private _city_key: string;
  public get city_key(): string {
    return this._city_key;
  }
  public set city_key(value: string) {
    this._city_key = value;
  }
  
  private _city_country: string;
  public get city_country(): string {
    return this._city_country;
  }
  public set city_country(value: string) {
    this._city_country = value;
  }
  
  private _cargo_status: string;
  public get cargo_status(): string {
    return this._cargo_status;
  }
  public set cargo_status(value: string) {
    this._cargo_status = value;
  }
  
  private _truck_status: string;
  public get truck_status(): string {
    return this._truck_status;
  }
  public set truck_status(value: string) {
    this._truck_status = value;
    if (this._truck_status) {
      if (this._truck_status == 'M') {
        if (this._cargo_status == 'L') {
          this._truck_status_icon = 'assets/icon/icon-state-new/load.svg';
        }
        else if (this._cargo_status == 'E') {
          this._truck_status_icon = 'assets/icon/icon-state-new/unload.svg';
        }
      }
      else {
        this._truck_status_icon = GoogleMapAdvMarker.icons[this._truck_status].url;
      }
    }
  }
  
  private _truck_status_icon: string = null;
  public get truck_status_icon(): string {
    return this._truck_status_icon;
  }

  private _speed: number;
  public get speed(): number {
    return this._speed;
  }
  public set speed(value: number) {
    this._speed = value;
  }
  
  private _cargo_weight: number;
  public get cargo_weight(): number {
    return this._cargo_weight;
  }
  public set cargo_weight(value: number) {
    this._cargo_weight = value;
  }
  
  private _driving_time: number;
  public get driving_time(): number {
    return this._driving_time;
  }
  public set driving_time(value: number) {
    this._driving_time = value;
    if (this._driving_time) {
      this._driving_time_formatted = DateTools.getHumanReadHoursAndMinutes(this._driving_time * 1000);
    }
  }
  
  private _driving_time_formatted: string = null;
  public get driving_time_formatted(): string {
    return this._driving_time_formatted;
  }

  private _city_name: string;
  public get city_name(): string {
    return this._city_name;
  }
  public set city_name(value: string) {
    this._city_name = value;
  }
  
  private _city_size: string;
  public get city_size(): string {
    return this._city_size;
  }
  public set city_size(value: string) {
    this._city_size = value;
  }
  
  private _city_zip: string;
  public get city_zip(): string {
    return this._city_zip;
  }
  public set city_zip(value: string) {
    this._city_zip = value;
    if (this._city_zip && this._city_zip.length > 2) {
      this._city_zip_short = this._city_zip.substring(0, 2);
    }
  }

  // custom
  private _city_zip_short: string;
  public get city_zip_short(): string {
    return this._city_zip_short;
  }
  
  private _city_gps: string;
  public get city_gps(): string {
    return this._city_gps;
  }
  public set city_gps(value: string) {
    this._city_gps = value;
  }

  private _odometer: number;
  public get odometer(): number {
    return this._odometer;
  }
  public set odometer(value: number) {
    this._odometer = value;
  }

  private _time: string; //"2019-09-24T10:15:00+02",
  get time(): string {
    return this._time;
  }
  set time(value: string) {
    this._time = value;
    this._timeDate = DateTools.isoFix(this.time);
  }

  private _fuel_tank_lt: number = null;
  public get fuel_tank_lt(): number {
    return this._fuel_tank_lt;
  }
  public set fuel_tank_lt(value: number) {
    this._fuel_tank_lt = value;
  }
  
  private _trailer_key: number = null;
  public get trailer_key(): number {
    return this._trailer_key;
  }
  public set trailer_key(value: number) {
    this._trailer_key = value;
  }
  
  private _trailer_temperature: number = null;
  public get trailer_temperature(): number {
    return this._trailer_temperature;
  }
  public set trailer_temperature(value: number) {
    this._trailer_temperature = value;
  }

  // custom
  private _trailer_number_plate: number = null;
  public get trailer_number_plate(): number {
    return this._trailer_number_plate;
  }
  public set trailer_number_plate(value: number) {
    this._trailer_number_plate = value;
  }

  // custom
  private _timeDate: Date;
  public get timeDate(): Date {
    return this._timeDate;
  }
  public set timeDate(value: Date) {
    this._timeDate = value;
  }

  private _order_number: Array<string> = [];
  public get order_number(): Array<string> {
    return this._order_number;
  }
  public set order_number(value: Array<string>) {
    this._order_number = value;
  }
  
  // custom previous associated event
  private _previousEvent: TrackingEvent = null;
  public get previousEvent(): TrackingEvent {
    return this._previousEvent;
  }
  public set previousEvent(value: TrackingEvent) {
    this._previousEvent = value;
    if (this._previousEvent && this._previousEvent._timeDate && this._timeDate) {
      this._timeFromPrevious = this._timeDate.getTime() - this._previousEvent._timeDate.getTime();
      this._timeFromPreviousFormatted = DateTools.getHumanReadHoursAndMinutes(this._timeFromPrevious);
    }
  }

  // custom time from previous associated event
  private _timeFromPrevious: number = null;
  public get timeFromPrevious(): number {
    return this._timeFromPrevious;
  }

  private _timeFromPreviousFormatted: string = null;
  public get timeFromPreviousFormatted(): string {
    return this._timeFromPreviousFormatted;
  }
  
  private _marked: boolean = false;
  public get marked(): boolean {
    return this._marked;
  }
  public set marked(value: boolean) {
    this._marked = value;
  }

  // could be used for POST/PUT event
  get apiObject(): any {
    return {
      accelerate_mark: this.accelerate_mark,
      asc_meter: this.asc_meter,
      braking_mark: this.braking_mark,
      car_key: this.car_key,
      cargo_status: this.cargo_status,
      cargo_weight: this.cargo_weight,
      desc_meter: this.desc_meter,
      distance: this.distance,
      driver_key: this.driver_key,
      driver2_key: this.driver2_key,
      driving_time: this.driving_time,
      max_speed_accum: this.max_speed_accum,
      pos_gps: this.pos_gps,
      settled_mark: this.settled_mark,
      speed_accum: this.speed_accum,
      speed: this.speed,
      speeding_mark: this.speeding_mark,
      secs_braking: this.secs_braking,
      time: this.time,
      truck_status: this.truck_status
    };
  }
}