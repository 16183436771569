import { ItineraryType } from "../config";
import { Itinerary } from "./itinerary.object";

export class ItineraryDairyDayCollection {

  get itineraries(): Array<Itinerary> {
    return this._itineraries;
  }
  set itineraries(value: Array<Itinerary>) {
    this._itineraries = value;
  }

  isIn(itinerary: Itinerary): boolean {
    return this._itineraries.indexOf(itinerary) > -1;
  }

  // array of agenda/obligation_keys that are included in yesterday,today,tomorrow
  private _obligation_keys: Array<number> = [];
  public get obligation_keys(): Array<number> {
    return this._obligation_keys;
  }
  public set obligation_keys(value: Array<number>) {
    this._obligation_keys = value;
  }
  

  constructor(
    private _itineraries: Array<Itinerary>
  ) {
    this.buildCollection();
  }

  // method for building whole collection again from itineraries array
  buildCollection(): void {
    this._byDay = {
      yesterday: [],
      today: [],
      tomorrow: []
    };

    this._byDayVehicle = {
      yesterday: [],
      today: [],
      tomorrow: []
    };

    let now = new Date();
    let yesterday = (new Date());
    let tomorrow = (new Date());
    tomorrow.setHours(23, 59, 59, 999);
    yesterday.setHours(0, 0, 0, 0);
    let day = 24 * 60 * 60 * 1000;

    let TYPES: Array<string> = [
      ItineraryType.LOADING, ItineraryType.UNLOADING,
      ItineraryType.WAREHOUSE, ItineraryType.WAREHOUSE_AUTO,
      ItineraryType.TRANSSHIPMENT, ItineraryType.CUSTOMS
    ];
    this._itineraries.forEach(
      it => {
        if (
          !it.arrival_time || !TYPES.includes(it.type) ||
          it.arrival_time.getTime() < (yesterday.getTime() - day) ||
          it.arrival_time.getTime() > (tomorrow.getTime() + day)
        ) {
          // ignore and continue loop
          return true;
        }

        // create byHour collection
        let hour = it.arrival_time.getHours();
        this.pushToByDayCollection(it, yesterday, tomorrow, hour);

        // create byVehicle collection
        this.pushToByDayVehicleCollection(it, yesterday, tomorrow);

        // saving obligation key, then remove duplicates
        this._obligation_keys.push(it.agenda.obligation_key);
      }
    );

    // remove duplicates from obligation_keys array
    this._obligation_keys = this._obligation_keys.filter(
      (item, index) => this._obligation_keys.indexOf(item) === index
    );

    // sort and mark
    this.sortCollection();
  }

  // sorting by arrival_time/number_plate
  sortCollection(): void {
    let itinerarySorting = (a: Itinerary, b: Itinerary) => {
      let result = 0;
      if (a.arrival_time > b.arrival_time) {
        result = 1;
      } 
      else if (a.arrival_time < b.arrival_time) {
        result = -1;
      }
      else {
        // warehouse itinerary has common arrival_time
        if (a.warehouse_in_itinerary && b.warehouse_out_itinerary) {
          result = -1;
        }
      }
      return result;
    };

    // sorting for hours
    let sortingHour = (a, b) => {
      a.itineraries.sort(itinerarySorting);
      b.itineraries.sort(itinerarySorting);
      if (a.hour > b.hour) {
        return 1;
      } 
      else if (a.hour < b.hour) {
        return -1;
      } 
      else if (a.warehouse_in_itinerary && b.warehouse_out_itinerary) {
        // warehouse itinerary has common arrival_time
        return -1;
      }
      else {
        return 0;
      }
    };
    this._byDay.yesterday.sort(sortingHour);
    this._byDay.tomorrow.sort(sortingHour);
    this._byDay.today.sort(sortingHour);

    // sorting for vehicles
    let sortingVehicles = (a, b) => {
      a.itineraries.sort(itinerarySorting);
      b.itineraries.sort(itinerarySorting);
      if (a.number_plate > b.number_plate) {
        return 1;
      } 
      else if (a.number_plate < b.number_plate) {
        return -1;
      } 
      else {
        return 0;
      }
    };
    this._byDayVehicle.yesterday.sort(sortingVehicles);
    this._byDayVehicle.tomorrow.sort(sortingVehicles);
    this._byDayVehicle.today.sort(sortingVehicles);

    // marking css
    this.markByHour();
  }


  /* Solution for sorting according to hours  */
  private _byDay: any = {
    yesterday: [],
    today: [],
    tomorrow: []
  };
  get byDay(): any {
    return this._byDay;
  }

  private pushToByDayCollection(itinerary: Itinerary, yesterday: Date, tomorrow: Date, hour: number) {
    let lookup: Array<any>;
    switch (true) {
      case itinerary.arrival_time < yesterday:
        lookup = this._byDay.yesterday;
        break;
      case itinerary.arrival_time > tomorrow:
        lookup = this._byDay.tomorrow;
        break;
      default:
        lookup = this._byDay.today;
        break;
    }
    let found = false;
    lookup.forEach(
      byHour => {
        if (byHour.hour === hour) {
          byHour.itineraries.push(itinerary);
          found = true;
        }
      }
    );
    if (!found) {
      lookup.push({
        hour: hour,
        hourHuman: hour < 10 ? '0' + hour : hour,
        itineraries: [itinerary],
        first: itinerary // save first pushed itinerary
      })
    }
  }
  
  markByHour(): void {
    let counter;
    let marking = () => {
      counter = 0;
      return (a, index) => {
        a.itineraries.map(innerMarking());
      };
    };
    let innerMarking = () => {
      return (a) => {
        if (counter % 2 === 0) {
          a.css_class = 'even';
        } else {
          a.css_class = 'odd';
        }
        counter++;
      }
    };

    this._byDay.yesterday.map(marking());
    this._byDay.today.map(marking());
    this._byDay.tomorrow.map(marking());
  }

  // method for finding itinerary in collection
  // findItineraryInByDay(itinerary_key: number): Itinerary {
  //   // TODO pokracovat v reseni websocketu upravy/vytvoreni nakl/vykl v diáři
  //   this._byDay.yesterday
  // }


  /* Solution for sorting according to number plates */
  private _byDayVehicle: any = {
    yesterday: [],
    today: [],
    tomorrow: []
  };
  get byDayVehicle(): any {
    return this._byDayVehicle;
  }

  private pushToByDayVehicleCollection(itinerary: Itinerary, yesterday: Date, tomorrow: Date) {
    let lookup: Array<any>;
    switch (true) {
      case itinerary.arrival_time < yesterday:
        lookup = this._byDayVehicle.yesterday;
        break;
      case itinerary.arrival_time > tomorrow:
        lookup = this._byDayVehicle.tomorrow;
        break;
      default:
        lookup = this._byDayVehicle.today;
        break;
    }
    let found = false;
    lookup.forEach(
      byVehicle => {
        if (byVehicle.number_plate === itinerary.number_plate) {
          byVehicle.itineraries.push(itinerary);
          found = true;
        }
      }
    );
    if (!found) {
      lookup.push({
        car_key: itinerary.car_key,
        number_plate: itinerary.number_plate,
        itineraries: [itinerary],
        first: itinerary // save first pushed itinerary
      })
    }
  }

  markByVehicle(): void {
    let counter;
    let marking = () => {
      counter = 0;
      return (a, index) => {
        a.itineraries.map(innerMarking());
      };
    };
    let innerMarking = () => {
      return (a) => {
        if (counter % 2 === 0) {
          a.css_class = 'even';
        } else {
          a.css_class = 'odd';
        }
        counter++;
      }
    };

    this._byDayVehicle.yesterday.map(marking());
    this._byDayVehicle.today.map(marking());
    this._byDayVehicle.tomorrow.map(marking());
  }
}