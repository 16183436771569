import { Subject } from "rxjs";
import { DateTools } from "../tools/DateTools";
// import { GoogleMapMarker } from "./google-map-marker.object";
import { ServiceEventObject } from "./service-event.object";
import { WarehouseEvent } from "./warehouse-event.object";
import { GeoPosition } from "./geo-position.object";
import { Vehicle } from "./vehicle.object";
import { Agenda } from "./agenda.object";
import { Obligation } from "./obligation.object";
import { ItineraryType, ServiceType } from "../config";
import { GoogleMapAdvMarker } from "./google-map-adv-marker.object";

export class Itinerary {
  private _address: string;
  get address(): string {
    return this._address;
  }
  set address(value: string) {
    this._address = value;
    if (this._address && this._address.length) {
      this.latestAddress = this._address;
    }
  }

  private _address_excerpt: string;
  get address_excerpt(): string {
    return this._address_excerpt;
  }
  set address_excerpt(value: string) {
    this._address_excerpt = value;
  }

  get address_excerpt_first4(): string {
    if (this._address_excerpt && this._address_excerpt.length > 4) {
      return this._address_excerpt.substring(0,3);
    }
    return this._address_excerpt;
  }

  // address components
  private _place_name: string;
  public get place_name(): string {
    return this._place_name;
  }
  public set place_name(value: string) {
    this._place_name = value;
  }
  
  private _place_city: string;
  public get place_city(): string {
    return this._place_city;
  }
  public set place_city(value: string) {
    this._place_city = value;
  }
  
  private _place_country: string;
  public get place_country(): string {
    return this._place_country;
  }
  public set place_country(value: string) {
    this._place_country = value;
  }
  
  private _place_street: string;
  public get place_street(): string {
    return this._place_street;
  }
  public set place_street(value: string) {
    this._place_street = value;
  }
  
  private _place_zip: string;
  public get place_zip(): string {
    return this._place_zip;
  }
  public set place_zip(value: string) {
    this._place_zip = value;
  }
  
  private _code: string;
  public get code(): string {
    return this._code;
  }
  public set code(value: string) {
    this._code = value;
  }
  
  private _destination: string;
  public get destination(): string {
    return this._destination;
  }
  public set destination(value: string) {
    this._destination = value;
  }

  // validation flag (max 2048 chars)
  private _address_invalid: boolean = false;
  public get address_invalid(): boolean {
    return this._address_invalid;
  }
  public set address_invalid(value: boolean) {
    this._address_invalid = value;
  }

  private _additional_activities: string;
  public get additional_activities(): string {
    return this._additional_activities;
  }
  public set additional_activities(value: string) {
    this._additional_activities = value;
  }

  activityCode(code: string): boolean {
    if (this._additional_activities && this._additional_activities.includes(code)) {
      return true;
    }
    return false;
  }

  addRemoveActivityCode(code: string): void {
    if (!this._additional_activities) {
      this._additional_activities = '';
    }

    if (this._additional_activities.includes(code)) {
      if (this._additional_activities.includes(',' + code)) {
        // remove comma with code
        this._additional_activities = this._additional_activities.replace(',' + code, '');
      }
      else {
        // remove code
        this._additional_activities = this._additional_activities.replace(code, '');
      }
    }
    else {
      // add code
      if (this._additional_activities.length) {
        // append
        this._additional_activities = this._additional_activities + ',' + code; 
      }
      else {
        // first code
        this._additional_activities = code;
      }
    }
  }

  
  private _adr: string;
  get adr(): string {
    return this._adr;
  }
  set adr(value: string) {
    this._adr = value;
  }

  private _arrival_time: Date;
  get arrival_time(): Date {
    return this._arrival_time;
  }
  set arrival_time(value) {
    if (value) {
      this._arrival_time = DateTools.isoFix(value.toString());
      this._arrival_time_custom = DateTools.isoForDatetimeInput(this._arrival_time);
    }
  }

  private _arrival_time_custom: string = null;
  public get arrival_time_custom(): string {
    return this._arrival_time_custom;
  }
  public set arrival_time_custom(value: string) {
    this._arrival_time_custom = value;
    if (this._arrival_time_custom) {
      this._arrival_time = new Date(this._arrival_time_custom);
    }
  }
  
  private _arrival_time_max: Date;
  get arrival_time_max(): Date {
    return this._arrival_time_max;
  }
  set arrival_time_max(value) {
    if (value) {
      this._arrival_time_max = DateTools.isoFix(value.toString());
    }
  }

  private _arrival_time_max_secs: number;
  get arrival_time_max_secs(): number {
    return this._arrival_time_max_secs;
  }
  set arrival_time_max_secs(value: number) {
    this._arrival_time_max_secs = value;
  }

  private _arrival_period_days: number = 0;
  public get arrival_period_days(): number {
    return this._arrival_period_days;
  }
  public set arrival_period_days(value: number) {
    this._arrival_period_days = value;
  }

  private _currentDate: Date = new Date();
  get inPast(): boolean {
    if (!this.arrival_time) return true;
    return this._currentDate > this.arrival_time;
  }

  private _currency: string;
  get currency(): string {
    return this._currency;
  }
  set currency(value: string) {
    this._currency = value;
  }

  private _gps_coord: GeoPosition;
  get gps_coord(): GeoPosition {
    return this._gps_coord;
  }
  set gps_coord(value: GeoPosition) {
    this._gps_coord = value;
  }

  private _completed: boolean;
  public get completed(): boolean {
    return this._completed;
  }
  public set completed(value: boolean) {
    this._completed = value;
  }

  // default string representation of gps coord in api
  private _gps_coord_string: string;
  public get gps_coord_string(): string {
    return this._gps_coord_string;
  }
  public set gps_coord_string(value: string) {
    this._gps_coord_string = value;
  }

  private _itinerary_key: number;
  get itinerary_key(): number {
    return this._itinerary_key;
  }
  set itinerary_key(value: number) {
    this._itinerary_key = value;
  }

  private _length: number;
  get length(): number {
    return this._length;
  }
  set length(value: number) {
    this._length = value;
  }

  private _line_key: number = null;
  get line_key(): number {
    return this._line_key;
  }
  set line_key(value: number) {
    this._line_key = value;
  }

  private _loading_at_weekend: Boolean;
  get loading_at_weekend(): Boolean {
    return this._loading_at_weekend;
  }
  set loading_at_weekend(value: Boolean) {
    this._loading_at_weekend = value;
  }

  private _loading_at_work_days: Boolean;
  get loading_at_work_days(): Boolean {
    return this._loading_at_work_days;
  }
  set loading_at_work_days(value: Boolean) {
    this._loading_at_work_days = value;
  }

  private _loading_overtime_fine: number;
  get loading_overtime_fine(): number {
    return this._loading_overtime_fine;
  }
  set loading_overtime_fine(value: number) {
    this._loading_overtime_fine = value;
  }

  // limit is 50min by default
  private _loading_time_limit: number = 50;
  get loading_time_limit(): number {
    return this._loading_time_limit;
  }
  set loading_time_limit(value: number) {
    this._loading_time_limit = value;
  }

  private _loading_time_real: number;
  public get loading_time_real(): number {
    return this._loading_time_real;
  }
  public set loading_time_real(value: number) {
    this._loading_time_real = value;
  }
  
  private _note: string;
  get note(): string {
    return this._note;
  }
  set note(value: string) {
    this._note = value;
  }
  // validation flag (max 2048 chars)
  private _note_invalid: boolean = false;
  public get note_invalid(): boolean {
    return this._note_invalid;
  }
  public set note_invalid(value: boolean) {
    this._note_invalid = value;
  }

  public get notePalChangeFlag(): boolean {
    if (this._note && this._note.includes($localize`Výměna obalu/palet`)) {
      return true;
    }
    return false;
  }

  
  private _notify_arriving: string;
  public get notify_arriving(): string {
    return this._notify_arriving;
  }
  public set notify_arriving(value: string) {
    this._notify_arriving = value;
  }
  // validation flag (max 13 chars)
  private _notify_arriving_invalid: boolean = false;
  public get notify_arriving_invalid(): boolean {
    return this._notify_arriving_invalid;
  }
  public set notify_arriving_invalid(value: boolean) {
    this._notify_arriving_invalid = value;
  }

  private _notify_completed: any;
  public get notify_completed(): any {
    return this._notify_completed;
  }
  public set notify_completed(value: any) {
    this._notify_completed = value;
  }
  // validation flag (max 13 chars)
  private _notify_completed_invalid: boolean = false;
  public get notify_completed_invalid(): boolean {
    return this._notify_completed_invalid;
  }
  public set notify_completed_invalid(value: boolean) {
    this._notify_completed_invalid = value;
  }

  private _obligation_key: number;
  get obligation_key(): number {
    return this._obligation_key;
  }
  set obligation_key(value: number) {
    this._obligation_key = value;
  }

  private _period_length_secs: number;
  get period_length_secs(): number {
    return this._period_length_secs;
  }
  set period_length_secs(value: number) {
    this._period_length_secs = value;
  }

  // position 1-based index in obligation
  private _position: number;
  get position(): number {
    return this._position;
  }
  set position(value: number) {
    this._position = value;
  }

  // position 1-based index in express route
  private _route_position: number;
  public get route_position(): number {
    return this._route_position;
  }
  public set route_position(value: number) {
    this._route_position = value;
  }

  private _route_part_length: number = null;
  get route_part_length(): number {
    return this._route_part_length;
  }
  set route_part_length(value: number) {
    this._route_part_length = value;
  }
  
  // custom
  private _route_part_length_estimated: number = 0;
  public get route_part_length_estimated(): number {
    return this._route_part_length_estimated;
  }
  public set route_part_length_estimated(value: number) {
    this._route_part_length_estimated = value;
  }
  
  // custom
  private _route_part_length_airline: number = 0;
  public get route_part_length_airline(): number {
    return this._route_part_length_airline;
  }
  public set route_part_length_airline(value: number) {
    this._route_part_length_airline = value;
  }

  /*
   * "A" || "L" = Nakladka
   * "B" || "U" = Vykladka
   * "C" || "X" = Tranzit
   * "D" || "M" = Prejezd
   * "E" || "F" = Tankovani 
   */
  private _type: string;
  get type(): string {
    return this._type;
  }
  set type(value: string) {
    this._type = value;
    // synchronize to new version
    this.synchronizeItineraryType();

    // set type to string
    this._typeName = '';
    if (this._type == ItineraryType.OLD_LOADING || this._type == ItineraryType.LOADING) {
      this._typeName = 'Nakládka';
    }
    else if (this._type == ItineraryType.OLD_UNLOADING || this._type == ItineraryType.UNLOADING) {
      this._typeName = 'Vykládka';
    }
    else if (this._type == ItineraryType.OLD_TRANSIT || this._type == ItineraryType.TRANSIT) {
      this._typeName = 'Tranzit';
    }
    else if (this._type == ItineraryType.OLD_MOVING || this._type == ItineraryType.MOVING) {
      this._typeName = 'Přejezd';
    }
    else if (this._type == ItineraryType.OLD_FUELING || this._type == ItineraryType.FUELING) {
      this._typeName = 'Tankování';
    }
    else if (this._type == ItineraryType.WAREHOUSE || this._type == ItineraryType.WAREHOUSE_AUTO) {
      this._typeName = 'Sklad';
    }
    else if (this._type == ItineraryType.CUSTOMS) {
      this._typeName = 'Celince';
    }
  }

  // custom dictionary
  private _typeName: string;
  get typeName(): string {
    return this._typeName;
  }
  set typeName(value: string) {
    this._typeName = value;
  }
  
  private _use_motorway: Boolean;
  get use_motorway(): Boolean {
    return this._use_motorway;
  }
  set use_motorway(value: Boolean) {
    this._use_motorway = value;
  }

  private _volume: number;
  get volume(): number {
    return this._volume;
  }
  set volume(value: number) {
    this._volume = value;
  }

  private _ware_pcs: number;
  get ware_pcs(): number {
    return this._ware_pcs;
  }
  set ware_pcs(value: number) {
    this._ware_pcs = value;
  }

  private _ware_type: string;
  get ware_type(): string {
    return this._ware_type;
  }
  set ware_type(value: string) {
    this._ware_type = value;
  }
  
  // validation flag (max 3 chars)
  private _ware_type_invalid: boolean = false;
  public get ware_type_invalid(): boolean {
    return this._ware_type_invalid;
  }
  public set ware_type_invalid(value: boolean) {
    this._ware_type_invalid = value;
  }

  private _weight: number;
  get weight(): number {
    return this._weight;
  }
  set weight(value: number) {
    this._weight = value;
  }

  private _work_day_begin: string;
  get work_day_begin(): string {
    return this._work_day_begin;
  }
  set work_day_begin(value: string) {
    this._work_day_begin = value;
  }

  get work_day_begin_formatted(): string {
    if (this._work_day_begin && this._work_day_begin.length > 5) {
      return this._work_day_begin.substring(0,5);
    }
    return this._work_day_begin;
  }

  private _work_day_begin_minutes: number = 420;
  public get work_day_begin_minutes(): number {
    return this._work_day_begin_minutes;
  }
  public set work_day_begin_minutes(value: number) {
    this._work_day_begin_minutes = value;
  }

  private _work_day_end: string;
  get work_day_end(): string {
    return this._work_day_end;
  }
  set work_day_end(value: string) {
    this._work_day_end = value;
  }

  get work_day_end_formatted(): string {
    if (this._work_day_end && this._work_day_end.length > 5) {
      return this._work_day_end.substring(0,5);
    }
    return this._work_day_end;
  }
  
  private _work_day_end_minutes: number = 960;
  public get work_day_end_minutes(): number {
    return this._work_day_end_minutes;
  }
  public set work_day_end_minutes(value: number) {
    this._work_day_end_minutes = value;
  }

  // TO OPTIMIZE custom
  get work_day_end_soon(): boolean {
    let today: Date = new Date();
    let current_hours: number = today.getHours();
    let current_minutes: number = today.getMinutes();
    // 60 minutes before work_day_end is defined as SOON (and later on..)
    if ((current_hours*60 + (current_minutes + 60)) >= this.work_day_end_minutes) {
      return true;
    }
    return false;
  }
  
  // google autocompleter
  private _googleAutocompleter: any;
  public get googleAutocompleter(): any {
    return this._googleAutocompleter;
  }
  public set googleAutocompleter(value: any) {
    this._googleAutocompleter = value;
  }

  private _googleAutocompleterActive: boolean = true;
  public get googleAutocompleterActive(): boolean {
    return this._googleAutocompleterActive;
  }
  public set googleAutocompleterActive(value: boolean) {
    this._googleAutocompleterActive = value;
  }

  // latest itinerary autocompleter
  public focusSubjectLatest$: Subject<string> = new Subject<string>();
  public clickSubjectLatest$: Subject<string> = new Subject<string>();

  private _latestAddress: any = '';
  public get latestAddress(): any {
    return this._latestAddress;
  }
  public set latestAddress(value: any) {
    this._latestAddress = value;
  }

  private _latestAutocompleterActive: boolean = true;
  public get latestAutocompleterActive(): boolean {
    return this._latestAutocompleterActive;
  }
  public set latestAutocompleterActive(value: boolean) {
    this._latestAutocompleterActive = value;
  }
  
  private _latestTypeahead: any;
  public get latestTypeahead(): any {
    return this._latestTypeahead;
  }
  public set latestTypeahead(value: any) {
    this._latestTypeahead = value;
  }

  // company_obligation subjects for autocompleters of address
  public focusSubject$: Subject<string> = new Subject<string>();
  public clickSubject$: Subject<string> = new Subject<string>();

  private _favouriteTMActive: boolean = false;
  public get favouriteTMActive(): boolean {
    return this._favouriteTMActive;
  }
  public set favouriteTMActive(value: boolean) {
    this._favouriteTMActive = value;
  }

  private _address_autocompleter_favorite: string;
  public get address_autocompleter_favorite(): string {
    return this._address_autocompleter_favorite;
  }
  public set address_autocompleter_favorite(value: string) {
    this._address_autocompleter_favorite = value;
  }

  private _gpsActive: boolean = false;
  public get gpsActive(): boolean {
    return this._gpsActive;
  }
  public set gpsActive(value: boolean) {
    this._gpsActive = value;
  }

  private _gpsLatLng: string;
  public get gpsLatLng(): string {
    return this._gpsLatLng;
  }
  public set gpsLatLng(value: string) {
    this._gpsLatLng = value;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    if (this._vehicle) {
      return this._vehicle;
    }
    else if (this._agenda && this._agenda.vehicle) {
      return this._agenda.vehicle;
    }
    return null;
  }
  set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  private _css_class: string;
  public get css_class(): string {
    return this._css_class;
  }
  public set css_class(value: string) {
    this._css_class = value;
  }

  private _manualTrackingMode: boolean = false;
  public get manualTrackingMode(): boolean {
    return this._manualTrackingMode;
  }
  public set manualTrackingMode(value: boolean) {
    this._manualTrackingMode = value;
  }

  private _manualTrackingModeLoading: boolean = false;
  public get manualTrackingModeLoading(): boolean {
    return this._manualTrackingModeLoading;
  }
  public set manualTrackingModeLoading(value: boolean) {
    this._manualTrackingModeLoading = value;
  }

  private _marker: GoogleMapAdvMarker = null;
  public get marker(): GoogleMapAdvMarker {
    return this._marker;
  }
  public set marker(value: GoogleMapAdvMarker) {
    this._marker = value;
  }


  /****************************************************/
  /* Custom properties part */
  /****************************************************/
  // circular reference to parent obligation
  private _obligation: Obligation = null;
  public get obligation(): Obligation {
    return this._obligation;
  }
  public set obligation(value: Obligation) {
    this._obligation = value;
  }

  // custom reference to parent obligation~agenda
  private _agenda;
  get agenda() {
    return this._agenda;
  }
  set agenda(value: Agenda) {
    this._agenda = value;
  }

  // custom
  private _car_key: number;
  public get car_key(): number {
    return this._car_key;
  }
  public set car_key(value: number) {
    this._car_key = value;
  }

  // custom
  private _number_plate: string;
  public get number_plate(): string {
    return this._number_plate;
  }
  public set number_plate(value: string) {
    this._number_plate = value;
  }
  
  // custom
  private _accepted_by: string;
  public get accepted_by(): string {
    return this._accepted_by;
  }
  public set accepted_by(value: string) {
    this._accepted_by = value;
  }

  // custom
  private _driver_name: string;
  public get driver_name(): string {
    return this._driver_name;
  }
  public set driver_name(value: string) {
    this._driver_name = value;
  }
  
  // custom
  private _driver_phone: string;
  public get driver_phone(): string {
    return this._driver_phone;
  }
  public set driver_phone(value: string) {
    this._driver_phone = value;
  }

  // custom
  private _order_company: string;
  public get order_company(): string {
    return this._order_company;
  }
  public set order_company(value: string) {
    this._order_company = value;
  }

  // custom
  private _diary_detail: boolean = false;
  public get diary_detail(): boolean {
    return this._diary_detail;
  }
  public set diary_detail(value: boolean) {
    this._diary_detail = value;
  }

  get arrival_time_formatted(): string {
    return DateTools.formatLocaleString(this._arrival_time);
  }

  get loading_time_limit_miliseconds(): number {
    return this._loading_time_limit * 60 * 1000;
  }

  get order_comp_book_name(): string {
    if (this._agenda && this._agenda.order_comp_book) {
      return this._agenda.order_comp_book.company;
    }
    return '';
  }

  get order_number(): string {
    if (this._agenda) {
      return this._agenda.order_number;
    }
    return '';
  }

  numberPlateCar(): string {
    if (this._vehicle) {
      return this._vehicle.number_plate;
    }
    else if (this._agenda && this._agenda.vehicle) {
      return this._agenda.vehicle.number_plate;
    }
    else if (this._obligation && this._obligation.car) {
      return this._obligation.car.number_plate;
    }
    else if (this._obligation && this._obligation.orders && this._obligation.orders.length) {
      return this._obligation.orders[0].spz;
    }
    return '';
  }

  // method for synchronizing itinerary types (new version got some changes)
  synchronizeItineraryType(): void {
    if (this.type == ItineraryType.OLD_LOADING) this.type = ItineraryType.LOADING;  // nakladka-load
    else if (this.type == ItineraryType.OLD_UNLOADING) this.type = ItineraryType.UNLOADING; // vykladka-unload
    else if (this.type == ItineraryType.OLD_TRANSIT) this.type = ItineraryType.TRANSIT;   // tranzit
    else if (this.type == ItineraryType.OLD_MOVING) this.type = ItineraryType.MOVING;     // prejezd
    else if (this.type == ItineraryType.OLD_FUELING) this.type = ItineraryType.FUELING;   // tankovani
  }

  private _service_events: Array<ServiceEventObject>;
  get service_events(): Array<ServiceEventObject> {
    return this._service_events;
  }
  set service_events(value: Array<ServiceEventObject>) {
    this._service_events = value;

    // reinit attributes
    this._startTime = null;
    this._endTime = null;
    this._isDone = false;
    this._hasStarted = false;
    this._delayedArrival = 0;
    this._duration = 0;
    this._delayedArrivalFormatted = '--:--';
    this._durationFormatted = '--:--';
    this._weightServiceEvent = '0t';
    this._lengthMetersServiceEvent = '0ldm';
    this._containersOutServiceEvent = '-0';
    this._containersInServiceEvent = '+0';

    if (this._service_events && this._service_events.length) {
      // temporary real arrival time setter
      if (this._type == ItineraryType.LOADING) {
        let loading_event = this._service_events.find(e => e.type == ServiceType.LOADING_END);
        if (loading_event) {
          this._arrival_time = loading_event.timeDate;
        }
      }
      else if (this._type == ItineraryType.UNLOADING) {
        let unloading_event = this._service_events.find(e => e.type == ServiceType.UNLOADING_END);
        if (unloading_event) {
          this._arrival_time = unloading_event.timeDate;
        }
      }
      else if (this._type == ItineraryType.WAREHOUSE || this._type == ItineraryType.WAREHOUSE_AUTO) {
        if (this._warehouse_in_itinerary) {
          let unloading_event = this._service_events.find(e => e.type == ServiceType.UNLOADING_END);
          if (unloading_event) {
            this._arrival_time = unloading_event.timeDate;
          }
        }
        else if (this._warehouse_out_itinerary) {
          let loading_event = this._service_events.find(e => e.type == ServiceType.LOADING_END);
          if (loading_event) {
            this._arrival_time = loading_event.timeDate;
          }
        }
      }

      // set start time
      let startEvent = this._service_events.find(
        e => e.type == Vehicle.LOADING_START || e.type == Vehicle.UNLOADING_START
      );
      if (startEvent) {
        this._startTime = DateTools.isoFix(startEvent.time);
      }

      // set end time
      let endEvent = this._service_events.find(
        e => e.type == Vehicle.LOADING_END || e.type == Vehicle.UNLOADING_END
      );
      if (endEvent) {
        this._endTime = DateTools.isoFix(endEvent.time);
      }

      // itinerary started
      // loadings / unloadings
      if (this._service_events.find(e => e.type == Vehicle.LOADING_START || e.type == Vehicle.UNLOADING_START)) {
        this._hasStarted = true;
      }
      else {
        this._hasStarted = false;
      }

      // itinerary completed ~ isDone
      if (this._service_events.find(e => e.type == Vehicle.LOADING_END || e.type == Vehicle.UNLOADING_END)) {
        this._isDone = true;
        this._hasStarted = true;
      }
      else {
        this._isDone = false;
      }

      // delayedArrival
      if (!this.hasStarted || !this.arrival_time) {
        this._delayedArrival = 0;
      }
      let delayedArrival: number = 0;
      this._service_events.forEach(
        ev => {
          if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(ev.type) > -1) {
            if (ev.time && this._arrival_time) {
              delayedArrival = (DateTools.isoFix(ev.time)).getTime() - this._arrival_time.getTime();
            }
          }
        }
      );
      
      // delayedArrivalFormatted
      if (!this._delayedArrival) {
        this._delayedArrivalFormatted = '--:--';
      }
      this._delayedArrivalFormatted = (this._delayedArrival > 0 ? '+' : '') + DateTools.getHumanReadHoursAndMinutes(this._delayedArrival);

      // duration
      if (!this.startTime || !this.endTime) {
        this._duration = null;
      }
      let duration: number = 0;
      let firstEvent: ServiceEventObject;
      this._service_events.forEach(
        serviceEvent => {
          if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
            firstEvent = serviceEvent;
          }
        }
      );
      this._service_events.forEach(
        serviceEvent => {
          if (firstEvent && [Vehicle.LOADING_END, Vehicle.UNLOADING_END].indexOf(serviceEvent.type) > -1) {
            duration = (DateTools.isoFix(serviceEvent.time)).getTime() - (DateTools.isoFix(firstEvent.time)).getTime()
          }
        }
      )
      this._duration = Math.abs(duration);

      // durationFormatted
      if (this._duration == 0) {
        this._durationFormatted = '00:00';
      }
      else if (!this._duration) {
        this._durationFormatted = '--:--';
      }
      this._durationFormatted = DateTools.getHumanReadHoursAndMinutes(this._duration);

      // weight formatted
      let w: string = '0t';
      this._service_events.forEach(
        serviceEvent => {
          if ([Vehicle.LOADING_END, Vehicle.UNLOADING_END].indexOf(serviceEvent.type) > -1) {
            w = (serviceEvent.weight ? serviceEvent.weight : 0) + 't';
          }
        }
      )
      this._weightServiceEvent = w;

      // length formatted
      let length: string = '0ldm';
      this._service_events.forEach(
        serviceEvent => {
          if ([Vehicle.LOADING_END, Vehicle.UNLOADING_END].indexOf(serviceEvent.type) > -1) {
            length = (serviceEvent.length ? serviceEvent.length : 0) + 'ldm';
          }
        }
      )
      this._lengthMetersServiceEvent = length;

      // containers out formatted
      let containers_out: string = '+0';
      this._service_events.forEach(
        serviceEvent => {
          if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
            containers_out = '-' + (serviceEvent.containers_out ? serviceEvent.containers_out : 0);
          }
        }
      )
      this._containersOutServiceEvent = containers_out;

      // containers in formatted
      let containers_in: string = '+0';
      this._service_events.forEach(
        serviceEvent => {
          if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
            containers_in = '+' + (serviceEvent.containers_in ? serviceEvent.containers_in : 0);
          }
        }
      )
      this._containersInServiceEvent = containers_in;
    }
  }

  private _startTime: Date = null;
  public get startTime(): Date {
    return this._startTime;
  }

  private _endTime: Date = null;
  get endTime(): Date {
    return this._endTime;
  }

  private _isDone: boolean = false;
  get isDone(): boolean {
    return this._isDone;
  }

  private _hasStarted: boolean = false;
  public get hasStarted(): boolean {
    return this._hasStarted;
  }
  
  private _delayedArrival: number = 0;
  public get delayedArrival(): number {
    return this._delayedArrival;
  }

  private _duration: number = 0;
  public get duration(): number {
    return this._duration;
  }
  
  private _delayedArrivalFormatted: string = '--:--';
  public get delayedArrivalFormatted(): string {
    return this._delayedArrivalFormatted;
  }

  private _durationFormatted: string = '--:--';
  public get durationFormatted(): string {
    return this._durationFormatted;
  }

  private _weightServiceEvent: string = '0t';
  public get weightServiceEvent(): string {
    return this._weightServiceEvent;
  }
  
  private _lengthMetersServiceEvent: string = '0ldm';
  public get lengthMetersServiceEvent(): string {
    return this._lengthMetersServiceEvent;
  }
  
  private _containersOutServiceEvent: string = '-0';
  public get containersOutServiceEvent(): string {
    return this._containersOutServiceEvent;
  }
  
  private _containersInServiceEvent: string = '+0';
  public get containersInServiceEvent(): string {
    return this._containersInServiceEvent;
  }
  

  // get startTime(): Date {
  //   let startEvent = this._service_events.find(
  //     e => e.type == Vehicle.LOADING_START || e.type == Vehicle.UNLOADING_START
  //   );
  //   if (startEvent) {
  //     return DateTools.isoFix(startEvent.time);
  //   }
  //   return null;
  // }

  // get endTime(): Date {
  //   let endEvent = this._service_events.find(
  //     e => e.type == Vehicle.LOADING_END || e.type == Vehicle.UNLOADING_END
  //   );
  //   if (endEvent) {
  //     return DateTools.isoFix(endEvent.time);
  //   }
  //   return null;
  // }

  // isDone(): boolean {
  //   let isDone: boolean = false;
  //   if (this._service_events) {
  //     // loadings / unloadings
  //     if (this._service_events.find(e => e.type == Vehicle.LOADING_END || e.type == Vehicle.UNLOADING_END)) {
  //       isDone = true;
  //     }
  //   }
  //   return isDone;
  // }

  // hasStarted(): boolean {
  //   let hasStarted: boolean = false;
  //   if (this._service_events) {
  //     // loadings / unloadings
  //     if (this._service_events.find(e => e.type == Vehicle.LOADING_START || e.type == Vehicle.UNLOADING_START)) {
  //       hasStarted = true;
  //     }
  //   }
  //   // if its done, it must also started
  //   if (this.isDone()) {
  //     hasStarted = true;
  //   }
  //   return hasStarted;
  // }

  // delayedArrival(): number {
  //   if (!this.hasStarted() || !this.arrival_time) {
  //     return 0;
  //   }
  //   let delayedArrival: number = 0;
  //   if (this._service_events) {
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
  //           delayedArrival = (DateTools.isoFix(serviceEvent.time)).getTime() - this.arrival_time.getTime();
  //         }
  //       }
  //     )
  //   }
  //   return delayedArrival;
  // }

  // duration() {
  //   if (!this.startTime || !this.endTime) {
  //     return null;
  //   }
  //   let duration: number = 0;
  //   if (this._service_events) {
  //     let startEvent: ServiceEventObject;
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
  //           startEvent = serviceEvent;
  //         }
  //       }
  //     );
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if (startEvent && [Vehicle.LOADING_END, Vehicle.UNLOADING_END].indexOf(serviceEvent.type) > -1) {
  //           duration = (DateTools.isoFix(serviceEvent.time)).getTime() - (DateTools.isoFix(startEvent.time)).getTime()
  //         }
  //       }
  //     )
  //   }
  //   return Math.abs(duration);
  // }

  // delayedArrivalFormatted(): string {
  //   let delayedArrival = this.delayedArrival();
  //   if (!delayedArrival) {
  //     return '--:--';
  //   }
  //   return (delayedArrival > 0 ? '+' : '') + DateTools.getHumanReadHoursAndMinutes(delayedArrival);
  // }

  // durationFormatted(): string {
  //   let duration = this.duration();
  //   if (duration == 0) {
  //     return '00:00'
  //   }
  //   else if (!duration) {
  //     return '--:--';
  //   }
  //   return DateTools.getHumanReadHoursAndMinutes(duration);
  // }

  // get weightServiceEvent(): string {
  //   let w: string = '0t';
  //   if (this._service_events) {
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if ([Vehicle.LOADING_END, Vehicle.UNLOADING_END].indexOf(serviceEvent.type) > -1) {
  //           w = (serviceEvent.weight ? serviceEvent.weight : 0) + 't';
  //         }
  //       }
  //     )
  //   }
  //   return w;
  // }

  // get lengthMetersServiceEvent(): string {
  //   let length: string = '0ldm';
  //   if (this._service_events) {
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if ([Vehicle.LOADING_END, Vehicle.UNLOADING_END].indexOf(serviceEvent.type) > -1) {
  //           length = (serviceEvent.length ? serviceEvent.length : 0) + 'ldm';
  //         }
  //       }
  //     )
  //   }
  //   return length;
  // }

  // get containersOutServiceEvent(): string {
  //   let containers: string = '+0';
  //   if (this._service_events) {
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
  //           containers = '-' + (serviceEvent.containers_out ? serviceEvent.containers_out : 0);
  //         }
  //       }
  //     )
  //   }
  //   return containers;
  // }

  // get containersInServiceEvent(): string {
  //   let containers: string = '+0';
  //   if (this._service_events) {
  //     this._service_events.forEach(
  //       serviceEvent => {
  //         if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(serviceEvent.type) > -1) {
  //           containers = '+' + (serviceEvent.containers_in ? serviceEvent.containers_in : 0);
  //         }
  //       }
  //     )
  //   }
  //   return containers;
  // }


  // muze dochazet k prekryti typu s last_position.truck_status ikonou v dashboardu
  // proto jsou v google-map-marker oddeleny ikony pro mapu s predponou serviceEvent_
  public get type_marker(): string {
    return 'serviceEvent_' + this._type;
  }

  typeIcon(): string {
    return GoogleMapAdvMarker.icons[this.type_marker].url;
  }

  // grayscale 24px versions of icons
  public get grayscale_type_marker(): string {
    return 'grayscale_' + this._type;
  }
  
  typeIconGrayscale(): string {
    return GoogleMapAdvMarker.icons[this.grayscale_type_marker].url;
  }

  // smaller 24px versions of icons
  public get sm_type_marker(): string {
    return 'sm_' + this._type;
  }
  
  typeIconSm(): string {
    return GoogleMapAdvMarker.icons[this.sm_type_marker].url;
  }
  
  // smaller grayscale 24px versions of icons
  public get grayscale_sm_type_marker(): string {
    return 'grayscale_sm_' + this._type;
  }
  
  typeIconGrayscaleSm(): string {
    return GoogleMapAdvMarker.icons[this.grayscale_sm_type_marker].url;
  }

  // background color according to itinerary type
  get backgroundType(): string {
    switch (this._type) {
      case ItineraryType.LOADING:
        return '#bde3c5';
      case ItineraryType.UNLOADING:
        return '#ffee7c';
      case ItineraryType.TRANSIT:
        return '#dedede';
      case ItineraryType.MOVING:
        return '#dedede';
      case ItineraryType.FUELING:
        return '#99caff';
      case ItineraryType.WAREHOUSE:
        return '#84d4c9';
      case ItineraryType.WAREHOUSE_AUTO:
        return '#84d4c9';
      case ItineraryType.TRANSSHIPMENT:
        return '#fbc97e';
      default:
        return '#fff'
    }
  }

  // incrementing/decrementing current weight in order with another itinerary items
  private _currentWeight: number = 0;
  public get currentWeight(): number {
    return this._currentWeight;
  }
  public set currentWeight(value: number) {
    this._currentWeight = value;
  }
    
  // custom flag for itinerary being added to express route
  private _addingToExpress: boolean = false;
  public get addingToExpress(): boolean {
    return this._addingToExpress;
  }
  public set addingToExpress(value: boolean) {
    this._addingToExpress = value;
  }


  /*****************************************************/
  /* Warehouse temporary logic */
  /*****************************************************/
  private _warehouse_in_itinerary: boolean = false;
  public get warehouse_in_itinerary(): boolean {
    return this._warehouse_in_itinerary;
  }
  public set warehouse_in_itinerary(value: boolean) {
    this._warehouse_in_itinerary = value;
  }

  private _warehouse_out_itinerary: boolean = false;
  public get warehouse_out_itinerary(): boolean {
    return this._warehouse_out_itinerary;
  }
  public set warehouse_out_itinerary(value: boolean) {
    this._warehouse_out_itinerary = value;
  }

  private _warehouse_in_event: WarehouseEvent = null;
  public get warehouse_in_event(): WarehouseEvent {
    return this._warehouse_in_event;
  }
  public set warehouse_in_event(value: WarehouseEvent) {
    this._warehouse_in_event = value;
  }
  
  private _warehouse_out_event: WarehouseEvent = null;
  public get warehouse_out_event(): WarehouseEvent {
    return this._warehouse_out_event;
  }
  public set warehouse_out_event(value: WarehouseEvent) {
    this._warehouse_out_event = value;
  }

  
  /*****************************************************/
  /* Transshipment temporary logic */
  /*****************************************************/
  private _transshipment_in_itinerary: boolean = false;
  public get transshipment_in_itinerary(): boolean {
    return this._transshipment_in_itinerary;
  }
  public set transshipment_in_itinerary(value: boolean) {
    this._transshipment_in_itinerary = value;
  }

  private _transshipment_out_itinerary: boolean = false;
  public get transshipment_out_itinerary(): boolean {
    return this._transshipment_out_itinerary;
  }
  public set transshipment_out_itinerary(value: boolean) {
    this._transshipment_out_itinerary = value;
  }

  private _transshipment_in_event: WarehouseEvent = null;
  public get transshipment_in_event(): WarehouseEvent {
    return this._transshipment_in_event;
  }
  public set transshipment_in_event(value: WarehouseEvent) {
    this._transshipment_in_event = value;
  }
  
  private _transshipment_out_event: WarehouseEvent = null;
  public get transshipment_out_event(): WarehouseEvent {
    return this._transshipment_out_event;
  }
  public set transshipment_out_event(value: WarehouseEvent) {
    this._transshipment_out_event = value;
  }

  // creates copy of this itinerary object
  get itineraryCopy(): Itinerary {
    let it: Itinerary = new Itinerary();
    it.address = this._address;
    it.address_excerpt = this._address_excerpt;
    it.additional_activities = this._additional_activities;
    it.place_name = this._place_name;
    it.place_city = this._place_city;
    it.place_country = this._place_country;
    it.place_street = this._place_street;
    it.place_zip = this._place_zip;
    it.adr = this._adr;
    it.arrival_time = this._arrival_time;
    it.arrival_time_custom = this._arrival_time_custom;
    it.arrival_period_days = this._arrival_period_days;
    it.gps_coord = this._gps_coord;
    it.gps_coord_string = this._gps_coord_string;
    it.completed = this._completed;
    it.code = this._code;
    it.destination = this._destination;
    it.itinerary_key = this._itinerary_key;
    it.length = this._length;
    it.line_key = this._line_key;
    it.loading_at_weekend = this._loading_at_weekend;
    it.loading_at_work_days = this._loading_at_work_days;
    it.loading_overtime_fine = this._loading_overtime_fine;
    it.loading_time_limit = this._loading_time_limit;
    it.loading_time_real = this._loading_time_real;
    it.note = this._note;
    it.obligation_key = this._obligation_key;
    it.period_length_secs = this._period_length_secs;
    it.position = this._position;
    it.route_part_length = this._route_part_length;
    it.type = this._type;
    it.use_motorway = this._use_motorway;
    it.volume = this._volume;
    it.ware_pcs = this._ware_pcs;
    it.ware_type = this._ware_type;
    it.weight = this._weight;
    it.work_day_begin = this._work_day_begin;
    it.service_events = this._service_events;
    it.marker = this._marker;
    return it;
  }
}