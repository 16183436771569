<div class="col-12 dashboard-head itinerary-dairy" style="font-size: 26px;">
  <img class="rightMenuImg" src="assets/icons-new/notebook3.svg" alt="calendar"/>
  <ng-container i18n>Plánovač</ng-container>&nbsp;
  <a (click)="moveToPast()" class="pointer mr-1">
    <img src="assets/icons-new/navigate_left.svg" alt="navigate_left" height="18px"/>
  </a>
  <span>{{selectedDay | date: 'dd.MM.'}}</span>
  <a (click)="moveToFuture()" class="pointer ml-1">
    <img src="assets/icons-new/navigate_right.svg" alt="navigate_right" height="18px"/>
  </a>
  <!-- <a [style.visibility]="!isYesterdayVisible ? 'visible' : 'hidden'" (click)="moveToPast()" class="pointer">
    <img src="assets/icons-new/navigate_left.svg" alt="navigate_left" height="18px"/>
  </a>
  {{current_selected_text}}
  <a [style.visibility]="!isTomorrowVisible ? 'visible' : 'hidden'" (click)="moveToFuture()" class="pointer">
    <img src="assets/icons-new/navigate_right.svg" alt="navigate_right" height="18px"/>
  </a> -->
</div>

<div *ngIf="reloading" class="dashboard-body text-center py-3">
  <h6 i18n>Itinerář byl upraven - dojde k přenačtení...</h6>
  <div class="spinner-wrapper py-4 text">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<div *ngIf="loadingObligations" class="dashboard-body text-center py-3">
  <div class="spinner-wrapper py-4">
    <div class="spinner-border text-primary" style="width:3rem; height:3rem;" role="status">
      <span class="sr-only"></span>
    </div>
  </div>
</div>

<div *ngIf="!validAccess" class="dashboard-body">
  <div class="container text-center py-3">
    <h5>
      <ng-container i18n>Váš přístup vypršel. Máte-li zájem o další využívání, předplaťte si přístup</ng-container>&nbsp;
      <a href="javascript:void(0)" (click)="openSubsNewTab()" i18n>zde</a>.
    </h5>
  </div>
</div>

<div *ngIf="!reloading && !loadingObligations && !selectedDayItinerary.length" class="dashboard-body">
  <div class="container text-center py-3">
    <h5 i18n>Žádné nakládky/vykládky.</h5>
  </div>
</div>

<div *ngIf="sortedItinerary && validAccess && !reloading && !loadingObligations" class="dashboard-body">
  <div class="clearfix">
    <table class="col-12 table-bordered">
      <tbody>
        <!-- sorting switch option -->
        <tr *ngIf="selectedDayItinerary?.length">
          <td colspan="2">
            <div class="w-100 d-flex">
              <div class="w-50 text-center">
                <button [class]="'btn btn-' + (sortByHour ? '' : 'outline-') + 'primary button-sort'" 
                (click)="sortByHour=true">
                  <ng-container i18n>Řadit dle času</ng-container>
                </button>
              </div>
              <div class="w-50 text-center">
                <button [class]="'btn btn-' + (sortByHour ? 'outline-' : '') + 'primary button-sort'" 
                (click)="sortByHour=false">
                  <ng-container i18n>Řadit dle zakázek</ng-container>
                </button>
              </div>
            </div>
          </td>
        </tr>
        
        <!-- sorted itinerary list -->
        <ng-template ngFor let-lookup [ngForOf]="selectedDayItinerary">
          <tr *ngIf="!sortByHour">
            <td colspan="2" class="bg-secondary text-light">
              <strong class="mx-2">ZA{{lookup.first.obligation?.order_number_standard}}</strong>
              <span *ngIf="lookup.first.obligation?.order_number">
                ({{lookup.first.obligation?.order_number}})
              </span>
            </td>
          </tr>
          <tr>
            <td class="text-center" 
            [style.background]="isCurrentHour(lookup.hour) ? '#ff7f2a' : ''"
            [attr.rowspan]="lookup.itineraries.length + (lookup.itineraries.length === 1 ? 0 : 1)">
              {{lookup.hourHuman}}
            </td>

            <!-- only one itinerary for particular hour/vehicle -->
            <td *ngIf="lookup.itineraries.length === 1 && lookup.first" 
            class="w-100 px-1 {{lookup.first.css_class}}">
              <ng-container *ngTemplateOutlet="itineraryTemplate, context: {it: lookup.first}">
              </ng-container>
            </td>
          </tr>

          <!-- more itinerary for particular hour/vehicle -->
          <ng-template [ngIf]="lookup.itineraries.length > 1">
            <tr *ngFor="let it of lookup.itineraries" [class]="it.css_class">
              <td class="w-100 px-1">
                <ng-container *ngTemplateOutlet="itineraryTemplate, context: {it: it}">
                </ng-container>
              </td>
            </tr>
          </ng-template>
        </ng-template>
      </tbody>
    </table>

    <!-- because of tooltips scrolling problems in last row of diary table -->
    <div *ngIf="selectedDayItinerary?.length" style="height: 10px;"></div>
  </div>
</div>


<!-- template for each itinerary -->
<ng-template #itineraryTemplate let-it="it">
  <div class="d-inline-flex">
    <div class="d-flex">
      <div class="padding-top-5 my-auto">
        <!-- <img [src]="it.typeIcon()" width="30" height="30"/> -->
        <ng-container *ngIf="it.type != 'V' && it.type != 'W'; else warehouseIcon">
          <img [src]="it.typeIcon()" class="pointer" width="30" height="30"/>
        </ng-container>
        <ng-template #warehouseIcon>
          <img *ngIf="it.warehouse_in_itinerary" src="assets/icons-new/collection.svg" 
          class="pointer" width="30" height="30"/>
          <img *ngIf="it.warehouse_out_itinerary" src="assets/icons-new/delivery.svg" 
          class="pointer" width="30" height="30"/>
        </ng-template>
      </div>
      <div class="padding-top-5 pointer my-auto">
        <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING || it.type == ItineraryType.CUSTOMS">
          <img *ngIf="!it.completed" width="30" height="30" 
          src="assets/icons-new/navigate_cross_red_transition_1.svg"
          (click)="itineraryToComplete = it" ngbTooltip="Nezahájena" i18n-ngbTooltip
          data-toggle="modal" data-target="#completeItineraryModal"/>
          <img *ngIf="it.completed" width="30" height="30"
          src="assets/icons-new/check-green-transition.svg"
          ngbTooltip="Dokončena" i18n-ngbTooltip/>
        </div>
        <div *ngIf="it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO">
          <img *ngIf="!it.warehouse_in_event && !it.warehouse_out_event" width="30" height="30" 
          src="assets/icons-new/navigate_cross_red_transition_1.svg"
          (click)="itineraryToComplete = it" ngbTooltip="Nezahájena" i18n-ngbTooltip 
          data-toggle="modal" data-target="#completeItineraryModal"/>
          <img *ngIf="it.warehouse_in_event || it.warehouse_out_event" width="30" height="30"
          src="assets/icons-new/check-green-transition.svg"
          ngbTooltip="Dokončena" i18n-ngbTooltip/>
        </div>
      </div>
    </div>
    <div class="text-left" style="min-width:80px; width:80px;">
      <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING">
        <div>
          {{it.work_day_begin_formatted}}-{{it.work_day_end_formatted}}
        </div>
        <div *ngIf="!it.completed" class="text-center">
          <img src="assets/icons-new/bell.svg" height="28px" class="pointer"
          [style.opacity]="it.work_day_end_soon ? 1 : 0.5"
          ngbTooltip="Urgence dokončení" container="body" placement="auto" i18n-ngbTooltip
          (click)="setItineraryToCompleted(it)"/>
        </div>
      </div>
      <div *ngIf="it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO">
        <div>&nbsp;</div>
        <div *ngIf="!it.warehouse_in_event && !it.warehouse_out_event" class="text-center">
          <img src="assets/icons-new/bell.svg" height="28px" class="pointer"
          [style.opacity]="it.work_day_end_soon ? 1 : 0.5"
          ngbTooltip="Urgence dokončení" container="body" placement="auto" i18n-ngbTooltip
          (click)="setItineraryToCompleted(it)"/>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="!it.accepted_by && !it.number_plate" class="pointer text-danger diary-truncate text-truncate"
      (click)="openObligationDetail(it)">
        <ng-container i18n>Nepotvrzeno!</ng-container>
      </div>
      <div *ngIf="it.accepted_by || it.number_plate" class="pointer diary-truncate text-truncate"
      (click)="openObligationDetail(it)">
        <strong *ngIf="it.number_plate; else noItinerarySpz">{{it.number_plate}}</strong>
        <ng-template #noItinerarySpz><em i18n>SPZ chybí</em></ng-template>,
        <strong *ngIf="it.driver_name; else noItineraryDriverName">{{it.driver_name}} </strong>
        <ng-template #noItineraryDriverName><em i18n>řidič chybí </em></ng-template>
        <strong *ngIf="it.number_plate; else noItineraryDriverPhone">{{it.driver_phone}} </strong>
        <ng-template #noItineraryDriverPhone><em i18n>tel. chybí </em></ng-template>,
        {{it.order_company}}
      </div>
      <div class="pointer diary-truncate text-truncate" (click)="openObligationDetail(it)">
        <!-- {{it.address}} -->
        {{it.place_country}} {{it.place_zip}} {{it.place_city}},
        {{it.weight ? it.weight : 0}}t,
        {{it.ware_pcs ? it.ware_pcs : 0}}
        {{it.ware_type ? it.ware_type : 'PAL'}}
        {{it.note ? ',' + it.note : ''}}
      </div>
      <div class="d-flex justify-content-between diary-truncate">
        <div class="pointer font-weight-bold text-truncate" (click)="openObligationDetail(it)">
          {{it.obligation.order_comp_book?.company}}
          <!-- orders jména dopravců -->
          <ng-container *ngIf="it.obligation?.orders">
            <span *ngFor="let order of it.obligation.orders">
              -> {{order.order_comp_book?.company}}
            </span>
          </ng-container>
        </div>
        <!-- show also badge for attachments of completed unloading itinerary -->
        <div *ngIf="it.type == ItineraryType.UNLOADING" class="text-nowrap">
          <span *ngIf="!it.obligation.attachments.length" class="badge badge-dark pointer" 
          ngbTooltip="Žádné přiložené soubory" container="body" placement="auto" i18n-ngbTooltip>
            0
          </span>
          
          <span *ngIf="it.obligation.attachments.length" 
            class="badge badge-dark pointer"
            [ngbPopover]="attachmentsPopover" [ngbTooltip]="attachmentsTooltip"
            [autoClose]="'outside'" placement="left auto" container="body"
            (click)="downloadThumbnails(it.obligation);">
            {{it.obligation.attachments.length}}
          </span>

          <span *ngIf="it.obligation.attachments.length && !it.obligation.email_headers.length" 
          class="pointer ml-1" data-toggle="modal" data-target="#sendAttachmentsModal" 
          (click)="obligationToSend = it.obligation"
          ngbTooltip="Odeslat dokumenty" container="body" placement="left auto" i18n-ngbTooltip>
            <img src="assets/icons-new/mail.svg" height="18px"/>
          </span>

          <span *ngIf="it.obligation.attachments.length && it.obligation.email_headers.length" 
          class="pointer ml-1" container="body" placement="left auto"
          [ngbPopover]="emailsInfoPopover" ngbTooltip="Dokumenty odeslány" i18n-ngbTooltip>
            <img src="assets/icons-new/mail-green.svg" height="18px"/>
          </span>
          
          <ng-template #emailsInfoPopover>
            <div *ngIf="it.obligation.email_headers?.length">
              <div *ngFor="let header of it.obligation.email_headers; let i = index">
                <a href="javascript:void(0)" (click)="showEmailDetail(header.email_msg_id)">
                  {{i+1}})
                  <ng-container i18n>Email odeslán</ng-container>&nbsp;
                  - {{header.sent_date_object ? (header.sent_date_object | date: 'dd.MM.yyyy HH:mm') : ''}}
                </a>
              </div>
            </div>
          </ng-template>

          <ng-template #attachmentsTooltip>
            <ng-container i18n>Počet přiložených souborů</ng-container>: 
            {{it.obligation.attachments.length}}
            <br>ZA{{it.obligation.order_number}}
          </ng-template>

          <ng-template #attachmentsPopover>
            <div *ngIf="it.obligation.attachments.length">
              <h6 class="normal-font-size">
                <ng-container i18n>Přiložené soubory</ng-container>&nbsp;- ZA{{it.obligation.order_number}}
              </h6>
              <h6 *ngIf="downloadingFile" class="text-primary normal-font-size text-truncate d-flex">
                <div class="mr-2" i18n>Otevírám zvolený soubor...</div>
                <div class="spinner-wrapper">
                  <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                    <span class="sr-only"></span>
                  </div>
                </div>
              </h6>

              <div class="row p-1" [style.width]="widthPopoverOfFiles(it.obligation)">
                <div *ngFor="let file of it.obligation.attachments" class="p-0 px-2 mb-2 position-relative" style="width:130px">
                  <!-- loading thumbnail -->
                  <div *ngIf="file.loadingThumbnail" class="w-100 text-center">
                    <div class="spinner-wrapper">
                      <div class="spinner-border text-primary" style="width:1rem; height:1rem;" role="status">
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  </div>
                  <!-- thumbnail loaded -->
                  <div *ngIf="!file.loadingThumbnail">
                    <a (click)="downloadAttachment(file)" href="javascript:void(0)">
                      <div *ngIf="file.thumbnail" class="thumbnail border rounded shadow" [ngbTooltip]="file.name">
                        <img class="thumbnail" [src]="file.thumbnail.content | safeResource" alt="Náhled"/>
                      </div>
                      <div *ngIf="!file.thumbnail" class="thumbnail border rounded shadow" [ngbTooltip]="file.name">
                        <img class="thumbnail" src="assets/icons-new/folder_document.svg" alt="Náhled"/>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <!-- details -->
  <div class="w-100">
    <div *ngIf="it.obligation && it.diary_detail" class="container pt-1 border-top text-left">
      <div class="row">
        <div class="col-12">
          <div class="border-bottom border-secondary">
            <div>
              <ng-container i18n>Detail zakázky</ng-container>
              <a class="font-weight-bold" href="javascript:void(0)" 
              (click)="openObligation(it.obligation.obligation_key)">
                {{it.obligation.order_number_standard}}
                <span *ngIf="it.obligation.order_number">
                  ({{it.obligation.order_number}})
                </span>
              </a>
            </div>
            <div *ngIf="it.obligation.order_comp_book" class="font-weight-bold">
              {{it.obligation.order_comp_book.company}} ({{it.obligation.order_comp_book.country}})
            </div>
            <div>
              <span *ngIf="it.obligation.payer_contact; else noObligationPayerContact">
                {{it.obligation.payer_contact}}
              </span>
              <ng-template #noObligationPayerContact><em i18n>kontakt chybí</em></ng-template>,
              <span *ngIf="it.obligation.payer_phone; else noObligationPayerPhone">
                <a [href]="'tel:' + it.obligation.payer_phone">{{it.obligation.payer_phone}}</a>
              </span>
              <ng-template #noObligationPayerPhone><em i18n>tel. chybí</em></ng-template>,
              <span *ngIf="it.obligation.payer_email; else noObligationPayerEmail">
                <a [href]="'mailto:' + it.obligation.payer_email">{{it.obligation.payer_email}}</a>
              </span>
              <ng-template #noObligationPayerEmail><em i18n>email chybí</em></ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="it.obligation.orders" class="col-12">
          <div *ngFor="let order of it.obligation.orders" class="border-bottom border-secondary">
            <div>
              <ng-container i18n>Detail objednávky</ng-container>
              <a class="font-weight-bold" href="javascript:void(0)" 
              (click)="openOrder(order.order_key)">
                {{order.order_number_standard}}
              </a>
            </div>
            <div *ngIf="order.order_comp_book" class="font-weight-bold">
              {{order.order_comp_book.company}} ({{order.order_comp_book.country}})
            </div>
            <div>
              <ng-container i18n>Dispečer</ng-container>: 
              <span *ngIf="order.order_contact; else noOrderContact">{{order.order_contact}}</span>
              <ng-template #noOrderContact><em i18n>kontakt chybí</em></ng-template>,
              <span *ngIf="order.order_phone; else noOrderPhone">
                <a [href]="'tel:' + order.order_phone">{{order.order_phone}}</a>
              </span>
              <ng-template #noOrderPhone><em i18n>tel. chybí</em></ng-template>,
              <span *ngIf="order.order_email; else noOrderEmail">
                <a [href]="'mailto:' + order.order_email">{{order.order_email}}</a>
              </span>
              <ng-template #noOrderEmail><em i18n>email chybí</em></ng-template>
            </div>
            <div>
              <ng-container i18n>Řidič</ng-container>: 
              <span *ngIf="order.spz; else noOrderSpz">{{order.spz}}</span>
              <ng-template #noOrderSpz><em i18n>SPZ chybí</em></ng-template>,
              <span *ngIf="order.driver_name; else noOrderDriverName">{{order.driver_name}}</span>
              <ng-template #noOrderDriverName><em i18n>řidič chybí</em></ng-template>,
              <span *ngIf="order.driver_phone; else noOrderDriverPhone">
                <a [href]="'tel:' + order.driver_phone">{{order.driver_phone}}</a>
              </span>
              <ng-template #noOrderDriverPhone><em i18n>tel. chybí</em></ng-template>
            </div>
          </div>
        </div>
    
        <div class="col-12"><ng-container i18n>Itinerář</ng-container>:</div>
        <div *ngFor="let it of it.obligation.itinerary; let i=index" class="col-12 mb-1">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              {{i+1}})
              <img [src]="it.typeIcon()" class="mx-1" alt="icon" height="16px"/>
              <span *ngIf="it.arrival_time">
                {{it.arrival_time | date: 'dd.MM.yyyy HH:mm'}}
                (<ng-container i18n>Lze</ng-container> 
                {{it.work_day_begin_formatted}}-{{it.work_day_end_formatted}})
              </span>
              <img *ngIf="it.completed" class="ml-2" src="assets/icons-new/check-solid.svg" height="16px"
              ngbTooltip="Dokončena" placement="auto" i18n-ngbTooltip/>
            </div>
            <div *ngIf="it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING || it.type == ItineraryType.WAREHOUSE">
              {{it.weight ? it.weight : 0}}t,
              {{it.ware_pcs ? it.ware_pcs : 0}}
              {{it.ware_pcs ? it.ware_type : ''}}
            </div>
          </div>
          <div>
            {{it.address}}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- modal for sending attachments via email -->
<div class="modal fade" role="dialog" tabindex="-1" id="sendAttachmentsModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <div *ngIf="obligationToSend">
          <h4 class="modal-title">
            <ng-container i18n>Odeslat doklady</ng-container> - {{obligationToSend.order_number}}
          </h4>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="obligationToSend">
          <app-ta1-obligation-emailing #myEmailingChild
          [agenda]="obligationToSend"
          (emailHasBeenSent)="eventAfterEmailSent($event)"
          [attachments]="attachmentsToSend">
          </app-ta1-obligation-emailing>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal for completing loading/unloading itinerary via api -->
<div class="modal fade" role="dialog" tabindex="-1" id="completeItineraryModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <div class="modal-title">
          <h4>Dokončení {{itineraryToComplete?.type == ItineraryType.LOADING ? 'nakládky' : 'vykládky'}} pomocí TAgendy</h4>
          <h6 *ngIf="itineraryToComplete" class="text-center my-1">
            {{itineraryToComplete.address}}
          </h6>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body bg-secondary-dark">
        <div *ngIf="itineraryToComplete" style="min-height: 600px;"
        class="container-fluid itinerary-complete bg-secondary-dark py-3 h-100">
          <div class="container">
            <div class="row">
              <div class="col-12 mb-2">
                <div class="form-group row">
                  <label class="col-5 text-white" i18n>Zakázka</label>
                  <div class="col-7">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="text" placeholder="Číslo zakázky" disabled
                    [ngModel]="itineraryToComplete.obligation?.order_number_standard"/>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-1">
                <div class="form-group row">
                  <label class="col-5 text-white" i18n>Datum a čas</label>
                  <div class="col-7">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="datetime-local" [(ngModel)]="itineraryToComplete.arrival_time_custom"/>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2 pt-3 border-top">
                <div class="form-group row">
                  <label class="col-5 text-white" i18n>Hmotnost (t)</label>
                  <div class="col-7">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="number" placeholder="0" min="0" max="100" 
                    [(ngModel)]="itineraryToComplete.weight"/>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="form-group row">
                  <label class="col-5 text-white" i18n>Počet kusů</label>
                  <div class="col-7">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="number" placeholder="0" min="0" [(ngModel)]="itineraryToComplete.ware_pcs"/>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <div class="form-group row">
                  <label class="col-5 text-white" i18n>Balení</label>
                  <div class="col-7">
                    <input class="form-control text-white bg-secondary-dark" 
                    type="text" [(ngModel)]="itineraryToComplete.ware_type"/>
                  </div>
                </div>
              </div>

              <div class="col-12 pt-2 border-top">
                <div class="form-group row">
                  <h5 class="col-12 text-white text-center" i18n>Poznámka</h5>
                  <div class="col-12">
                    <textarea class="form-control text-white bg-secondary-dark" 
                    placeholder="Text poznámky až 100 znaků..." style="height: 80px"
                    [(ngModel)]="itineraryToComplete.note"></textarea>
                  </div>
                </div>
              </div>
            </div>
          
            <div class="row my-4">
              <div class="col-12 mb-1">
                <button class="btn btn-primary w-100" type="button" (click)="completeItinerary()">
                  <ng-container i18n>Potvrdit dokončení</ng-container>
                </button>
              </div>
              <!-- <div class="col-6 mb-2">
                <button class="btn btn-warning w-100" type="button" disabled>
                  Naviguj
                </button>
              </div>
              <div class="col-6">
                <button class="btn btn-secondary w-100" type="button" disabled>
                  Dokumenty
                </button>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- modal for sending order completed notification -->
<div class="modal fade" role="dialog" tabindex="-1" id="sendOrderCompletedModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <div *ngIf="itineraryToCompletedEmail">
          <h4 class="modal-title">
            <ng-container i18n>Urgence dokončení</ng-container> 
            {{itineraryToCompletedEmail.type == "L" ? "nakládky" : "vykládky"}}
            {{itineraryToCompletedEmail.place_country}} {{itineraryToCompletedEmail.place_city}}
          </h4>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="orderToCompletedEmail?.order_key">
          <app-ta1-order-emailing 
            [order]="orderToCompletedEmail"
            [itinerary_to_email]="itineraryToCompletedEmail">
          </app-ta1-order-emailing>
        </div>
      </div>
    </div>
  </div>
</div>