import { AfterViewChecked, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs/index";

import { TruckManagerLayoutService}  from "../../service/truck-manager-layout.service";
import { GoogleMapsApiService } from "../../service/google-maps-api.service";
import { MessageService } from "../../service/message.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { FilesService } from "../../service/files.service";
import { NotificationService } from "../../service/notification-service";
import { Vehicle } from "../../model/vehicle.object";
// import { GoogleMapMarker } from "../../model/google-map-marker.object";
import { Numbers } from "../../tools/Numbers";
import { ServiceConfiguration } from "../../config";
import { ExternalApiRequest } from "src/app/model/external-api-request.object";
import { ExternalApiRequestService } from "src/app/service/external-api-request.service";
import { GoogleMapAdvMarker } from "src/app/model/google-map-adv-marker.object";

declare var google: any;
declare var base64js: any;

@Component({
  selector: 'vehicle-dispositions-configuration',
  templateUrl: './r-vehicle-dispositions-configuration.component.html',
  styleUrls: ['./r-vehicle-dispositions-configuration.component.css']
})
export class RVehicleDispositionsConfigurationComponent implements OnInit, OnDestroy, AfterViewChecked {

  private subscriptions: Array<Subscription> = [];

  private autocomplete: any;
  private vehicleId: number;
  
  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this.defaultInit();
    this._vehicle = vehicle;
  }

  private _loading: boolean = false;
  get loading(): boolean {
    return this._loading;
  }

  private _currentPdf: any;
  get currentPdf(): any {
    return this._currentPdf;
  }

  private _types = [2, 1, 3, 4, 5];
  get types(): Array<number> {
    return this._types;
  }

  private _errorMessage: string;
  public get errorMessage(): string {
    return this._errorMessage;
  }

  private _routeLength: number = 0; //km
  get routeLength(): number {
    return this._routeLength;
  }

  private _markers: Array<GoogleMapAdvMarker> = [];
  get markers(): Array<GoogleMapAdvMarker> {
    return this._markers;
  }
  set markers(value: Array<GoogleMapAdvMarker>) {
    this._markers = value;
  }

  private _waypoints: Array<any> = [];
  get waypoints() {
    return this._waypoints;
  }

  private _sendGoogleMapsLink: boolean = false;
  get sendGoogleMapsLink(): boolean {
    return this._sendGoogleMapsLink;
  }
  set sendGoogleMapsLink(value: boolean) {
    this._sendGoogleMapsLink = value;
  }

  private _address: string = '';
  get address(): string {
    return this._address;
  }
  set address(value: string) {
    this._address = value;
  }

  private _text: string = '';
  get text(): string {
    return this._text;
  }
  set text(value: string) {
    this._text = value;
  }

  private _orderNumber: string = '';
  get orderNumber(): string {
    return this._orderNumber;
  }
  set orderNumber(value: string) {
    this._orderNumber = value;
    this.generateMessage(this._markers);
  }

  private _editableText: boolean = false;
  get editableText(): boolean {
    return this._editableText;
  }
  set editableText(value: boolean) {
    this._editableText = value;
    this.generateMessage(this.markers);
  }

  private _closed: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  get closed(): Observable<any> {
    return this._closed.asObservable();
  }

  constructor(
    private messageService: MessageService,
    private notificationService: NotificationService,
    private googleMapsService: GoogleMapsApiService,
    private layout: TruckManagerLayoutService,
    private vehicleService: VehicleNewService,
    private filesService: FilesService,
    private _externalApiRequestServ: ExternalApiRequestService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    // layout.isDashboardFullMode = true;
    messageService.newMessagesDialogDisabled = true;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.route.params.subscribe(
        params => {
          this.vehicleId = parseInt(params['vehicleId']);
          this.subscriptions.push(
            this.vehicleService.getVehicles().subscribe(
              vehicles => {
                vehicles.forEach(
                  vehicle => {
                    if (vehicle.car_key === this.vehicleId) {
                      this.vehicle = vehicle;
                    }
                  }
                )
              }
            )
          );
        }
      )
    );
  }

  ngAfterViewChecked() {
    if (!this.autocomplete) {
      let input = document.getElementById('search-input');
      if (input instanceof HTMLInputElement) {
        this.createAutocomplete(input);
      }
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    // this.layout.isDashboardFullMode = false;
    this.messageService.newMessagesDialogDisabled = false;
    this.autocomplete = null;
  }

  defaultInit(): void {
    this._currentPdf = null;
    this._routeLength = 0;
    this._markers = [];
    this._waypoints = [];
    this._address = '';
    this._text = '';
    this._orderNumber = '';
  }


  get routeLengthReloading(): boolean {
    return this._routeLength === null;
  }

  createAutocomplete(input: any) {
    // only 3 basic fields
    const options: any = {
      fields: ["formatted_address", "geometry", "name"]
    };
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'autocomplete';
    api_request_log.descr = 'vehicle-dispositions-configuration';
    api_request_log.price = 0.00227;
    this._externalApiRequestServ.createRequestLog(api_request_log);

    this.autocomplete.addListener('place_changed', () => {
      // api request call
      let api_request_log: ExternalApiRequest = new ExternalApiRequest();
      api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
      api_request_log.type = 'place-details';
      api_request_log.descr = 'vehicle-dispositions-configuration';
      api_request_log.price = 0.004;
      this._externalApiRequestServ.createRequestLog(api_request_log);

      // place details
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      this.address = place.name + ' ' + place.formatted_address;
      let marker = this.createMarker(place.geometry.location);
      marker.setData('address', this.address);
      this.addMarker(marker);
    });
  }

  close() {
    this._closed.emit(true);
  }

  backToVehicles(): void {
    this.router.navigate([{outlets: {right: ["cars"]}}])
  }

  dropped(event): void {
    if (event.target && event.target.files instanceof FileList) {
      let file = event.target.files[0];

      if (file.type !== '' && (file.type.match(/image|pdf|text/))) {
        this._currentPdf = {
          content: URL.createObjectURL(file),
          type: file.type
        };
      } else {
        this.filesService.convertToPdfBase64(file).subscribe(
          (pdfContent => {

            this._currentPdf = {
              content: 'data:application/pdf;base64, ' + pdfContent,
              type: 'application/pdf'
            };
          })
        );
      }
      event.target.value = null;
    }
  }

  removePdf() {
    this._currentPdf = null;
    this._routeLength = 0;
  }


  private generateMessage(markers: Array<GoogleMapAdvMarker>) {
    let text: string = '';

    if (this._orderNumber.length) {
      text += $localize`Číslo zakázky` + ': ' + this._orderNumber + '\n------------------------\n';
    }

    this._markers.forEach(
      marker => {
        this.updateMarker(marker);
        text += marker.infoWindowContent;
        if (marker.getData('additionalText')) {
          text += ', ' + marker.getData('additionalText')
        }
        text += '\n------------------------\n';
      }
    );
    let routeLength = this.routeLengthReloading ? '0' : this._routeLength.toString();
    text += ServiceConfiguration.manualTrackingMessageRouteDistanceFormat.replace('%DISTANCE%', routeLength);
    this._text = text;
  }

  sendMessageToVehicle() {
    let message = this._text;
    if (this._sendGoogleMapsLink) {
      let waypoints: Array<string> = [];
      this._markers.forEach(
        marker => {
          waypoints.push(
            Numbers.round(marker.latitude, 4) + ',' + Numbers.round(marker.longitude, 4)
          )
        }
      );
      message += '\n' + ServiceConfiguration.manualTrackingMessageGMapsLinkFormat.replace(
        '%WAYPOINTS%',
        waypoints.join(
          ServiceConfiguration.manualTrackingMessageGMapsLinkWaypointGlue
        )
      ) + '\n';
    }
    if (this._orderNumber) {
      message += '<#ord:' + this._orderNumber + '>';
    }

    this.messageService.sendMessageToVehicle(
      message,
      this._vehicle
    ).subscribe(
      () => {
        this.notificationService.alert($localize`Zpráva odeslána`, 'success');
        this.backToVehicles();
      },
      () => {
        this.notificationService.alert($localize`Zpráva nebyla odeslána`, 'error');
      }
    )
  }

  
  /***************************************************/
  /* google map methods and stuff */
  /***************************************************/
  private _mapProperties = {
    scrollwheel: true,
    zoom: 5
  };

  get mapProperties(): { scrollwheel: boolean; zoom: number } {
    return this._mapProperties;
  }

  private getClosesMatchingLocationFromGeocodeResponse(result) {
    return GoogleMapsApiService.getLocationClosesMatch(result);
  }

  mapClicked(clickData: any) {
    this._loading = true;
    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'geocoding';
    api_request_log.descr = 'vehicle-dispositions-configuration';
    api_request_log.price = 0.005;
    this._externalApiRequestServ.createRequestLog(api_request_log);

    this.googleMapsService.geocode(Numbers.round(clickData.latLng.lat(), 4).toString() + ',' + Numbers.round(clickData.latLng.lng(), 4).toString(),
      (result, status) => {
        if (status === 'OK') {
          let closestMatch: string = this.getClosesMatchingLocationFromGeocodeResponse(result);
          if (!closestMatch) {
            closestMatch = Numbers.round(clickData.latLng.lat(), 4).toString() + ',' + Numbers.round(clickData.latLng.lng(), 4).toString();
          }
          this.address = closestMatch;
          this.search();
        } else {
          this.address = Numbers.round(clickData.latLng.lat(), 4).toString() + ',' + Numbers.round(clickData.latLng.lng(), 4).toString();
          this.search();
        }
      }
    );
  }

  search() {
    if (!this._address.trim().length) {
      this._errorMessage = $localize`Adresa je povinná`;
    }
    this._errorMessage = null;
    this._loading = true;
    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'geocoding';
    api_request_log.descr = 'vehicle-dispositions-configuration';
    api_request_log.price = 0.005;
    this._externalApiRequestServ.createRequestLog(api_request_log);

    this.googleMapsService.geocode(this.address, (results: any, status: string) => {
      if (status === 'OK') {
        let marker = this.createMarker(results[0].geometry.location);
        marker.setData('address', this._address);
        this.addMarker(marker);
        this.address = '';
      } else {
        this._errorMessage = $localize`Adresa nebyla nalezena mapovou službou`;
      }
      this._loading = false;
    });
  }

  directionsChange(event) {
    this.updateMarkerLocation(this.markers[event.index], event.rendered.getDirections().request.origin.location || event.rendered.getDirections().request.origin);
    if (this.markers[event.index + 1]) {
      this.updateMarkerLocation(this.markers[event.index + 1], event.rendered.getDirections().request.destination.location || event.rendered.getDirections().request.destination);
    }
    event.rendered.getDirections().request.waypoints.forEach(
      waypoint => {
        let location = waypoint.location.location ? waypoint.location.location : waypoint.location;
        if (location) {
          let self = this;

          // api request log call
          let api_request_log: ExternalApiRequest = new ExternalApiRequest();
          api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
          api_request_log.type = 'geocoding';
          api_request_log.descr = 'vehicle-dispositions-configuration';
          api_request_log.price = 0.005;
          this._externalApiRequestServ.createRequestLog(api_request_log);

          this.googleMapsService.geocode(location.lat() + ',' + location.lng(),
            (result, status) => {
              if (status === 'OK') {
                let marker = self.createMarker(location);
                let address = self.getClosesMatchingLocationFromGeocodeResponse(result);
                if (!address) {
                  address = Numbers.round(location.lat(), 4).toString() + ',' + Numbers.round(location.lng(), 4).toString();
                }
                marker.setData('address', address);
                self.updateMarker(marker);
                let firstPart = self.markers.slice(0, event.index + 1);
                let secondPart = [marker];
                let thirdPart = self.markers.slice(event.index + 1, self.markers.length);
                self.markers = firstPart.concat(secondPart, thirdPart);
                self.rebuildMarkers();
              } else {
                console.warn('Google service error');
              }
            })
        }
      }
    );
    if (event.index === this._markers.length - 2) {
      let distance = 0;
      event.allRendered.forEach(
        directionsRenderer => {
          if (directionsRenderer.getDirections()) {
            distance += Math.round(directionsRenderer.getDirections().routes[0].legs[0].distance.value / 1000)
          }
        }
      );
      this._routeLength = distance;
    }
    this.generateMessage(this._markers);
  }


  private rebuildMarkers() {
    this._markers = this._markers.slice();
    this._markers.forEach(
      (marker, index) => {
        marker.icon = 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=' + (index + 1) + '|FE6256|000000';
      }
    );
    this.generateMessage(this._markers);
  }

  private createMarker(location: any): GoogleMapAdvMarker {
    let marker = new GoogleMapAdvMarker();
    marker.position = location;
    marker.draggable = true;
    marker.setData('type', this._types[0]);
    marker.setData('id', Math.random().toString(36).substring(7));
    marker.getGoogleMarker().addListener('dragend', (event) => {
      let self = this;
      // api request log call
      let api_request_log: ExternalApiRequest = new ExternalApiRequest();
      api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
      api_request_log.type = 'geocoding';
      api_request_log.descr = 'vehicle-dispositions-configuration';
      api_request_log.price = 0.005;
      this._externalApiRequestServ.createRequestLog(api_request_log);

      this.googleMapsService.geocode(event.latLng.lat() + ',' + event.latLng.lng(),
        (result, status) => {
          if (status === 'OK') {
            let address = self.getClosesMatchingLocationFromGeocodeResponse(result);
            if (!address) {
              address = Numbers.round(location.lat(), 4).toString() + ',' + Numbers.round(location.lng(), 4).toString();
            }
            marker.setData('address', address);
            this.updateMarker(marker);
            this.rebuildMarkers();
          } else {
            console.warn('Google service error');
          }
        })
    });
    return marker;
  }
  
  private addMarker(marker: GoogleMapAdvMarker) {
    let length = this._markers.push(marker);
    if (this.markers.length === 1) {
      this._routeLength = 0;
    } else {
      this._routeLength = null;
    }
    this.updateMarker(marker);
    this.rebuildMarkers();
  }
  
  private updateMarker(marker: GoogleMapAdvMarker) {
    marker.infoWindowContent = ServiceConfiguration.manualTrackingMessagePointFormat
      .replace('%INDEX%', (this._markers.indexOf(marker) + 1).toString())
      .replace('%TYPE%', marker.getData('type'))
      .replace('%ADDRESS%', marker.getData('address') + ' (' + Numbers.round(marker.latitude, 4).toString() + ',' + Numbers.round(marker.longitude, 4).toString() + ') ');
  }
  
  private updateMarkerLocation(marker: GoogleMapAdvMarker, location: any) {
    let address = marker.getData('address');
    let regenerate = false;
    if (address === marker.latitude + ',' + marker.longitude) {
      regenerate = true;
    }
    marker.position = location;
    if (regenerate) {
      marker.setData('address', marker.latitude + ',' + marker.latitude);
    }
  }

  changeMarkerType(marker: GoogleMapAdvMarker, value: any) {
    this.changeType(value, marker);
  }

  changeType(value, marker: GoogleMapAdvMarker) {
    marker.setData('type', value);
    this.generateMessage(this._markers);
  }

  moveUp(marker: GoogleMapAdvMarker): void {
    this.swapMarkers(marker, 1);
  }

  moveDown(marker: GoogleMapAdvMarker): void {
    this.swapMarkers(marker, -1);
  }

  private swapMarkers(marker: GoogleMapAdvMarker, move: number): void {
    let index = this.markers.indexOf(marker);
    if (index === this.markers.length - 1 && move === -1) {
      this.markers = [marker].concat(this.markers.slice(0, this.markers.length - 1));
    } else if (index === 0 && move === 1) {
      this.markers = this.markers.slice(1).concat([marker]);
    } else {
      let before = index - move;
      let temp = this.markers[before];
      this.markers[before] = this.markers[index];
      this.markers[index] = temp;
    }
    this.rebuildMarkers();
  }
  
  removeMarker(marker: GoogleMapAdvMarker) {
    let position = this._markers.indexOf(marker);
    if (position > -1) {
      this._markers.splice(position, 1);

      if (this.markers.length === 1) {
        this._routeLength = 0;
      } else {
        this._routeLength = null;
      }
      this.rebuildMarkers();
      marker.map = null;
    }
  }
}
