import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router, Scroll } from "@angular/router";
import { Subscription } from "rxjs";
import { TruckManagerLayoutService } from "../../service/truck-manager-layout.service";
import { CompanyService } from "../../service/company.service";
import { DelegationsService } from "../../service/delegations.service";
import { NotificationService } from "../../service/notification-service";
import { Delegation } from 'src/app/model/delegation.object';
import { IS_DEMO, IS_PRODUCTION } from "src/app/config";
import { AuthenticationService } from "src/app/service/authentication.service";
import { User } from "src/app/model/user.object";

// import * as $ from 'jquery';
// declare let $ : any;
declare var $: any;

@Component({
  selector: 'menu-left',
  templateUrl: './menu-left.component.html',
  styleUrls: ['./menu-left.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DelegationsService]
})
export class MenuLeftComponent implements OnInit, OnDestroy {

  private _navigationSubscription: any;
  private _subscribed: Array<Subscription> = [];

  private readonly _iconSrc: string = 'assets/icons-new/';

  private readonly _mycarsUrl: string = 'left:tm/mycars';
  private readonly _servicesUrl: string = 'left:tm/services';
  private readonly _statisticsUrl: string = 'left:tm/statistics';
  private readonly _carbookUrl: string = 'left:tm/carbook';
  private readonly _company_obligationUrl: string = 'left:ta1-obligation/company_obligation';
  private readonly _company_orderUrl: string = 'left:ta1-order/company_order';
  private readonly _obligation_listUrl: string = 'left:ta1-obligation/obligation_list';
  private readonly _order_listUrl: string = 'left:ta1-order/order_list';
  private readonly _obligation_salesUrl: string = 'left:ta1-obligation/obligation_sales';
  private readonly _fueling_statsUrl: string = 'left:ta1-other/fueling_stats';
  private readonly _warehouse_planUrl: string = 'left:ta1-other/warehouse-plan';
  private readonly _warehouse_controUrl: string = 'left:ta1-other/warehouse-control';
  private readonly _emissioncalcUrl: string = 'left:ta1-other/emission-calc';
  private readonly _express_listUrl: string = 'left:ta1-obligation/express_list';
  private readonly _express_planUrl: string = 'left:ta1-obligation/express_plan';
  private readonly _searchUrl: string = 'left:ta2/search';
  private readonly _company_newUrl: string = 'left:ta2/company_new';
  private readonly _company_importUrl: string = 'left:ta2/company_import';
  private readonly _invoice_conditionUrl: string = 'left:ta3/invoice_condition';
  private readonly _invoice_in_conditionUrl: string = 'left:ta3/invoice_in_condition';
  private readonly _invoice_listUrl: string = 'left:ta3/invoice_list';
  private readonly _gen_for_invoicingUrl: string = 'left:ta3/gen_for_invoicing';
  private readonly _paletteUrl: string = 'left:ta3/palette';
  private readonly _invoice_exportUrl: string = 'left:ta3/invoice_export';
  private readonly _payment_bankUrl: string = 'left:ta3/payment_bank';
  private readonly _remindersUrl: string = 'left:ta3/reminders';
  private readonly _code_series_obligation: string = 'left:ta4/code_series_obligation';
  private readonly _code_overheadsUrl: string = 'left:ta4/code_overheads';
  private readonly _code_invoice_paramUrl: string = 'left:ta4/code_invoice_param';
  private readonly _code_order_paramUrl: string = 'left:ta4/code_order_param';
  private readonly _code_reminders_paramUrl: string = 'left:ta4/code_reminders_param';
  private readonly _code_often_used_posUrl: string = 'left:ta4/code_often_used_pos';
  private readonly _delivery_linesUrl: string = 'left:ta4/delivery_lines';
  private readonly _user_editUrl: string = 'left:ta4/user_edit';
  private readonly _contactUrl: string = 'left:general/contact';
  private readonly _profileUrl: string = 'left:general/profile';
  private readonly _subscriptionUrl: string = 'left:general/subscription';

  public readonly dispBoard: string = 'truckagenda/disp_board.php';
  public readonly dispDiary: string = 'truckagenda/disp_diary.php';
  public readonly dataOverview: string = 'login_charts/operating_data_overview.php';
  public readonly calc: string = 'truckagenda/calc/index.php?fromNew=truckmanager&from=eurosped2';
  public readonly cargoGather: string = 'truckagenda/cargo_gather.php';
  public readonly genOrder: string = 'truckagenda/genOrderForInvoicing.php';
  public readonly genPallet: string = 'truckagenda/genPalletFlow.php';

  public readonly delDefInvoice: string = 'truckagenda/code_invoice_param.php?actions=deldeffile';
  public readonly delDefInvoiceAuto: string = 'truckagenda/code_invoice_param.php?actions=delautofile';
  public readonly delDefInvoiceItem: string = 'truckagenda/code_invoice_item.php?actions=deldeffile';
  public readonly delDefStredisko: string = 'truckagenda/code_series_obligation.php?actions=deldeffile';


  get user(): User {
    return this._authServ.user;
  }

  constructor(
    private _layout: TruckManagerLayoutService,
    private _authServ: AuthenticationService,
    private _company: CompanyService,
    private _delegationsServ: DelegationsService,
    private _notificationServ: NotificationService,
    private _cdr: ChangeDetectorRef,
    private _router: Router
  ) {
    // subscribe to the router events. Store the subscription so we can unsubscribe later.
    this._navigationSubscription = this._router.events.subscribe(
      (e: any) => {
        // If it is a NavigationEnd event -> actualize active tabs and icons
        // if (e instanceof NavigationEnd || (e instanceof Scroll && e.routerEvent instanceof NavigationEnd)) {
        if (e instanceof Scroll && e.routerEvent instanceof NavigationEnd) {
          let url: string = '';
          if (e.routerEvent) {
            url = e.routerEvent.urlAfterRedirects;
          }
          if (url) {
            this._activeUrl = url;

            // set active tabs
            // tm
            // this.setIsDefaultUrl();
            this.setIsStatisticsActive();
            this.setIsCarbookActive();
            this.setIsServicesActive();
            this.setIsMycarsActive();
            this.setIsManagerActive();
            
            // tagenda
            this.setIsObligationListActive();
            this.setIsCompanyObligationActive();
            this.setIsExpressListActive();
            this.setIsOrderListActive();
            this.setIsCompanyOrderActive();
            this.setIsObligationSalesActive();
            this.setFuelingStatsActive();
            this.setIsWarehousePlanActive();
            this.setIsWarehouseControlActive();
            this.setIsEmissionCalcActive();
            this.setIsZakazkyActive();

            this.setIsSearchActive();
            this.setIsCompanyNewActive();
            this.setIsCompanyImportActive();
            this.setIsAdresarActive();

            this.setIsInvoiceListActive();
            this.setIsGenForInvoicingActive();
            this.setIsInvoiceConditionActive();
            this.setIsInvoiceInConditionActive();
            this.setIsPaymentBank();
            this.setIsRemindersActive();
            this.setIsFuelingImportActive();
            // this.setIsExportActive();
            this.setIsPaletteActive();
            this.setIsFakturyActive();

            this.setIsCodeSeriesObligation();
            this.setIsCodeOverheads();
            this.setIsCodeInvoiceParam();
            this.setIsCodeOrderParam();
            this.setIsCodeRemindersParam();
            this.setIsCodeOftenUsedPos();
            this.setIsDeliveryLines();
            this.setIsUserEdit();
            this.setIsNastaveniActive();

            this.setIsAgendaActive();
            
            // contact
            this.setIsContactActive();
            
            // user config
            this.setIsProfileActive();
            this.setIsSubscriptionActive();
            this.setIsConfigActive();

            // set current color icons (gray/white/black)
            this.setManagerSrc();
            this.setStatisticsSrc();
            this.setCarbookSrc();
            this.setServicesSrc();
            this.setMycarsSrc();
            this.setAgendaSrc();
            this.setObligation_listSrc();
            this.setCompany_obligationSrc();
            this.setExpress_listSrc();
            this.setOrder_listSrc();
            this.setObligation_salesSrc();
            this.setFuelingStatsSrc();
            this.setWarehousePlanSrc();
            this.setWarehouseControlSrc();
            this.setCalculatorSrc();
            this.setSearchSrc();
            this.setInvoice_listSrc();
            this.setGen_for_invoicingSrc();
            this.setPayment_bankSrc();
            this.setRemindersSrc();
            this.setInvoice_inSrc();
            this.setContactSrc();
            this.setConfigSrc();

            // fast choice div
            this.setStatisticsSrcFast();
            this.setCarbookSrcFast();
            this.setServicesSrcFast();
            this.setMycarsSrcFast();
            this.setObligation_listSrcFast();
            this.setCompany_obligationSrcFast();
            this.setExpress_listSrcFast();
            this.setOrder_listSrcFast();
            this.setObligation_salesSrcFast();
            this.setFuelingStatsSrcFast();
            this.setWarehousePlanSrcFast();
            this.setWarehouseControlSrcFast();
            this.setCalculatorSrcFast();
            this.setSearchSrcFast();
            this.setInvoice_listSrcFast();
            this.setGen_for_invoicingSrcFast();
            this.setPayment_bankSrcFast();
            this.setRemindersSrcFast();
            this.setInvoice_inSrcFast();
          }
        }
      }
    );
    
    this._subscribed.push(
      this._authServ.authenticationResult.subscribe(
        user => {
          if (user) {
            // show alert when 5 days or later just before user admittance
            let nowPlusFiveDays: Date = new Date(Date.now() + 5*24*60*60*1000);
            if (user.admittance && user.admittance <= nowPlusFiveDays) {
              window.setTimeout(
                () => {
                  // show danger modal alert
                  (<any>$('#admittanceModal')).modal('show');
                }, 2000
              );
            }
          }
        }
      )
    );
    // // detect changes
    // window.setTimeout(
    //   () => {
    //     this._cdr.detectChanges();
    //   }, 500
    // );

    // custom change detection strategy
    // this._cdr.detach();
    // window.setTimeout(
    //   () => {
    //     this._cdr.detectChanges();
    //   }, 250
    // );
    // setInterval(
    //   () => {
    //     this._cdr.detectChanges();
    //   }, 3000
    // );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this._navigationSubscription) {
      this._navigationSubscription.unsubscribe();
      this._navigationSubscription = null;
    }
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  get staticContentResource(): string {
    return this._layout.staticContentResource;
  }

  // datum konce pristupu zakaznika
  get admittanceDate(): string {
    return this._layout.user.admittance.toLocaleDateString();
  }

  // pocet do konce pristupu zakaznika
  get remains(): string {
    let secondsRemains: number = this._layout.user.admittance.getTime() / 1000;
    let secondsCurrent: number = new Date().getTime() / 1000;
    let days: number = Math.floor((secondsRemains - secondsCurrent) / 86400);

    return days.toString();
  }

  get companyId(): string {
    return this._company.company_id;
  }

  get company(): string {
    return this._company.company;
  }

  get isProduction(): boolean {
    return IS_PRODUCTION;
  }

  private _activeUrl: string = '';
  isRouteActive(url: string): boolean {
    return this._activeUrl.includes(url);
    // return this._router.url.includes(url);
  }
  

  // tm tabs
  public isManagerActive: boolean = true;
  setIsManagerActive(): void {
    this.isManagerActive = (
      // this.isDefaultUrl ||
      this.isStatisticsActive ||
      this.isCarbookActive ||
      this.isServicesActive ||
      this.isMycarsActive
    );
  }
  
  // public isDefaultUrl: boolean = true;
  // setIsDefaultUrl(): void {
  //   // default component is history/statistics
  //   if (!this._activeUrl.includes('left') || this.isRouteActive(this._statisticsUrl)) {
  //     this.isDefaultUrl = true;
  //   }
  //   else {
  //     this.isDefaultUrl = false;
  //   }
  // }

  public isStatisticsActive: boolean = false;
  setIsStatisticsActive(): void {
    this.isStatisticsActive = this.isRouteActive(this._statisticsUrl);
  }

  public isCarbookActive: boolean = false;
  setIsCarbookActive(): void {
    this.isCarbookActive = this.isRouteActive(this._carbookUrl);
  }
  
  public isServicesActive: boolean = false;
  setIsServicesActive(): void {
    this.isServicesActive = this.isRouteActive(this._servicesUrl);
  }
  
  public isMycarsActive: boolean = false;
  setIsMycarsActive(): void {
    this.isMycarsActive = this.isRouteActive(this._mycarsUrl);
  }


  // agenda tabs
  public isAgendaActive: boolean = false;
  setIsAgendaActive(): void {
    this.isAgendaActive = (
      this.isZakazkyActive || 
      this.isAdresarActive || 
      this.isFakturyActive || 
      this.isNastaveniActive
    );
  }

  public isZakazkyActive: boolean = false;
  setIsZakazkyActive(): void {
    this.isZakazkyActive = (
      this.isObligationListActive ||
      this.isCompanyObligationActive ||
      this.isExpressListActive ||
      this.isWarehousePlanActive ||
      this.isWarehouseControlActive ||
      this.isOrderListActive ||
      this.isCompanyOrderActive ||
      this.isObligationSalesActive ||
      this.isEmissionCalcActive
    );
  }

  public isObligationListActive: boolean = false;
  setIsObligationListActive(): void {
    this.isObligationListActive = this.isRouteActive(this._obligation_listUrl);
  }
  
  public isCompanyObligationActive: boolean = false;
  setIsCompanyObligationActive(): void {
    this.isCompanyObligationActive = this.isRouteActive(this._company_obligationUrl);
  }

  public isExpressListActive: boolean = false;
  setIsExpressListActive(): void {
    this.isExpressListActive = this.isRouteActive(this._express_listUrl);
  }

  public isWarehousePlanActive: boolean = false;
  setIsWarehousePlanActive(): void {
    this.isWarehousePlanActive = this.isRouteActive(this._warehouse_planUrl);
  }

  public isWarehouseControlActive: boolean = false;
  setIsWarehouseControlActive(): void {
    this.isWarehouseControlActive = this.isRouteActive(this._warehouse_controUrl);
  }

  public isOrderListActive: boolean = false;
  setIsOrderListActive(): void {
    this.isOrderListActive = this.isRouteActive(this._order_listUrl);
  }

  public isCompanyOrderActive: boolean = false;
  setIsCompanyOrderActive(): void {
    this.isCompanyOrderActive = this.isRouteActive(this._company_orderUrl);
  }

  public isObligationSalesActive: boolean = false;
  setIsObligationSalesActive(): void {
    this.isObligationSalesActive = this.isRouteActive(this._obligation_salesUrl);
  }

  public isFuelingStatsActive: boolean = false;
  setFuelingStatsActive(): void {
    this.isFuelingStatsActive = this.isRouteActive(this._fueling_statsUrl);
  }

  public isEmissionCalcActive: boolean = false;
  setIsEmissionCalcActive(): void {
    this.isEmissionCalcActive = this.isRouteActive(this._emissioncalcUrl);
  }


  public isAdresarActive: boolean = false;
  setIsAdresarActive(): void {
    this.isAdresarActive = (
      this.isSearchActive ||
      this.isCompanyNewActive ||
      this.isCompanyImportActive
    );
  }

  public isSearchActive: boolean = false;
  setIsSearchActive(): void {
    this.isSearchActive = this.isRouteActive(this._searchUrl);
  }

  public isCompanyNewActive: boolean = false;
  setIsCompanyNewActive(): void {
    this.isCompanyNewActive = this.isRouteActive(this._company_newUrl);
  }

  public isCompanyImportActive: boolean = false;
  setIsCompanyImportActive(): void {
    this.isCompanyImportActive = this.isRouteActive(this._company_importUrl);
  }


  public isFakturyActive: boolean = false;
  setIsFakturyActive(): void {
    this.isFakturyActive = (
      this.isInvoiceListActive ||
      this.isGenForInvoicingActive ||
      this.isPaymentBankActive ||
      this.isRemindersActive ||
      this.isInvoiceConditionActive ||
      this.isInvoiceInConditionActive ||
      this.isPaletteActive ||
      this.isFuelingImportActive 
      // this.isExportActive
    );
  }
  
  public isInvoiceListActive: boolean = false;
  setIsInvoiceListActive(): void {
    this.isInvoiceListActive = this.isRouteActive(this._invoice_listUrl);
  }

  public isGenForInvoicingActive: boolean = false;
  setIsGenForInvoicingActive(): void {
    this.isGenForInvoicingActive = this.isRouteActive(this._gen_for_invoicingUrl);
  }
  
  public isRemindersActive: boolean = false;
  setIsRemindersActive(): void {
    this.isRemindersActive = this.isRouteActive(this._remindersUrl);
  }

  public isPaymentBankActive: boolean = false;
  setIsPaymentBank(): void {
    this.isPaymentBankActive = this.isRouteActive(this._payment_bankUrl);
  }

  public isInvoiceConditionActive: boolean = false;
  setIsInvoiceConditionActive(): void {
    this.isInvoiceConditionActive = this.isRouteActive(this._invoice_conditionUrl);
  }

  public isInvoiceInConditionActive: boolean = false;
  setIsInvoiceInConditionActive(): void {
    this.isInvoiceInConditionActive = this.isRouteActive(this._invoice_in_conditionUrl);
  }
  
  public isPaletteActive: boolean = false;
  setIsPaletteActive(): void {
    this.isPaletteActive = this.isRouteActive(this._paletteUrl);
  }

  public isFuelingImportActive: boolean = false;
  setIsFuelingImportActive(): void {
    this.isFuelingImportActive = this.isRouteActive(this._invoice_exportUrl);
  }

  // public isExportActive: boolean = false;
  // setIsExportActive(): void {
  //   this.isExportActive = this.isRouteActive(this._invoice_exportUrl);
  // }


  public isNastaveniActive: boolean = false;
  setIsNastaveniActive(): void {
    this.isNastaveniActive = (
      this.isCodeSeriesObligation ||
      this.isCodeOverheads ||
      this.isCodeInvoiceParam ||
      this.isCodeOrderParam ||
      this.isCodeRemindersParam ||
      this.isCodeOftenUsedPos ||
      this.isDeliveryLines ||
      this.isUserEdit
    );
  }

  public isCodeSeriesObligation: boolean = false;
  setIsCodeSeriesObligation(): void {
    this.isCodeSeriesObligation = this.isRouteActive(this._code_series_obligation);
  }

  public isCodeOverheads: boolean = false;
  setIsCodeOverheads(): void {
    this.isCodeOverheads = this.isRouteActive(this._code_overheadsUrl);
  }
  
  public isCodeInvoiceParam: boolean = false;
  setIsCodeInvoiceParam(): void {
    this.isCodeInvoiceParam = this.isRouteActive(this._code_invoice_paramUrl);
  }
  
  public isCodeOrderParam: boolean = false;
  setIsCodeOrderParam(): void {
    this.isCodeOrderParam = this.isRouteActive(this._code_order_paramUrl);
  }
  
  public isCodeRemindersParam: boolean = false;
  setIsCodeRemindersParam(): void {
    this.isCodeRemindersParam = this.isRouteActive(this._code_reminders_paramUrl);
  }
  
  public isCodeOftenUsedPos: boolean = false;
  setIsCodeOftenUsedPos(): void {
    this.isCodeOftenUsedPos = this.isRouteActive(this._code_often_used_posUrl);
  }

  public isDeliveryLines: boolean = false;
  setIsDeliveryLines(): void {
    this.isDeliveryLines = this.isRouteActive(this._delivery_linesUrl);
  }
  
  public isUserEdit: boolean = false;
  setIsUserEdit(): void {
    this.isUserEdit = this.isRouteActive(this._user_editUrl);
  }


  // contact
  public isContactActive: boolean = false;
  setIsContactActive(): void {
    this.isContactActive = this.isRouteActive(this._contactUrl);
  }

  // config of profil or subscription
  public isConfigActive: boolean = false;
  setIsConfigActive(): void {
    this.isConfigActive = (
      this.isProfileActive ||
      this.isSubscriptionActive
    );
  }
  
  public isProfileActive: boolean = false;
  setIsProfileActive(): void {
    this.isProfileActive = this.isRouteActive(this._profileUrl);
  }
  
  public isSubscriptionActive: boolean = false;
  setIsSubscriptionActive(): void {
    this.isSubscriptionActive = this.isRouteActive(this._subscriptionUrl);
  }


  /*************************************************/
  /* Icon color source */
  /*************************************************/
  public managerSrc: string = this._iconSrc + 'logo-gray.svg';
  setManagerSrc(): void {
    this.managerSrc = (this.isManagerActive ?
      (this._iconSrc + 'logo-white.svg') :
      (this._iconSrc + 'logo-gray.svg'));
  }

  public statisticsSrc: string = this._iconSrc + 'history-gray.svg';
  setStatisticsSrc(): void {
    this.statisticsSrc = (this.isRouteActive(this._statisticsUrl) ?
      (this._iconSrc + 'history-white.svg') :
      (this._iconSrc + 'history-gray.svg'));
  }

  public carbookSrc: string = this._iconSrc + 'reports.svg';
  setCarbookSrc(): void {
    this.carbookSrc = (this.isRouteActive(this._carbookUrl) ?
      (this._iconSrc + 'reports-white.svg') :
      (this._iconSrc + 'reports-gray.svg'));
  }

  public servicesSrc: string = this._iconSrc + 'tools-gray.svg';
  setServicesSrc(): void {
    this.servicesSrc = (this.isRouteActive(this._servicesUrl) ?
      (this._iconSrc + 'tools-white.svg') :
      (this._iconSrc + 'tools-gray.svg'));
  }

  public mycarsSrc: string = this._iconSrc + 'small_truck-gray.svg';
  setMycarsSrc(): void {
    this.mycarsSrc = (this.isRouteActive(this._mycarsUrl) ?
      (this._iconSrc + 'small_truck-white.svg') :
      (this._iconSrc + 'small_truck-gray.svg'));
  }

  public agendaSrc: string = this._iconSrc + 'folders2-gray.svg';
  setAgendaSrc(): void {
    this.agendaSrc = (this.isAgendaActive ?
      (this._iconSrc + 'folders2-white.svg') :
      (this._iconSrc + 'folders2-gray.svg'));
  }

  public obligation_listSrc: string = this._iconSrc + 'folders2-gray.svg';
  setObligation_listSrc(): void {
    this.obligation_listSrc = (this.isRouteActive(this._obligation_listUrl) ?
      (this._iconSrc + 'folders2-white.svg') :
      (this._iconSrc + 'folders2-gray.svg'));
  }

  public company_obligationSrc: string = this._iconSrc + 'folder3-gray.svg';
  setCompany_obligationSrc(): void {
    this.company_obligationSrc = (this.isRouteActive(this._company_obligationUrl) ?
      (this._iconSrc + 'folder3-white.svg') :
      (this._iconSrc + 'folder3-gray.svg'));
  }

  public express_listSrc: string = this._iconSrc + 'route-gray.svg';
  setExpress_listSrc(): void {
    this.express_listSrc = (this.isRouteActive(this._express_listUrl) ?
      (this._iconSrc + 'route-white.svg') :
      (this._iconSrc + 'route-gray.svg'));
  }

  public order_listSrc: string = this._iconSrc + 'shopping_cart-gray.svg';
  setOrder_listSrc(): void {
    this.order_listSrc = (this.isRouteActive(this._order_listUrl) ?
      (this._iconSrc + 'shopping_cart-white.svg') :
      (this._iconSrc + 'shopping_cart-gray.svg'));
  }

  public obligation_salesSrc: string = this._iconSrc + 'chart_line-gray.svg';
  setObligation_salesSrc(): void {
    this.obligation_salesSrc = (this.isRouteActive(this._obligation_salesUrl) ?
      (this._iconSrc + 'chart_line-white.svg') :
      (this._iconSrc + 'chart_line-gray.svg'));
  }
  
  public fuelingStatsSrc: string = this._iconSrc + 'fuel-gray.svg';
  setFuelingStatsSrc(): void {
    this.fuelingStatsSrc = (this.isRouteActive(this._fueling_statsUrl) ?
      (this._iconSrc + 'fuel-white.svg') :
      (this._iconSrc + 'fuel-gray.svg'));
  }

  public warehousePlanSrc: string = this._iconSrc + 'delivery3-gray.svg';
  setWarehousePlanSrc(): void {
    this.warehousePlanSrc = (this.isRouteActive(this._warehouse_planUrl) ?
    (this._iconSrc + 'delivery3-white.svg') :
    (this._iconSrc + 'delivery3-gray.svg'));
  }

  public warehouseControlSrc: string = this._iconSrc + 'warehouse1-gray.svg';
  setWarehouseControlSrc(): void {
    this.warehouseControlSrc = (this.isRouteActive(this._warehouse_controUrl) ?
    (this._iconSrc + 'warehouse1-white.svg') :
    (this._iconSrc + 'warehouse1-gray.svg'));
  }
  
  public calculatorSrc: string = this._iconSrc + 'calculator-gray.svg';
  setCalculatorSrc(): void {
    this.calculatorSrc = (this.isRouteActive(this._emissioncalcUrl) ?
      (this._iconSrc + 'calculator-white.svg') :
      (this._iconSrc + 'calculator-gray.svg'));
  }

  public searchSrc: string = this._iconSrc + 'address-book2-gray.svg';
  setSearchSrc(): void {
    this.searchSrc = (this.isRouteActive(this._searchUrl) ?
      (this._iconSrc + 'address-book2-white.svg') :
      (this._iconSrc + 'address-book2-gray.svg'));
  }

  public invoice_listSrc: string = this._iconSrc + 'invoice-gray.svg';
  setInvoice_listSrc(): void {
    this.invoice_listSrc = (this.isRouteActive(this._invoice_listUrl) ?
      (this._iconSrc + 'invoice-white.svg') :
      (this._iconSrc + 'invoice-gray.svg'));
  }
  
  public gen_for_invoicingSrc: string = this._iconSrc + 'server_document-gray.svg';
  setGen_for_invoicingSrc(): void {
    this.gen_for_invoicingSrc = (this.isRouteActive(this._gen_for_invoicingUrl) ?
      (this._iconSrc + 'server_document-white.svg') :
      (this._iconSrc + 'server_document-gray.svg'));
  }

  public payment_bankSrc: string = this._iconSrc + 'bank_building-gray.svg';
  setPayment_bankSrc(): void {
    this.payment_bankSrc = (this.isRouteActive(this._payment_bankUrl) ?
      (this._iconSrc + 'bank_building-white.svg') :
      (this._iconSrc + 'bank_building-gray.svg'));
  }

  public remindersSrc: string = this._iconSrc + 'reminder-gray.svg';
  setRemindersSrc(): void {
    this.remindersSrc = (this.isRouteActive(this._remindersUrl) ?
      (this._iconSrc + 'reminder-white.svg') :
      (this._iconSrc + 'reminder-gray.svg'));
  }

  public invoice_inSrc: string = this._iconSrc + 'invoice-received-gray.svg';
  setInvoice_inSrc(): void {
    this.invoice_inSrc = (this.isRouteActive(this._invoice_in_conditionUrl) ?
      (this._iconSrc + 'invoice-received-white.svg') :
      (this._iconSrc + 'invoice-received-gray.svg'));
  }

  public contactSrc: string = this._iconSrc + 'telephone2-gray.svg';
  setContactSrc(): void {
    this.contactSrc = (this.isRouteActive(this._contactUrl) ?
      (this._iconSrc + 'telephone2-white.svg') :
      (this._iconSrc + 'telephone2-gray.svg'));
  }

  public supportSrc: string = this._iconSrc + 'hotline-gray.svg';
  // setSupportSrc(): void {
  //   this.supportSrc = (this.isSupportActive ?
  //     (this._iconSrc + 'hotline-white.svg') :
  //     (this._iconSrc + 'hotline-gray.svg'));
  // }

  public configSrc: string = this._iconSrc + 'user-gray.svg';
  setConfigSrc(): void {
    this.configSrc = (this.isConfigActive ?
      (this._iconSrc + 'user-white.svg') :
      (this._iconSrc + 'user-gray.svg'));
  }


  // fast choice div
  public statisticsSrcFast: string = this._iconSrc + 'history.svg';
  setStatisticsSrcFast(): void {
    this.statisticsSrcFast = (this.isRouteActive(this._statisticsUrl) ?
      (this._iconSrc + 'history-white.svg') :
      (this._iconSrc + 'history.svg'));
  }

  public carbookSrcFast: string = this._iconSrc + 'reports.svg';
  setCarbookSrcFast(): void {
    this.carbookSrcFast = (this.isRouteActive(this._carbookUrl) ?
      (this._iconSrc + 'reports-white.svg') :
      (this._iconSrc + 'reports.svg'));
  }

  public servicesSrcFast: string = this._iconSrc + 'tools.svg';
  setServicesSrcFast(): void {
    this.servicesSrcFast = (this.isRouteActive(this._servicesUrl) ?
      (this._iconSrc + 'tools-white.svg') :
      (this._iconSrc + 'tools.svg'));
  }

  public mycarsSrcFast: string = this._iconSrc + 'small_truck.svg';
  setMycarsSrcFast(): void {
    this.mycarsSrcFast = (this.isRouteActive(this._mycarsUrl) ?
      (this._iconSrc + 'small_truck-white.svg') :
      (this._iconSrc + 'small_truck.svg'));
  }
  
  public fuelingStatsSrcFast: string = this._iconSrc + 'fuel.svg';
  setFuelingStatsSrcFast(): void {
    this.fuelingStatsSrcFast = (this.isRouteActive(this._fueling_statsUrl) ?
      (this._iconSrc + 'fuel-white.svg') :
      (this._iconSrc + 'fuel.svg'));
  }

  public obligation_listSrcFast: string = this._iconSrc + 'folders2.svg';
  setObligation_listSrcFast(): void {
    this.obligation_listSrcFast = (this.isRouteActive(this._obligation_listUrl) ?
      (this._iconSrc + 'folders2-white.svg') :
      (this._iconSrc + 'folders2.svg'));
  }

  public company_obligationSrcFast: string = this._iconSrc + 'folder3.svg';
  setCompany_obligationSrcFast(): void {
    this.company_obligationSrcFast = (this.isRouteActive(this._company_obligationUrl) ?
      (this._iconSrc + 'folder3-white.svg') :
      (this._iconSrc + 'folder3.svg'));
  }

  public express_listSrcFast: string = this._iconSrc + 'route.svg';
  setExpress_listSrcFast(): void {
    this.express_listSrcFast = (this.isRouteActive(this._express_listUrl) ?
      (this._iconSrc + 'route-white.svg') :
      (this._iconSrc + 'route.svg'));
  }

  public order_listSrcFast: string = this._iconSrc + 'shopping_cart.svg';
  setOrder_listSrcFast(): void {
    this.order_listSrcFast = (this.isRouteActive(this._order_listUrl) ?
      (this._iconSrc + 'shopping_cart-white.svg') :
      (this._iconSrc + 'shopping_cart.svg'));
  }

  public obligation_salesSrcFast: string = this._iconSrc + 'chart_line.svg';
  setObligation_salesSrcFast(): void {
    this.obligation_salesSrcFast = (this.isRouteActive(this._obligation_salesUrl) ?
      (this._iconSrc + 'chart_line-white.svg') :
      (this._iconSrc + 'chart_line.svg'));
  }

  public warehousePlanSrcFast: string = this._iconSrc + 'delivery3.svg';
  setWarehousePlanSrcFast(): void {
    this.warehousePlanSrcFast = (this.isRouteActive(this._warehouse_planUrl) ?
      (this._iconSrc + 'delivery3-white.svg') :
      (this._iconSrc + 'delivery3.svg'));
  }

  public warehouseControlSrcFast: string = this._iconSrc + 'warehouse1.svg';
  setWarehouseControlSrcFast(): void {
    this.warehouseControlSrcFast = (this.isRouteActive(this._warehouse_controUrl) ?
      (this._iconSrc + 'warehouse1-white.svg') :
      (this._iconSrc + 'warehouse1.svg'));
  }
  
  public calculatorSrcFast: string = this._iconSrc + 'calculator.svg';
  setCalculatorSrcFast(): void {
    this.calculatorSrcFast = (this.isRouteActive(this._emissioncalcUrl) ?
      (this._iconSrc + 'calculator-white.svg') :
      (this._iconSrc + 'calculator.svg'));
  }

  public searchSrcFast: string = this._iconSrc + 'address-book2.svg';
  setSearchSrcFast(): void {
    this.searchSrcFast = (this.isRouteActive(this._searchUrl) ?
      (this._iconSrc + 'address-book2-white.svg') :
      (this._iconSrc + 'address-book2.svg'));
  }

  public invoice_listSrcFast: string = this._iconSrc + 'invoice.svg';
  setInvoice_listSrcFast(): void {
    this.invoice_listSrcFast = (this.isRouteActive(this._invoice_listUrl) ?
      (this._iconSrc + 'invoice-white.svg') :
      (this._iconSrc + 'invoice.svg'));
  }
  
  public gen_for_invoicingSrcFast: string = this._iconSrc + 'server_document.svg';
  setGen_for_invoicingSrcFast(): void {
    this.gen_for_invoicingSrcFast = (this.isRouteActive(this._gen_for_invoicingUrl) ?
      (this._iconSrc + 'server_document-white.svg') :
      (this._iconSrc + 'server_document.svg'));
  }

  public payment_bankSrcFast: string = this._iconSrc + 'bank_building.svg';
  setPayment_bankSrcFast(): void {
    this.payment_bankSrcFast = (this.isRouteActive(this._payment_bankUrl) ?
      (this._iconSrc + 'bank_building-white.svg') :
      (this._iconSrc + 'bank_building.svg'));
  }

  public remindersSrcFast: string = this._iconSrc + 'reminder.svg';
  setRemindersSrcFast(): void {
    this.remindersSrcFast = (this.isRouteActive(this._remindersUrl) ?
      (this._iconSrc + 'reminder-white.svg') :
      (this._iconSrc + 'reminder.svg'));
  }

  public invoice_inSrcFast: string = this._iconSrc + 'invoice-received.svg';
  setInvoice_inSrcFast(): void {
    this.invoice_inSrcFast = (this.isRouteActive(this._invoice_in_conditionUrl) ?
      (this._iconSrc + 'invoice-received-white.svg') :
      (this._iconSrc + 'invoice-received.svg'));
  }


  /********************************************/
  /* Dropdowns closing/opening + mobile menu controller */
  /********************************************/
  // flags for controlling dropdowns open/close
  public managerHover: boolean = false;
  public agendaHover: boolean = false;
  public supportHover: boolean = false;
  public mydataHover: boolean = false;
  public agenda1Hover: boolean = false;
  public agenda2Hover: boolean = false;
  public agenda3Hover: boolean = false;
  public agenda4Hover: boolean = false;
  public importHover: boolean = false;
  public exportHover: boolean = false;
  public servisDBHover: boolean = false;


  // setters ignoring smaller devices (corresponding with bootstrap expand-lg)
  setManagerHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.managerHover = flag;
  }

  setAgendaHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.agendaHover = flag;
  }

  setSupportHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.supportHover = flag;
  }

  setMydataHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.mydataHover = flag;
  }

  setAgenda1Hover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.agenda1Hover = flag;
  }

  setAgenda2Hover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.agenda2Hover = flag;
  }

  setAgenda3Hover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.agenda3Hover = flag;
  }

  setAgenda4Hover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.agenda4Hover = flag;
  }

  setImportHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.importHover = flag;
  }

  setExportHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.exportHover = flag;
  }

  setServisDBHover(flag: boolean): void {
    // device has a mouse pointer and navbar is wide
    if (matchMedia('(pointer:fine)').matches && window.innerWidth > 991) this.servisDBHover = flag;
  }

  // method for routing to given route and closing all dropdowns and collapse navbar 
  routeAndClose(routeName: string = null): void {
    this.closeDropdowns();
    // collapse navbar via jquery
    // TODO error in angular 9!
    (<any>$('#navbarSupportedContentMain.navbar-collapse')).collapse('hide');
    // routing
    if (routeName) {
      this._router.navigate(
        [ { outlets: {left: routeName} } ], 
        { queryParams: { reloadPossible: true } }
      );
    }
  }

  // method for routing to given route and closing all dropdowns and collapse navbar 
  // + reinit e.g. new obligation
  routeCloseInitNew(routeName: string = null): void {
    this.closeDropdowns();
    // collapse navbar via jquery
    // TODO error in angular 9!
    (<any>$('#navbarSupportedContentMain.navbar-collapse')).collapse('hide');
    // routing
    if (routeName) {
      this._router.navigate(
        [ { outlets: {left: routeName} } ], 
        { queryParams: { reloadPossible: true, initNew: true } }
      );
    }
  }

  public routeRightMenu(route_right: string) {
    // keep previous route query params
    let previous_route: any = this._router.parseUrl(this._router.url);
    let params = previous_route.queryParams;
    params['rightMenu'] = true;
    // open dashbord
    let url = this._router.serializeUrl(
      this._router.createUrlTree(
        [{outlets: {right: route_right}}], {queryParams: params} 
      )
    );
    this._router.navigateByUrl(url);
  }
  
  // method for closing all dropdowns(customized for our multi-level menu)
  closeDropdowns(): void {
    this.managerHover = false;
    this.agendaHover = false;
    this.supportHover = false;
    this.mydataHover = false;

    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;

    this.exportHover = false;
    this.servisDBHover = false;
  }

  // method for toggling dropdown - called when clicked on parent element 'li'
  toggleManager(): void {
    this.managerHover = !this.managerHover;
    // hide others
    this.agendaHover = false;
    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
    this.supportHover = false;
    this.mydataHover = false;
  }

  toggleAgenda(): void {
    this.agendaHover = !this.agendaHover;
    // hide others
    this.managerHover = false;
    this.supportHover = false;
    this.mydataHover = false;
    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
  }

  toggleSupport(): void {
    this.supportHover = !this.supportHover;
    // hide others
    this.managerHover = false;
    this.agendaHover = false;
    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
    this.mydataHover = false;
  }

  toggleMydata(): void {
    this.mydataHover = !this.mydataHover;
    // hide others
    this.managerHover = false;
    this.agendaHover = false;
    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
    this.supportHover = false;
  }

  toggleAgenda1(): void {
    this.agenda1Hover = !this.agenda1Hover;
    // hide others from agenda
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
  }

  toggleAgenda2(): void {
    this.agenda2Hover = !this.agenda2Hover;
    // hide others from agenda
    this.agenda1Hover = false;
    this.agenda3Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
  }

  toggleAgenda3(): void {
    this.agenda3Hover = !this.agenda3Hover;
    // hide others from agenda
    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda4Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
  }

  toggleAgenda4(): void {
    this.agenda4Hover = !this.agenda4Hover;
    // hide others from agenda
    this.agenda1Hover = false;
    this.agenda2Hover = false;
    this.agenda3Hover = false;
    this.exportHover = false;
    this.servisDBHover = false;
  }

  toggleImport(): void {
    this.importHover = !this.importHover;
  }

  toggleExport(): void {
    this.exportHover = !this.exportHover;
  }

  toggleServisDB(): void {
    this.servisDBHover = !this.servisDBHover;
  }

  // method for scrolling to specified target
  scroll(el: HTMLElement) {
    if (window.innerWidth < 992) {
      // scroll after 60ms because of loading ul that used to be d-none
      setTimeout(() => {
        el.scrollIntoView({behavior: 'smooth'});
      }, 60);
    }
  }



  /*************************************************************/
  // method for getting/creating delegation token
  /*************************************************************/
  private _token: string = null;
  public get token(): string {
    return this._token;
  }

  private _loadingToken: boolean = false;
  public get loadingToken(): boolean {
    return this._loadingToken;
  }
  
  
  // method for loading delegations (if not exists delegation obligation_scanner - create new)
  getDelegations(): void {
    this._loadingToken = true;

    this._delegationsServ.getDelegationsList().subscribe(
      response => {
        if (response && response.length) {
          let array: Array<Delegation> = this._delegationsServ.buildDelegationsFromData(response); 
          // last token is valid -> sort according to date
          array = array.sort((a, b) => (a.created < b.created) ? 1 : -1);
          let token = array.find(t => t.action == 'obligation_scanner');
          if (token) {
            console.log(token);
            this._token = token.token;
            this._loadingToken = false;
          }
          else {
            console.log('Nenalezen Token obligation_scanner -> vytvorime novy...');
            // automaticky vytvorit, pokud tam nemaji..
            this.createNewToken();
          }
        }
        // empty array
        else if (response) {
          console.log(response);
          console.log('Nenalezen zadny token -> vytvorime novy...');
          // automaticky vytvorit, pokud tam nemaji..
          this.createNewToken();
        }
      },
      error => {
        console.log(error);
        console.log('Nenalezen Token obligation_scanner -> vytvorime novy...');
        // automaticky vytvorit, pokud tam nemaji..
        this.createNewToken();
      }
    );
  }

  // method for creating new delegation - actione obligation_scanner
  createNewToken(): void {
    this._loadingToken = true;
    // need to send additional data in body for post..
    let data: any = { company_key: this._company.company_key };

    this._delegationsServ.createDelegation('obligation_scanner', data).subscribe(
      response => {
        if (response) {
          let delegation: Delegation = this._delegationsServ.buildDelegation(response);
          this._token = delegation.token;
          this._loadingToken = false;
        }
      },
      error => {
        this._notificationServ.alert(
          $localize`Chyba při vytváření tokenu "obligation_scanner"!`, 'error', 4000
        );
        console.log(error);
        this._loadingToken = false;
      }
    );
  }

  // method for copy token to clipboard
  // https://stackoverflow.com/questions/49102724/angular-5-copy-to-clipboard
  copyToken(): void {
    let listener = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (this.token));
      e.preventDefault();
    };

    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
  }

  // open Anydesk windows app
  openAnydesk(): void {
    // const anyDeskUrl = 'anydesk:invite?target_id=1073124160';
    const anyDeskUrl = 'anydesk:';

    console.log(anyDeskUrl);

    const link = document.createElement('a');
    link.href = anyDeskUrl;

    // Optionally hide the link from the user
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();

    // Remove the link element after the click
    document.body.removeChild(link);
  }

  // open Anydesk windows app
  inviteHonza(): void {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/anydesk_truckagenda.bat';
    link.download = 'anydesk_truckagenda.bat';
    document.body.appendChild(link);
    link.click();
    link.remove();

    // window.open('assets/anydesk_truckagenda.bat', '_blank');
  }
}
