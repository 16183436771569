import { AddressBookItem } from '../model/address-book-item.object';
import { Attachment } from '../model/attachment.object';
import { GeoPosition } from '../model/geo-position.object';
import { Email } from '../model/email.object';
import { Itinerary } from '../model/itinerary.object';
import { Message } from '../model/message.object';
import { Obligation } from '../model/obligation.object';
import { Vehicle } from '../model/vehicle.object';
import { DateTools } from './DateTools';
import { Haversine } from './Haversine';
import { GoogleLatLng } from '../model/google-lat-lng.object';
import { ServiceEventObject } from '../model/service-event.object';
import { Agenda } from '../model/agenda.object';
import { OrderTools } from './OrderTools';
import { InvoiceTools } from './InvoiceTools';
import { ItineraryType, ObligationStatus, ServiceType } from '../config';
import { Driver } from '../model/driver.object';
import { Company } from '../model/company.object';
import { ObligationCosts } from '../model/obligation-costs.object';

// Tools for building obligation objects (used in more services)
export class ObligationTools {

  // method for creating obligation objects from api objects
  static buildObligationsFromData(data: Array<any>): any {
    let obligations: Array<Obligation> = [];
    data.forEach(
      o => {
        let obligation = this.buildObligation(o);
        obligations.push(obligation);
      }
    );
    return obligations;
  }

  // method for creating a single obligation object
  static buildObligation(o: any): Obligation {
    let obligation: Obligation = new Obligation();

    // nested json objects
    if (o.car) {
      let car = new Vehicle();
      for (let key in o.car) {
        car[key] = o.car[key];
      }
      o.car = car;
    }

    // HOTFIX - api sends car2 object even if this is null
    if (!o.car2_key) {
      o.car2 = null;
    }

    if (!o.attachments) {
      o.attachments = [];
    }
    else if (o.attachments.length) {
      let attachmentArr = new Array<Attachment>();
      o.attachments.forEach(
        at => {
          let attachment = new Attachment();
          for (let key in at) {
            attachment[key] = at[key];
          }
          attachmentArr.push(attachment);
        }
      );
      o.attachments = attachmentArr;
    }

    if (!o.files) {
      o.files = [];
    }
    else if (o.files.length) {
      let filesArr = new Array<Attachment>();
      o.files.forEach(
        f => {
          let file = new Attachment();
          for (let key in f) {
            file[key] = f[key];
          }
          // custom alias for files origin
          file.origin = 'TM';
          filesArr.push(file);
        }
      );
      o.files = filesArr;
    }

    if (o.invoices && o.invoices.length) {
      o.invoices = InvoiceTools.buildInvoicesFromData(o.invoices);
    }
    if (o.itinerary && o.itinerary.length) {
      let itineraryArr = new Array<Itinerary>();
      o.itinerary.forEach(
        it => {
          let itinerary = ObligationTools.buildItinerary(it);
          itineraryArr.push(itinerary);
        }
      );
      itineraryArr = itineraryArr.sort((a, b) => (a.position > b.position) ? 1 : -1);
      o.itinerary = itineraryArr;
    }
    if (o.orders && o.orders.length) {
      o.orders = OrderTools.buildOrdersFromData(o.orders);
    }
    if (o.order_comp_book) {
      let order_comp_book = new AddressBookItem();
      for (let key in o.order_comp_book) {
        order_comp_book[key] = o.order_comp_book[key];
      }
      o.order_comp_book = order_comp_book;

      if (o.order_comp_book.company && o.order_comp_book.city && o.order_comp_book.tin) {
        o.order_comp_name_long = o.order_comp_book.company + ', ' + o.order_comp_book.city + ', ' + o.order_comp_book.tin;
      } 
      else {
        o.order_comp_name_long = '';
      }

      // set DHL tin 
      if (o.order_comp_book.tin == 'CZ08566330') {
        o.isDHL = true;
      }
      // set Wienerberger tin 
      if (o.order_comp_book.tin == 'CZ00015253') {
        o.isWienerberger = true;
      }
    }
    // we prefer array here, not null
    if (!o.email_msg_ids) o.email_msg_ids = [];
    if (!o.email_headers) o.email_headers = [];
    if (o.email_headers && o.email_headers.length) {
      let headersArr = new Array<Email>();
      o.email_headers.forEach(
        (x, index) => {
          let header = new Email();
          for (let key in x) {
            header[key] = x[key];
          }
          // save also id of email
          if (o.email_msg_ids[index]) {
            header.email_msg_id = o.email_msg_ids[index];
          }
          headersArr.push(header);
        }
      );
      o.email_headers = headersArr;
    }

    // possibly set piece shipment delivery (custom setter) - only for existing obligation
    if (o.piece_shipment && o.obligation_key && o.itinerary && o.itinerary.length) {
      if (!o.itinerary.find(it => it.type == ItineraryType.LOADING)) {
        o.piece_shipment_delivery = true;
      }
    }

    for (let key in o) {
      obligation[key] = o[key];
    }

    // compute straight line distance
    obligation.route_length_estimated = ObligationTools.computeRouteLengthEstimation(obligation.itinerary);

    return obligation;
  }

  static buildItineraryFromArray(arr: Array<any>): Array<Itinerary> {
    if (arr) {
      let result_arr: Array<Itinerary> = [];
      arr.forEach(
        i => {
          let it = ObligationTools.buildItinerary(i);
          result_arr.push(it);
        }
      );
      return result_arr;
    }
    return [];
  }
    
  // method for creating a single obligation object
  static buildItinerary(o: any, fromWebsocket: boolean = false): Itinerary {
    if (!o) return null;
    let itinerary: Itinerary = new Itinerary();

    if (o.address) {
      o.address_book_item = new AddressBookItem();
      o.address_book_item.company = o.address;
      o.address_autocompleter_favorite = o.address;
    }
    if (o.arrival_time) {
      // websockets got format 2021-02-04T06:48:00.000+0100
      if (fromWebsocket) {
        // initialize arrival_time_custom that is used for datetime-local input
        // let date: Date = DateTools.apiIsoToDate(o.arrival_time);
      }
      // normal format from api is 2021-02-04T06:48:00+01
      else {
        // initialize arrival_time_custom that is used for datetime-local input
        let date: Date = DateTools.apiIsoToDate(o.arrival_time);
        o.arrival_time_custom = DateTools.isoForDatetimeInput(date);
      }
    }
    if (o.gps_coord) {
      let itinerary_gps_location = new GeoPosition();
      itinerary_gps_location.gps_coord = o.gps_coord;
      o.gps_coord = itinerary_gps_location;
      o.gps_coord_string = itinerary_gps_location.gps_coord;
    }
    if (o.work_day_begin && o.work_day_begin.length > 4 && o.work_day_end && o.work_day_end.length > 4) {
      // computing minutes of work_day
      let begin_hours: number = parseInt(o.work_day_begin.substring(0,2));
      let begin_minutes: number = parseInt(o.work_day_begin.substring(3,5));
      let end_hours: number = parseInt(o.work_day_end.substring(0,2));
      let end_minutes: number = parseInt(o.work_day_end.substring(3,5));

      o.work_day_begin_minutes = begin_hours * 60 + begin_minutes;
      o.work_day_end_minutes = end_hours * 60 + end_minutes;
    }

    for (let key in o) {
      itinerary[key] = o[key];
    }

    return itinerary;
  }

  // straight line distance
  static computeRouteLengthEstimation(itinerary: Array<Itinerary>): number {
    let estimation: number = 0;
    if (itinerary && itinerary.length) {
      itinerary.forEach(
        (it2, idx) => {
          if (idx > 0) {
            // previous itinerary
            let it1: Itinerary = itinerary[idx - 1];
            if (it1.gps_coord && it2.gps_coord) {
              // computing distance using haversine
              let dist = ObligationTools.computeDistanceEstimation(
                it1.gps_coord.googleLatLang, it2.gps_coord.googleLatLang
              );
              if (dist) {
                estimation += Math.abs(Math.round(dist));
              }
            }
          }
        }
      );
    }
    return estimation;
  }

  static computeDistanceEstimation(latLng1: GoogleLatLng, latLng2: GoogleLatLng): number {
    let distance: number = 0;
    if (latLng1 && latLng2) {
      // computing distance using haversine
      distance = Haversine.haversine(
        { latitude: latLng1.lat, longitude: latLng1.lng },
        { latitude: latLng2.lat, longitude: latLng2.lng }
      );
      if (distance) {
        distance = Math.abs(Math.round(distance));
      }
    }
    return distance;
  }

  // method for building message object
  static buildMessage(data: any): Message {
    let message = new Message();
    for (let key in data) {
      message[key] = data[key];
    }
    return message;
  }

  
  // method for setting background color according to status of obligation
  static setBackground(status: string): string {
    switch (status) {
      case ObligationStatus.POPTAVKA:
        return 'grayCyanBackground';
      case ObligationStatus.K_VYRIZENI:
        return 'yellowBackground';
      case ObligationStatus.ZAHAJENA:
        return 'blueBackground';
      case ObligationStatus.SKLAD_SVOZ:
        return 'cyan1Background';
      case ObligationStatus.SKLAD:
        return 'cyan2Background';
      case ObligationStatus.SKLAD_K_ROZVOZU:
        return 'cyan2Background';
      case ObligationStatus.SKLAD_ROZVOZ:
        return 'cyan3Background';
      case ObligationStatus.K_FAKTURACI:
        return 'redBackground';
      case ObligationStatus.FAKTUROVANA:
        return 'purpleBackground';
      case ObligationStatus.DOKONCENA:
        return 'greenBackground';
      case ObligationStatus.MIMO_PROVOZ:
        return 'lightGrayBackground';
      case ObligationStatus.STORNOVANA:
        return 'grayBackground';
      default:
        return '';
    }
  }

  // method for setting background color of table row according to status of obligation(with alpha)
  static setBackgroundTableRow(status: string): string {
    switch (status) {
      case ObligationStatus.POPTAVKA:
        return 'grayCyanBackgroundRow';
      case ObligationStatus.K_VYRIZENI:
        return 'yellowBackgroundRow';
      case ObligationStatus.ZAHAJENA:
        return 'blueBackgroundRow';
      case ObligationStatus.SKLAD_SVOZ:
        return 'cyan1BackgroundRow';
      case ObligationStatus.SKLAD:
        return 'cyan2BackgroundRow';
      case ObligationStatus.SKLAD_K_ROZVOZU:
        return 'cyan2BackgroundRow';
      case ObligationStatus.SKLAD_ROZVOZ:
        return 'cyan3BackgroundRow';
      case ObligationStatus.K_FAKTURACI:
        return 'redBackgroundRow';
      case ObligationStatus.FAKTUROVANA:
        return 'purpleBackgroundRow';
      case ObligationStatus.DOKONCENA:
        return 'greenBackgroundRow';
      case ObligationStatus.MIMO_PROVOZ:
        return 'lightGrayBackgroundRow';
      case ObligationStatus.STORNOVANA:
        return 'grayBackgroundRow';
      default:
        return '';
    }
  }


  /************************************************/
  /* Obligation updating methods according to real events data */
  /************************************************/
  static parseNotePrice(o: Obligation): void {
    const MAGIC_WIENERBERGER_TIN: string = 'CZ00015253';
    
    if (o.obligation_key && o.order_comp_book && o.order_comp_book.tin == MAGIC_WIENERBERGER_TIN) {
      // no parsing price for Wienerberger in existing obligation with existing invoice
      if (o.invoices && o.invoices.length) {
        return;
      }
    }

    if (!o.note_price || !o.note_price.length) {
      return;
    }
    // decimal comma to dot
    o.note_price = o.note_price.replace(/,/g, '.');

    // splitting by space
    let arr: Array<string> = o.note_price.split(' ');

    let tunaNaklString: string = arr.find(s => s.startsWith('#tuna-nakl='));
    let tunaVyklString: string = arr.find(s => s.startsWith('#tuna-vykl='));
    let tunaString: string = arr.find(s => s.startsWith('#tuna='));
    let trasaPlanString: string = arr.find(s => s.startsWith('#trasa-plan'));
    let trasaRealString: string = arr.find(s => s.startsWith('#trasa-real'));
    let trasaPrejezdString: string = arr.find(s => s.startsWith('#trasa+prejezd'));
    let trasaVzduchString: string = arr.find(s => s.startsWith('#trasa-vzduch'));
    let trasaZonaString: string = arr.find(s => s.startsWith('#trasa-zona'));
    let limitString: string = arr.find(s => s.startsWith('#limit-nakladka'));
    // let fuelString: string = arr.find(s => s.startsWith('#PHM-priplatek-TM'));
    let palString: string = arr.find(s => s.startsWith('#pal'));
    let hrString: string = arr.find(s => s.startsWith('#hr'));
    let gmpaString: string = arr.find(s => s.startsWith('#gmpa'));
    let gmpbString: string = arr.find(s => s.startsWith('#gmpb'));
    let gmpcString: string = arr.find(s => s.startsWith('#gmpc'));
    let gmpdString: string = arr.find(s => s.startsWith('#gmpd'));
    
    let tunaNaklNumber: number = null;
    let tunaVyklNumber: number = null;
    let tunaNumber: number = null;
    let trasaPlanNumber: number = null;
    let trasaRealNumber: number = null;
    let trasaPrejezdNumber: number = null;
    let trasaVzduchNumber: number = null;
    let trasaZonaNumber: number = null;
    let limitNumber: number = null;
    // let fuelNumber: number = null;
    let palNumber: number = null;
    let hrNumber: number = null;
    let gmpaNumber: number = null;
    let gmpbNumber: number = null;
    let gmpcNumber: number = null;
    let gmpdNumber: number = null;

    if (tunaNaklString || tunaVyklString || tunaString 
      || trasaPlanString || trasaRealString || trasaPrejezdString 
      || trasaVzduchString || trasaZonaString || limitString
      || palString || hrString || gmpaString || gmpbString || gmpcString || gmpdString
    ) {
      // reinit price
      o.contracted_price = 0;
      
      if (tunaNaklString) {
        tunaNaklString = tunaNaklString.replace('#tuna-nakl=', '');
        tunaNaklNumber = parseFloat(tunaNaklString);
        if (tunaNaklNumber) {
          let weight: number = 0;
          if (o.itinerary) {
            o.itinerary.forEach(
              it => {
                if (it.weight && it.type == ItineraryType.LOADING) {
                  weight += it.weight;
                }
              }
            );
          }
          o.contracted_price += weight * tunaNaklNumber;
        }
      }
      if (tunaVyklString) {
        tunaVyklString = tunaVyklString.replace('#tuna-vykl=', '');
        tunaVyklNumber = parseFloat(tunaVyklString);
        if (tunaVyklNumber) {
          let weight: number = 0;
          if (o.itinerary) {
            o.itinerary.forEach(
              it => {
                if (it.weight && it.type == ItineraryType.UNLOADING) {
                  weight += it.weight;
                }
              }
            );
          }
          o.contracted_price += weight * tunaVyklNumber;
        }
      }
      if (tunaString && !tunaNaklString && !tunaVyklString) {
        tunaString = tunaString.replace('#tuna=', '');
        tunaNumber = parseFloat(tunaString);
        if (tunaNumber) {
          o.contracted_price += o.weight * tunaNumber;
        }
      }

      if (trasaPlanString) {
        trasaPlanString = trasaPlanString.replace('#trasa-plan=', '');
        trasaPlanNumber = parseFloat(trasaPlanString);
        if (trasaPlanNumber) {
          o.contracted_price += o.route_length * trasaPlanNumber;
        }
      }
      if (trasaRealString) {
        trasaRealString = trasaRealString.replace('#trasa-real=', '');
        trasaRealNumber = parseFloat(trasaRealString);
        if (trasaRealNumber) {
          o.contracted_price += o.route_length_real * trasaRealNumber;
        }
      }
      if (trasaPrejezdString) {
        trasaPrejezdString = trasaPrejezdString.replace('#trasa+prejezd=', '');
        trasaPrejezdNumber = parseFloat(trasaPrejezdString);
        if (trasaPrejezdNumber) {
          let total: number = o.route_length_real + o.transit_length_real;
          o.contracted_price += total * trasaPrejezdNumber;
        }
      }
      if (trasaVzduchString) {
        trasaVzduchString = trasaVzduchString.replace('#trasa-vzduch=', '');
        trasaVzduchNumber = parseFloat(trasaVzduchString);
        if (trasaVzduchNumber) {
          o.contracted_price += trasaVzduchNumber;
        }
      }
      if (trasaZonaString) {
        trasaZonaString = trasaZonaString.replace('#trasa-zona=', '');
        trasaZonaNumber = parseFloat(trasaZonaString);
        if (trasaZonaNumber) {
          o.contracted_price += trasaZonaNumber;
        }
      }
      if (limitString) {
        limitString = limitString.replace('#limit-nakladka=', '');
        limitNumber = parseFloat(limitString);
        if (limitNumber) {
          let minutesOver: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.loading_time_limit && it.loading_time_real) {
                if (it.loading_time_real > it.loading_time_limit) {
                  minutesOver += it.loading_time_real - it.loading_time_limit;
                }
              }
            }
          );
          o.contracted_price += minutesOver * limitNumber;
        }
      }
      if (palString) {
        palString = palString.replace('#pal=', '');
        palNumber = parseFloat(palString);
        if (palNumber) {
          let ware_pcs: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.activityCode('#pal') && it.ware_pcs) {
                ware_pcs += it.ware_pcs;
              }
            }
          );
          o.contracted_price += ware_pcs * palNumber;
        }
      }
      if (hrString) {
        hrString = hrString.replace('#hr=', '');
        hrNumber = parseFloat(hrString);
        if (hrNumber) {
          let ware_pcs: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.activityCode('#hr') && it.ware_pcs) {
                ware_pcs += it.ware_pcs;
              }
            }
          );
          o.contracted_price += ware_pcs * hrNumber;
        }
      }
      if (gmpaString) {
        gmpaString = gmpaString.replace('#gmpa=', '');
        gmpaNumber = parseFloat(gmpaString);
        if (gmpaNumber) {
          let count: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.activityCode('#gmpa')) {
                count += 1;
              }
            }
          );
          o.contracted_price += count * gmpaNumber;
        }
      }
      if (gmpbString) {
        gmpbString = gmpbString.replace('#gmpb=', '');
        gmpbNumber = parseFloat(gmpbString);
        if (gmpbNumber) {
          let count: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.activityCode('#gmpb')) {
                count += 1;
              }
            }
          );
          o.contracted_price += count * gmpbNumber;
        }
      }
      if (gmpcString) {
        gmpcString = gmpcString.replace('#gmpc=', '');
        gmpcNumber = parseFloat(gmpcString);
        if (gmpcNumber) {
          let count: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.activityCode('#gmpc')) {
                count += 1;
              }
            }
          );
          o.contracted_price += count * gmpcNumber;
        }
      }
      if (gmpdString) {
        gmpdString = gmpdString.replace('#gmpd=', '');
        gmpdNumber = parseFloat(gmpdString);
        if (gmpdNumber) {
          let count: number = 0;
          o.itinerary.forEach(
            it => {
              if (it.activityCode('#gmpd')) {
                count += 1;
              }
            }
          );
          o.contracted_price += count * gmpdNumber;
        }
      }
      
      // if (fuelString && o.car.consumption_avg) {
      //   fuelString = fuelString.replace('#PHM-priplatek', '');
      //   fuelNumber = parseFloat(fuelString);
      //   if (fuelNumber) {
      //     let price1km: number = (o.car.consumption_avg / 100) * o.obligationFuelPrice;
      //     o.contracted_price += price1km * o.route_length_real;
      //   }
      //   else if (o.obligationFuelPrice) {
      //     let price1km: number = (o.car.consumption_avg / 100) * o.obligationFuelPrice;
      //     o.contracted_price += price1km * o.route_length_real;
      //   }
      // }
    }
  }

  // new method for updating obligations itinerary from real events data
  static updateItineraryFromEvents(o: Obligation, events: Array<ServiceEventObject>): void {
    events.sort((a, b) => (a.time > b.time) ? 1 : -1);

    // could be done more cleverly - i use only two attributes for 4 types "u", "l", "U", "L"
    let start_time: Date = null;
    let end_time: Date = null;
    
    // reinit itinerary array
    o.itinerary.forEach(
      it => {
        it.service_events = [];
      }
    );

    events.forEach(
      e => {
        if (e.type == ServiceType.LOADING_START || e.type == ServiceType.UNLOADING_START) {
          if (e.timeDate) start_time = e.timeDate;
        }
        else if (e.type == ServiceType.LOADING_END || e.type == ServiceType.UNLOADING_END) {
          o.itinerary.forEach(
            it => {
              if (it.gps_coord && e.latLng && it.type == e.type) {
                let dist = Haversine.haversine(
                  { latitude: e.latLng.lat, longitude: e.latLng.lng },
                  { latitude: it.gps_coord.googleLatLang.lat, longitude: it.gps_coord.googleLatLang.lng }
                );
                // distance closer than 1 km
                if (dist < 1) {
                  if (it.service_events) {
                    it.service_events.push(e);
                  }
                  else {
                    it.service_events = [e];
                  }
                  it.weight = e.weight;
                  it.length = e.length;
                  it.arrival_time_custom = DateTools.isoForDatetimeInput(DateTools.apiIsoToDate(e.time));

                  // update ware_pcs - containers in/out
                  if (it.type == ItineraryType.LOADING && e.containers_in) {
                    it.ware_pcs = e.containers_in;
                  }
                  else if (it.type == ItineraryType.UNLOADING && e.containers_out) {
                    it.ware_pcs = e.containers_out;
                  }

                  if (e.timeDate) end_time = e.timeDate;
                  if (start_time && end_time) {
                    let diff: any = Math.abs(end_time.getTime() - start_time.getTime());
                    it.loading_time_real = Math.floor((diff/1000)/60);
                  }
                  else {
                    it.loading_time_real = 0;
                  }
                  start_time = null;
                  end_time = null;

                  if (e.descr) {
                    // save descr to itinerary note
                    if (it.note && !it.note.includes(e.descr)) {
                      it.note += ' ' + e.descr;
                    }
                    else if (!it.note) {
                      it.note = e.descr;
                    }
                    
                    // check event descr to find out additional_activity
                    let tmp_descr = e.descr.toLowerCase();
                    if (tmp_descr.includes('#pal') && !it.activityCode('#pal')) {
                      it.addRemoveActivityCode('#pal');
                    }
                    if (tmp_descr.includes('#hr') && !it.activityCode('#hr')) {
                      it.addRemoveActivityCode('#hr');
                    }
                    if (tmp_descr.includes('#gmpa') && !it.activityCode('#gmpa')) {
                      it.addRemoveActivityCode('#gmpa');
                    }
                    if (tmp_descr.includes('#gmpb') && !it.activityCode('#gmpb')) {
                      it.addRemoveActivityCode('#gmpb');
                    }
                    if (tmp_descr.includes('#gmpc') && !it.activityCode('#gmpc')) {
                      it.addRemoveActivityCode('#gmpc');
                    }
                    if (tmp_descr.includes('#gmpd') && !it.activityCode('#gmpd')) {
                      it.addRemoveActivityCode('#gmpd');
                    }
                  }

                  // break foreach
                  return false;
                }
              }
            }
          );
        }
      }
    );

    // compute total weight of obligation
    this.setObligationWeight(o);
  }

  
  // set total weight of obligation according to itinerary (type U - unloading)
  static setObligationWeight(o: Obligation): void {
    let weight: number = 0;
    o.itinerary.forEach(
      it => {
        if (it.weight && it.type == ItineraryType.UNLOADING) {
          weight += it.weight;
        }
      }
    );
    if (!weight) {
      // compute it from loading itinerary
      o.itinerary.forEach(
        it => {
          if (it.weight && it.type == ItineraryType.LOADING) {
            weight += it.weight;
          }
        }
      );
    }

    if (weight != o.weight) {
      o.weight = weight;
    }
  }

  static obligationToAgenda(o: Obligation): Agenda {
    let a: Agenda = new Agenda();

    a.obligation_key = o.obligation_key;
    a.car_key = o.car_key;
    a.express_route_key = o.express_route_key;
    a.itinerary = o.itinerary;
    a.number = o.number;
    a.order_number = o.order_number;
    a.order_number_standard = o.order_number_standard;
    a.express_delivery = o.express_delivery;
    a.route_length = o.route_length;
    a.series = o.series;
    a.status = o.status;
    a.backgroundColorClass = o.backgroundColorClass;
    a.backgroundColorRowClass = o.backgroundColorRowClass;
    a.subnumber = o.subnumber;
    a.year = o.year;
    a.vehicle = o.car;
    a.order_comp_book = o.order_comp_book;
    a.order_comp_book_key = o.order_comp_book_key;
    a.payer_contact = o.payer_contact;
    a.payer_email = o.payer_email;
    a.payer_phone = o.payer_phone;
    a.files = o.files;
    a.email_headers = o.email_headers;

    return a;
  }

  static addDriversToObligation(o: Obligation, company: Company): Obligation {
    if (o && company && company.driversArray.length) {
      if (o.car && o.car.driver_key) {
        let driver: Driver = company.driversArray.find(d => d.driver_key == o.car.driver_key);
        if (driver) {
          o.car.driver = driver;
          o.car.driver_key = driver.driver_key;
          o.car.driver_name = driver.name;
        }
      }
    }

    return o;
  }
  
  static addDriversToObligations(obligations: Array<Obligation>, company: Company): Array<Obligation> {
    if (obligations.length && company && company.driversArray.length) {
      obligations.forEach(
        o => {
          if (o.car && o.car.driver_key) {
            let driver: Driver = company.driversArray.find(d => d.driver_key == o.car.driver_key);
            if (driver) {
              o.car.driver = driver;
              o.car.driver_key = driver.driver_key;
              o.car.driver_name = driver.name;
            }
          }
        }
      );
    }

    return obligations;
  }

  
  // method for creating obligation costs objects from api objects
  static buildObligationCostsFromData(data: Array<any>): any {
    let costs_array: Array<ObligationCosts> = [];
    if (data) {
      data.forEach(
        o => {
          let costs = ObligationTools.buildObligationCosts(o);
          costs_array.push(costs);
        }
      );
    }
    return costs_array;
  }
  
  // method for building obligation costs object
  static buildObligationCosts(data: any): ObligationCosts {
    let costs = new ObligationCosts();
    if (data) {
      for (let key in data) {
        costs[key] = data[key];
      }
    }
    return costs;
  }
}
