import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

import { ExpressRouteService } from "src/app/service/express-route.service";
import { CompanyService } from "src/app/service/company.service";
// import { GoogleMapMarker } from "../../model/google-map-marker.object";
import { GoogleMapAdvMarker } from "src/app/model/google-map-adv-marker.object";
import { UserAccount } from "src/app/model/user-account.object";
import { Person } from "src/app/model/person.object";
import { Agenda } from "../../model/agenda.object";
import { ItineraryType } from "src/app/config";

@Component({
  selector: 'div.agenda-detail',
  templateUrl: './r-agenda-detail.component.html',
  styleUrls: ['./r-agenda-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ExpressRouteService
  ]
})
export class RAgendaDetailComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private _agenda: Agenda;
  get agenda(): Agenda {
    return this._agenda
  }
  @Input()
  set agenda(agenda: Agenda) {
    this._agenda = agenda;
    this.detectChanges();

    if (this._agenda && this._agenda.express_route_key) {
      this._subscribed.push(
        this._expressRouteServ.getExpressRoute(this._agenda.express_route_key).subscribe(
          express_route => {
            if (express_route) {
              // console.log(express_route);
              // load obligations of selected express route
              this._subscribed.push(
                this._expressRouteServ.getExpressObligations(express_route).subscribe(
                  obligations => {
                    if (obligations) {
                      express_route.obligations = obligations;
                      // keep only itinerary with some gps coord
                      express_route.itineraryOrdered = express_route.itineraryOrdered.filter(i => i.gps_coord);
                      // sort according to arrival time
                      express_route.itineraryOrdered.sort(
                        (a, b) => (a.arrival_time > b.arrival_time) ? 1 : -1
                      );
                      this._agenda.express_route = express_route;

                      this.detectChanges();
                    }
                  }
                )
              );
            } 
          }
        )
      );
    }
  }
  
  get icons(): any {
    return GoogleMapAdvMarker.icons;
  }
  
  // alias constant
  get ItineraryType(): any { 
    return ItineraryType;
  }

  private _persons: Array<Person> = [];
  get persons(): Array<Person> {
    return this._persons;
  }
  
  private _user_accounts: Array<UserAccount> = [];
  get user_accounts(): Array<UserAccount> {
    return this._user_accounts;
  }

  constructor(
    private _companyServ: CompanyService,
    private _expressRouteServ: ExpressRouteService, 
    private _cdr: ChangeDetectorRef,
    private _router: Router
  ) {
    this._subscribed.push(
      this._companyServ.getCompanyFullObservable().subscribe(
        company => {
          if (company) {
            this._persons = company.personsArray;
            this._persons.sort((a, b) => (a.name > b.name) ? 1 : -1);
            this._user_accounts = company.usersAsArray;
            this._user_accounts.sort((a, b) => (a.username > b.username) ? 1 : -1);
          }
        }
      )
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  detectChanges(): void {
    // detect changes 100 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }
  
  getPersonNameByUsername(username: string): string {
    if (username && username.startsWith('usr_')) username = username.substring(4);

    let user: UserAccount = this._user_accounts.find(u => u.username == username);
    if (user) {
      let person: Person = this._persons.find(p => p.person_key == user.person_key);
      if (person) {
        return person.name;
      } 
    }

    return null;
  }
  

  /******************************************************/
  /* Routing */
  /******************************************************/
  openObligation(obligation_key: number) {
    let queryParams: any = {
      obligation_key: obligation_key, 
      reloadPossible: true
    }
    let url = this._router.serializeUrl(
      this._router.createUrlTree([{outlets: {left: 'ta1-obligation/company_obligation'}}], {queryParams: queryParams})
    );
    this._router.navigateByUrl(url);
    // window.open('#' + url, '_blank');
  }
  
}
