import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Observable, Subscription } from 'rxjs';

import { StorageService } from 'src/app/service/storage.service';
import { UserConfigurationService } from 'src/app/service/user-configuration.service';
import { ExpressRouteService } from 'src/app/service/express-route.service';
import { TrackingEventDataObject } from 'src/app/model/tracking-event-data.object';
// import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { GoogleMapAdvMarker } from 'src/app/model/google-map-adv-marker.object';
import { ServiceEventObject } from 'src/app/model/service-event.object';
import { Vehicle } from "../../model/vehicle.object";
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';
import { Haversine } from 'src/app/tools/Haversine';
import { Colors } from 'src/app/tools/Colors';
import { Agenda } from 'src/app/model/agenda.object';
import { ExpressRoute } from 'src/app/model/express-route.object';
import { MAP_ALL_AGENDA, MAP_ONLY_CURRENT_AGENDA, StorageKeys } from "../../config";
import { Itinerary } from 'src/app/model/itinerary.object';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';

declare var google: any;

@Component({
  selector: 'div.googleMapCar',
  templateUrl: './google-map-car.component.html',
  styleUrls: ['./google-map-car.component.css']
})
export class GoogleMapCarComponent implements OnInit, OnDestroy {

  // usage in components - r-vehicle-list, ta1-warehouse-vehicle

  private _subscriptions: Array<Subscription> = [];
  
  private _map: any;
  private _currentPositionMarker: GoogleMapAdvMarker = null;
  private _todayStartMarker: GoogleMapAdvMarker = null;
  private _markers: Array<GoogleMapAdvMarker> = [];
  private _initialized: boolean = false;
  private _centeredSwitch: boolean = false;
  private _speedMode: boolean = false;
  private _bounds: any;
  private _polyLines: Array<any> = [];
  private _directions: Array<CustomDirection> = [];
  private _directionsRendered: Array<any> = [];
  private _directionsService = new google.maps.DirectionsService();
  private _trafficLayerShow: boolean = true;
  private _trafficLayer: any = null;

  private _DEFAULT_ZOOM: number = 7;
  private _FOCUS_ZOOM: number = 18;

  private _key: number;
  get key(): number {
    return this._key;
  }
  
  // inputs
  private _properties: any = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
    if (this._properties && this._properties['zoom']) {
      this._DEFAULT_ZOOM = this._properties['zoom'];
    }
  }
  
  private _vehicle: Vehicle = null;
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    if (this._vehicle) {
      // obligations/agenda might be part of express route
      this.loadExpressRoute();
    }
  }
  get vehicle(): Vehicle {
    return this._vehicle;
  }

  private _rebuildCurrentPosition: boolean = false;
  @Input()
  public set rebuildCurrentPosition(value: boolean) {
    this._rebuildCurrentPosition = value;
    if (this._rebuildCurrentPosition) {
      // update current position marker and center map
      this.rebuildData(false);
    }
  }


  constructor(
    private _elRef: ElementRef,
    private _datePipe: DatePipe,
    private _storageService: StorageService,
    private _expressRouteServ: ExpressRouteService,
    private _userConfig: UserConfigurationService,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
    // get traffic layer cookie
    let traffic: string = this._storageService.getItem(StorageKeys.map.traffic_layer, true);
    this._trafficLayerShow = (traffic == 'false') ? false : true;
  }

  ngOnInit(): void {
    if (this._vehicle) {
      this._subscriptions.push(
        this._vehicle.trackingDataLoaded.subscribe(
          (data: Array<TrackingEventDataObject>) => {
            // console.log(data);
            if (data && data.length > 0) {
              // update current position marker + red tracking path 
              this.buildDataVehicle();
            }
          }
        ),
        this._vehicle.destRouteChange.subscribe(
          () => {
            // dest route could be finished / removed / added -> rebuild
            this.rebuildData(false);
          }
        ),
        this._vehicle.latestServiceEvents(false).subscribe(
          (collection: Array<ServiceEventObject>) => {
            if (collection && collection.length) {
              // this.rebuildData(false);
              // there might occur some new not planned service event - add marker
              this.buildDataVehicle();
            }
          }
        ),
        this._userConfig.configChanged.subscribe(
          (changeSet: any) => {
            if (changeSet && changeSet.vehicle_map_agenda) {
              this.rebuildData(false);
            }
          }
        )
      );
    }
    this.buildData();
    this._initialized = true;

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-car';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearData(true);
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }

  private clearData(dropMap: boolean) {
    // dest route ~ manual tracking
    // this._manualTrackingRendered = false;
    if (this._vehicle.manualTrackingDirectionRender) {
      this._vehicle.manualTrackingDirectionRender.setMap(null);
    }

    // vehicle tracking start
    if (this._todayStartMarker) {
      this._todayStartMarker.map = null;
      this._todayStartMarker = null;
    }
    // vehicle current position
    if (this._currentPositionMarker) {
      this._currentPositionMarker.map = null;
      this._currentPositionMarker = null;
    }
    // polylines
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];
    // directions
    this.clearDirectionsRenderers();
    // markers
    this._markers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._bounds = null;
    // this.clearHeatMap();
    if (dropMap) {
      this._map = null;
    }
  }


  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this._rebuildTimeout = window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }
  
  private buildData() {
    this._building = true;

    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      if (this._properties) {
        this._properties.mapId = 'googleMap' + this._key;
      }
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
    }
    this._markers = [];
    if (this._vehicle) {
      // build current position and today tracking route
      this.buildDataVehicle();
      // handle agenda directions and markers
      this.buildAgendaDirections();
    }

    // markers and bounds
    if (this._markers.length) {
      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        (marker: GoogleMapAdvMarker) => {
          marker.map = this._map;
          this._bounds.extend(marker.position);
        }
      );
      if (this._currentPositionMarker) {
        this._bounds.extend(this._currentPositionMarker.position);
      }
    }
    
    if (this._map) {
      if (this._bounds && this._markers.length > 1) {
        this._map.fitBounds(this._bounds);
      } 
      else {
        if (this._markers.length) {
          this._map.setCenter(this._markers[0].position);
        }
        else if (this._currentPositionMarker) {
          this._map.setCenter(this._currentPositionMarker.position);
        }
        this._map.setZoom(this._DEFAULT_ZOOM);
      }

      // possibly draw traffic layer
      if (this._trafficLayerShow && !this._trafficLayer) {
        this.showTrafficLayer();
      }
    }

    this._building = false;
  }

  private buildDataVehicle() {
    if (this._vehicle) {
      // handle marker for current position of vehicle
      if (this._currentPositionMarker) {
        // remove previous position marker
        this._currentPositionMarker.map = null;
      }
      this._currentPositionMarker = this._vehicle.getCurrentPositionMarker();
      if (this._currentPositionMarker) {
        this._currentPositionMarker.map = this._map;
      }

      // always render dest routes direction
      this.buildManualTracking();

      // reinit polylines
      this._polyLines.forEach(
        polyLine => {
          polyLine.setMap(null);
        }
      );
      this._polyLines = [];

      this._vehicle.trackingData.forEach(
        (trackingData, index) => {
          // day tracking start
          if (index === 0) {
            // reinit start marker
            if (this._todayStartMarker) {
              this._todayStartMarker.map = null;
            }
            this._todayStartMarker = trackingData.toMarker(Vehicle.MARKER_ICON_START);
            if (this._todayStartMarker) {
              this._todayStartMarker.map = this._map;
            }
            // this._markers.push(trackingData.toMarker(Vehicle.MARKER_ICON_START));
          }

          // default red color of today route lines
          let strokeColor = '#ff0000';
          if (this._speedMode) {
            // compute color according to settled_mark
            let settled_mark: number = 0;
            if (index < this._vehicle.trackingData.length - 1) {
              settled_mark = this._vehicle.trackingData[index + 1].settled_mark;
            }
            strokeColor = this.colorAccordingToSettledMark(settled_mark); 
          }

          if (this._vehicle.trackingData[index + 1]) {
            this._polyLines.push(
              new google.maps.Polyline({
                path: [
                  trackingData.geoPos.googleLatLang.googleLtLn,
                  this._vehicle.trackingData[index + 1].geoPos.googleLatLang.googleLtLn,
                ],
                geodesic: true,
                strokeColor: strokeColor,
                strokeOpacity: 1.0,
                strokeWeight: 3,
                zIndex: 1000,
                map: this._map
              })
            );
          }

        }
      );
      // add marker for refueling and not planned service events
      this.createMarkersFromVehicleServiceEvents(this._vehicle.latestServiceEventsData);
    }
  }

  buildAgendaDirections(): void {
    if (this._vehicle) {
      // user config for display agenda in maps
      let config = this._userConfig.configuration;
      let vehicle_map_agenda = config.defaultVehicleListConfiguration.vehicle_map_agenda;
      
      let agendas: Array<Agenda> = [];
      // standard agenda handling
      this._vehicle.agenda.forEach(
        agenda => {
          // old version - show just current agendas according to last_position
          if (!agenda.express_delivery) {
            // if (this._vehicle.last_position && this._vehicle.last_position.order_number) {
            //   if (this._vehicle.last_position.order_number.includes(agenda.order_number)) {
            //     // current
            //     this.addMarkersAndDirections(agenda, true);
            //   }
            //   else if (vehicle_map_agenda === MAP_ALL_AGENDA) {
            //     // past or future
            //     this.addMarkersAndDirections(agenda, false);
            //   }
            // }

            if (vehicle_map_agenda === MAP_ONLY_CURRENT_AGENDA) {
              if (this._vehicle.last_position && this._vehicle.last_position.order_number) {
                if (this._vehicle.last_position.order_number.includes(agenda.order_number)) {
                  agendas.push(agenda);
                }
              }
            }
            else { // if (vehicle_map_agenda === MAP_ALL_AGENDA) {
              agendas.push(agenda);
            }
          }
        }
      );
      this.addMarkersAndDirections(agendas);

      // express routes agenda handling
      this._vehicle.expressRoutes.forEach(
        e => {
          let dir: CustomDirection = new CustomDirection();
          // dir.agenda = agenda;
          let dirError: boolean = false;
          dir.directionsColor = '#0075f2'; // default blue
          dir.directionsOpacity = 0.4;
          dir.directionsWeight = 10;

          e.itineraryOrdered.forEach(
            (itinerary, idx) => {
              // ignorujeme prejezd
              // if (itinerary.type !== 'M') {
              let marker = new GoogleMapAdvMarker();
              marker.position = itinerary.gps_coord.googleLatLang;
              // smaller 24px itinerary icons
              marker.icon = itinerary.typeIconSm();
              marker.zIndex = 200;
              marker.title = itinerary.address;
              marker.itinerary_key = itinerary.itinerary_key;
              marker.number = idx + 1;
              if (itinerary.completed) {
                marker.icon = itinerary.typeIconGrayscaleSm();
              }
              else {
                marker.icon = itinerary.typeIconSm();
              }
              
              this._markers.push(marker);

              if (itinerary.gps_coord && itinerary.gps_coord.googleLatLang) {
                dir.directionsArray.push(
                  { location: itinerary.gps_coord.googleLatLang.googleLtLn }
                );
              }
              else {
                // at least 1 invalid/null gps coord -> no direction rendered
                dirError = true;
              }
            }
          );

          // save direction
          if (dirError) dir.directionsArray = [];
          this._directions.push(dir);
        }
      )

      // rendering directions
      if (this._directions.length) {
        this._directions.forEach(
          d => {
            if (d.directionsArray.length) {
              // deep copy
              let options: any = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));
              if (options.polylineOptions) {
                if (d.directionsColor) {
                  options.polylineOptions.strokeColor = d.directionsColor;
                }
                if (d.directionsOpacity) {
                  options.polylineOptions.strokeOpacity = d.directionsOpacity;
                }
                if (d.directionsWeight) {
                  options.polylineOptions.strokeWeight = d.directionsWeight;
                }
              }

              let directionsRenderer = new google.maps.DirectionsRenderer(options);
              directionsRenderer.setMap(this._map);
              let request = {
                origin: d.directionsArray[0].location,
                destination: d.directionsArray[d.directionsArray.length - 1].location,
                waypoints: d.directionsArray,
                travelMode: google.maps.DirectionsTravelMode.DRIVING
              };
              this.callRequestOnDirectionsRender(directionsRenderer, request);
              this._directionsRendered.push(directionsRenderer);
            }
          }
        );
      }
    }
  }

  
  /*************************************************************/
  // Express routes loading
  /*************************************************************/
  loadExpressRoute(): void {
    if (this._vehicle.agenda && this._vehicle.agenda.length) {
      // all current express routes
      let express_route_keys: Array<number> = [];
      this._vehicle.agenda.forEach(
        agenda => {
          if (agenda.express_route_key) {
            if (!express_route_keys.includes(agenda.express_route_key)) {
              express_route_keys.push(agenda.express_route_key);
            }
          }
        }
      );
      
      // load express routes and draw directions
      express_route_keys.forEach(
        express_key => {
          this._subscriptions.push(
            this._expressRouteServ.getExpressRoute(express_key).subscribe(
              express_route => {
                if (express_route) {
                  // console.log(express_route);
                  // load obligations of selected express route
                  this._subscriptions.push(
                    this._expressRouteServ.getExpressObligations(express_route).subscribe(
                      obligations => {
                        if (obligations) {
                          express_route.obligations = obligations;
                          // init sorted itinerary array
                          this.initItineraryExpress(express_route);
                          // save express route for directions creating
                          this._vehicle.expressRoutes.push(express_route);

                          // rebuild data
                          this.rebuildData(false);
                        }
                      }
                    )
                  );
                } 
              }
            )
          );
        }
      );
    }
  }

  initItineraryExpress(express_route: ExpressRoute): void {
    // keep only itinerary with some gps coord
    express_route.itineraryOrdered = express_route.itineraryOrdered.filter(i => i.gps_coord);
    // sort according to arrival time
    express_route.itineraryOrdered.sort(
      (a, b) => (a.arrival_time > b.arrival_time) ? 1 : -1
    );
  }

  
  /*************************************************************/
  // Markers methods
  /*************************************************************/
  // OLD version
  // private addMarkersAndDirections(agenda: Agenda, currentAgenda: boolean): void {
  //   // add direction for agenda
  //   let dir: CustomDirection = new CustomDirection();
  //   dir.agenda = agenda;
  //   let dirError: boolean = false;
  //   // dir.directionsColor = '#0000ff'; // default blue
  //   dir.directionsColor = '#0075f2'; // default blue
  //   dir.directionsOpacity = 0.4;
  //   dir.directionsWeight = 10;

  //   // specification of not current agendas
  //   if (!currentAgenda) {
  //     // smaller/lighter weight
  //     dir.directionsWeight = 6;
  //     switch (agenda.status) {
  //       case 'A':
  //         // zahajena, kterou nejede (zrejme budouci planovana)
  //         dir.directionsColor = '#4f4f4f'; // gray
  //         dir.directionsOpacity = 0.3;
  //         break;
  //       case 'G':
  //         // svoz
  //         dir.directionsColor = '#91d8ce'; // cyan1
  //         dir.directionsOpacity = 0.7;
  //         break;
  //       case 'H':
  //         // sklad
  //         dir.directionsColor = '#48bfae'; // cyan2
  //         dir.directionsOpacity = 0.7;
  //         break;
  //       case 'L':
  //         // sklad - k rozvozu
  //         dir.directionsColor = '#48bfae'; // cyan2
  //         dir.directionsOpacity = 0.7;
  //         break;
  //       case 'J':
  //         // rozvoz
  //         dir.directionsColor = '#1ab19a'; // cyan3
  //         dir.directionsOpacity = 0.7;
  //         break;
  //       case 'B':
  //         // k fakturaci
  //         dir.directionsColor = '#ff626f'; // light red
  //         dir.directionsOpacity = 0.4;
  //         break;
  //       case 'C':
  //         // fakturovana
  //         dir.directionsColor = '#b04bb3'; // purple
  //         dir.directionsOpacity = 0.4;
  //         break;
  //       case 'F':
  //         // dokoncena
  //         dir.directionsColor = '#00ff00'; // green
  //         dir.directionsOpacity = 0.4;
  //         break;
  //       default:
  //         break;
  //     }
  //   }

  //   agenda.itinerary.forEach(
  //     itinerary => {
  //       // ignorujeme prejezd
  //       if (itinerary.type !== 'M') {
  //         let marker = new GoogleMapMarker();
  //         marker.position = itinerary.gps_coord.googleLatLang;
  //         // smaller 24px itinerary icons
  //         marker.icon = itinerary.typeIconSm();
  //         marker.zIndex = 200;
  //         marker.title = itinerary.address;
  //         marker.itinerary_key = itinerary.itinerary_key;
          
  //         this._markers.push(marker);
  //       }

  //       if (itinerary.gps_coord && itinerary.gps_coord.googleLatLang) {
  //         dir.directionsArray.push(
  //           { location: itinerary.gps_coord.googleLatLang.googleLtLn }
  //         );
  //       }
  //       else {
  //         // at least 1 invalid/null gps coord -> no direction rendered
  //         dirError = true;
  //       }
  //     }
  //   );

  //   // save direction
  //   if (dirError) dir.directionsArray = [];
  //   this._directions.push(dir);
  // }



  private addMarkersAndDirections(agendas: Array<Agenda>): void {
    if (agendas.length) {
      // add direction for agenda
      let dir: CustomDirection = new CustomDirection();
      let dirError: boolean = false;
      // dir.agenda = agenda;
      dir.directionsColor = '#0075f2'; // default blue
      dir.directionsOpacity = 0.4;
      dir.directionsWeight = 10;

      agendas.forEach(
        a => {
          let sortedItinerary: Array<Itinerary> = [];
          a.itinerary.forEach(
            it => {
              sortedItinerary.push(it);
            }
          );
          sortedItinerary.sort((a, b) => (a.arrival_time > b.arrival_time) ? 1 : -1);

          sortedItinerary.forEach(
            it => {
              // ignorujeme prejezd
              if (it.type !== 'M') {
                let marker = new GoogleMapAdvMarker();
                marker.position = it.gps_coord.googleLatLang;
                // smaller 24px itinerary icons
                marker.icon = it.typeIconSm();
                marker.zIndex = 200;
                marker.title = it.address;
                marker.itinerary_key = it.itinerary_key;
                
                this._markers.push(marker);
              }

              if (it.gps_coord && it.gps_coord.googleLatLang) {
                dir.directionsArray.push(
                  { location: it.gps_coord.googleLatLang.googleLtLn }
                );
              }
              else {
                // at least 1 invalid/null gps coord -> no direction rendered
                dirError = true;
              }
            }
          );
        }
      );
      
      // save direction
      if (dirError) dir.directionsArray = [];
      this._directions.push(dir);
    }
  }

  // markers created from service events (not planned)
  private createMarkersFromVehicleServiceEvents(events: Array<ServiceEventObject>) {
    // console.log(events);
    events.forEach(
      event => {
        let marker: GoogleMapAdvMarker = event.toMarker();
        if (event.type === ServiceEventObject.TYPE_REFUELING) {
          // fueling with info window now not supported
          // let content: string = this._translator.translate("map-title-service-event-fueling");
          // content = content.replace('%DATE', this._datePipe.transform(DateTools.isoFix(event.time), 'dd.MM.yyyy HH:mm'));
          // content = content.replace('%LITER', event.liter);
          // content = content.replace('%FULL_REFUELING', event.full_tank_refueling ? 'plná ' : '');
          // content = content.replace('%CODE', event.code);
          // content = content.replace('%PRICE', event.price ? event.price.toString() : 'N/A ');
          // content = content.replace('%PAYMENT_TYPE', event.payment_type ? event.payment_type : 'N/A ');
          // content = content.replace('%CURRENCY', event.currency);
          // content = content.replace('%CLOCK_UP', event.clock_up ? event.clock_up.toString() : 'N/A ');
          // marker.infoWindowContent = content;
          marker.map = this._map;
          this._markers.push(marker);
        }
        else if (event.type == ServiceEventObject.TYPE_LOADING_END || event.type == ServiceEventObject.TYPE_UNLOADING_END) {
          let found: boolean = false;
          for (let i = 0; i < this._markers.length; i++) {
            let m = this._markers[i];
            if (m.position) {
              let dist = Haversine.haversine(
                { latitude: event.latLng.lat, longitude: event.latLng.lng },
                { latitude: m.latitude, longitude: m.longitude }
                // { latitude: m.position.lat(), longitude: m.position.lng() }
              );
              // distance closer than 1 km
              if (dist < 1) {
                found = true;
                // break
                break;
              }
            }
          }

          // if not found -> not planned in agenda -> add marker
          if (!found) {
            marker.map = this._map;
            this._markers.push(marker);
          }
        }
      }
    );
  }

  
  /*************************************************************/
  // Centering methods
  /*************************************************************/
  centerToCurrentVehiclePosition() {
    if (this._vehicle && this._vehicle.last_position) {
      this.centerToPosition(this._vehicle.getCurrentPositionMarker().position);
    }
  }
  
  private centerToPosition(position: any) {
    if (!this._centeredSwitch) {
      this._map.setZoom(this._FOCUS_ZOOM);
      this._map.setCenter(position);
      this._map.setMapTypeId(google.maps.MapTypeId.HYBRID);

      this._centeredSwitch = true;
    } 
    else {
      // this.rebuildData(false);
      this._map.setZoom(this._DEFAULT_ZOOM);
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this._centeredSwitch = false;
    }
  }


  /*************************************************************/
  // Manual tracking methods
  /*************************************************************/
  private buildManualTracking() {
    if (this._vehicle && this._vehicle.temporaryManualTracking) {
      if (this._vehicle.temporaryManualTracking.length === 2) {
        this._vehicle.manualTrackingDirectionRender.setMap(this._map);
        this._vehicle.temporaryManualTracking[1].map = this._map;
        this._markers.push(this._vehicle.temporaryManualTracking[1]);
        this._directionsRendered.push(this._vehicle.manualTrackingDirectionRender);
      }
    }
  }

  
  /*************************************************************/
  // Waypoints/directions methods
  /*************************************************************/
  private _directionsRenderCalls: number = 0;

  private callRequestOnDirectionsRender(directionsRendered, request: any) {
    let isset = false;
    this._directionsRenderCalls++;
    window.setTimeout(
      () => {
        // api request call
        let api_request_log: ExternalApiRequest = new ExternalApiRequest();
        api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
        api_request_log.type = 'directions';
        api_request_log.descr = 'google-map-car';
        api_request_log.price = 0.005;
        this._externalApiRequestServ.createRequestLog(api_request_log);

        // google request
        this._directionsService.route(
          request,
          (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (isset === false) {
                directionsRendered.setDirections(response);
                isset = true;
              }
            }
            this._directionsRenderCalls--;
          });
      },
      500 * this._directionsRenderCalls // 1001 * this._directionsRenderCalls
    );
  }
  
  private clearDirectionsRenderers() {
    this._directionsRendered.forEach(
      directionRendered => {
        directionRendered.setMap(null);
      }
    );
    this._directionsRendered = [];
    this._directions = [];
  }
  

  /*************************************************************/
  // Traffic methods
  /*************************************************************/
  showTrafficLayer() {
    this._trafficLayer = new google.maps.TrafficLayer();
    this._trafficLayer.setMap(this._map);
  }

  toggleTrafficLayer() {
    if (!this._trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
    }

    if (this._trafficLayer.getMap() == null) {
      // traffic layer is disabled.. enable it
      this._trafficLayer.setMap(this._map);
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'true', true);
    }
    else {
      // traffic layer is enabled.. disable it
      this._trafficLayer.setMap(null);        
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'false', true);     
    }
  }  
  
  toggleSpeedView() {
    this._speedMode = !this._speedMode;
    // build current position and today tracking route
    this.buildDataVehicle();
  }

  
  /*************************************************************/
  // Colouring methods
  /*************************************************************/
  // method that computes driveManager color temperature
  colorAccordingToSettledMark(settled_mark: number): string {
    let color: string = '#999999';
    if (settled_mark >= 1.0) {
      // normalize <1.0-5.0> to <0.0-4.0>
      let normalized: number = settled_mark - 1;
      // https://stackoverflow.com/a/11850303
      let hue = (4.0 - normalized) * 120 / 4.0;  // go from green(120) to red(0)
      hue = hue / 360;  // normalize hue into <0-1> interval
      
      // hsv to rgb
      let rgb: any = Colors.HSVtoRGB(hue, 1, 1);
      // rgb to hexa string
      color = Colors.rgbToHex(rgb.r, rgb.g, rgb.b);
    }

    return color;
  }
}


export class CustomDirection {
  public agenda: Agenda = null;
  public directionsColor: string = null;
  public directionsOpacity: number = null;
  public directionsWeight: number = null;
  public directionsArray: Array<any> = [];
}


// TOFOUND OBSOLETE?
// Pockat si na report, protoze je tento usek pravdepodobne zbytecny
// if (!this._speedMode && trackingData.truck_status === Vehicle.INTERRUPTION && vehicle.trackingData.length > 1) {
//   let path: Array<any> = [];
//   if (index === 0) {
//     path = [
//       vehicle.trackingData[index].geoPos.googleLatLang.googleLtLn,
//       vehicle.trackingData[index + 1].geoPos.googleLatLang.googleLtLn
//     ];
//   } 
//   else {
//     path = [
//       vehicle.trackingData[index - 1].geoPos.googleLatLang.googleLtLn,
//       vehicle.trackingData[index].geoPos.googleLatLang.googleLtLn
//     ];
//   }

//   this._polyLines.push(
//     new google.maps.Polyline({
//       path: path,
//       geodesic: true,
//       strokeColor: '#000000',
//       strokeOpacity: 1.0,
//       strokeWeight: 2,
//       zIndex: 1001,
//       map: this._map
//     })
//   );
// }