 import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription, Subject, Observable, merge } from "rxjs";
import { distinctUntilChanged, debounceTime, switchMap, filter, map } from "rxjs/operators";
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { ContentEditableDirective } from '../../directive/content-editable.directive';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { TemplateJsonService } from 'src/app/service/template-json.service';
import { AddressBookService } from "../../service/address-book.service";
import { NotificationService } from "../../service/notification-service";
import { StorageService } from 'src/app/service/storage.service';
import { CompanyService } from "../../service/company.service";
import { SettingsService } from "../../service/settings.service";
import { OrderService } from 'src/app/service/order.service';
import { Settings } from "../../model/settings.object";
import { Company } from '../../model/company.object';
import { Order } from 'src/app/model/order.object';
import { User } from 'src/app/model/user.object';
import { Person } from 'src/app/model/person.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { EMAIL_REGEX, ItineraryType, StorageKeys } from 'src/app/config';

declare var $: any;

@Component({
  selector: 'app-ta1-order-emailing',
  templateUrl: './ta1-order-emailing.component.html',
  styleUrls: ['./ta1-order-emailing.component.css'],
  providers: [
    SettingsService,
    AddressBookService,
    OrderService,
    TemplateJsonService,
    ContentEditableDirective
  ]
})
export class Ta1OrderEmailingComponent implements OnInit, OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private _company: Company = null;
  public get company(): Company {
    return this._company;
  }

  private _user: User = null;
  private _companyEmails: Array<any> = [];
  private _settingsDocs: Settings = null;
  private _dictionary: any = {};

  private _order: Order;
  @Input()
  public set order(value: Order) {
    this._order = value;
    if (this._order) {
      // init email
      this.changeLanguageEmail();
      this.initEmail();
      this.initUpdatedBy();
      this.initContactFrom();
      this.displayPreviewPDF();
    }
  }
  public get order(): Order {
    return this._order;
  }
  
  private _itinerary_to_email: Itinerary = null;
  @Input()
  public set itinerary_to_email(value: Itinerary) {
    this._itinerary_to_email = value;
    this.reinitComponent();
    this.initEmail();
    this.initUpdatedBy();
    this.initContactFrom();
  }
  
  // output for binding email after it has been sent
  @Output() emailHasBeenSent: EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    private _authServ: AuthenticationService,
    private _companyServ: CompanyService,
    private _orderServ: OrderService,
    private _addressBookServ: AddressBookService,
    private _settingsServ: SettingsService,
    private _jsonServ: TemplateJsonService,
    private _notificationServ: NotificationService,
    private _datePipe: DatePipe,
    private _storageServ: StorageService
  ) { 
  }

  ngOnInit(): void {
    this.loadData();

    // init sendCopy
    let tmp = this._storageServ.getItem(StorageKeys.email.copy_order, true);
    this.sendCopy = (tmp == 'true') ? true : false;
    
    let tmp2 = this._storageServ.getItem(StorageKeys.email.copy_order_address, true);
    if (tmp2) {
      try {
        // copy could be string or stringified array
        let tmp_copy = JSON.parse(tmp2);
        if (Array.isArray(tmp_copy)) {
          tmp_copy.forEach(
            (item, idx) => {
              if (idx < tmp_copy.length - 1) {
                // multiple save to copy
                this.multipleCopyTo.push(item);
              }
              else {
                // rewrite last to current contactCopy
                this.contactCopy = item;
              }
            }
          );
        }
        else {
          this.contactCopy = tmp_copy;
        }
        this.contactCopyCookies = tmp_copy;
      }
      catch (e) {
        console.log(e);
        this.contactCopy = tmp2;
        this.contactCopyCookies = tmp2;
      }
    }
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      s => {
        s.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  // method for reinitalization from outside - e.g. from invoice_list
  public reinitComponent(): void {
    this._orderServ.sendCompleted = false;
    this._orderServ.sendOK = false;
  }

  // (re)inititialization
  private defaultInitialize(): void {
    this._settingsDocs = null;
    this._dictionary = {};
  }

  private loadData(): void {
    // (re)inititialization
    this.defaultInitialize();

    this._subscribed.push(
      this._jsonServ.getInvoiceTemplateDictionary().subscribe(
        data => {
          if (data && this._dictionary !== data) {
            this._dictionary = data;
          }
        },
        error => {
          console.log(error);
        }
      ),
      this._settingsServ.getSettingsDocs().subscribe(
        docs => {
          if (docs && this._settingsDocs !== docs) {
            this._settingsDocs = docs;
            
            // init email info
            this.initEmail();
            this.initUpdatedBy();
            this.initContactFrom();
          }
        }
      ),
      this._companyServ.getCompanyFullObservable().subscribe(
        response => {
          if (response && this._company !== response) {
            this._company = response;
            if (this._company.contactsAsArray && this._company.contactsAsArray.length) {
              // concat company emails
              this._companyEmails = this._companyEmails.concat(
                this._company.contactsAsArray.filter(c => c.type == "E")
              );
              this._companyEmails.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
            }
            // init email info
            this.initEmail();
            this.initUpdatedBy();
            this.initContactFrom();
          }
        }
      ),
      this._authServ.authenticationResult.subscribe(
        user => {
          if (user) {
            this._user = user;
            this.initContactFrom();
          } 
        }
      )
    );
  }

  // flag when email is currently being sent
  public get sending(): boolean {
    return this._orderServ.sendingEmail;
  }

  // flag for email has been sent
  public get sendCompleted(): boolean {
    return this._orderServ.sendCompleted;
  }

  // flag when email has been correctly sent
  public get sendOK(): boolean {
    return this._orderServ.sendOK;
  }

  // fix user input (ctrl+v) with space
  emailTruncate(email: any): string {
    console.log(email);
    console.log('contactTo - ', this.contactTo);

    if (email) {
      if (email.email) {
        email.email = email.email.trim();
      }
      else if (email.value) {
        email.value = email.value.trim();
      }
      else {
        email = email.trim();
      }
    }
    
    console.log(email);

    this.contactTo = email;
    console.log('contactTo - ', this.contactTo);

    return email;
  }


  /************************************************************/
  /* Sending email part */
  /************************************************************/
  public languageEmail: string = 'EN';

  public subjectEmail: string = '';
  public textEmail: string = '';
  public signatureEmail: string = '';

  private _personName: string = '';
  private _personEmail: string = '';
  private _personPhone: string = '';

  // custom validation of person's email
  validEmailAddress(email: any) {
    if (!email) {
      return true;
    }

    if (email.email) {
      if (email.email.match(EMAIL_REGEX)) {
        return true;
      }
    }
    else if (email.value) {
      if (email.value.match(EMAIL_REGEX)) {
        return true;
      }
    }
    else if (email.match(EMAIL_REGEX)) {
      return true;
    }

    return false;
  }

  validContactFrom(email: any) {
    if (this._company && !this._company.send_emails_from_their_domain) {
      // info@truckmanager.eu
      return true;
    }

    return this.validEmailAddress(email);
  }

  // method for possible changing of language for preview
  private changeLanguageEmail(): void {
    let c: string = 'EN';
    if (this._order.order_comp_book) 
      c = this._order.order_comp_book.country;
    
    if (c == 'CZ' || c == 'SK' || c == 'EN' || c == 'DE' || c == 'ES' || c == 'IT' || c == 'PL') {
      this.languageEmail = c;
    }
    else if (c == 'AT') {
      // Austria
      this.languageEmail = 'DE';
    }
    else {
      // other countries -> EN language
      this.languageEmail = 'EN';
    }
  }
  
  // handler after emitting into contenteditable div 
  public afterTextEmailChange(event: any): void {
    this.textEmail = event;
  }

  // handler after emitting into contenteditable div 
  public afterSignatureEmailChange(event: any): void {
    this.signatureEmail = event;
  }


  // order pdf preview
  private _loadingPreview: boolean = false;
  public get loadingPreview(): boolean {
    return this._loadingPreview;
  }
  
  // method for displaying preview of invoice pdf
  public displayPreviewPDF(): void {
    if (!this._order || !this._order.order_key || this._loadingPreview) return;
    // reinit displayed pdf
    this._order.previewPDF = null;

    // set language of preview
    let lng: string = this.languageEmail ? this.languageEmail : 'CZ';
    
    if (!['CZ', 'SK', 'EN', 'DE', 'ES', 'IT'].includes(lng)) lng = 'EN';
    
    // set flag for loading
    this._loadingPreview = true;

    this._subscribed.push(
      this._orderServ.getPreview(this._order, lng).subscribe(
        response => {
          let newBlob: any = new Blob([response], { type: (response.type ? response.type : 'application/pdf') });
          // save data to attachment fileContent property
          this._order.previewPDF = URL.createObjectURL(newBlob);
          // set flag after successful loading
          this._loadingPreview = false;
        },
        error => {
          console.log(error);
          // set flag after error loading
          this._loadingPreview = false;
          this._notificationServ.alert(
            $localize`Chyba při vytváření PDF náhledu - ` + this._order.orderNumberFormatted, 
            'error', 3500
          );
        }
      )
    );
  }

  // method for initializing info about email
  public initEmail(changeLanguage: boolean = false): void {
    // default init
    // init email subject
    if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.order_mail_subject) {
      this.subjectEmail = this._settingsDocs.texts.order_mail_subject[this.languageEmail];
      if (this.subjectEmail && this._company) 
        this.subjectEmail = this.subjectEmail.replace(/@company\/company/g, this._company.company);
      if (this._order && this.subjectEmail) 
        this.subjectEmail = this.subjectEmail.replace(/@order\/order_number_standard/g, this._order.orderNumberFormatted);
    }
    // init email text (order sending)
    if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.order_mail_text) {
      this.textEmail = this._settingsDocs.texts.order_mail_text[this.languageEmail];
      if (this.textEmail && this._order) {
        this.textEmail = this.textEmail.replace(/@order\/order_number_standard/g, this._order.orderNumberFormatted);
        if (this._order.contracted_price || this._order.contracted_price == 0) {
          let price_replace: string = this._order.contracted_price.toString() + ',-' + this._order.currency;
          this.textEmail = this.textEmail.replace(/@order\/contracted_price/g, price_replace);
        }
        else {
          this.textEmail = this.textEmail.replace(/@order\/contracted_price/g, 'N/A');
        }
        
        let itinerary: string = '';
        if (this._order.obligations && this._order.obligations.length) {
          this._order.obligations.forEach(
            o => {
              itinerary += '<b>' + o.obligationNumberFormatted + '</b><br>';

              // itinerary from obligation - could be restricted by warehouse
              let obligation_itinerary: Array<Itinerary> = [];
              if (this._order.delivery_type == "P") {
                obligation_itinerary = o.itinerary_warehouse_collection;
              }
              else if (this._order.delivery_type == "D") {
                obligation_itinerary = o.itinerary_warehouse_delivery;
              }
              else {
                obligation_itinerary = o.itinerary;
              }

              obligation_itinerary.forEach(
                (it, index) => {
                  let types: Array<string> = [
                    ItineraryType.LOADING, ItineraryType.UNLOADING, 
                    ItineraryType.WAREHOUSE, ItineraryType.WAREHOUSE_AUTO
                  ];
                  if (types.includes(it.type)) {
                    if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                      itinerary += (index + 1) + ') ' +  it.typeName + ' ';
                    }
                    else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                      itinerary += (index + 1) + ') ';
                      itinerary += (this.order.delivery_type == 'P' ? 'Vykládka' : 'Nakládka');
                      itinerary += ' (' + it.typeName + ') ';
                    }
                    itinerary += it.arrival_time_formatted + '&nbsp;' + it.address;
                    itinerary += ' (' + (it.weight ? (it.weight + 't, ') : ''); 
                    itinerary += (it.ware_pcs ? (it.ware_pcs + ' ') : ''); 
                    itinerary += (it.ware_type ? (it.ware_type + ', ') : ''); 
                    itinerary += (it.note ? ('pozn.: ' + it.note) : '') + ')'; 
                    itinerary += '<br>';
                  }
                }
              );
              itinerary += '<br>';
            }
          );
        }
        if (this.textEmail) {
          this.textEmail = this.textEmail.replace('@obligation/itinerary', itinerary);

          if (this._order.accept_token) {
            // replace external access token to ext-order
            let url: string = 'https://ext2.truckmanager.eu/#/ext-order';
            url += '?order=' + this._order.order_number_standard;
            url += '&token=' + this._order.accept_token;
            this.textEmail = this.textEmail.replace(/@token/g, url);

            let now: Date = new Date();
            // increment + 2 hours
            let now2Hours: Date = new Date(now.getTime() + 2*60*60*1000);
            let agreement_timer: string = this._datePipe.transform(now2Hours, 'dd.MM.yyyy HH:mm');
            this.textEmail = this.textEmail.replace(/@agreement_timer/g, agreement_timer);
          }
        }
      }
    }

    // init email text (completing itinerary/order notification)
    if (this._itinerary_to_email) {
      // init email subject
      if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.order_completed_mail_subject) {
        this.subjectEmail = this._settingsDocs.texts.order_completed_mail_subject[this.languageEmail];
        if (this.subjectEmail && this._company) 
          this.subjectEmail = this.subjectEmail.replace(/@company\/company/g, this._company.company);
        if (this._order && this.subjectEmail) 
          this.subjectEmail = this.subjectEmail.replace(/@order\/order_number_standard/g, this._order.orderNumberFormatted);
      }
      // init email text
      if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.order_completed_mail_text) {
        this.textEmail = this._settingsDocs.texts.order_completed_mail_text[this.languageEmail];
        console.log(this.textEmail);
        if (this.textEmail && this._order) {
          this.textEmail = this.textEmail.replace(/@order\/order_number_standard/g, this._order.orderNumberFormatted);
          if (this._order.contracted_price || this._order.contracted_price == 0) {
            let price_replace: string = this._order.contracted_price.toString() + ',-' + this._order.currency;
            this.textEmail = this.textEmail.replace(/@order\/contracted_price/g, price_replace);
          }
          else {
            this.textEmail = this.textEmail.replace(/@order\/contracted_price/g, 'N/A');
          }
          
          let itinerary: string = '';
          if (this._order.obligations && this._order.obligations.length) {
            this._order.obligations.forEach(
              o => {
                itinerary += '<b>' + o.obligationNumberFormatted + '</b><br>';
  
                // itinerary from obligation - could be restricted by warehouse
                let obligation_itinerary: Array<Itinerary> = [];
                if (this._order.delivery_type == "P") {
                  obligation_itinerary = o.itinerary_warehouse_collection;
                }
                else if (this._order.delivery_type == "D") {
                  obligation_itinerary = o.itinerary_warehouse_delivery;
                }
                else {
                  obligation_itinerary = o.itinerary;
                }
                
                let loading_name: string = 'nakládka';
                let unloading_name: string = 'vykládka';
                switch (this.languageEmail) {
                  case 'CZ':
                    loading_name = 'Nakládka';
                    unloading_name = 'Vykládka';
                    break;
                  case 'SK':
                    loading_name = 'Nakládka';
                    unloading_name = 'Vykládka';
                    break;
                  case 'EN':
                    loading_name = 'Loading';
                    unloading_name = 'Unloading';
                    break;
                  case 'DE':
                    loading_name = 'Laden';
                    unloading_name = 'EntLaden';
                    break;
                  case 'PL':
                    loading_name = 'Załadunku';
                    unloading_name = 'Rozładunku';
                    break;
                  case 'IT':
                    loading_name = 'Carico';
                    unloading_name = 'Scarico';
                    break;
                  case 'ES':
                    loading_name = 'Cargando';
                    unloading_name = 'Descarga';
                    break;
                  default:
                    break;
                }

                obligation_itinerary.forEach(
                  (it, index) => {
                    // rename typeName according to language of email
                    it.typeName = it.type == ItineraryType.LOADING ? loading_name : unloading_name;

                    let types: Array<string> = [
                      ItineraryType.LOADING, ItineraryType.UNLOADING, 
                      ItineraryType.WAREHOUSE, ItineraryType.WAREHOUSE_AUTO
                    ];
                    if (types.includes(it.type)) {
                      if (it.itinerary_key == this._itinerary_to_email.itinerary_key) {
                        itinerary += '<b>';
                      }
                      if (it.type == ItineraryType.LOADING || it.type == ItineraryType.UNLOADING) {
                        itinerary += (index + 1) + ') ' +  it.typeName + ' ';
                      }
                      else if (it.type == ItineraryType.WAREHOUSE || it.type == ItineraryType.WAREHOUSE_AUTO) {
                        itinerary += (index + 1) + ') ';
                        itinerary += (this.order.delivery_type == 'P' ? 'Vykládka' : 'Nakládka');
                        itinerary += ' (' + it.typeName + ') ';
                      }
                      itinerary += it.arrival_time_formatted + '&nbsp;' + it.address;
                      itinerary += ' (' + (it.weight ? (it.weight + 't, ') : ''); 
                      itinerary += (it.ware_pcs ? (it.ware_pcs + ' ') : ''); 
                      itinerary += (it.ware_type ? (it.ware_type + ', ') : ''); 
                      itinerary += (it.note ? ('pozn.: ' + it.note) : '') + ')'; 
                      if (it.itinerary_key == this._itinerary_to_email.itinerary_key) {
                        itinerary += '</b>';
                      }
                      itinerary += '<br>';
                    }
                  }
                );
                itinerary += '<br>';
              }
            );
          }
          if (this.textEmail) {
            this.textEmail = this.textEmail.replace(/@obligation\/itinerary/g, itinerary);
            this.textEmail = this.textEmail.replace(/@itinerary\/work_day_end/g, this._itinerary_to_email.work_day_end_formatted);

            let loading_name: string = 'nakládka';
            let unloading_name: string = 'vykládka';
            switch (this.languageEmail) {
              case 'CZ':
                loading_name = 'nakládka';
                unloading_name = 'vykládka';
                break;
              case 'SK':
                loading_name = 'nakládka';
                unloading_name = 'vykládka';
                break;
              case 'EN':
                loading_name = 'loading';
                unloading_name = 'unloading';
                break;
              case 'DE':
                loading_name = 'laden';
                unloading_name = 'entladen';
                break;
              case 'PL':
                loading_name = 'załadunku';
                unloading_name = 'rozładunku';
                break;
              case 'IT':
                loading_name = 'carico';
                unloading_name = 'scarico';
                break;
              case 'ES':
                loading_name = 'cargando';
                unloading_name = 'descarga';
                break;
              default:
                break;
            }
            this.textEmail = this.textEmail.replace(/@itinerary\/type/g, this._itinerary_to_email.type == "L" ? loading_name : unloading_name);


            if (this._order.accept_token) {
              // replace external access token to ext-order
              let url: string = 'https://ext2.truckmanager.eu/#/ext-order';
              url += '?order=' + this._order.order_number_standard;
              url += '&token=' + this._order.accept_token;
              url += '&nav=4';  // nav 4 ~ itinerary completing
              this.textEmail = this.textEmail.replace(/@token/g, url);
  
              let now: Date = new Date();
              // increment + 2 hours
              let now2Hours: Date = new Date(now.getTime() + 2*60*60*1000);
              let agreement_timer: string = this._datePipe.transform(now2Hours, 'dd.MM.yyyy HH:mm');
              this.textEmail = this.textEmail.replace(/@agreement_timer/g, agreement_timer);
            }
          }
        }
      }
    }

    // init email signature
    if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.invoice_mail_signature) {
      this.signatureEmail = this._settingsDocs.texts.invoice_mail_signature[this.languageEmail];

      if (this.signatureEmail && this._order) {
        let name: string = '';
        if (this._order.person_key && this._order.person && this._order.person.name)
          name = this._order.person.name;
        else if (this._company.company)
          name = this._company.company;

        this.signatureEmail = this.signatureEmail.replace('@person', name);
      }
      
      if (this.signatureEmail && this._company) {
        this.signatureEmail = this.signatureEmail.replace('@company/company', this._company.company);
        this.signatureEmail = this.signatureEmail.replace('@company/street', this._company.street);
        this.signatureEmail = this.signatureEmail.replace('@company/zip', this._company.zip);
        this.signatureEmail = this.signatureEmail.replace('@company/city', this._company.city);
        this.signatureEmail = this.signatureEmail.replace('@company/country', this._company.country);
      }
    }

    // sender, receiver, copy init
    this.multipleContactsTo = [];
    this.multipleCopyTo = [];
    if (!changeLanguage) {
      // init email from
      if (this._companyEmails.length) {
        if (this._order.person_key && this._order.person) {
          let contact = this._companyEmails.find(e => e.importance == this._order.person.importance);
          if (contact) {
            // set person responsible for this order
            this.contactFrom = contact;
            if (!this.contactCopyCookies) {
              this.contactCopy = contact;
            }
            this._replyTo = contact.value;
          }
          else {
            // according to importance
            this.contactFrom = this._companyEmails[0];
            if (!this.contactCopyCookies) {
              this.contactCopy = this._companyEmails[0];
            }
            this._replyTo = this._companyEmails[0].value;
          }
        }
        else {
          // according to importance
          this.contactFrom = this._companyEmails[0];
          if (!this.contactCopyCookies) {
            this.contactCopy = this._companyEmails[0];
          }
          this._replyTo = this._companyEmails[0].value;
        }
      }
      // init email to
      if (this._order && this._order.order_email) {
        this.contactTo = this._order.order_email;
      }
    }
  }

  // initialization of contacts
  private initUpdatedBy(): void {
    if (this._company && this._company.personsAsArray && this._company.personsAsArray.length) {
      // using person from order
      if (this._order && this._order.person_key && this._order.person) {
        let importance: number = this._order.person.importance;
        this._personName = this._order.person.name;

        let email: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'E');
        if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'S');
        if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'U');
        if (email) this._personEmail = email.value;
        
        let phone: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'G');
        if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'T');
        if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'F');
        if (phone) this._personPhone = phone.value;
      }
      // using company contacts
      else {
        let persons: Array<any> = this._company.personsAsArray;
        persons.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
        // set the most important person as contact on invoice
        let importance: number = persons[0].importance;
        this._personName = persons[0].name;
        
        let email: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'E');
        if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'S');
        if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'U');
        if (email) this._personEmail = email.value;
        
        let phone: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'G');
        if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'T');
        if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'F');
        if (phone) this._personPhone = phone.value;
      }
    }
  }

  private initContactFrom(): void {
    if (this._user && this._company && this._company.send_emails_from_their_domain) {
      let user_account = this._company.usersAsArray.find(u => u.username == this._user.username);
      // check if current user is not global
      if (user_account && user_account.person_key) {
        let person = this._company.personsAsArray.find(p => p.person_key == user_account.person_key);
        if (person) {
          // person ~ current logged user email
          let contact = this._companyEmails.find(c => c.importance == person.importance);
          if (contact) {
            this.contactFrom = contact;
            this.contactCopy = contact;
            this._replyTo = contact.value;
          }
        }
      }
    }
  }


  /************************************************************/
  /* Autocompleters part */
  /************************************************************/
  // type "any" because of autocompleters ngbtypeahead
  public contactFrom: any = null;

  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusContactFrom$ = new Subject<string>();
  clickContactFrom$ = new Subject<string>();
  @ViewChild('autocompleteContactFrom', {static: false}) autocompleteContactFrom: NgbTypeahead;
  
  autoCompleteContactFrom = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusContactFrom$;
    const inputClick$ = this.focusContactFrom$.pipe(filter(() => !this.autocompleteContactFrom.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      map(term => (term === '' ? 
          this._companyEmails : 
          this._companyEmails.filter(e => e.value.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
      )
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueContactFrom(value: any) {
    if (value.value)
      return value.value;
    return value;
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListContactFrom(value: any) {
    if (value.value)
      return value.value;
    return value;
  }

  // copy switch
  public sendCopy: boolean = false;
  sendCopyChange(): void {
    this._storageServ.setItem(StorageKeys.email.copy_order, this.sendCopy.toString(), true);
  }
  

  // type "any" because of autocompleters ngbtypeahead
  public contactCopy: any = null;
  public contactCopyCookies: any = null;
  
  copyChange(): void {
    if (this.contactCopy) {
      if (this.contactCopy.value) {
        this.contactCopyCookies = this.contactCopy.value;
        this._storageServ.setItem(
          StorageKeys.email.copy_order_address, JSON.stringify(this.contactCopy.value), true
        );
      }
      else {
        this.contactCopyCookies = this.contactCopy;
        this._storageServ.setItem(
          StorageKeys.email.copy_order_address, JSON.stringify(this.contactCopy), true
        );
      }
    }
  }


  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusContactCopy$ = new Subject<string>();
  clickContactCopy$ = new Subject<string>();
  @ViewChild('autocompleteContactCopy', {static: false}) autocompleteContactCopy: NgbTypeahead;
  
  autoCompleteContactCopy = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusContactCopy$;
    const inputClick$ = this.focusContactCopy$.pipe(filter(() => !this.autocompleteContactCopy.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      map(term => (term === '' ? 
          this._companyEmails : 
          this._companyEmails.filter(e => e.value.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
      )
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueContactCopy(value: any) {
    if (value.value)
      return value.value;
    return value;
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListContactCopy(value: any) {
    if (value.value)
      return value.value;
    return value;
  }

  // multiple contacts in copy
  public multipleCopyTo: Array<any> = [];

  onKeyUpCopy(e: any) {
    if (e.target.value && e.target.value.endsWith(",")) {
      // remove comma
      if (this.contactCopy.value) {
        this.contactCopy.value = this.contactCopy.value.substring(0, this.contactCopy.value.length - 1);
      }
      else {
        this.contactCopy = this.contactCopy.substring(0, this.contactCopy.length - 1);
      }
      // add new email input
      this.addContactCopy();
    }
  }

  addContactCopy(): void {
    if (!this.contactCopy) {
      let text: string = $localize`Nejprve vyplňte prvního příjemce, poté bude možné přidat další.`;
      this._notificationServ.alert(text, 'error', 4000);
      return;
    }
    if (this.contactCopy.value) {
      this.multipleCopyTo.push(this.contactCopy.value);
    }
    else {
      this.multipleCopyTo.push(this.contactCopy);
    }
    this.contactCopy = null;
  }

  removeContactCopy(email: string): void {
    this.multipleCopyTo = this.multipleCopyTo.filter(c => c != email);
  }


  // type "any" because of autocompleters ngbtypeahead
  public contactTo: any = null;

  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusContactTo$ = new Subject<string>();
  clickContactTo$ = new Subject<string>();
  @ViewChild('autocompleteContactTo', {static: false}) autocompleteContactTo: NgbTypeahead;
  
  autoCompleteContactTo = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusContactTo$;
    const inputClick$ = this.clickContactTo$.pipe(filter(() => !this.autocompleteContactTo.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      switchMap( (search) => this._addressBookServ.getContacts(this.order.order_comp_book_key, search))
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueContactTo(value: any) {
    if (value.email)  // object
      return value.email;
    else if (value.length)  // string
      return value;
    return '';
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListContactTo(value: any) {
    let result: string = '';
    result += value.email ? value.email : '';
    if (value.name && value.position) {
      result += ' (' + value.name + ' - ' + value.positionName + ')';
    }
    else if (value.name) {
      result += ' (' + value.name + ')';
    }
    return result;
  }
  // save selected order_comp_book
  onSelectContactTo($event: any) {
    if ($event.item) {
      this._order.order_contact = $event.item.name;
      this._order.order_email = $event.item.email;
      this._order.order_phone = $event.item.mobile ? $event.item.mobile : $event.item.phone;
    }
  };

  // multiple contacts/receivers
  public multipleContactsTo: Array<any> = [];
  // https://stackoverflow.com/questions/40314732/angular-2-2-way-binding-with-ngmodel-in-ngfor
  trackByIndex(index: number, obj: any): any {
    return index;
  }

  public onKeyUp(e: any) {
    if (e.target.value && e.target.value.endsWith(",")) {
      // remove comma
      if (this.contactTo.email) {
        this.contactTo.email = this.contactTo.email.substring(0, this.contactTo.email.length - 1);
      }
      else {
        this.contactTo = this.contactTo.substring(0, this.contactTo.length - 1);
      }
      // add new email input
      this.addContactTo();
    }
  }

  public addContactTo(): void {
    if (!this.contactTo) {
      let text: string = $localize`Nejprve vyplňte prvního příjemce, poté bude možné přidat další.`;
      this._notificationServ.alert(text, 'error', 4000);
      return;
    }
    if (this.contactTo.person_key) {
      if (this.contactTo.email) {
        this.multipleContactsTo.push(this.contactTo.email);
      }
      else {
        let text: string = $localize`Tento kontakt nemá uložen email, upravte jej v detailu této firmy.`;
        this._notificationServ.alert(text, 'error', 4000);
        return;
      }
    }
    else {
      this.multipleContactsTo.push(this.contactTo);
    }
    this.contactTo = null;
  }

  removeContactTo(email: string): void {
    this.multipleContactsTo = this.multipleContactsTo.filter(c => c != email);
  }


  /*************************************************************/
  /* Methods for sending email with initialized html body */
  /*************************************************************/
  private _replyTo: string = null;

  // method for sending email
  public sendEmail(): void {
    // if (!this.contactFrom) { this._notificationServ.alert('Nebyl zadán odesílatel!', 'error', 3500); return; 
    if (!this.contactTo && !this.multipleContactsTo.length) { 
      this._notificationServ.alert($localize`Nebyl zadán příjemce!`, 'error', 3500); return; 
    }
    if (!this.subjectEmail) { 
      this._notificationServ.alert($localize`Nebyl zadán předmět emailu!`, 'error', 3500); return; 
    }
    
    // contactFrom, contactCopy and contactTo could be objects
    let from: string = 'info@truckmanager.eu';
    if (this._company.send_emails_from_their_domain) {
      from = this.contactFrom.value ? this.contactFrom.value : this.contactFrom;
      this._replyTo = from;
    }

    // let copy: string = this.contactCopy.value ? this.contactCopy.value : this.contactCopy;
    // append copyTo to array
    if (this.contactCopy && this.contactCopy.value) {
      this.multipleCopyTo.push(this.contactCopy.value);
    }
    else if (this.contactCopy) {
      this.multipleCopyTo.push(this.contactCopy);
    }

    // save to cookies
    this.contactCopyCookies = this.multipleCopyTo;
    this._storageServ.setItem(
      StorageKeys.email.copy_order_address, JSON.stringify(this.multipleCopyTo), true
    );

    // append contactTo to array
    if (this.contactTo && this.contactTo.email) {
      this.multipleContactsTo.push(this.contactTo.email);
    }
    else if (this.contactTo) {
      this.multipleContactsTo.push(this.contactTo);
    }
    
    console.log(this.multipleContactsTo);

    // just basic validation
    this.multipleContactsTo = this.multipleContactsTo.filter(c => c && c.includes("@"));

    // check if order_email is empty
    if (!this.order.order_email) {
      // use given contactTo
      this.order.order_email = this.multipleContactsTo[0];
      this._subscribed.push(
        this._orderServ.updateOrder(this.order).subscribe()
      );
    }

    // check if email exists in comp_book
    if (this.order.order_comp_book_key) {
      this._subscribed.push(
        // init contacts/persons
        this._addressBookServ.getContactsToComponent(this.order.order_comp_book_key).subscribe(
          (response: any) => {
            if (response) {
              let persons: Array<Person> = this._addressBookServ.buildPersonsFromData(response);

              // possibly save person/email to user's address book 
              let findOrderPerson: Person = persons.find(p => p.email == this.multipleContactsTo[0]);
              if (!findOrderPerson) {
                let newPerson = new Person();
                newPerson.importance = 1;
                newPerson.email = this.multipleContactsTo[0];
                if (newPerson.email && newPerson.email.includes('@')) {
                  newPerson.name = newPerson.email.substring(0, newPerson.email.indexOf('@'));
                }
                
                this._subscribed.push(
                  this._addressBookServ.createPerson(this.order.order_comp_book_key, newPerson).subscribe()
                );
              }
            }
          }
        )
      );
    }

    let email: any = {
      from: from,
      to: this.multipleContactsTo.length > 1 ? this.multipleContactsTo : this.multipleContactsTo[0],
      order_key: this._order.order_key,
      // cc: (this.sendCopy ? copy : []),
      reply_to: this._replyTo,
      subject: this.subjectEmail,
      language: this.languageEmail,
      body: this.initHtmlNoImages()
    };

    // handle copy / multiple copy
    if (this.sendCopy) {
      email.cc = this.multipleCopyTo.length > 1 ? this.multipleCopyTo : this.multipleCopyTo[0];
    }
    else {
      email.cc = [];
    }

    this._subscribed.push(
      this._orderServ.sendEmail(email).subscribe(
        response => {
          if (response) {
            // emit output to parent
            this.emailHasBeenSent.emit(response);
            // close parent modal
            window.setTimeout(
              () => {
                (<any>$('#sendOrderModal')).modal('hide');
              }, 3000
            );
          }
        }
      )
    );
  }
  
  private initHtmlNoImages(): string {
    let html: string = 
    `<html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <title>` + 
    ((this._company.invoice_address && this._company.invoice_address.company) ? this._company.invoice_address.company : this._company.company) +
    `</title>
    </head>

    <body bgcolor="#f5f7f8" bottommargin="50" lang="cs" topmargin="30" style="font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 16px">
      <table cellpadding="0" cellspacing="0" bgcolor="#FFFFFF" width="780" align="center" style="box-shadow: 0px 0px 15px -5px grey">

        <!-- Hlavička - logo -->
        <tr>
          <td style="padding: 20px; font-size: 24px; font-weight: bold; background-color:` + 
          ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color2) 
          ? this._settingsDocs.presets.invoice_color2 : '#FF9900') + 
          `; color:` + 
          ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color1) 
          ? this._settingsDocs.presets.invoice_color1 : '#444444') + 
          `">
            ` + this._company.company + `
          </td>
        </tr>

        <!-- Barevná linka -->
        <tr style="height: 3px;" bgcolor="` + 
        ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color1) 
        ? this._settingsDocs.presets.invoice_color1 : '#444444') + 
        `">
          <td height="3px"></td>
        </tr>

        <!-- Text -->
        <tr>
          <td style="padding: 20px 20px 0px 20px; height: 76px;">` + this.textEmail + `</td>
        </tr>

        <!-- Podpis -->			
        <tr>
          <td style="padding: 20px 20px 20px 20px;">
            <p>` + this.signatureEmail + `</p>
          </td>
        </tr>

        <!-- Kontakt -->
        <tr bgcolor="#444444">
          <td style="padding: 20px;">
            <font color="#C0C0C0">` +
              this._company.company + `<br>`+
              this._company.street + `<br>`+
              this._company.zip + ' ' + this._company.city + `<br>`+
              this._dictionary['contact'][this.languageEmail] + `<br>`+
              this._dictionary['email'][this.languageEmail] + ': ' + this._personEmail + `<br>`+
              this._dictionary['telephone'][this.languageEmail] + ': ' + this._personPhone +
            `</font>
          </td>
        </tr>
      </table>

      <table style="width: 100%">
        <tr>
          <td align="center" style="font-size:12px">
            <a href="https://truckmanager.eu" target="_blank">
              Tato objednávka odeslána z dopravního a spedičního systému TruckManager &amp; TruckAgenda
            </a>
          </td>
        </tr>
      </table>
    </body>
    </html>`;
    return html;
  }
}