import { Component, OnDestroy } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { Subscription } from "rxjs";

import { UserConfigurationService } from "../../service/user-configuration.service";
import { AuthenticationService } from "../../service/authentication.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { CompanyService } from "src/app/service/company.service";
// import { VehicleService } from "../../service/vehicle.service";
import { Vehicle } from "../../model/vehicle.object";
import { UserConfigurationInterface } from "../../interface/user-configuration.interface";
import { ConfigurationListMenuInterface } from "../../interface/configuration-list-menu.interface";
import { slideUp } from "../../animations/slide-vertical.animation";
import {
  HIGHLIGHT_DRIVER_NAMES,
  HIGHLIGHT_LICENCE_PLATES,
  MAP_ONLY_CURRENT_AGENDA,
  MAP_ALL_AGENDA,
  ServiceConfiguration
} from "../../config";
import { Person } from "src/app/model/person.object";

@Component({
  selector: 'configuration-list',
  templateUrl: './r-user-configuration-list.component.html',
  styleUrls: ['./r-user-configuration-list.component.css'],
  animations: [
    trigger('activeMenu', [
      state('active', style({
        backgroundColor: '#ccc'
      })),
      state('inactive', style({})),
      transition('inactive <=> active', animate('100ms ease-in'))
    ]),
    trigger('activeTab', slideUp)
  ]
})
export class RUserConfigurationListComponent implements OnDestroy {

  private _subscribed: Array<Subscription> = [];

  private _config: UserConfigurationInterface;
  private _menus: Array<ConfigurationListMenuInterface> = [
    { key: 'vehicles', state: 'active' },
    { key: 'messages', state: 'inactive' }
  ];

  private _vehicles: Array<Vehicle> = [];
  public get vehicles(): Array<Vehicle> {
    return this._vehicles;
  }

  private _persons: Array<Person> = [];
  public get persons(): Array<Person> {
    return this._persons;
  }


  constructor(
    private _configurationService: UserConfigurationService,
    // private _vehicleService: VehicleService,
    private _vehicleNewService: VehicleNewService,
    private _companyServ: CompanyService,
    private _authService: AuthenticationService
  ) {
    // set config default / changed from storage
    this._config = Object.assign(
      ServiceConfiguration.userConfiguration, 
      JSON.parse(JSON.stringify(_configurationService.configuration))
    );

    this._subscribed.push(
      // this._authService.authenticationResult.subscribe(
      //   () => {
      //     this._vehicleKeys = [];
      //   }
      // ),
      this._vehicleNewService.getFilteredVehiclesCache().subscribe(
        (vehicles: Array<Vehicle>) => {
          if (vehicles && vehicles.length && vehicles != this._vehicles) {
            this._vehicles = vehicles;
          }
          else {
            this._vehicles = [];
          }
        }
      ),
      this._companyServ.getPersonsObservable().subscribe(
        persons => {
          if (this._persons !== persons && persons.length) {
            this._persons = persons;
            this._persons.sort((a, b) => (a.name > b.name) ? 1 : -1);
            // console.log(this._persons);
          }
          else if (this._persons !== persons){
            this._persons = [];
          }
        }
      )
    )
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  // menu managing
  menuActive(menuKey: string): string {
    let menuState;
    this._menus.forEach(
      menu => {
        if (menu.key === menuKey) {
          menuState = menu.state;
        }
      }
    );
    return menuState;
  }

  activate(menuKey: string) {
    this._menus.forEach(
      menu => {
        menu.state = 'inactive';
        if (menu.key === menuKey) {
          menu.state = 'active';
        }
      }
    )
  }

  /****************************************/
  /* Flags for dashboard */
  /****************************************/
  // version of dashboard - full/light
  get dashboard_version(): string {
    return this._config.defaultVehicleListConfiguration.dashboard;
  }
  set dashboard_version(value: string) {
    this._config.defaultVehicleListConfiguration.dashboard = value;
    this.saveChanges();
  }

  // info about last_cargo in dashboard
  get last_cargo_text_show(): boolean {
    return this._config.defaultVehicleListConfiguration.last_cargo_text_show;
  }
  set last_cargo_text_show(value: boolean) {
    this._config.defaultVehicleListConfiguration.last_cargo_text_show = value;
    this.saveChanges();
  }

  // aetr row in dashboard
  get aetr_show(): boolean {
    return this._config.defaultVehicleListConfiguration.aetr_show;
  }
  set aetr_show(value: boolean) {
    this._config.defaultVehicleListConfiguration.aetr_show = value;
    this.saveChanges();
  }

  // gannt-row ~ 48 hod agenda of vehicle in dashboard
  get gantt_show(): boolean {
    return this._config.defaultVehicleListConfiguration.gantt_show;
  }
  set gantt_show(value: boolean) {
    this._config.defaultVehicleListConfiguration.gantt_show = value;
    this.saveChanges();
  }

  // sending message to vehicle feature in dashboard
  get messages_show(): boolean {
    return this._config.defaultVehicleListConfiguration.messages_show;
  }
  set messages_show(value: boolean) {
    this._config.defaultVehicleListConfiguration.messages_show = value;
    this.saveChanges();
  }
  
  // sound alert on/off
  get sound_alert(): boolean {
    return this._config.defaultVehicleListConfiguration.sound_alert;
  }
  set sound_alert(value: boolean) {
    this._config.defaultVehicleListConfiguration.sound_alert = value;
    this.saveChanges();
  }
  

  /****************************************/
  /* Radio switches */
  /****************************************/
  // priority for number plates vs. driver names
  private _vehicleListHighlightOptions = [
    HIGHLIGHT_DRIVER_NAMES, HIGHLIGHT_LICENCE_PLATES
  ];
  get vehicleListHighlightOptions(): Array<string> {
    return this._vehicleListHighlightOptions;
  }

  get vehicle_list_highlight(): string {
    return this._config.defaultVehicleListConfiguration.vehicle_list_highlight;
  }
  set vehicle_list_highlight(value: string) {
    this._config.defaultVehicleListConfiguration.vehicle_list_highlight = value;
    this.saveChanges();
    // this._vehicleService.sortVehicles();
  }

  // google maps displaying only current agendas
  // or previous, current and future agendas (+-2days)
  private _vehicleMapAgendaOptions = [
    MAP_ONLY_CURRENT_AGENDA, MAP_ALL_AGENDA
  ];
  get vehicleMapAgendaOptions(): Array<string> {
    return this._vehicleMapAgendaOptions;
  }

  get vehicle_map_agenda(): string {
    return this._config.defaultVehicleListConfiguration.vehicle_map_agenda;
  }
  set vehicle_map_agenda(value: string) {
    this._config.defaultVehicleListConfiguration.vehicle_map_agenda = value;
    this.saveChanges();
  }


  /****************************************/
  /* Vehicle filtering */
  /****************************************/
  // private _vehicleKeys = [];
  // get vehicleKeys(): Array<any> {
  //   if (this._vehicleKeys.length === 0) {
  //     this._vehicleService.vehicleKeysIdentified.forEach(
  //       (vehicle: Vehicle) => {
  //         this._vehicleKeys.push({
  //           value: vehicle.car_key,
  //           name: vehicle.number_plate,
  //           driver_key: vehicle.driver_key
  //         });
  //       }
  //     );
  //   }
  //   return this._vehicleKeys.sort(
  //     (a, b) => {
  //       return (a.name >= b.name ? (a.name > b.name ? 1 : 0) : -1);
  //     }
  //   );
  // }


  get vehicle_filter(): Array<number> {
    return this._config.defaultVehicleListConfiguration.vehicle_filter;
  }
  set vehicle_filter(value: Array<number>) {
    let key: number = this._authService.user ? this._authService.user.company_key : 0;
    this._config.defaultVehicleListConfiguration.vehicle_filters[key] = value;
    this._config.defaultVehicleListConfiguration.vehicle_filter = value;
    this.saveChanges();
  }

  resetVehicleFilter() {
    this.vehicle_filter = [];
  }

  
  /******************************************************/
  /* Vehicle filtering according to person_key */
  /******************************************************/
  get vehicle_person_filter(): Array<number> {
    return this._config.defaultVehicleListConfiguration.vehicle_person_filter;
  }
  set vehicle_person_filter(value: Array<number>) {
    let key: number = this._authService.user ? this._authService.user.company_key : 0;
    if (!this._config.defaultVehicleListConfiguration.vehicle_person_filters) {
      this._config.defaultVehicleListConfiguration.vehicle_person_filters = {};
      this._config.defaultVehicleListConfiguration.vehicle_person_filter = [];
    }
    this._config.defaultVehicleListConfiguration.vehicle_person_filters[key] = value;
    this._config.defaultVehicleListConfiguration.vehicle_person_filter = value;
    this.saveChanges();
  }

  resetVehiclePersonFilter() {
    this.vehicle_person_filter = [];
  }


  /****************************************/
  /* New messages configuration */
  /****************************************/
  get messages_messages_dialog_show(): boolean {
    return this._config.defaultMessagesConfiguration.new_messages_dialog_show;
  }
  set messages_messages_dialog_show(value: boolean) {
    this._config.defaultMessagesConfiguration.new_messages_dialog_show = value;
    this.saveChanges();
  }

  get message_new_message_sound_notification(): boolean {
    return this._config.defaultMessagesConfiguration.new_messages_sound_notification;
  }
  set message_new_message_sound_notification(value: boolean) {
    this._config.defaultMessagesConfiguration.new_messages_sound_notification = value;
    this.saveChanges();
  }

  get order_messages_show(): boolean {
    return this._config.defaultMessagesConfiguration.order_messages_show;
  }
  set order_messages_show(value: boolean) {
    this._config.defaultMessagesConfiguration.order_messages_show = value;
    this.saveChanges();
  }
  
  get invoice_messages_show(): boolean {
    return this._config.defaultMessagesConfiguration.invoice_messages_show;
  }
  set invoice_messages_show(value: boolean) {
    this._config.defaultMessagesConfiguration.invoice_messages_show = value;
    this.saveChanges();
  }
  
  get vehicle_messages_show(): boolean {
    return this._config.defaultMessagesConfiguration.vehicle_messages_show;
  }
  set vehicle_messages_show(value: boolean) {
    this._config.defaultMessagesConfiguration.vehicle_messages_show = value;
    this.saveChanges();
  }

  // saving changes method
  private saveChanges() {
    this._configurationService.saveUserConfiguration(this._config);
  }
}

