import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subscription, Subject, Observable, merge } from "rxjs";
import { distinctUntilChanged, debounceTime, switchMap, filter, map } from "rxjs/operators";
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { TemplateJsonService } from 'src/app/service/template-json.service';
import { CompanyService } from "../../service/company.service";
import { SettingsService } from "../../service/settings.service";
import { AddressBookService } from "../../service/address-book.service";
import { InvoiceService } from "../../service/invoice.service";
import { ObligationService } from "../../service/obligation.service";
import { FilesService } from "../../service/files.service";
import { NotificationService } from "../../service/notification-service";
import { AuthenticationService } from 'src/app/service/authentication.service';
import { TruckManagerLayoutService } from 'src/app/service/truck-manager-layout.service';
import { StorageService } from 'src/app/service/storage.service';
import { ContentEditableDirective } from '../../directive/content-editable.directive';
import { Settings } from "../../model/settings.object";
import { Obligation } from "../../model/obligation.object";
import { Attachment } from "../../model/attachment.object";
import { Company } from '../../model/company.object';
import { Agenda } from 'src/app/model/agenda.object';
import { EMAIL_REGEX, StorageKeys } from 'src/app/config';

@Component({
  selector: 'app-ta1-obligation-emailing',
  templateUrl: './ta1-obligation-emailing.component.html',
  styleUrls: ['./ta1-obligation-emailing.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SettingsService,
    AddressBookService,
    InvoiceService,
    FilesService,
    TemplateJsonService,
    ContentEditableDirective
  ]
})
export class Ta1ObligationEmailingComponent implements OnInit {

  private _subscribed: Array<Subscription> = [];

  private _company: Company = null;
  public get company(): Company {
    return this._company;
  }

  private _companyEmails: Array<any> = [];
  private _settingsDocs: Settings = null;
  private _dictionary: any = {};

  private _obligation: Obligation;
  @Input()
  public set obligation(value: Obligation) {
    this._obligation = value;
    if (this._obligation) {
      // init email
      this.changeLanguageEmail();
      this.initEmail();
      this.initContactFrom();
      this.detectChanges();
    }
  }
  public get obligation(): Obligation {
    return this._obligation;
  }

  private _agenda: Agenda;
  @Input()
  public set agenda(value: Agenda) {
    this._agenda = value;
    this._obligation = new Obligation();

    this._obligation.obligation_key = this._agenda.obligation_key;
    this._obligation.car = this._agenda.vehicle;
    this._obligation.car_key = this._agenda.car_key;
    this._obligation.order_comp_book = this._agenda.order_comp_book;
    this._obligation.order_comp_book_key = this._agenda.order_comp_book_key;
    this._obligation.order_number = this._agenda.order_number;
    this._obligation.order_number_standard = this._agenda.order_number_standard;

    if (this._obligation) {
      // init email
      this.changeLanguageEmail();
      this.initEmail();
      this.detectChanges();
    }
  }

  private _attachments: Array<Attachment> = [];
  @Input()
  public set attachments(value: Array<Attachment>) {
    this._attachments = value;
  }
  public get attachments(): Array<Attachment> {
    return this._attachments;
  }

  // output for binding email after it has been sent
  @Output() emailHasBeenSent: EventEmitter<any>  = new EventEmitter<any>();

  constructor(
    private _authServ: AuthenticationService,
    private _layout: TruckManagerLayoutService,
    private _companyServ: CompanyService,
    private _obligationServ: ObligationService,
    private _addressBookServ: AddressBookService,
    private _settingsServ: SettingsService,
    private _jsonServ: TemplateJsonService,
    private _notificationServ: NotificationService,
    private _storageServ: StorageService,
    private _cdr: ChangeDetectorRef
  ) { 
    this._cdr.detach();
    setInterval(
      () => {
        this._cdr.detectChanges();
      }, 5000
    );
  }

  ngOnInit(): void {
    this.loadData();

    // init sendCopy
    let tmp = this._storageServ.getItem(StorageKeys.email.copy_obligation, true);
    this.sendCopy = (tmp == 'true') ? true : false;
    
    let tmp2 = this._storageServ.getItem(StorageKeys.email.copy_obligation_address, true);
    if (tmp2) {
      try {
        // copy could be string or stringified array
        let tmp_copy = JSON.parse(tmp2);
        if (Array.isArray(tmp_copy)) {
          tmp_copy.forEach(
            (item, idx) => {
              if (idx < tmp_copy.length - 1) {
                // multiple save to copy
                this.multipleCopyTo.push(item);
              }
              else {
                // rewrite last to current contactCopy
                this.contactCopy = item;
              }
            }
          );
        }
        else {
          this.contactCopy = tmp_copy;
        }
        this.contactCopyCookies = tmp_copy;
      }
      catch (e) {
        console.log(e);
        this.contactCopy = tmp2;
        this.contactCopyCookies = tmp2;
      }
    }
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
  }
  
  public detectChanges(): void {
    // detect changes 500 ms after change
    window.setTimeout(
      () => {
        this._cdr.detectChanges();
      }, 100
    );
  }

  // method for reinitalization from outside - e.g. from invoice_list
  public reinitComponent(): void {
    this._obligationServ.sendCompleted = false;
    this._obligationServ.sendOK = false;
    this.detectChanges();
  }

  // (re)inititialization
  private defaultInitialize(): void {
    this._settingsDocs = null;
    this._dictionary = {};

    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    this._subscribed = [];
  }

  loadData(): void {
    // (re)inititialization
    this.defaultInitialize();

    this._subscribed.push(
      this._jsonServ.getInvoiceTemplateDictionary().subscribe(
        data => {
          if (data && this._dictionary !== data) {
            this._dictionary = data;
          }
        },
        error => {
          console.log(error);
        }
      ),
      this._settingsServ.getSettingsDocs().subscribe(
        docs => {
          if (docs && this._settingsDocs !== docs) {
            this._settingsDocs = docs;
            if (this._settingsDocs.presets && this._settingsDocs.presets.invoice_mail) {
              // concat company emails
              let emailObj: any = {
                type: "E",
                importance: 42, // largest importance - invoicing mail as last
                value: this._settingsDocs.presets.invoice_mail
              }
              this._companyEmails.push(emailObj);
              this._companyEmails.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
            }
            // init email info
            this.initEmail();
            this.initUpdatedBy();
            this.initContactFrom();
            this.detectChanges();
          }
        }
      ),
      this._companyServ.getCompanyFullObservable().subscribe(
        response => {
          if (response && this._company !== response) {
            this._company = response;
            if (this._company.contactsAsArray && this._company.contactsAsArray.length) {
              // concat company emails
              this._companyEmails = this._companyEmails.concat(
                this._company.contactsAsArray.filter(c => c.type == "E")
              );
              this._companyEmails.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
            }
            // init email info
            this.initEmail();
            this.initUpdatedBy();
            this.initContactFrom();
            this.detectChanges();
          }
        }
      )
    );
  }

  // flag when email is currently being sent
  public get sending(): boolean {
    return this._obligationServ.sendingEmail;
  }

  // flag for email has been sent
  public get sendCompleted(): boolean {
    return this._obligationServ.sendCompleted;
  }

  // flag when email has been correctly sent
  public get sendOK(): boolean {
    return this._obligationServ.sendOK;
  }


  /************************************************************/
  /* Sending email part */
  /************************************************************/
  public languageEmail: string = 'EN';

  public subjectEmail: string = '';
  public textEmail: string = '';
  public signatureEmail: string = '';

  private _updatedByName: string = '';
  private _updatedByEmail: string = '';
  private _updatedByPhone: string = '';

  // custom validation of person's email
  validEmailAddress(email: any) {
    if (!email) {
      return true;
    }

    if (email.email) {
      if (email.email.match(EMAIL_REGEX)) {
        return true;
      }
    }
    else if (email.value) {
      if (email.value.match(EMAIL_REGEX)) {
        return true;
      }
    }
    else if (email.match(EMAIL_REGEX)) {
      return true;
    }

    return false;
  }
  
  validContactFrom(email: any) {
    if (this._company && !this._company.send_emails_from_their_domain) {
      // info@truckmanager.eu
      return true;
    }

    return this.validEmailAddress(email);
  }
  
  // method for possible changing of language for preview
  changeLanguageEmail(): void {
    if (!this._obligation.order_comp_book) {
      // other countries -> EN language
      this.languageEmail = 'EN';
      return;
    }

    let c: string = this._obligation.order_comp_book.country;
    if (c == 'CZ' || c == 'SK' || c == 'EN' || c == 'DE' || c == 'ES' || c == 'IT' || c == 'PL') {
      this.languageEmail = c;
    }
    else if (c == 'AT') {
      // Austria
      this.languageEmail = 'DE';
    }
    else {
      // other countries -> EN language
      this.languageEmail = 'EN';
    }
    this.detectChanges();
  }

  // handler after emitting into contenteditable div 
  afterTextEmailChange(event: any): void {
    this.textEmail = event;
    this.detectChanges();
  }

  // handler after emitting into contenteditable div 
  afterSignatureEmailChange(event: any): void {
    this.signatureEmail = event;
    this.detectChanges();
  }


  // method for initializing info about email
  initEmail(changeLanguage: boolean = false): void {
    // init email subject
    if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.document_mail_subject) {
      this.subjectEmail = this._settingsDocs.texts.document_mail_subject[this.languageEmail];

      if (!this.subjectEmail) this.subjectEmail = '';

      if (this._company) 
        this.subjectEmail = this.subjectEmail.replace('@company/company', this._company.company);

      if (this._obligation && this._obligation.order_number)
        this.subjectEmail = this.subjectEmail.replace('@obligations/order_number', this._obligation.order_number);
      else
        this.subjectEmail = this.subjectEmail.replace('@obligations/order_number', '');
    }
    // init email text
    if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.document_mail_text) {
      this.textEmail = this._settingsDocs.texts.document_mail_text[this.languageEmail];
     
      if (!this.textEmail) this.textEmail = '';

      if (this._obligation && this._obligation.order_number) {
        this.textEmail = this.textEmail.replace('@obligations/order_number', this._obligation.order_number);
      }
      else {
        this.textEmail = this.textEmail.replace('@obligations/order_number', '');
      }
    }
    // init email signature
    if (this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.invoice_mail_signature) {
      this.signatureEmail = this._settingsDocs.texts.invoice_mail_signature[this.languageEmail];

      if (!this.signatureEmail) this.signatureEmail = '';

      // email
      if (this._settingsDocs && this._settingsDocs.presets && this._settingsDocs.presets.invoice_email) {
        this.signatureEmail = this.signatureEmail.replace('@invoices/contact_email', this._settingsDocs.presets.invoice_email);
      }
      else {
        this.signatureEmail = this.signatureEmail.replace('@invoices/contact_email', '');
      }
      // name
      if (this._settingsDocs && this._settingsDocs.presets && this._settingsDocs.presets.invoice_name) {
        this.signatureEmail = this.signatureEmail.replace('@invoices/contact_name', this._settingsDocs.presets.invoice_name);
      }
      else {
        this.signatureEmail = this.signatureEmail.replace('@invoices/contact_name', '');
      }
      // phone
      if (this._settingsDocs && this._settingsDocs.presets && this._settingsDocs.presets.invoice_phone) {
        this.signatureEmail = this.signatureEmail.replace('@invoices/contact_phone', this._settingsDocs.presets.invoice_phone);
      }
      else {
        this.signatureEmail = this.signatureEmail.replace('@invoices/contact_phone', '');
      }
      
      if (this._company) {
        this.signatureEmail = this.signatureEmail.replace('@company/company', this._company.company);
        this.signatureEmail = this.signatureEmail.replace('@company/street', this._company.street);
        this.signatureEmail = this.signatureEmail.replace('@company/zip', this._company.zip);
        this.signatureEmail = this.signatureEmail.replace('@company/city', this._company.city);
        this.signatureEmail = this.signatureEmail.replace('@company/country', this._company.country);
      }
    }

    // sender and receiver
    this.multipleContactsTo = [];
    if (!changeLanguage) {
      // init email from
      if (this._companyEmails.length) {
        this.contactFrom = this._companyEmails[0];
        if (!this.contactCopyCookies) {
          this.contactCopy = this._companyEmails[0];
        }
        this._replyTo = this._companyEmails[0].value;
      }
      // init email to
      if (this._obligation.payer_email) {
        this.contactTo = this._obligation.payer_email;
      }
    }
    this.detectChanges();
  }

  // initialization of contacts
  initUpdatedBy(): void {
    // priority is settings/docs
    if (this._settingsDocs 
      && this._settingsDocs.presets 
      && this._settingsDocs.presets.invoice_mail 
      && this._settingsDocs.presets.invoice_phone 
      && this._settingsDocs.presets.invoice_name) {
      this._updatedByName = this._settingsDocs.presets.invoice_name;
      this._updatedByEmail = this._settingsDocs.presets.invoice_mail;
      this._updatedByPhone = this._settingsDocs.presets.invoice_phone;
    }
    // else using company contacts
    else if (this._company && this._company.personsAsArray && this._company.personsAsArray.length) {
      let persons: Array<any> = this._company.personsAsArray;
      persons.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
      // set the most important person as contact on invoice
      let importance: number = persons[0].importance;
      this._updatedByName = persons[0].name;
      
      if (this._company.contactsAsArray) {
        let email: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'E');
        if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'S');
        if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'U');
        if (email) this._updatedByEmail = email.value;
        
        let phone: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'G');
        if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'T');
        if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'F');
        if (phone) this._updatedByPhone = phone.value;
      }
    }
    this.detectChanges();
  }
  
  initContactFrom(): void {
    if (this._company) {
      // init email from
      if (this._companyEmails.length) {
        if (this._layout.user && this._layout.user.user_account && this._layout.user.user_account.person_key
        && this._company.personsArray && this._company.contactsArray) {
          let person = this._company.personsArray.find(
            p => p.person_key == this._layout.user.user_account.person_key
          );
          if (person) {
            let contact = this._company.contactsArray.find(
              c => c.importance == person.importance && c.type=="E"
            );
            if (contact) {
              if (this._company.send_emails_from_their_domain) {
                this.contactFrom = contact;
              }
              if (!this.contactCopyCookies) {
                this.contactCopy = contact;
              }
              this._replyTo = contact.value;
            }
            else {
              if (this._company.send_emails_from_their_domain) {
                this.contactFrom = this._companyEmails[0];
              }
              if (!this.contactCopyCookies) {
                this.contactCopy = this._companyEmails[0];
              }
              this._replyTo = this._companyEmails[0].value;
            }
          }
          else {
            if (this._company.send_emails_from_their_domain) {
              this.contactFrom = this._companyEmails[0];
            }
            if (!this.contactCopyCookies) {
              this.contactCopy = this._companyEmails[0];
            }
            this._replyTo = this._companyEmails[0].value;
          }
        }
        else {
          if (this._company.send_emails_from_their_domain) {
            this.contactFrom = this._companyEmails[0];
          }
          if (!this.contactCopyCookies) {
            this.contactCopy = this._companyEmails[0];
          }
          this._replyTo = this._companyEmails[0].value;
        }
      }
    }
    this.detectChanges();
  }


  /************************************************************/
  /* Autocompleters part */
  /************************************************************/
  // type "any" because of autocompleters ngbtypeahead
  public contactFrom: any = 'info@truckmanager.eu';

  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusContactFrom$ = new Subject<string>();
  clickContactFrom$ = new Subject<string>();
  @ViewChild('autocompleteContactFrom', {static: false}) autocompleteContactFrom: NgbTypeahead;
  
  autoCompleteContactFrom = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusContactFrom$;
    const inputClick$ = this.focusContactFrom$.pipe(filter(() => !this.autocompleteContactFrom.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      map(term => (term === '' ? 
          this._companyEmails : 
          this._companyEmails.filter(e => e.value.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
      )
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueContactFrom(value: any) {
    if (value.value)
      return value.value;
    return value;
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListContactFrom(value: any) {
    if (value.value)
      return value.value;
    return value;
  }

  
  // type "any" because of autocompleters ngbtypeahead
  public contactCopy: any = null;
  public contactCopyCookies: any = null;
  
  copyChange(): void {
    if (this.contactCopy) {
      if (this.contactCopy.value) {
        this.contactCopyCookies = this.contactCopy.value;
        this._storageServ.setItem(
          StorageKeys.email.copy_obligation_address, JSON.stringify(this.contactCopy.value), true
        );
      }
      else {
        this.contactCopyCookies = this.contactCopy;
        this._storageServ.setItem(
          StorageKeys.email.copy_obligation_address, JSON.stringify(this.contactCopy), true
        );
      }
    }
  }

  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusContactCopy$ = new Subject<string>();
  clickContactCopy$ = new Subject<string>();
  @ViewChild('autocompleteContactCopy', {static: false}) autocompleteContactCopy: NgbTypeahead;
  
  autoCompleteContactCopy = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusContactCopy$;
    const inputClick$ = this.focusContactCopy$.pipe(filter(() => !this.autocompleteContactCopy.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      map(term => (term === '' ? 
          this._companyEmails : 
          this._companyEmails.filter(e => e.value.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10)
      )
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueContactCopy(value: any) {
    if (value.value)
      return value.value;
    return value;
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListContactCopy(value: any) {
    if (value.value)
      return value.value;
    return value;
  }
  
  // multiple contacts in copy
  public multipleCopyTo: Array<any> = [];

  onKeyUpCopy(e: any) {
    if (e.target.value && e.target.value.endsWith(",")) {
      // remove comma
      if (this.contactCopy.value) {
        this.contactCopy.value = this.contactCopy.value.substring(0, this.contactCopy.value.length - 1);
      }
      else {
        this.contactCopy = this.contactCopy.substring(0, this.contactCopy.length - 1);
      }
      // add new email input
      this.addContactCopy();
    }
    this.detectChanges();
  }

  addContactCopy(): void {
    if (!this.contactCopy) {
      let text: string = $localize`Nejprve vyplňte prvního příjemce, poté bude možné přidat další.`;
      this._notificationServ.alert(text, 'error', 4000);
      return;
    }
    if (this.contactCopy.value) {
      this.multipleCopyTo.push(this.contactCopy.value);
    }
    else {
      this.multipleCopyTo.push(this.contactCopy);
    }
    this.contactCopy = null;
    this.detectChanges();
  }

  removeContactCopy(email: string): void {
    this.multipleCopyTo = this.multipleCopyTo.filter(c => c != email);
    this.detectChanges();
  }

  // type "any" because of autocompleters ngbtypeahead
  public contactTo: any = null;

  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusContactTo$ = new Subject<string>();
  clickContactTo$ = new Subject<string>();
  @ViewChild('autocompleteContactTo', {static: false}) autocompleteContactTo: NgbTypeahead;
  
  autoCompleteContactTo = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusContactTo$;
    const inputClick$ = this.clickContactTo$.pipe(filter(() => !this.autocompleteContactTo.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      switchMap( () => this._addressBookServ.getContacts(this._obligation.order_comp_book_key))
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueContactTo(value: any) {
    if (value.email)
      return value.email;
    return value;
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListContactTo(value: any) {
    let result: string = '';
    result += value.email ? value.email : '';
    if (value.name && value.position) {
      result += ' (' + value.name + ' - ' + value.positionName + ')';
    }
    else if (value.name) {
      result += ' (' + value.name + ')';
    }
    return result;
  }
  // save selected order_comp_book
  onSelectContactTo($event: any) {
  };


  // multiple contacts/receivers
  public multipleContactsTo: Array<any> = [];
  // https://stackoverflow.com/questions/40314732/angular-2-2-way-binding-with-ngmodel-in-ngfor
  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onKeyUp(e: any) {
    if (e.target.value && e.target.value.endsWith(",")) {
      // remove comma
      if (this.contactTo.email) {
        this.contactTo.email = this.contactTo.email.substring(0, this.contactTo.email.length - 1);
      }
      else {
        this.contactTo = this.contactTo.substring(0, this.contactTo.length - 1);
      }
      // add new email input
      this.addContactTo();
    }
    this.detectChanges();
  }

  addContactTo(): void {
    if (!this.contactTo) {
      let text: string = $localize`Nejprve vyplňte prvního příjemce, poté bude možné přidat další.`;
      this._notificationServ.alert(text, 'error', 4000);
      return;
    }
    if (this.contactTo.email) {
      this.multipleContactsTo.push(this.contactTo.email);
    }
    else {
      this.multipleContactsTo.push(this.contactTo);
    }
    this.contactTo = null;
    this.detectChanges();
  }

  // getter for all attached files
  get attachedFiles(): Array<Attachment> {
    return this._attachments.filter(a => a.isAttached);
  }

  // getter for urls of all attached files
  get attachedFilesUrl(): Array<string> {
    let arr: Array<string> = [];
    this.attachedFiles.forEach(
      f => {
        if (f.url_normalized) {
          arr.push(f.url_normalized);
        }
      }
    );

    return arr;
  }

  // method for opening attachment in new tab
  openAttachmentNewTab(attachment: Attachment): void {
    this._obligationServ.openAttachmentNewTab(attachment);
  }


  /*************************************************************/
  /* Methods for sending email with initialized html body */
  /*************************************************************/
  public sendCopy: boolean = false;
  sendCopyChange(): void {
    this._storageServ.setItem(StorageKeys.email.copy_obligation, this.sendCopy.toString(), true);
  }

  private _replyTo: string = null;

  // method for sending email
  sendEmail(): void {
    // TODO we will use domain @truckagenda and we will solve also DNS with companies
    // that would like to have sending emails from their domains

    // if (!this.contactFrom) { this._notificationServ.alert('Nebyl zadán odesílatel!', 'error', 3500); return; }
    if (!this.contactTo && !this.multipleContactsTo.length) { 
      this._notificationServ.alert($localize`Nebyl zadán příjemce!`, 'error', 3500); return; 
    }
    if (!this.subjectEmail) { 
      this._notificationServ.alert($localize`Nebyl zadán předmět emailu!`, 'error', 3500); return; 
    }
    
    // contactFrom, contactCopy and contactTo could be objects
    let from: string = 'info@truckmanager.eu';
    if (this._company.send_emails_from_their_domain) {
      from = this.contactFrom.value ? this.contactFrom.value : this.contactFrom;
      this._replyTo = from;
    }

    let copy: string = this.contactCopy.value ? this.contactCopy.value : this.contactCopy;

    // append contactTo to array
    if (this.contactTo && this.contactTo.email) {
      this.multipleContactsTo.push(this.contactTo.email);
    }
    else if (this.contactTo) {
      this.multipleContactsTo.push(this.contactTo);
    }

    // just basic validation
    this.multipleContactsTo = this.multipleContactsTo.filter(c => c && c.includes("@"));

    let email: any = {
      from: from,
      to: this.multipleContactsTo.length > 1 ? this.multipleContactsTo : this.multipleContactsTo[0],
      obligation_key: this._obligation.obligation_key,
      cc: (this.sendCopy ? copy : []),
      reply_to: this._replyTo,
      subject: this.subjectEmail,
      language: this.languageEmail,
      body: this.initHtmlNoImages(),
      files: this.attachedFilesUrl
    };

    // console.log(email);
    // console.log(this._attachments);
    this._obligationServ.sendEmail(email).subscribe(
      response => {
        if (response) {
          // emit output to parent
          this.emailHasBeenSent.emit(response);
        }
      }
    );
  }

  // get apiLanguage(): string {
  //   if (this.languageEmail == 'CZ') return 'cs';
  //   else if (this.languageEmail == 'SK') return 'sk';
  //   else if (this.languageEmail == 'EN') return 'en';
  //   else if (this.languageEmail == 'DE') return 'de';
  //   else if (this.languageEmail == 'ES') return 'es';
  //   else if (this.languageEmail == 'IT') return 'it';
  //   return 'cs';
  // } 
  
  
  initHtmlNoImages(): string {
    let html: string = 
    `<html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <title>` + 
    ((this._company.invoice_address && this._company.invoice_address.company) ? this._company.invoice_address.company : this._company.company) +
    `</title>
    </head>

    <body bgcolor="#f5f7f8" bottommargin="50" lang="cs" topmargin="30" style="font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 16px">
      <table cellpadding="0" cellspacing="0" bgcolor="#FFFFFF" width="780" align="center" style="box-shadow: 0px 0px 15px -5px grey">

        <!-- Hlavička - logo -->
        <tr>
          <td style="padding: 20px; font-size: 24px; font-weight: bold; background-color:` + 
          ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color2) 
          ? this._settingsDocs.presets.invoice_color2 : '#FF9900') + 
          `; color:` + 
          ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color1) 
          ? this._settingsDocs.presets.invoice_color1 : '#444444') + 
          `">
            ` + this._company.company + `
          </td>
        </tr>

        <!-- Barevná linka -->
        <tr style="height: 3px;" bgcolor="` + 
        ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color1) 
        ? this._settingsDocs.presets.invoice_color1 : '#444444') + 
        `">
          <td height="3px"></td>
        </tr>
        
        <!-- Předmět -->
        <tr>
          <td style="padding: 20px 20px 0px 20px;">` + this.subjectEmail + `</td>
        </tr>

        <!-- Text -->
        <tr>
          <td style="padding: 20px 20px 0px 20px; height: 76px;">` + this.textEmail + `</td>
        </tr>

        <!-- Podpis -->			
        <tr>
          <td style="padding: 20px 20px 20px 20px;">
            <p>` + this.signatureEmail + `</p>
          </td>
        </tr>

        <!-- Kontakt -->
        <tr bgcolor="#444444">
          <td style="padding: 20px;">
            <font color="#C0C0C0">` +
              this._company.company + `<br>`+
              this._company.street + `<br>`+
              this._company.zip + ' ' + this._company.city + `<br>`+
              this._dictionary['contact'][this.languageEmail] + `<br>`+
              this._dictionary['email'][this.languageEmail] + ': ' + this._updatedByEmail + `<br>`+
              this._dictionary['telephone'][this.languageEmail] + ': ' + this._updatedByPhone +
            `</font>
          </td>
        </tr>
      </table>

      <table style="width: 100%">
        <tr>
          <td align="center" style="font-size:12px">
            <a href="https://truckmanager.eu" target="_blank">
              Odesláno z dopravního a spedičního systému TruckManager &amp; TruckAgenda
            </a>
          </td>
        </tr>
      </table>
    </body>
    </html>`;
    return html;
  }
}
