import { DatePipe } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { HIGHLIGHT_LICENCE_PLATES, IS_DEMO, ItineraryType, ServiceConfiguration } from '../config';
import { AuthenticationService } from './authentication.service';
import { DestinationRoutesService } from './destination-routes.service';
import { UserConfigurationService } from './user-configuration.service';
import { HttpClientService } from './http-client.service';
import { ServiceEventService } from './service-event.service';
import { WebsocketService } from './websocket.service';
import { CompanyService } from './company.service';
import { VehicleListConfigurationInterface } from '../interface/vehicle-list-configuration.interface';
import { AetrActivityDataInterface } from '../interface/aetr-activity-data.interface';
import { DestinationRouteDataInterface } from '../interface/destination-route-data.interface';
import { TrackingEventDataInterface } from '../interface/tracking-event-data.interface';
import { UserConfigurationInterface } from '../interface/user-configuration.interface';
import { WebsocketResponse } from '../interface/websocket-response.interface';
import { AetrHistoryCollectionObject } from '../model/aetr-history-collection.object';
import { AetrDataCollectionObject } from '../model/aetr-data-collection.object';
import { TrackingEventDataObject } from '../model/tracking-event-data.object';
import { ServiceEventObject } from '../model/service-event.object';
import { VehicleHealthObject } from '../model/vehicle-health.object';
import { CargoActivity } from '../model/cargo-activity.object';
import { GeoPosition } from '../model/geo-position.object';
import { Agenda } from '../model/agenda.object';
import { Company } from '../model/company.object';
import { Vehicle } from '../model/vehicle.object';
import { DateTools } from '../tools/DateTools';
import { Haversine } from '../tools/Haversine';
import { VehicleTools } from '../tools/VehicleTools';

@Injectable({
  providedIn: 'root'
})
export class VehicleNewService {

  private _subscribed: Array<Subscription> = [];

  private _lastLoadDate: Date = new Date();
  get lastLoadDate(): Date {
    return this._lastLoadDate;
  }

  // all vehicles
  private _allVehiclesCache: Array<Vehicle> = [];
  private _allVehicles: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]);
  get allVehicles(): Array<Vehicle> {
    return this._allVehicles.getValue();
  }

  // vehicles filtered by user configuration
  private _filteredVehiclesCache: Array<Vehicle> = [];
  private _filteredVehicles: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]);
  get filteredVehicles(): Array<Vehicle> {
    return this._filteredVehicles.getValue();
  }

  // particular vehicle
  private _vehicleCache: Vehicle = null;
  private _vehicle: BehaviorSubject<Vehicle> = new BehaviorSubject(null);
  get vehicle(): Vehicle {
    return this._vehicle.getValue();
  }

  private _loadingVehicles: boolean = false;
  public get loadingVehicles(): boolean {
    return this._loadingVehicles;
  }
  
  // user configuration
  private _userConfig: UserConfigurationInterface;
  private _vehiclesConfig: VehicleListConfigurationInterface;
  private _company: Company;
  
  // flag for vehicle service used in all cars map
  private _allInOneMap: boolean = false;
  public set allInOneMap(value: boolean) {
    this._allInOneMap = value;
  }
  
  private _vehicleManualTrackingStopIntervalInitalized: any = {};
  
  private _vehicleDataChanged: EventEmitter<Vehicle> = new EventEmitter();
  get vehicleDataChanged(): Observable<Vehicle> {
    return this._vehicleDataChanged.asObservable();
  }

  private _vehicleServiceEventsChanged: EventEmitter<Vehicle> = new EventEmitter();
  public get vehicleServiceEventsChanged(): Observable<Vehicle> {
    return this._vehicleServiceEventsChanged.asObservable();
  }

  private _agendaChanged: EventEmitter<Agenda> = new EventEmitter();
  public get agendaChanged(): Observable<Agenda> {
    return this._agendaChanged.asObservable();
  }

  constructor(
    private _authService: AuthenticationService,
    private _companyService: CompanyService,
    private _websocketService: WebsocketService,
    private _destinationRoutesService: DestinationRoutesService,
    private _configurationService: UserConfigurationService,
    private _http: HttpClientService,
    private _datePipe: DatePipe
  ) { 
    // load user configuration  
    this._userConfig = JSON.parse(JSON.stringify(this._configurationService.configuration));
    this._vehiclesConfig = this._configurationService.configuration.defaultVehicleListConfiguration;

    window.setInterval(
      () => {
        this.setLastLoadDate();
      },
      60000
    );

    this._subscribed.push(
      this._companyService.getCompanyFullObservable().subscribe(
        company => {
          if (company) {
            this._company = company;
            this._allVehiclesCache = VehicleTools.associateVehiclesDrivers(
              this._allVehiclesCache, this._company
            );
          }
        }
      ),
      this._configurationService.configChanged.subscribe(
        () => {
          // load user configuration  
          this._userConfig = JSON.parse(JSON.stringify(this._configurationService.configuration));
          this._vehiclesConfig = this._configurationService.configuration.defaultVehicleListConfiguration;

          this._filteredVehiclesCache = this.filterVehicleCacheByVehicleFilter();
          this._filteredVehicles.next(this._filteredVehiclesCache);
        }
      ),
      this._websocketService.companyMessage.subscribe(
        response => {
          // console.log(response);
          switch (response.relation) {
            case WebsocketService.RELATIONS.tracking:
              this.processTracking(response);
              break;
            case WebsocketService.RELATIONS.service:
              this.processService(response);
              break;
            case WebsocketService.RELATIONS.aetr:
              this.processAetr(response);
              break;
            case WebsocketService.RELATIONS.fueling:
              this.processFueling(response);
              break;
            case WebsocketService.RELATIONS.health:
              this.processHealth(response);
              break;
            case WebsocketService.RELATIONS.dest_route:
              this.processVehicleDestination(response);
              break;
            case WebsocketService.RELATIONS.reach_event:
              this.processVehicleDestination(response);
              break;
            case WebsocketService.RELATIONS.car:
              if (response.operation == WebsocketService.OPERATIONS.update) {
                // TEST NEEDED!
                this.getAllVehiclesCache(true);
              }
              break;
            default:
              return;
          }
          this.setLastLoadDate();
        }
      )
    );
  }

  // GET /cars
  getVehicles(): Observable<Array<Vehicle>> {
    let result: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingVehicles = true;
      this._http.get(ServiceConfiguration.vehicle.api).subscribe(
        response => {
          if (response) {
            let vehicles = VehicleTools.buildVehiclesFromData(response);
            vehicles = VehicleTools.associateVehiclesDrivers(vehicles, this._company);
            result.next(vehicles);
            this._loadingVehicles = false;
          }
        },
        error => {
          //handle error
          console.log(error);
          this._loadingVehicles = false;
        }
      );
    }

    return result.asObservable();
  }

  // GET /cars
  getAllVehicles(): Observable<Array<Vehicle>> {
    let result: BehaviorSubject<Array<Vehicle>> = new BehaviorSubject([]);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      this._loadingVehicles = true;
      this._http.get(ServiceConfiguration.vehicle.apiInactive).subscribe(
        response => {
          if (response) {
            let vehicles = VehicleTools.buildVehiclesFromData(response);
            vehicles = VehicleTools.associateVehiclesDrivers(vehicles, this._company);
            result.next(vehicles);
            this._loadingVehicles = false;
          }
        },
        error => {
          //handle error
          console.log(error);
          this._loadingVehicles = false;
        }
      );
    }

    return result.asObservable();
  }

  // returns already cached vehicles or load them
  getAllVehiclesCache(reload: boolean = false): Observable<Array<Vehicle>> {
    if ((reload || !this._allVehiclesCache.length) && !this._loadingVehicles) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        // loading process
        this._loadingVehicles = true;
        this._http.get(ServiceConfiguration.vehicle.api).subscribe(
          response => {
            if (response) {
              this._allVehiclesCache = VehicleTools.buildVehiclesFromData(response);
              // add drivers from company to vehicles
              this._allVehiclesCache = VehicleTools.associateVehiclesDrivers(
                this._allVehiclesCache, this._company
              );
              // sort according to user storage configuration
              this.sortByUserConfig();
              // filter according to user storage configuration
              this._filteredVehiclesCache = this.filterVehicleCacheByVehicleFilter();
              
              this._allVehicles.next(this._allVehiclesCache);
              this._filteredVehicles.next(this._filteredVehiclesCache);
              this._loadingVehicles = false;
              this.setLastLoadDate();
            }
          },
          error => {
            console.log(error);
            this._loadingVehicles = false;
          }
        );
      }
    }
    return this._allVehicles.asObservable();
  }

  getFilteredVehiclesCache(reload: boolean = false, change_event: boolean = false): Observable<Array<Vehicle>> {
    if ((reload || !this._filteredVehiclesCache.length) && !this._loadingVehicles) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        // loading process
        this._loadingVehicles = true;
        this._http.get(ServiceConfiguration.vehicle.api).subscribe(
          response => {
            if (response) {
              this._allVehiclesCache = VehicleTools.buildVehiclesFromData(response);
              // add drivers from company to vehicles
              this._allVehiclesCache = VehicleTools.associateVehiclesDrivers(
                this._allVehiclesCache, this._company
              );
              // sort according to user storage configuration
              this.sortByUserConfig();
              // filter according to user storage configuration
              this._filteredVehiclesCache = this.filterVehicleCacheByVehicleFilter();
              
              this._allVehicles.next(this._allVehiclesCache);
              this._filteredVehicles.next(this._filteredVehiclesCache);
              this._loadingVehicles = false;

              // emit also vehicleDataChanged event (deleting obligation WS missing car_key)
              if (change_event) {
                this._allVehiclesCache.forEach(
                  v => {
                    this._vehicleDataChanged.emit(v);
                  }
                );
              }
            }
          },
          error => {
            console.log(error);
            this._loadingVehicles = false;
          }
        );
      }
    }
    return this._filteredVehicles.asObservable();
  }

  // GET /cars - saving to this vehicle service cache
  // getVehiclesCache(): Observable<Array<Vehicle>> {
  //   if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
  //     this._loadingVehicles = true;
  //     this._http.get(ServiceConfiguration.vehicle.api).subscribe(
  //       response => {
  //         if (response) {
  //           this._allVehiclesCache = VehicleTools.buildVehiclesFromData(response);
  //           this.associateVehiclesDrivers(this._allVehiclesCache);
  //           this._filteredVehiclesCache = this.filterVehicleCacheByVehicleFilter();
            
  //           this._filteredVehicles.next(this._filteredVehiclesCache);
  //           this._allVehicles.next(this._allVehiclesCache);
  //           this._loadingVehicles = false;
  //         }
  //       },
  //       error => {
  //         console.log(error);
  //         this._loadingVehicles = false;
  //       }
  //     );
  //   }
    
  //   return this._filteredVehicles.asObservable();
  // }


  private filterVehicleCacheByVehicleFilter(): Array<Vehicle> {
    if (IS_DEMO) {
      return this._allVehiclesCache;
    }
    else if (this._vehiclesConfig) {
      let config = this._vehiclesConfig.vehicle_filter;
      let user_config = this._vehiclesConfig.vehicle_user_filter;
      if (user_config && user_config.length) {
        let filtered_vehicles: Array<Vehicle> = [];
        let yesterday: number = Date.now() - 24*60*60*1000;
        let tomorrow: number = Date.now() + 24*60*60*1000;

        this._allVehiclesCache.forEach(
          v => {
            let vehicle_add: boolean = false;
            if (v.agenda && v.agenda.length) {
              v.agenda.forEach(
                a => {
                  // check user that updated obligation
                  if (!vehicle_add && user_config.includes(a.updated_by)) {
                    if (a.itinerary && a.itinerary.length) {
                      a.itinerary.forEach(
                        it => {
                          // check if any itinerary is in +-24hours interval
                          if (!vehicle_add && 
                            it.arrival_time.getTime() >= yesterday && 
                            it.arrival_time.getTime() <= tomorrow
                          ) {
                            // set flag to filter vehicle
                            vehicle_add = true;
                          }
                        }
                      );
                    }
                  }
                }
              );
            }
            // save vehicle
            if (vehicle_add) {
              filtered_vehicles.push(v);
            }
          }
        );
        return filtered_vehicles;
      }
      else if (config && config.length) {
        return this._allVehiclesCache.filter(v => config.indexOf(v.car_key) > -1);
      } 
      else {
        // no vehicles filtered -> keep all 
        return this._allVehiclesCache;
      }
    }
    return [];
  }

  private sortByUserConfig(): any {
    // sorting according to vehicle plate OR driver name
    let sortByNumberPlate = this._vehiclesConfig.vehicle_list_highlight === HIGHLIGHT_LICENCE_PLATES;
    this._allVehiclesCache.sort(
      (vehicleA, vehicleB) => {
        let driverA = vehicleA.driver_name;
        let driverB = vehicleB.driver_name;
        let valueA = sortByNumberPlate ? vehicleA.number_plate : driverA;
        let valueB = sortByNumberPlate ? vehicleB.number_plate : driverB;
        
        if (!valueA) {
          return 1;
        }
        else if (!valueB) {
          return -1;
        }
        else {
          return valueA.localeCompare(valueB, 'cs');
        }
        // else if (valueA < valueB) {
        //   return -1;
        // }
        // else if (valueA > valueB) {
        //   return 1;
        // }
        // return 0;
      }
    );
  }


  /*******************************************************/
  /* Particular vehicle */
  /*******************************************************/
  // GET /cars/<car_key>
  getCar(car_key: number): Observable<Vehicle> {
    let result: BehaviorSubject<Vehicle> = new BehaviorSubject(null);

    if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
      // default url without any filter
      let url: string = ServiceConfiguration.vehicle.apiCarKey;
      url = url.replace('%CAR_KEY%', car_key.toString());

      this._http.get(url).subscribe(
        response => {
          // response is array and car is first item (to fix?)
          if (response && response.length) {
            let car = VehicleTools.buildVehicle(response[0]);
            result.next(car);
          }
        },
        error => {
          // handle error
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // GET /cars/<car_key>
  getVehicleCache(car_key: number, reload: boolean = false): Observable<Vehicle> {
    if (reload || (!this._vehicleCache && !this._loadingVehicles)) {
      if (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated())) {
        this.loadVehicleCache(car_key);
      }
    }
    return this._vehicle.asObservable();
  }

  // GET /cars/<car_key>
  loadVehicleCache(car_key: number): void {
    this._loadingVehicles = true;
    // default url without any filter
    let url: string = ServiceConfiguration.vehicle.apiCarKey;
    url = url.replace('%CAR_KEY%', car_key.toString());

    this._http.get(url).subscribe(
      response => {
        // response is array and car is first item (to fix?)
        if (response && response.length) {
          this._vehicleCache = VehicleTools.buildVehicle(response[0]);
          this._vehicle.next(this._vehicleCache);
          this._loadingVehicles = false;
        }
      },
      error => {
        console.log(error);
        this._loadingVehicles = false;
      }
    );
  }

  
  /*******************************************************/
  /* Last load date */
  /*******************************************************/
  setLastLoadDate(): void {
    this._lastLoadDate = new Date(); //.setTime(Date.now());
    this._filteredVehiclesCache.forEach(
      v => {
        if (v.last_position) {
          v.last_position.currentDate = this._lastLoadDate;
        }
      }
    );
  }

  
  /*******************************************************/
  /* Vehicle Agenda/Obligation */
  /*******************************************************/
  getVehicleAgendaNoCache(vehicle: Vehicle, tf: string, tt: string): Observable<Vehicle> {
    let result: BehaviorSubject<Vehicle> = new BehaviorSubject(null);

    if (vehicle && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.vehicle.apiAgenda;
      url = url.replace('%CAR_KEY%', vehicle.car_key.toString());
      url += '?tf=' + tf;
      url += '&tt=' + tt; 

      this._http.get(url).subscribe(
        agenda => {
          let obj: any = { agenda: agenda };
          obj = VehicleTools.buildAgendaInVehicle(obj);

          vehicle.agenda = obj.agenda;
          result.next(vehicle);
        },
        error => {
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  getVehicleAgenda(car_key: number, tf: string, tt: string): Observable<Vehicle> {
    let result: BehaviorSubject<Vehicle> = new BehaviorSubject(null);

    if (car_key && (IS_DEMO || (!IS_DEMO && this._authService.isAuthenticated()))) {
      let url: string = ServiceConfiguration.vehicle.apiAgenda;
      url = url.replace('%CAR_KEY%', car_key.toString());
      url += '?tf=' + tf;
      url += '&tt=' + tt; 

      this._http.get(url).subscribe(
        agenda => {
          let obj: any = { agenda: agenda };
          obj = VehicleTools.buildAgendaInVehicle(obj);

          let vehicle: Vehicle = this._allVehiclesCache.find(v => v.car_key == car_key);
          if (vehicle) {
            vehicle.agenda = obj.agenda;
            result.next(vehicle);
          }
          else if (this._vehicleCache) {
            this._vehicleCache.agenda = obj.agenda;
            result.next(this._vehicleCache);
          }
        },
        error => {
          console.log(error);
        }
      );
    }

    return result.asObservable();
  }

  // method for reloading car's agenda
  reloadVehicleAgenda(car_key: number, obligation_key: number): void {
    if (car_key) {
      this._http.get(ServiceConfiguration.vehicle.api + '/' + car_key).subscribe(
        response => {
          // cars/<car_key> is array
          if (response && response.length) {
            let updated = response[0];
            let vehicle: Vehicle = this._allVehiclesCache.find(v => v.car_key == car_key);
            if (vehicle) {
              updated = VehicleTools.buildAgendaInVehicle(updated);
              vehicle.agenda = updated.agenda;
              // emit change to gannt-drive component
              this._vehicleDataChanged.emit(vehicle);
              this._vehicleServiceEventsChanged.emit(vehicle);

              // console.log(vehicle.agenda);
              // emit change to gannt-drive component
              let agendaOfInterest: Agenda = vehicle.agenda.find(
                a => a.obligation_key == obligation_key
              );
              this._agendaChanged.emit(agendaOfInterest);
            }
          }
        },
        error => {
          //handle error
          console.log(error);
        }
      );
    }
  }
  
  /******************************************************/
  /* Vehicle health */
  /******************************************************/
  public createVehicleHealthLazyload(vehicle: Vehicle): Function {
    let url: string = ServiceConfiguration.vehicle.apiHealth;
    url = url.replace(/%CAR_KEY%/, vehicle.car_key.toString());
    let loader = () => {
      return this._http.get(url).pipe(
        map(
          response => {
            return this.buildVehicleHealth(response)
          }
        )
      );
    };
    return loader;
  }

  private buildVehicleHealth(o: any): VehicleHealthObject {
    if (o) {
      let health: VehicleHealthObject = new VehicleHealthObject();
      for (let key in o) {
        health[key] = o[key];
      }
      return health;
    }
    return null;
  }


  /******************************************************/
  /* AETR part */
  /******************************************************/
  public createAetrLazyloader(vehicle: Vehicle): Function {
    let url: string = ServiceConfiguration.vehicle.apiAetrCar;
    url = url.replace(/%CAR_KEY%/, vehicle.car_key.toString());

    let loader = () => {
      return this._http.get(url).pipe(
        map(
          response => {
            return this.buildAetrCollection(response[vehicle.car_key])
          }
        )
      );
    };
    return loader;
  }

  private buildAetrCollection(response: any): AetrDataCollectionObject {
    let data = response;
    if (data) {
      let collection = new AetrDataCollectionObject(data.past, data.current, data.upcoming);
      collection.work_shift_start = data.work_shift_start;
      collection.work_shift_duration = data.work_shift_duration;
      collection.work_shift_duration_dbg = data.work_shift_duration_dbg;
      collection.work_shift_errors = data.work_shift_errors;
      collection.work_shift_errors_alt = data.work_shift_errors_alt;
      collection.segments.forEach(
        segment => {
          if (!segment.date_stop) {
            switch (true) {
              case collection.isSegmentPast(segment):
                segment.date_stop = DateTools.isoFix(segment.time_stop);

                break;
              case collection.isSegmentCurrent(segment):
                segment.date_stop = new Date(segment.remaining * 1000 + Date.now());
                break;
              default:
                segment.date_stop = new Date(DateTools.isoFix(segment.time_start).getTime() + segment.length * 1000);
                break;
            }
          }
          if (!segment.date_start) {
            switch (true) {
              case collection.isSegmentPast(segment):
                segment.date_start = new Date(DateTools.isoFix(segment.time_stop).getTime() - segment.length * 1000);
                break;
              case collection.isSegmentCurrent(segment):
                segment.date_start = new Date(new Date(segment.remaining * 1000 + Date.now()).getTime() - segment.length * 1000);
                break;
              default:
                segment.date_start = DateTools.isoFix(segment.time_start);
                break;
            }
          }
        }
      );
      return collection;
    } else {
      throw new Error('Not json response');
    }
  }

  public createLatestAetrLazyload(vehicle: Vehicle): Function {
    let url: string = ServiceConfiguration.vehicle.apiAetrCarLatest;
    url = url.replace(/%CAR_KEY%/, vehicle.car_key.toString());

    return () => {
      return this._http.get(url).pipe(
        map(
          (response: any) => {
            // console.log(response);
            if (response[vehicle.car_key] && response[vehicle.car_key] instanceof Array) {
              let found = [];
              response[vehicle.car_key].reverse().forEach(
                (aetrData: AetrActivityDataInterface, index) => {
                  if ([0, 1].indexOf(index) > -1) { //last 2
                    found.push(aetrData);
                  }
                }
              );
              return found;
            } else {
              null;
            }
          }
        )
      );
    }
  }
  
  public createAetrHistoryLazyload(vehicle: Vehicle): Function {
    let url: string = ServiceConfiguration.vehicle.apiAetrInfoCar;
    url = url.replace(/%CAR_KEY%/, vehicle.car_key.toString());

    return () => {
      return this._http.get(url).pipe(
        map(
          response => {
            return this.buildAetrHistory(response);
          }
        )
      );
    }
  }

  private buildAetrHistory(data: any) {
    if (data.activities instanceof Array) {
      return new AetrHistoryCollectionObject(data.activities);
    }
  }
  
  
  /******************************************************/
  /* Tracking events part */
  /******************************************************/
  public createVehicleTrackingEventsLazyload(vehicle: Vehicle): Function {
    let url: string = ServiceConfiguration.vehicle.apiDriveByAetr;
    url = url.replace(/%CAR_KEY%/, vehicle.car_key.toString());

    return () => {
      return this._http.get(url).pipe(
        map(
          response => {
            return this.buildVehicleTrackingEvents(response);
          }
        )
      );
    }
  }
  
  private buildVehicleTrackingEvents(data: Array<TrackingEventDataInterface>): Array<TrackingEventDataObject> {
    let objects = [];
    data.forEach(
      (value) => {
        objects.push(
          TrackingEventDataObject.create(value)
        )
      }
    );
    return objects;
  }

  
  /******************************************************/
  /* Service events part */
  /******************************************************/
  public createLatestServiceEventsLazyload(vehicle: Vehicle): Function {
    let today: Date = new Date();
    // api returns today / or last aetr day (we use only today... filtering here...)
    let todayFormatted = this._datePipe.transform(today, 'yyyy-MM-dd');
    return () => {
      return this._http.get(ServiceConfiguration.vehicle.apiServices.replace(/%CAR_KEY%/, vehicle.car_key.toString())).pipe(
        map(
          response => {
            // console.log(response);
            response = response.filter(ev => ev.time.startsWith(todayFormatted));
            return this.buildLatestServiceEvents(response);
          }
        )
      );
    }
  }
  
  private buildLatestServiceEvents(data: any): Array<ServiceEventObject> {
    if (data instanceof Array) {
      return ServiceEventService.buildServiceEventsFromData(data);
    } 
    return [];
  }


  /******************************************************/
  /* Service events part */
  /******************************************************/
  public createVehicleManualTrackingStopInterval(vehicle: Vehicle) {
    if (!this._vehicleManualTrackingStopIntervalInitalized[vehicle.car_key]) {
      this._vehicleManualTrackingStopIntervalInitalized[vehicle.car_key] = window.setInterval(
        () => {
          if (vehicle.temporaryManualTrackingTarget) {
            if (vehicle.isMoving()) {
              this.clearVehicleManualTrackingStopInterval(vehicle);
            } else {
              vehicle.temporaryManualTrackingTarget.stops += 60;
              vehicle.emitDestRouteChange();
            }
          } else {
            this.clearVehicleManualTrackingStopInterval(vehicle);
          }
        },
        60000
      );
    }
  }
  
  private clearVehicleManualTrackingStopInterval(vehicle: Vehicle) {
    if (!isNaN(this._vehicleManualTrackingStopIntervalInitalized[vehicle.car_key])) {
      window.clearInterval(this._vehicleManualTrackingStopIntervalInitalized[vehicle.car_key]);
      this._vehicleManualTrackingStopIntervalInitalized[vehicle.car_key] = null;
    }
  }


  /******************************************************/
  /* Destination route part */
  /******************************************************/
  loadDestinationRoute(vehicle: Vehicle): void {
    this._subscribed.push(
      this._destinationRoutesService.getRoute(vehicle).subscribe(
        (data: DestinationRouteDataInterface) => {
          let strokeColor: string = this._allInOneMap ? null : '#ff0000';
          DestinationRoutesService.setReachDestinationToVehicleFromApiData(vehicle, data, strokeColor);
        },
        error => {
          // console.log(error);
        }
      )
    );
  }


  /******************************************************/
  /* Websocket processing part */
  /******************************************************/
  private processTracking(response: WebsocketResponse) {
    if (response.operation == WebsocketService.OPERATIONS.insert) {
      let data: TrackingEventDataInterface = response.record;

      // let vehicle = this.getVehicleByKey(response.record.car_key);
      let vehicle = this._allVehiclesCache.find(v => v.car_key == response.record.car_key);
      let trackingEvent = new TrackingEventDataObject(data);
      if (vehicle) {
        // Last position
        let lastPosition = vehicle.last_position;
        if (!lastPosition) {
          lastPosition = new GeoPosition();
        }
        let lastStatus = lastPosition.truck_status;
        for (let key in data) {
          lastPosition[key] = data[key];
        }
        if (!vehicle.last_position || vehicle.last_position.pos_key < data.pos_key) {
          let reloadAetr: boolean = false;
          if (lastStatus !== Vehicle.STOP && lastPosition.truck_status === Vehicle.STOP) {
            vehicle.startStopInterval();
            reloadAetr = true;
          } else if (lastStatus === Vehicle.STOP && lastPosition.truck_status !== Vehicle.STOP) {
            vehicle.stopStopInterval();
            reloadAetr = true;
          }
          if (lastStatus !== Vehicle.SLEEP && lastPosition.truck_status === Vehicle.SLEEP) {
            reloadAetr = true;
          } else if (lastStatus === Vehicle.SLEEP && lastPosition.truck_status !== Vehicle.SLEEP) {
            reloadAetr = true;
          }
          if (reloadAetr) {
            vehicle.aetr_lazyload = this.createAetrLazyloader(vehicle);
            vehicle.aetr = vehicle.aetr; //ensure loading
          }
        }
        let position = new GeoPosition(this._lastLoadDate);
        for (let key in data) {
          position[key] = data[key];
        }
        position.currentDate = this._lastLoadDate;

        // TODO
        // console.log('--------------------------------');
        // console.log(vehicle.number_plate);
        // console.log(position.time);
        // console.log(position.currentDate);
        // console.log(position.getDateDiff);
        // console.log(position.getDateDiffString);

        if (vehicle.agenda.length) {
          // Update agenda
          vehicle.agenda.forEach(
            (agenda: Agenda) => {
              if (vehicle.last_position && vehicle.last_position.order_number) {
                if (vehicle.last_position.order_number.includes(agenda.order_number)) {
                  agenda.addTrackedPosition(position);
                  agenda.tracked_distance += position.distance;
                }
              }
            }
          );
        } 
        else if (vehicle.temporaryManualTracking && vehicle.temporaryManualTrackingDirection) {
          let trackingData = vehicle.temporaryManualTrackedData;
          trackingData.push(position.googleLatLang);
          vehicle.temporaryManualTrackedData = trackingData;
        }

        if (vehicle.aetr) {
          if (vehicle.aetr.isSegmentDrive(vehicle.aetr.currentSegment)) {
            vehicle.aetr.currentSegment.distance += data.distance;
            vehicle.aetr.currentSegment.length += data.driving_time;
            vehicle.aetr.currentSegment.distance_remaining -= data.distance;
            vehicle.aetr.currentSegment.remaining -= data.driving_time;
          }
          if (vehicle.aetr.currentSegment.remaining <= 0) { //in case of error or not clear data reload aetr
            vehicle.aetr = null;
            vehicle.aetr_lazyload = this.createAetrLazyloader(vehicle);
          }
        }
        if (vehicle.temporaryManualTrackingTarget) {
          if (vehicle.temporaryManualTrackingTarget.distance > 0 && vehicle.isMoving()) {
            vehicle.temporaryManualTrackingTarget.distance -= (data.distance * 1000); //only substract distance if there is any time left
          }
          if (vehicle.temporaryManualTrackingTarget.distance <= 0) {
            vehicle.temporaryManualTrackingTarget.distance = 0
          }
          if (vehicle.temporaryManualTrackingTarget.duration > 0 && vehicle.isMoving()) {
            vehicle.temporaryManualTrackingTarget.duration -= data.driving_time;
          }
          if (vehicle.temporaryManualTrackingTarget.duration < 0) {
            vehicle.temporaryManualTrackingTarget.duration = 0;
          }
          if (!vehicle.isMoving() && vehicle.temporaryManualTrackingTarget) {
            this.createVehicleManualTrackingStopInterval(vehicle);
          }
        }
        vehicle.rebuildTrackedPositionBasedData();
        vehicle.addTrackingData(trackingEvent);
        this._vehicleDataChanged.emit(vehicle);
      }
    }
  }

  private processService(response: WebsocketResponse) {
    let data = response.record;
    // let vehicle = this.getVehicleByKey(data.car_key);
    let vehicle = this._allVehiclesCache.find(v => v.car_key == response.record.car_key);
    // if (data.type == Vehicle.LOADING_START || data.type == Vehicle.LOADING_END || data.type == Vehicle.UNLOADING_START || data.type == Vehicle.UNLOADING_END) {
    //     console.log('WS relation service');
    //     console.log(data);
    // }
    if (vehicle) {
      if (data.type == Vehicle.LOADING_START || data.type == Vehicle.LOADING_END || data.type == Vehicle.UNLOADING_START || data.type == Vehicle.UNLOADING_END) {
        let newCargoActivity = vehicle.last_cargo_activity;
        if (!newCargoActivity) {
          newCargoActivity = new CargoActivity();
        }

        // reinit order_company and order_number
        if (data.type == Vehicle.LOADING_START || data.type == Vehicle.UNLOADING_START) {
          newCargoActivity.order_company = null;
          newCargoActivity.order_number = null;
        }

        // update last cargo
        if (vehicle.last_cargo_activity && (vehicle.last_cargo_activity.length == 0 || vehicle.last_cargo_activity.service_key < data.service_key)) {
          var length = 0;
          if ((vehicle.last_cargo_activity.type == Vehicle.LOADING_START && data.type == Vehicle.LOADING_END) || (vehicle.last_cargo_activity.type == Vehicle.UNLOADING_START && data.type == Vehicle.UNLOADING_END)) {
            var start: Date = vehicle.last_cargo_activity.time;
            // var end = new Date(data.time);
            var end: Date = DateTools.isoFix(data.time);
            length = Math.round((end.getTime() - start.getTime()) / 1000);
            newCargoActivity.time_start = vehicle.last_cargo_activity.time;
            newCargoActivity.event_took = length;
          } else if ([Vehicle.LOADING_START, Vehicle.UNLOADING_START].indexOf(data.type) > -1) {
            newCargoActivity.time_start = new Date(data.time);
            newCargoActivity.event_took = null;
          }
          let city_gps = new GeoPosition();
          for (let key in data) {
            city_gps[key] = data[key];
            newCargoActivity[key] = data[key];
          }
          if (data.city) {
            city_gps.city_name = data.city.name;
            city_gps.city_zip = data.city.zip;
            city_gps.city_country = data.city.country;
          }
          newCargoActivity.city_gps = city_gps;
          newCargoActivity.city_gps.currentDate = this._lastLoadDate;

          // compute event_took_parse_string
          newCargoActivity.set_event_took_parse();
          // console.log(newCargoActivity);
        }

        // update agenda
        vehicle.agenda.forEach(
          (agenda, index) => {
            // Find matching itinerary item
            if ((agenda.order_number !== data.order_number && agenda.order_number.indexOf(data.order_number) === -1) || !agenda.itinerary || !agenda.itinerary.length) {
              return true;
            }
            let bestIdx: number = -1;
            let bestDist: number = 99999999;
            let timeLimit: number = 24*60*60*1000;  // in msec
            let distLimit: number = 1;		// used to be 6 in km
            let dEvent: Date = DateTools.isoFix(data.time);
            let gpsEvent = GeoPosition.parseToLocationArray(data.pos_gps);
            agenda.itinerary.forEach(
              (it, index) => {
                // possible itinerary types "l<=>L" "u<=>U"
                // if (itinerary.type != data.type.toUpperCase())
                //   return true;

                // skip - use only loadings, unloadings and warehouses itinerary
                const possible_types: Array<string> = [
                  ItineraryType.LOADING, ItineraryType.UNLOADING, 
                  ItineraryType.WAREHOUSE, ItineraryType.WAREHOUSE_AUTO
                ];
                if (!possible_types.includes(it.type)) {
                  return true;
                }
                // skip - different type
                if (it.type == ItineraryType.LOADING && data.type.toUpperCase() != ItineraryType.LOADING) {
                  return true;
                }
                // skip - different type
                if (it.type == ItineraryType.UNLOADING && data.type.toUpperCase() != ItineraryType.UNLOADING) {
                  return true;
                }

                let dItem: Date = it.arrival_time;
                // HOT FIX - ignoring null gps coords
                if (!it.gps_coord || !it.gps_coord.googleLatLang) {
                  console.error('Null or undefined LatLng object!');
                  return true;
                }

                let dist = Haversine.haversine(
                  {
                    latitude: parseFloat(gpsEvent[0]),
                    longitude: parseFloat(gpsEvent[1])
                  },
                  {
                    latitude: it.gps_coord.googleLatLang.lat,
                    longitude: it.gps_coord.googleLatLang.lng
                  }
                );

                if (dist && dist < distLimit && Math.abs(dItem.getTime() - dEvent.getTime()) < timeLimit) {
                  if (bestIdx == -1 || bestDist > dist) {
                    bestIdx = index;
                    bestDist = dist;
                    // console.log(bestIdx);
                    // console.log(bestDist);
                    it.service_events.push(ServiceEventService.buildServiceEvent(data));
                    // invoke setter of service events
                    it.service_events = it.service_events.slice();
                    newCargoActivity.arrival_time = it.arrival_time;
                    newCargoActivity.arrival_time_max = it.arrival_time_max;
                    return false;
                  }
                }
              }
            );
          }
        );
        // vehicle.addServiceEvent(new ServiceEventObject(data));
        vehicle.addServiceEvent(ServiceEventService.buildServiceEvent(data));
        // this.setLastCargo(vehicle);
        VehicleTools.setLastCargo(vehicle);
        this._vehicleDataChanged.emit(vehicle);
        this._vehicleServiceEventsChanged.emit(vehicle);
      }
    }
  }

  private processAetr(response: WebsocketResponse) {
    switch (true) {
      case [WebsocketService.OPERATIONS.update, WebsocketService.OPERATIONS.delete].indexOf(response.operation) > -1:
        for (let car_key in response.record) {
          // let vehicle = this.getVehicleByKey(parseInt(car_key));
          let vehicle = this._allVehiclesCache.find(v => v.car_key == parseInt(car_key));
          if (vehicle) {
            vehicle.aetr = this.buildAetrCollection(response.record[vehicle.car_key]);
            vehicle.resetTrackingData();
            vehicle.trackingDataLazyload = this.createVehicleTrackingEventsLazyload(vehicle);
          }
        }
        break;
    }
  }
  
  public processHealth(response: WebsocketResponse) {
    // let vehicle = this.getVehicleByKey(response.record.car_key);
    let vehicle = this._allVehiclesCache.find(v => v.car_key == response.record.car_key);
    if (vehicle) {
      vehicle.health = this.buildVehicleHealth(response.record);
    }
  }

  private processFueling(response: WebsocketResponse) {
    // let vehicle = this.getVehicleByKey(response.record.car_key);
    let vehicle = this._allVehiclesCache.find(v => v.car_key == response.record.car_key);
    if (vehicle) {
      vehicle.consumption_avg = response.record.consumption_avg;
      vehicle.last_refueling_distance = response.record.last_refueling_distance;
      vehicle.last_refueling_liters = response.record.last_refueling_liters;
      vehicle.last_refueling_time = response.record.last_refueling_time;
      vehicle.tank_volume = response.record.tank_volume;
      vehicle.tank_gauge_percentage = response.record.tank_gauge_percentage;
    }
  }

  private processVehicleDestination(response: WebsocketResponse) {
    // let vehicle = this.getVehicleByKey(response.record.car_key);
    let vehicle = this._allVehiclesCache.find(v => v.car_key == response.record.car_key);
    // console.log(response);
    if (vehicle) {
      // console.log(vehicle);
      switch (response.operation) {
        case WebsocketService.OPERATIONS.delete:
          vehicle.resetManualTemporaryTracking();
          break;
        default:
          this._destinationRoutesService.getRoute(vehicle).subscribe(
            (data: DestinationRouteDataInterface) => {
              let strokeColor: string = this._allInOneMap ? null : '#ff0000';
              DestinationRoutesService.setReachDestinationToVehicleFromApiData(vehicle, data, strokeColor);
            },
            error => {
              console.log(error);
            }
          );
          break;
      }
    }
  }

}
