import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { StorageKeys } from "../../config";
import { StorageService } from 'src/app/service/storage.service';
// import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { GoogleMapAdvMarker } from "src/app/model/google-map-adv-marker.object";
import { Vehicle } from "../../model/vehicle.object";
import { ServiceEventObject } from 'src/app/model/service-event.object';
import { TrackingEventDataObject } from 'src/app/model/tracking-event-data.object';
import { Haversine } from 'src/app/tools/Haversine';
import { TrackingEvent } from 'src/app/model/tracking-event.object';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';

declare var google: any;

@Component({
  selector: 'div.googleMapManualTracking',
  templateUrl: './google-map-manual-tracking.component.html',
  styleUrls: ['./google-map-manual-tracking.component.css']
})
export class GoogleMapManualTrackingComponent implements OnInit, OnDestroy {

  // usage in components
  // r-vehicle-manual-tracking-configuration, r-vehicle-manual-tracking-itinerary
  // r-diary-mobile, r-diary-mobile-map, r-vehicle-dispositions

  private _subscriptions: Array<Subscription> = [];
  
  private _map: any;
  private _initMarkers: Array<GoogleMapAdvMarker> = [];
  private _currentPositionMarker: GoogleMapAdvMarker = null;
  private _initialized: boolean = false;
  private _centeredSwitch: boolean = false;
  private _bounds: any;
  private _polyLines: Array<any> = [];
  private _directions: Array<any> = [];
  private _directionsRendered: Array<any> = [];
  private _directionsService = new google.maps.DirectionsService();
  private _trafficLayerShow: boolean = true;
  private _trafficLayer: any = null;
  // private _path: Array<any> = [];
  // private _manualTrackingRendered: any = null;

  private _key: number;
  get key(): number {
    return this._key;
  }
  
  private _waypoints = [];
  @Input()
  set waypoints(value: Array<any>) {
    this._waypoints = value;
    if (this._initialized) {
      this.rebuildData(false);
    }
  }

  // inputs
  private _properties: any = {};
  @Input()
  set properties(properties: any) {
    this._properties = properties;
  }
  
  private _showPolylines: boolean = false;
  @Input()
  set showPolylines(value: boolean) {
    this._showPolylines = value;
  }
  
  private _vehicle: Vehicle = null;
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
  }
  get vehicle(): Vehicle {
    return this._vehicle;
  }

  private _markers: Array<GoogleMapAdvMarker> = [];
  @Input()
  set markers(markers: Array<GoogleMapAdvMarker>) {
    let same: boolean = markers === this._initMarkers;
    // removing not null
    this._initMarkers = markers.filter(m => m);

    if (!same && this._initialized) {    
      this._map.setMapTypeId('roadmap');
      this.rebuildData(false);
    }
  }

  // private _obligationTrackingEvents: Array<TrackingEvent> = [];
  // @Input()
  // public set obligationTrackingEvents(value: Array<TrackingEvent>) {
  //   this._obligationTrackingEvents = value;
  //   this.rebuildData(false);
  // }

  private _markerDestRoute: GoogleMapAdvMarker;
  @Input()
  set markerDestRoute(marker: GoogleMapAdvMarker) {
    this._markerDestRoute = marker;
    // this.rebuildData(false);
  }
  
  private _drawMarkerDestRoute: boolean = false;
  @Input()
  set drawMarkerDestRoute(value: boolean) {
    this._drawMarkerDestRoute = value;
  }

  private _directionsDraggable: boolean = false;
  @Input()
  set directionsDraggable(value: boolean) {
    this._directionsDraggable = value;
  }
  
  private _createDirectionDestRoute: boolean = false;
  @Input()
  set createDirectionDestRoute(value: boolean) {
    this._createDirectionDestRoute = value;
    // update current position and draw direction of dest route
    this.buildDataVehicle();
    this.drawDirectionDestRoute();
  }

  private _drawDirections: boolean = false;
  @Input()
  set drawDirections(value: boolean) {
    this._drawDirections = value;
  }

  private _drawAllItinerary: boolean = false;
  @Input()
  public set drawAllItinerary(value: boolean) {
    this._drawAllItinerary = value;
  }

  private _mapClickCallback: Function = null;
  @Input()
  set clickCallback(value: Function) {
    this._mapClickCallback = value;
  }
  
  @Input() rebuildMap: Observable<boolean> = new Observable();
  
  // outputs
  private _clickEmitter: EventEmitter<any> = new EventEmitter();
  @Output()
  get clicked(): Observable<any> {
    return this._clickEmitter.asObservable();
  }

  private _directionsChange: EventEmitter<any> = new EventEmitter();
  @Output()
  get directionsChange(): Observable<any> {
    return this._directionsChange.asObservable();
  }
  

  constructor(
    private _elRef: ElementRef,
    private _storageService: StorageService,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
    // get traffic layer cookie
    let traffic: string = this._storageService.getItem(StorageKeys.map.traffic_layer, true);
    this._trafficLayerShow = (traffic == 'false') ? false : true;
  }

  ngOnInit(): void {
    if (this._vehicle) {
      this._subscriptions.push(
        this.rebuildMap.subscribe(
          (rebuild: boolean) => {
            if (rebuild) {
              this.rebuildData(false);
            }
          }
        ),
        this._vehicle.trackingDataLoaded.subscribe(
          (data: Array<TrackingEventDataObject>) => {
            if (data.length > 0) {
              // update current position marker + red tracking path 
              this.buildDataVehicle();
            }
          }
        ),
        this._vehicle.destRouteChange.subscribe(
          () => {
            // dest route could be finished / removed / added -> rebuild
            this.rebuildData(false);
          }
        )
      );
    }
    this.buildData();
    this._initialized = true;

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-manual-tracking';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearData(true);
    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }
  
  private clearData(dropMap: boolean) {
    // dest route ~ manual tracking
    // this._manualTrackingRendered = null;
    // vehicle current position
    if (this._currentPositionMarker) {
      // remove previous position marker
      this._currentPositionMarker.map = null;
      this._currentPositionMarker = null;
    }
    // polylines
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];
    // directions
    this.clearDirectionsRenderers();
    // markers
    this._markers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._initMarkers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._markers = this._initMarkers.slice();
    this._bounds = null;
    // this.clearHeatMap();
    if (dropMap) {
      this._map = null;
    }
  }
  
  
  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  private _rebuildTimeout: number = null;
  private _building: boolean = false;
  
  private rebuildData(dropMap: boolean) {
    if (!this._initialized) {
      return;
    }
    if (!this._building && this._rebuildTimeout === null) {
      this._rebuildTimeout = window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
          this._rebuildTimeout = null;
        },
        1000
      )
    }
  }

  private buildData() {
    this._building = true;

    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      if (this._properties) {
        this._properties.mapId = 'googleMap' + this._key;
      }
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));
      if (this._mapClickCallback) {
        this._map.addListener('click', (data) => {
          this._mapClickCallback.apply(this, [data]);
          this._clickEmitter.emit(data);
        });
      } 
      else {
        this._map.addListener('click', (data) => {
          this._clickEmitter.emit(data);
        });
      }
    }
    this._markers = this._initMarkers.slice();
    if (this._vehicle) {
      // build current position and today tracking route
      this.buildDataVehicle();
      // dest route direction may be currently be set up
      this.drawDirectionDestRoute();
    }
    
    // if (this._drawDirections && this._markers.length > 1) {
    //   this._markers.forEach(
    //     (marker, index) => {
    //       if (!this._markers[index + 1]) {
    //         return false;
    //       }
    //       let manualTrackingOptions: any;
    //       manualTrackingOptions = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));
    //       // manualTrackingRouteOptions.polylineOptions.strokeColor = Colors.getColor(vehicle.index);
    //       // manualTrackingRouteOptions.polylineOptions.strokeOpacity = 0.5;
    //       if (this._directionsDraggable) {
    //         manualTrackingOptions.draggable = true;
    //         manualTrackingOptions.preserveViewport = true;
    //         manualTrackingOptions.suppressMarkers = true;
    //       }

    //       let directionRenderer = new google.maps.DirectionsRenderer(manualTrackingOptions);
    //       directionRenderer.addListener('directions_changed', () => {
    //         this._directionsChange.emit({
    //           index: index,
    //           rendered: directionRenderer,
    //           allRendered: this._directionsRendered
    //         });
    //       });
    //       directionRenderer.setMap(this._map);
    //       this.callRequestOnDirectionsRender(directionRenderer, {
    //         origin: marker.position,
    //         destination: this._markers[index + 1].position,
    //         waypoints: [],
    //         travelMode: google.maps.DirectionsTravelMode.DRIVING
    //       });
    //       this._directionsRendered.push(directionRenderer);
    //     }
    //   );
    // }

    // directions (obligation/agenda route) are rendered always
    // if (this._directions.length) {
    //   let directionsRendered: any;
    //   directionsRendered = new google.maps.DirectionsRenderer(GoogleMapTools.RendererOptions);
    //   directionsRendered.setMap(this._map);
    //   let request = {
    //     origin: this._directions[0].location,
    //     destination: this._directions[(this._directions.length - 1)].location,
    //     waypoints: this._directions,
    //     travelMode: google.maps.DirectionsTravelMode.DRIVING
    //   };
    //   this.callRequestOnDirectionsRender(directionsRendered, request);
    //   this._directionsRendered.push(directionsRendered);
    // }

    if (this._markers.length) {
      this._markers.forEach(
        (marker: GoogleMapAdvMarker) => {
          marker.map = this._map;
        }
      );
      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        marker => {
          this._bounds.extend(marker.position);
        }
      );
      if (this._currentPositionMarker) {
        this._bounds.extend(this._currentPositionMarker.position);
      }
    }

    if (this._map) {
      if (this._bounds) {
        this._map.fitBounds(this._bounds);
      } 
      else {
        // default zoom fix
        if (this._markers.length) {
          this._map.setCenter(this._markers[0].position);
        }
        this._map.setZoom(6);
      }

      // possibly draw traffic layer
      if (this._trafficLayerShow && !this._trafficLayer) {
        this.showTrafficLayer();
      }
    }

    this._building = false;
  }
  
  private buildDataVehicle() {
    if (this._vehicle) {
      // handle marker for current position of vehicle
      if (this._currentPositionMarker) {
        // remove previous position marker
        this._currentPositionMarker.map = null;
      }
      this._currentPositionMarker = this._vehicle.getCurrentPositionMarker();
      // use truck_status icon (do not use LOADING/UNLOADING icons)
      if (this._vehicle.last_position && this._vehicle.last_position.truck_status) {
        let truck_status = this._vehicle.last_position.truck_status;
        this._currentPositionMarker.icon = GoogleMapAdvMarker.icons[truck_status];
        this._currentPositionMarker.map = this._map;
      }

      // always render dest routes direction
      this.buildManualTracking();

      // show red polylines of todays tracking events
      if (this._showPolylines) {
        // reinit polylines
        this._polyLines.forEach(
          polyLine => {
            polyLine.setMap(null);
          }
        );
        this._polyLines = [];

        this._vehicle.trackingData.forEach(
          (trackingData, index) => {
            // day tracking start
            if (index === 0) {
              this._markers.push(trackingData.toMarker(Vehicle.MARKER_ICON_START));
            }
  
            // default red color of today route lines
            let strokeColor = '#ff0000';
            if (this._vehicle.trackingData[index + 1]) {
              this._polyLines.push(
                new google.maps.Polyline({
                  path: [
                    trackingData.geoPos.googleLatLang.googleLtLn,
                    this._vehicle.trackingData[index + 1].geoPos.googleLatLang.googleLtLn,
                  ],
                  geodesic: true,
                  strokeColor: strokeColor,
                  strokeOpacity: 1.0,
                  strokeWeight: 3,
                  zIndex: 1000,
                  map: this._map
                })
              );
            }
          }
        );
      }
      

      // // init directions
      // this._directions = [];
      // this._vehicle.direction.forEach(
      //   direction => {
      //     this._directions.push(
      //       {
      //         location: direction.googleLtLn
      //       }
      //     )
      //   }
      // );
      // // init path
      // // this._path = [];
      // if (this._drawAllItinerary) {
      //   this._vehicle.agenda.forEach(
      //     agenda => {
      //       if (this._vehicle.last_position && this._vehicle.last_position.order_number) {
      //         if (this._vehicle.last_position.order_number.includes(agenda.order_number)) {
      //           agenda.itinerary.forEach(
      //             itinerary => {
      //               // pridavat znovu pouze ty markery, ktere nejsou jeste vykresleny 
      //               if (!this._markers.find(m => m.itinerary_key == itinerary.itinerary_key)) {
      //                 // ignorujeme prejezd
      //                 if (itinerary.type !== 'M') {
      //                   let marker = new GoogleMapAdvMarker();
      //                   marker.position = itinerary.gps_coord.googleLatLang;
      //                   marker.icon = GoogleMapAdvMarker.icons[itinerary.type_marker];
      //                   marker.zIndex = 200;
      //                   marker.title = itinerary.address;
      //                   marker.itinerary_key = itinerary.itinerary_key;
                        
      //                   this._markers.push(marker);
      //                 }
      //               }
      //             }
      //           );
      //         }
      //       }
      //     }
      //   );
      // }
    }
  }


  /*************************************************************/
  // Centering methods
  /*************************************************************/
  centerToCurrentVehiclePosition() {
    if (this._vehicle && this._vehicle.last_position) {
      this.centerToPosition(this._vehicle.getCurrentPositionMarker().position);
    }
  }
  
  private centerToPosition(position: any) {
    if (!this._centeredSwitch) {
      let bounds: any = new google.maps.LatLngBounds();
      bounds.extend(position);
      this._map.fitBounds(bounds);
      this._map.setZoom(18);
      this._map.setCenter(position);
      this._map.setMapTypeId(google.maps.MapTypeId.HYBRID);

      this._centeredSwitch = true;
    } 
    else {
      this.rebuildData(false);
      this._map.setZoom(10);
      this._map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
      this._centeredSwitch = false;
    }
  }

  
  /*************************************************************/
  // Manual tracking methods
  /*************************************************************/
  private buildManualTracking(): void {
    if (this._vehicle && this._vehicle.temporaryManualTracking) {
      if (this._vehicle.temporaryManualTracking.length === 2) {
        this._vehicle.manualTrackingDirectionRender.setMap(this._map);
        this._directionsRendered.push(this._vehicle.manualTrackingDirectionRender);
        this._vehicle.temporaryManualTracking[1].map = this._map;
        this._markers.push(this._vehicle.temporaryManualTracking[1]);
      }
    }
  }

  private drawDirectionDestRoute(): void {
    if (this._createDirectionDestRoute && this._currentPositionMarker && this._markerDestRoute) {
      let manualTrackingOptions: any;
      // deep copy of options
      manualTrackingOptions = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));
      manualTrackingOptions.polylineOptions.strokeColor = '#ff0000';
      manualTrackingOptions.polylineOptions.strokeOpacity = 0.5;
      manualTrackingOptions.polylineOptions.zIndex = 201;
      if (this._directionsDraggable) {
        manualTrackingOptions.draggable = true;
        // manualTrackingRouteOptions = Object.assign({draggable: true}, {});
      }

      let directionRenderer: any;
      directionRenderer = new google.maps.DirectionsRenderer(manualTrackingOptions);
      directionRenderer.addListener('directions_changed', (event) => {
        this._directionsChange.emit(directionRenderer);
      });
      directionRenderer.setMap(this._map);

      // save waypoints
      this._waypoints = this._vehicle.temporaryManualTrackingWaypoints;

      this.callRequestOnDirectionsRender(directionRenderer, {
        origin: this._currentPositionMarker.position,
        destination: this._markerDestRoute.position,
        waypoints: this._waypoints,
        travelMode: google.maps.DirectionsTravelMode.DRIVING
      });
      this._directionsRendered.push(directionRenderer);

      // render also marker 
      if (this._drawMarkerDestRoute) {
        this._markerDestRoute.map = this._map;
        this._markers.push(this._markerDestRoute);
      }
    } 
  }

  
  /*************************************************************/
  // Waypoints/directions methods
  /*************************************************************/
  private _directionsRenderCalls: number = 0;

  private callRequestOnDirectionsRender(directionsRendered, request: any) {
    let isset = false;
    this._directionsRenderCalls++;
    window.setTimeout(
      () => {
        // api request call
        let api_request_log: ExternalApiRequest = new ExternalApiRequest();
        api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
        api_request_log.type = 'directions';
        api_request_log.descr = 'google-map-manual-tracking';
        api_request_log.price = 0.005;
        this._externalApiRequestServ.createRequestLog(api_request_log);

        // google request
        this._directionsService.route(
          request,
          (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (isset === false) {
                directionsRendered.setDirections(response);
                isset = true;
              }
            }
            this._directionsRenderCalls--;
          });
      },
      501 * this._directionsRenderCalls // 1001 * this._directionsRenderCalls
    );
  }
  
  private clearDirectionsRenderers() {
    this._directionsRendered.forEach(
      directionRendered => {
        directionRendered.setMap(null);
      }
    );
    this._directionsRendered = [];
  }


  /*************************************************************/
  // Traffic methods
  /*************************************************************/
  showTrafficLayer() {
    this._trafficLayer = new google.maps.TrafficLayer();
    this._trafficLayer.setMap(this._map);
  }

  toggleTrafficLayer() {
    if (!this._trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
    }

    if (this._trafficLayer.getMap() == null) {
      // traffic layer is disabled.. enable it
      this._trafficLayer.setMap(this._map);
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'true', true);
    }
    else {
      // traffic layer is enabled.. disable it
      this._trafficLayer.setMap(null);        
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'false', true);     
    }
  }
}
