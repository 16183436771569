import { Component, OnDestroy, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { merge, Observable, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

import { NotificationService } from "src/app/service/notification-service";
import { AddressBookService } from "../../service/address-book.service";
import { ObligationService } from "src/app/service/obligation.service";
import { TemplateJsonService } from "src/app/service/template-json.service";
import { SettingsService } from "src/app/service/settings.service";
import { EmailingService } from "src/app/service/emailing.service";
import { CompanyService } from "src/app/service/company.service";
import { AddressBookItem } from "../../model/address-book-item.object";
import { CitiesService } from "src/app/service/cities.service";
import { Settings } from "src/app/model/settings.object";
import { Company } from "src/app/model/company.object";
import { Person } from "../../model/person.object";
import { HomeStandKey } from "src/app/model/home-stand-key.object";
import { GeoPosition } from "src/app/model/geo-position.object";
import { EMAIL_REGEX } from "src/app/config";

declare var $: any;

@Component({
  selector: 'address-book-item',
  templateUrl: './left-address-book-item.component.html',
  styleUrls: ['./left-address-book-item.component.css'],
  providers: [
    AddressBookService,
    SettingsService,
    ObligationService,
    EmailingService,
    TemplateJsonService
  ]
})
export class LeftAddressBookItemComponent implements OnInit, OnDestroy {

  // array for saving subscriptions
  private _subscribed: Array<Subscription> = [];

  private _addressBookItem: any;
  @Input()
  public set addressBookItem(value: any) {
    // && value instanceof AddressBookItem
    if (value && value != this._addressBookItem) {
      this._addressBookItem = value;
      this.initEditAddressBookItem();
      this.initAddressBookItemCars();
    }
  }
  public get addressBookItem(): any {
    return this._addressBookItem;
  }
  // output for 2-way binding - need call it with the same name with 'Change' on the end
  // https://stackoverflow.com/a/42006822/8226013
  @Output() addressBookItemChange: EventEmitter<any>  = new EventEmitter<any>();


  private _addressBookItemKey: number;
  @Input()
  public set addressBookItemKey(value: number) {
    this._addressBookItemKey = value;
  }
  public get addressBookItemKey(): number {
    return this._addressBookItemKey;
  }
  // output for 2-way binding - need call it with the same name with 'Change' on the end
  // https://stackoverflow.com/a/42006822/8226013
  @Output() addressBookItemKeyChange: EventEmitter<number> = new EventEmitter<number>();

  // flag for creation / editation
  private _creationMode: boolean = true;
  @Input()
  public set creationMode(value: boolean) {
    this._creationMode = value;
    if (this._creationMode) {
      this.addressBookItemForm.controls['cin'].enable();
      this.addressBookItemForm.controls['cin_vat'].enable();
      this.addressBookItemForm.controls['tin'].enable();
      this.addressBookItemForm.controls['company'].enable();
      this.addressBookItemForm.controls['street'].enable();
      this.addressBookItemForm.controls['city'].enable();
      this.addressBookItemForm.controls['zip'].enable();
      this.addressBookItemForm.controls['country'].enable();
    }
  }
  public get creationMode(): boolean {
    return this._creationMode;
  }
  
  // flag for signalizing that TIN has been given in input from outside (e.g. company_obligation company input)
  private _initOpen: boolean = false;

  // flag for invoking finding company in vies
  private _startFindCompany: boolean = false;
  @Input() 
  public set startFindCompany(value: boolean) {
    this._startFindCompany = value;
    if (this._creationMode && this._startFindCompany) {
      this._initOpen = true;
      this.findCompany();
    }
    else if (this._addressBookItem && this._startFindCompany) {
      this.initEditAddressBookItem();
    }
  }

  private _findTin: string = null;
  @Input() 
  public set findTin(value: string) {
    this._findTin = value;
    if (this._findTin) {
      this._initOpen = true;
      this.findCompany();
      // console.log(this._findTin);
    }
  }
  
  // method for reinitalizing currentAddressBookItem object
  private _reinit: boolean = false;
  @Input()
  public set reinit(value: boolean) {
    this._reinit = value;
    if (this._reinit) {
      this._simulationReloading = true;
      this._currentAddressBookItem = new AddressBookItem();
      window.setTimeout( () => { this._simulationReloading = false; }, 500 );
    }
  }

  private _enableAddressBookUpdate: boolean = false;
  @Input()
  public set enableAddressBookUpdate(value: boolean) {
    this._enableAddressBookUpdate = value;
    if (!this._enableAddressBookUpdate && this._creationMode) {
      // disable editing of therse attributes 
      this.addressBookItemForm.controls['cin'].disable();
      this.addressBookItemForm.controls['tin'].disable();
      this.addressBookItemForm.controls['cin_vat'].disable();
      this.addressBookItemForm.controls['company'].disable();
      this.addressBookItemForm.controls['street'].disable();
      this.addressBookItemForm.controls['city'].disable();
      this.addressBookItemForm.controls['zip'].disable();
      this.addressBookItemForm.controls['country'].disable();
    }
    else {
      this.addressBookItemForm.controls['cin'].enable();
      this.addressBookItemForm.controls['tin'].enable();
      this.addressBookItemForm.controls['cin_vat'].enable();
      this.addressBookItemForm.controls['company'].enable();
      this.addressBookItemForm.controls['street'].enable();
      this.addressBookItemForm.controls['city'].enable();
      this.addressBookItemForm.controls['zip'].enable();
      this.addressBookItemForm.controls['country'].enable();
    }
  }
  public get enableAddressBookUpdate(): boolean {
    return this._enableAddressBookUpdate;
  }

  // output event for finish/closing (e.g. accordion or modal in component)
  @Output() close: EventEmitter<any> = new EventEmitter();
  
  // flag for simulating reloading of address book item component
  private _simulationReloading: boolean = false;
  public get simulationReloading(): boolean {
    return this._simulationReloading;
  }

  // array for duplicit items
  private _alreadyExistingItems: Array<AddressBookItem> = [];
  public get alreadyExistingItems(): Array<AddressBookItem> {
    return this._alreadyExistingItems;
  }
  public set alreadyExistingItems(value: Array<AddressBookItem>) {
    this._alreadyExistingItems = value;
  }


  // emailing stuff
  private _settingsDocs: Settings = null;
  private _company: Company = null;
  private _dictionary: any = {};

  // valid VIES
  public validViesResponse: boolean = true;
  public viesCompanyFound: boolean = false;
  public viesUnavailable: boolean = false;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _notificationServ: NotificationService,
    private _addressBookServ: AddressBookService,
    private _obligationServ: ObligationService,
    private _settingsServ: SettingsService,
    private _emailingServ: EmailingService,
    private _citiesServ: CitiesService,
    private _companyServ: CompanyService,
    private _jsonServ: TemplateJsonService,
    private _router: Router
  ) {
    // validator form
    this.addressBookItemForm = this._formBuilder.group({
      blocked: [false],
      cin: ['', Validators.required],
      tin: ['', Validators.required],
      cin_vat: [''],
      company: ['', Validators.required],
      street: [''],
      city: ['', Validators.required],
      zip: ['', [Validators.required, Validators.maxLength(10)]],
      country: ['', [Validators.required, this.checkValidCountry]],
      account: ['', Validators.maxLength(34)],
      iban: ['', Validators.maxLength(40)], // allow 40 because spacing formatting (34 varchar in db)
      swift: ['', Validators.maxLength(12)]
    });

    // disable editing of therse attributes 
    this.addressBookItemForm.controls['cin'].disable();
    this.addressBookItemForm.controls['tin'].disable();
    this.addressBookItemForm.controls['cin_vat'].disable();
    this.addressBookItemForm.controls['company'].disable();
    this.addressBookItemForm.controls['street'].disable();
    this.addressBookItemForm.controls['city'].disable();
    this.addressBookItemForm.controls['zip'].disable();
    this.addressBookItemForm.controls['country'].disable();
  }

  ngOnInit() {
    this._subscribed.push(
      this._companyServ.getCompanyFullObservable().subscribe(
        response => {
          if (response && this._company !== response) {
            this._company = response;
            this.initNetOrderEmail();
          }
        }
      ),
      this._settingsServ.getSettingsDocs().subscribe(
        docs => {
          if (docs) {
            this._settingsDocs = docs;
            // console.log(this._settingsDocs);
            this.initNetOrderEmail();
          }
        }
      ),
      this._jsonServ.getInvoiceTemplateDictionary().subscribe(
        data => {
          if (data && this._dictionary !== data) {
            this._dictionary = data;
          }
        },
        error => {
          console.log(error);
        }
      )
    );
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
  }

  public get loadingVies(): boolean {
    return this._addressBookServ.loadingVies;
  }

  private _loadingAres: boolean = false; 
  public get loadingAres(): boolean {
    return this._loadingAres;
  }

  public addressBookItemForm: UntypedFormGroup;
  public addressBookItemSubmitted = false;
  // convenience getter for easy access to form fields
  get f() { 
    return this.addressBookItemForm.controls; 
  }

  private _currentAddressBookItem: AddressBookItem = new AddressBookItem();
  public get currentAddressBookItem(): AddressBookItem {
    return this._currentAddressBookItem;
  }
  public set currentAddressBookItem(value: AddressBookItem) {
    this._currentAddressBookItem = value;
  }
  initNewAddressBookItem(): void {
    this._currentAddressBookItem = new AddressBookItem();
  }

  initEditAddressBookItem(): void {
    // editing address book item
    if (this._addressBookItem) {
      this._currentAddressBookItem.account = this._addressBookItem.account;
      this._currentAddressBookItem.bank = this._addressBookItem.bank;
      this._currentAddressBookItem.book_key = this._addressBookItem.book_key;
      this._currentAddressBookItem.blocked = this._addressBookItem.blocked;
      this._currentAddressBookItem.cin = this._addressBookItem.cin;
      this._currentAddressBookItem.cin_vat = this._addressBookItem.cin_vat;
      this._currentAddressBookItem.city = this._addressBookItem.city;
      this._currentAddressBookItem.company_key = this._addressBookItem.company_key;
      this._currentAddressBookItem.company = this._addressBookItem.company;
      this._currentAddressBookItem.country = this._addressBookItem.country;
      this._currentAddressBookItem.customer_tracking = this._addressBookItem.customer_tracking;
      this._currentAddressBookItem.edoc_required_itinerary = this._addressBookItem.edoc_required_itinerary;
      this._currentAddressBookItem.edoc_required_documents = this._addressBookItem.edoc_required_documents;
      this._currentAddressBookItem.edoc_required_invoice = this._addressBookItem.edoc_required_invoice;
      this._currentAddressBookItem.edoc_required_agreement = this._addressBookItem.edoc_required_agreement;
      this._currentAddressBookItem.iban = this._addressBookItem.iban;
      this._currentAddressBookItem.loading_at_weekend = this._addressBookItem.loading_at_weekend;
      this._currentAddressBookItem.loading_at_work_days = this._addressBookItem.loading_at_work_days;
      this._currentAddressBookItem.maturity = this._addressBookItem.maturity;
      this._currentAddressBookItem.notify_email = this._addressBookItem.notify_email;
      this._currentAddressBookItem.notify_sms = this._addressBookItem.notify_sms;
      this._currentAddressBookItem.original_documents = this._addressBookItem.original_documents;
      this._currentAddressBookItem.street = this._addressBookItem.street;
      this._currentAddressBookItem.swift = this._addressBookItem.swift;
      this._currentAddressBookItem.tin = this._addressBookItem.tin;
      this._currentAddressBookItem.work_day_begin = this._addressBookItem.work_day_begin;
      this._currentAddressBookItem.work_day_end = this._addressBookItem.work_day_end;
      this._currentAddressBookItem.zip = this._addressBookItem.zip;
      this._currentAddressBookItem.company_invoice = this._addressBookItem.company_invoice;
      this._currentAddressBookItem.country_invoice = this._addressBookItem.country_invoice;
      this._currentAddressBookItem.city_invoice = this._addressBookItem.city_invoice;
      this._currentAddressBookItem.street_invoice = this._addressBookItem.street_invoice;
      this._currentAddressBookItem.zip_invoice = this._addressBookItem.zip_invoice;

      // obligation creating token for this book_item
      this.netOrderToken = this._addressBookItem.obligation_token;
      this.initNetOrderEmail();

      // init form values
      this.initForm();

      // init contacts/persons
      this._subscribed.push(
        this._addressBookServ.getContacts(this._currentAddressBookItem.book_key).subscribe(
          response => {
            if (response && response.length) {
              this._newPersons = response;
            }
            else {
              this._newPersons = [];
            }
          }
        )
      );
      return;
    }
  }
  
  initAddressBookItemCars(): void {
    if (this._addressBookItem && this._addressBookItem.book_key) {
      this._subscribed.push(
        this._addressBookServ.getBookItemCarDests(this._addressBookItem.book_key).subscribe(
          response => {
            if (response && response.length) {
              this._addressBookItem.car_dests = response;
              this._currentAddressBookItem.car_dests = response;
            }
          }
        )
      )
    }
  }

  initForm(): void {
    this.addressBookItemForm.controls['blocked'].setValue(this._currentAddressBookItem.blocked);
    this.addressBookItemForm.controls['cin'].setValue(this._currentAddressBookItem.cin);
    this.addressBookItemForm.controls['tin'].setValue(this._currentAddressBookItem.tin);
    this.addressBookItemForm.controls['cin_vat'].setValue(this._currentAddressBookItem.cin_vat);
    this.addressBookItemForm.controls['company'].setValue(this._currentAddressBookItem.company);
    this.addressBookItemForm.controls['street'].setValue(this._currentAddressBookItem.street);
    this.addressBookItemForm.controls['city'].setValue(this._currentAddressBookItem.city);
    this.addressBookItemForm.controls['zip'].setValue(this._currentAddressBookItem.zip);
    this.addressBookItemForm.controls['country'].setValue(this._currentAddressBookItem.country);
    this.addressBookItemForm.controls['account'].setValue(this._currentAddressBookItem.account);
    this.addressBookItemForm.controls['swift'].setValue(this._currentAddressBookItem.swift);
    if (this._currentAddressBookItem.iban) {
      let formatted: string = this._currentAddressBookItem.iban;
      formatted = formatted.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      this.addressBookItemForm.controls['iban'].setValue(formatted);
    }
  }

  initItemByFormValues(): void {
    this._currentAddressBookItem.blocked = this.addressBookItemForm.controls['blocked'].value;
    this._currentAddressBookItem.cin = this.addressBookItemForm.controls['cin'].value;
    this._currentAddressBookItem.tin = this.addressBookItemForm.controls['tin'].value;
    this._currentAddressBookItem.cin_vat = this.addressBookItemForm.controls['cin_vat'].value;
    this._currentAddressBookItem.company = this.addressBookItemForm.controls['company'].value;
    this._currentAddressBookItem.street = this.addressBookItemForm.controls['street'].value;
    this._currentAddressBookItem.city = this.addressBookItemForm.controls['city'].value;
    this._currentAddressBookItem.zip = this.addressBookItemForm.controls['zip'].value;
    this._currentAddressBookItem.country = this.addressBookItemForm.controls['country'].value;
    this._currentAddressBookItem.account = this.addressBookItemForm.controls['account'].value;
    this._currentAddressBookItem.swift = this.addressBookItemForm.controls['swift'].value;
    this._currentAddressBookItem.iban = this.addressBookItemForm.controls['iban'].value;
    if (this._currentAddressBookItem.iban) {
      // remove whitespaces
      this._currentAddressBookItem.iban = this._currentAddressBookItem.iban.replace(/\s+/g, '');
    }
  }

  // method for finding company according TIN (DIČ)
  findCompany(): void {
    // reinit flag for form control
    this.addressBookItemSubmitted = false;

    // (re)init persons array
    this.initPersons();

    // tin format
    let findTin: string;
    if (this._initOpen) {
      if (this._findTin) {
        findTin = this._findTin;
      }
      else if (this._addressBookItem instanceof AddressBookItem) {
        findTin = this._addressBookItem.tin;
      }
      else if (this._addressBookItem) {
        // should be string from parent component
        findTin = this._addressBookItem;
      }
      else {
        // might be string inside this componet
        findTin = this.addressBookItemForm.controls['tin'].value;
      }
    }
    else {
      findTin = this.addressBookItemForm.controls['tin'].value;
    }

    if (!findTin) {
      if (!this._initOpen) {
        this._notificationServ.alert(
          $localize`Nebylo zadáno DIČ, firmu nelze vyhledat.`, 'error', 4000
        );
      }
      return;
    }

    // lets unsubscribe all
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );

    // first find if any address book item with given tin already exists
    this._subscribed.push(
      this._addressBookServ.getAddressBookItems(findTin).subscribe(
        response => {
          if (this._addressBookServ.loadedNow && response && response instanceof Array && response.length) {
            console.log(response);
            this._alreadyExistingItems = response;
            // filtering response 
            this._alreadyExistingItems = this._alreadyExistingItems.filter(
              i => i.tin == findTin
            );
            if (this._alreadyExistingItems.length) {
              // open modal for confirmation
              (<any>$('#confirmFindBookItemModal')).modal('show');
            }
            // reinit flag
            this._addressBookServ.loadedNow = false;
          }
          else if (this._addressBookServ.loadedNow && response) {
            this._alreadyExistingItems = [];
            // reinit flag
            this._addressBookServ.loadedNow = false;
            // start finding
            this.sendFindRequest();
          }
        }
      )
    );
  }

  // method for sending api request - finding company according TIN (DIČ)
  sendFindRequest(): void {
    // btw same init as above, but we need it in template (solution would be attribute _findTin)
    // tin format
    let findTin: string;
    if (this._initOpen) {
      if (this._findTin) {
        findTin = this._findTin;
      }
      else if (this._addressBookItem instanceof AddressBookItem) {
        findTin = this._addressBookItem.tin;
      }
      else if (this._addressBookItem) {
        // should be string from parent component
        findTin = this._addressBookItem;
      }
      else {
        // might be string inside this componet
        findTin = this.addressBookItemForm.controls['tin'].value;
      }
    }
    else {
      findTin = this.addressBookItemForm.controls['tin'].value;
    }

    // reinit init open flag
    this._initOpen = false;


    if (findTin && findTin.startsWith('CZ')) {
      // ares uses ICO
      let cin: string = findTin.substring(2);
      this.tryAresFinding(findTin, cin, true);
    }
    else {
      this.tryViesFinding(findTin, true);
    }
  }

  tryAresFinding(findTin: string, cin: string, vies_retry: boolean = false): void {
    this._loadingAres = true;
    this._subscribed.push(
      this._addressBookServ.getDataFromAres2(cin).subscribe(
        response => {
          if (response) {
            console.log(response);
            this._loadingAres = false;
            // if (response.stav == 'ok') {
            this.validViesResponse = true;
            this.viesCompanyFound = true;
            this.viesUnavailable = false;
            this._currentAddressBookItem.cin = response.ico;
            this._currentAddressBookItem.tin = response.dic;
            this._currentAddressBookItem.company = response.obchodniJmeno;
            this._currentAddressBookItem.country = 'CZ';
            if (response.sidlo) {
              // handle street conditions
              if (response.sidlo.nazevUlice) {
                this._currentAddressBookItem.street = response.sidlo.nazevUlice;
              }
              else {
                this._currentAddressBookItem.street = response.sidlo.nazevCastiObce;
              }
              if (this._currentAddressBookItem.street && response.sidlo.cisloDomovni) {
                this._currentAddressBookItem.street += ' ' + response.sidlo.cisloDomovni;
              }
              if (this._currentAddressBookItem.street && response.sidlo.cisloOrientacni) {
                this._currentAddressBookItem.street += '/' + response.sidlo.cisloOrientacni;
              }

              this._currentAddressBookItem.city = response.sidlo.nazevObce;
              this._currentAddressBookItem.zip = response.sidlo.psc;
            }
            // init form
            this.initForm();
          }
        },
        error => {
          console.log(error);
          this._loadingAres = false;
          this.initNewAddressBookItem();
          this._currentAddressBookItem.tin = findTin;
          // init form
          this.initForm();
          this.validViesResponse = false;
          // this.viesUnavailable = true;
          this._notificationServ.alert(
            $localize`Nepodařilo se vyhledat firmu v systému ARES.`, 'error', 4000
          );

          // try vies possibly
          if (vies_retry) {
            this.tryViesFinding(findTin, false);
          }
        }
      )
    );
  }

  tryViesFinding(tin: string, ares_retry: boolean = false): void {
    this._subscribed.push(
      this._addressBookServ.getDataFromVies(tin).subscribe(
        response => {
          if (response) {
            if (response.stav == 'ok') {
              this.validViesResponse = true;
              this.viesCompanyFound = true;
              this.viesUnavailable = false;
              this._currentAddressBookItem.cin = response.ico;
              this._currentAddressBookItem.tin = response.dic;
              this._currentAddressBookItem.company = response.firma;
              this._currentAddressBookItem.country = response.country;
              this._currentAddressBookItem.street = response.ulice;
              if (response.mesto) {
                console.log(response);
                // string parsing
                let idx: number = this.loopStringChars(response.mesto);
                // first X chars should be zip code
                this._currentAddressBookItem.zip = response.mesto.substring(0, idx).trim();
                // the rest is city
                this._currentAddressBookItem.city = response.mesto.substring(idx, response.mesto.length);
              }
            }
            else {
              this.initNewAddressBookItem();
              this._currentAddressBookItem.tin = tin;
              if (response.stav && response.stav.includes('nebylo nalezeno')) {
                this.validViesResponse = false;
                this.viesCompanyFound = false;
                this._notificationServ.alert(
                  $localize`Nepodařilo se vyhledat danou firmu (zkontrolujte DIČ).`, 'error', 4000
                );
              }
              else if (response.stav && response.stav.includes('služba právě není dostupná')) {
                this.validViesResponse = false;
                this.viesUnavailable = true;
                this._notificationServ.alert(
                  $localize`Služba VIES není aktuálně dostupná.`, 'error', 4000
                );
              }
          
              // try ares possibly
              if (ares_retry) {
                this.tryAresFinding(tin, tin, false);
              }
            }
            // init form
            this.initForm();
          }
        },
        error => {
          console.log(error);
          this.initNewAddressBookItem();
          this._currentAddressBookItem.tin = tin;
          // init form
          this.initForm();
          this.validViesResponse = false;
          this.viesUnavailable = true;
          this._notificationServ.alert(
            $localize`Služba VIES není aktuálně dostupná.`, 'error', 4000
          );
          
          // try ares possibly
          if (ares_retry) {
            this.tryAresFinding(tin, tin, false);
          }
        }
      )
    );
  }


  // returns index of first letter char
  private loopStringChars(str: string): number {
    if (!str) return null;

    let index: number = 0;
    for (let i = 0; i < str.length; i++) {
      let char: any = str.charAt(i);
      if (this.isNumberOrSpace(char) || char=='-') {            
        index++;
      }
      else {
        return index;
      }
    }
    return index;
  }

  // checks if character is number or space
  private isNumberOrSpace(c: any): boolean {
    return (c == ' ') || !isNaN(c);
  }

  ibanFormatting(): void {
    let value: string = this.addressBookItemForm.controls['iban'].value;
    if (value) {
      value = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      this.addressBookItemForm.controls['iban'].setValue(value);
    }
  }
  
  // fix user input (ctrl+v) with space
  emailTruncate(person: Person): void {
    console.log(person.email);
    if (person && person.email) {
      person.email = person.email.trim();
    }
    console.log(person.email);
  }

  // function for creating/updating
  submitAddressBookItem(): void {
    this.addressBookItemSubmitted = true;

    // fix for private customers without tin/cin
    // use first N chars from company string
    if (!this.addressBookItemForm.controls['cin'].value && this.addressBookItemForm.controls['company'].value) {
      let cin: string = this.addressBookItemForm.controls['company'].value;
      if (cin && cin.length > 15) {
        cin = cin.substring(0, 14);
      }
      this.addressBookItemForm.controls['cin'].setValue(cin);
    }
    if (!this.addressBookItemForm.controls['tin'].value && !this.addressBookItemForm.controls['company'].value) {
      let tin: string = this.addressBookItemForm.controls['company'].value;
      if (tin && tin.length > 15) {
        tin = tin.substring(0, 14);
      }
      this.addressBookItemForm.controls['tin'].setValue(tin);
    }


    // stop here if form is invalid
    if (this.addressBookItemForm.invalid) {
      if (this.f.account && this.f.account.errors) {
        this._notificationServ.alert(
          $localize`Číslo účtu může mít maximálně 34 znaků.`, 'error', 4000
        );
      }
      else if (this.f.iban && this.f.iban.errors) {
        this._notificationServ.alert(
          $localize`IBAN může mít maximálně 34 znaků.`, 'error', 4000
        );
      }
      else if (this.f.swift && this.f.swift.errors) {
        this._notificationServ.alert(
          $localize`SWIFT může mít maximálně 12 znaků.`, 'error', 4000
        );
      }
      else {
        this._notificationServ.alert(
          $localize`Zadejte/opravte všechna povinná pole.`, 'error', 4000
        );
      }

      // user error key as minlength not minLength
      if (this.f.zip && this.f.zip.errors && this.f.zip.errors.maxlength) {
        this._notificationServ.alert(
          $localize`PSČ může mít maximálně 10 znaků.`, 'error', 4000
        );
      }

      return;
    }

    if (this._creationMode) {
      // create after filling also with gps_coord
      // remove whitespaces and use country + zip (remove also '-' from zip)
      console.log(this.addressBookItemForm.controls['country'].value);
      console.log(this.addressBookItemForm.controls['zip'].value);

      let tmp_country: string = this.addressBookItemForm.controls['country'].value.replace(/\s+/g, '');
      let tmp_zip: string = this.addressBookItemForm.controls['zip'].value.toString().replace(/\s+/g, '').replace(/\-/g, '');
      let filter_obj = { country: tmp_country, zip: tmp_zip };
      console.log(this._currentAddressBookItem.city);
      console.log(tmp_country + ' ' + tmp_zip);

      // load city from our db
      this._subscribed.push(
        this._citiesServ.getCitiesNoObservable(filter_obj).subscribe(
          (cities: Array<HomeStandKey>) => {
            if (cities && cities.length) {
              // create
              this.processCity(cities[0]);
              this.createAddressBookItem();
            }
            else if (cities) {
              // remove last number from zip - simply substring
              let tmp_zip2 = tmp_zip.substring(0, tmp_zip.length - 1);
              let filter_obj2 = { country: tmp_country, zip: tmp_zip2 };
              console.log(tmp_zip2);

              this._subscribed.push(
                this._citiesServ.getCitiesNoObservable(filter_obj2).subscribe(
                  (cities: Array<HomeStandKey>) => {
                    if (cities && cities.length) {
                      // create
                      this.processCity(cities[0]);
                      this.createAddressBookItem();
                    }
                    else if (cities) {
                      // remove last number from zip - simply substring
                      let tmp_zip3 = tmp_zip2.substring(0, tmp_zip2.length - 1);
                      let filter_obj3 = { country: tmp_country, zip: tmp_zip3 };
                      console.log(tmp_zip3);

                      this._subscribed.push(
                        this._citiesServ.getCitiesNoObservable(filter_obj3).subscribe(
                          (cities: Array<HomeStandKey>) => {
                            if (cities && cities.length) {
                              // create
                              this.processCity(cities[0]);
                              this.createAddressBookItem();
                            }
                            else if (cities) {
                              // create
                              this.createAddressBookItem();
                            }
                          }
                        )
                      );
                    }
                  }
                )
              );
            }
          }
        )
      );
    }
    else {
      this.updateAddressBookItem();
    }
  }

  processCity(city: HomeStandKey): void {
    if (city) {
      this._currentAddressBookItem.gps_coord = city.geo_pos;
      if (this._currentAddressBookItem.gps_coord) {
        // parse string and round to 5 decimals
        let geo_pos_array = GeoPosition.parseToLocationArray(this._currentAddressBookItem.gps_coord);
        if (geo_pos_array && geo_pos_array.length == 2) {
          let x: number = parseFloat(geo_pos_array[0]);
          let y: number = parseFloat(geo_pos_array[1]);
          if (x && y) {
            // round to 5 decimals
            this._currentAddressBookItem.gps_coord = '(';
            this._currentAddressBookItem.gps_coord += Math.round(x * 100000) / 100000;
            this._currentAddressBookItem.gps_coord += ',';
            this._currentAddressBookItem.gps_coord += Math.round(y * 100000) / 100000;
            this._currentAddressBookItem.gps_coord += ')';
          }
        }
      }
    }
  }
  
  // create address book item -> post request
  createAddressBookItem(): void {
    // lets unsubscribe all
    this._subscribed.forEach(
      subsriber => {
        subsriber.unsubscribe();
      }
    );
    // use form values
    this.initItemByFormValues();

    // first find if any address book item with given tin already exists
    this._subscribed.push(
      this._addressBookServ.getAddressBookItems(this._currentAddressBookItem.tin).subscribe(
        response => {
          if (this._addressBookServ.loadedNow && response && response instanceof Array && response.length) {
            this._alreadyExistingItems = response;
            // reinit flag
            this._addressBookServ.loadedNow = false;

            // filtering response 
            this._alreadyExistingItems = this._alreadyExistingItems.filter(
              i => i.tin == this._currentAddressBookItem.tin
            );
            if (this._alreadyExistingItems.length) {
              // open modal for confirmation
              (<any>$('#confirmBookItemModal')).modal('show');
            }
            else {
              this.sendCreateRequest();
            }
          }
          else if (this._addressBookServ.loadedNow && response) {
            // reinit flag
            this._addressBookServ.loadedNow = false;
            this.sendCreateRequest();
          }
        }
      )
    );
  }

  // method for sending POST request to API
  sendCreateRequest(): void {
    // reinit if any existed (must have been confirmed already)
    this._alreadyExistingItems = [];

    // send request to api
    this._subscribed.push(
      this._addressBookServ.createAddressBookItem(this._currentAddressBookItem).subscribe(
        (response: AddressBookItem) => {
          if (response && response.book_key) {
            this._addressBookItem = response;
            this._addressBookItemKey = response.book_key;
            
            // emit output to parent
            this.addressBookItemChange.emit(this._addressBookItem);
            this.addressBookItemKeyChange.emit(this._addressBookItemKey);
            this.close.emit(null);

            this.initTemplate();

            // create all defined contacts/persons
            this.createPersons(response.book_key);
          }
        }
      )
    );
  }

  // update address book item -> put request
  updateAddressBookItem(): void {
    // use form values
    this.initItemByFormValues();

    this._subscribed.push(
      this._addressBookServ.updateAddressBookItem(this._currentAddressBookItem).subscribe(
        (response: AddressBookItem) => {
          if (response && response.book_key) {
            this._addressBookItem = response;
            this._addressBookItemKey = response.book_key;

            // emit output to parent
            this.addressBookItemChange.emit(this._addressBookItem);
            this.addressBookItemKeyChange.emit(this._addressBookItemKey);
            this.close.emit(null);

            // create all defined contacts/persons
            this.updatePersons(response.book_key);

            this.initTemplate();
          }
        }
      )
    );
  }

  // method for init actions
  initTemplate(): void {
    this._personsToRemove = [];
    
    // hide all bootstrap subaccordions via javascript
    (<any>$('#collapseID2')).collapse('hide');
    (<any>$('#collapseID3')).collapse('hide');
    (<any>$('#collapseID4')).collapse('hide');
    (<any>$('#collapseID5')).collapse('hide');
  }

  
  public readonly COUNTRIES: Array<any> = [
    { mpz: 'AL', name: $localize`AL-Albánie` },
    { mpz: 'AD', name: $localize`AD-Andora` },
    { mpz: 'AM', name: $localize`AM-Arménie` },
    { mpz: 'AT', name: $localize`AT-Rakousko` },
    { mpz: 'AZ', name: $localize`AZ-Ázerbajdžán` },
    { mpz: 'BE', name: $localize`BE-Belgie` },
    { mpz: 'BG', name: $localize`BG-Bulharsko` },
    { mpz: 'BA', name: $localize`BA-Bosna a Hercegovina` },
    { mpz: 'BY', name: $localize`BY-Bělorusko` },
    { mpz: 'CY', name: $localize`CY-Kypr` },
    { mpz: 'CZ', name: $localize`CZ-Česká republika` },
    { mpz: 'DE', name: $localize`DE-Německo` },
    { mpz: 'DK', name: $localize`DK-Dánsko` },
    { mpz: 'EE', name: $localize`EE-Estonsko` },
    { mpz: 'EL', name: $localize`EL-Řecko` },
    { mpz: 'ES', name: $localize`ES-Španělsko` },
    { mpz: 'FI', name: $localize`FI-Finsko` },
    { mpz: 'FL', name: $localize`FL-Lichtenštejnsko` },
    { mpz: 'FR', name: $localize`FR-Francie` },
    { mpz: 'GB', name: $localize`GB-Velká Británie` },
    { mpz: 'GE', name: $localize`GE-Gruzie` },
    { mpz: 'HR', name: $localize`HR-Chorvatsko` },
    { mpz: 'HU', name: $localize`HU-Maďarsko` },
    { mpz: 'CH', name: $localize`CH-Švýcarsko` },
    { mpz: 'IE', name: $localize`IE-Irsko` },
    { mpz: 'IT', name: $localize`IT-Itálie` },
    { mpz: 'KZ', name: $localize`KZ-Kazachstán` },
    { mpz: 'LT', name: $localize`LT-Litva` },
    { mpz: 'LU', name: $localize`LU-Lucembursko` },
    { mpz: 'LV', name: $localize`LV-Lotyšsko` },
    { mpz: 'MD', name: $localize`MD-Moldavsko` },
    { mpz: 'ME', name: $localize`ME-Černá hora` },
    { mpz: 'MK', name: $localize`MK-Severní Makedonie` },
    { mpz: 'MT', name: $localize`MT-Malta` },
    { mpz: 'NL', name: $localize`NL-Nizozemsko` },
    { mpz: 'NO', name: $localize`NO-Norsko` },
    { mpz: 'PL', name: $localize`PL-Polsko` },
    { mpz: 'PT', name: $localize`PT-Portugalsko` },
    { mpz: 'RO', name: $localize`RO-Rumunsko` },
    { mpz: 'SM', name: $localize`SM-San Marino` },
    { mpz: 'RU', name: $localize`RU-Rusko` },
    { mpz: 'SE', name: $localize`SE-Švédsko` },
    { mpz: 'SI', name: $localize`SI-Slovinsko` },
    { mpz: 'SK', name: $localize`SK-Slovensko` },
    { mpz: 'RS', name: $localize`RS-Srbsko` },
    { mpz: 'TR', name: $localize`TR-Turecko` },
    { mpz: 'UA', name: $localize`UA-Ukrajina` },
    { mpz: 'XK', name: $localize`XK-Kosovo` }
  ];

  // custom form validator for country
  private checkValidCountry(control: UntypedFormControl): {[key: string]: any} | null {
    // used to be...
    // let validCountries: Array<string> = [
    //   'A', 'AT', 'AL', 'AND', 'B', 'BE', 'BG', 'BIH', 'BY', 'CY', 'CZ', 'DE', 'DK',
    //   'EE', 'EL', 'ES', 'EST', 'F', 'FR', 'FI', 'FIN', 'FL', 'FO', 'GB', 'BGZ', 'GR',
    //   'H', 'HU', 'HR', 'CH', 'I', 'IT', 'IRL', 'IE', 'IS', 'KN', 'L', 'LU', 'LT', 'LV',
    //   'M', 'MC', 'MD', 'MK', 'MNE', 'MT', 'N', 'NL', 'P', 'PT', 'PL', 'RO', 'RSM', 'RUS',
    //   'S', 'SE', 'SK', 'SLO', 'SI', 'SRB', 'TR', 'UA', 'V'
    // ];
    
    let validCountries: Array<string> = [
      'AL', 'AD', 'AM', 'AT', 'AZ', 'BE', 'BG', 'BA', 'BY', 'CY', 'CZ', 
      'DE', 'DK', 'EE', 'EL', 'ES', 'FR', 'FI', 'FL', 'FR', 'GB', 'GE',
      'HU', 'HR', 'CH', 'IT', 'IE', 'KZ', 'LU', 'LT', 'LV', 'ME', 'MD', 'MT', 
      'NL', 'MK', 'PT', 'PL', 'RO', 'SM', 'RU', 'RS', 'SE', 'SK', 'SI', 'TR', 'UA'
    ];

    // OK
    if (control.value && validCountries.includes(control.value)) {
      return null;
    } 
    // error
    else {
      return {countryValidator: {valid: false}};
    }
  }


  /****************************************************************/
  /* Person managing */
  /****************************************************************/
  // managing persons that could be added with new company(address book item)
  private _newPersons: Array<Person> = [];
  public get newPersons(): Array<Person> {
    return this._newPersons;
  }
  public set newPersons(value: Array<Person>) {
    this._newPersons = value;
  }

  // array fo persons that should be removed
  private _personsToRemove: Array<Person> = [];

  // method for default initialization of persons array
  initPersons(): void {
    this._newPersons = [];
    this._personsToRemove = [];
    this.addNewPerson();
  }

  // method for adding default person
  addNewPerson(): void {
    let p = new Person();
    p.importance = 1;
    this._newPersons.push(p);
  }

  // method fo removing selected person
  removeSelectedPerson(p: Person) {
    // save person to be removed
    this._personsToRemove.push(p);
    // remove person from view array
    this._newPersons = this._newPersons.filter(element => element !== p);
  }

  // custom validation of person's mobile
  invalidPersonMobile(p: Person): boolean {
    // length is clear
    if (!p.mobile || p.mobile.length < 18) {
      return false;
    }
    return true;
  }

  // custom validation of person's email
  invalidPersonEmail(p: Person) {
    if (!p.email) {
      return false;
    }
    if (p.email.match(EMAIL_REGEX)) {
      return false;
    }

    return true;
  }

  // create persons -> post requests
  createPersons(book_key: number): void {
    // remove all persons with empty names
    this._newPersons = this._newPersons.filter(element => element.name != null);

    this._newPersons.forEach(
      p => {
        if (this.invalidPersonMobile(p)) {        
          this._notificationServ.alert(
            ($localize`Kontakt` + ' "' + p.name + '" ' +
            $localize`nelze uložit - zadané telefonní číslo je příliš dlouhé.`), 'error', 5000
          );
          return;
        }
        if (this.invalidPersonEmail(p)) {    
          this._notificationServ.alert(
            ($localize`Kontakt` + ' "' + p.name + '" ' +
            $localize`nelze uložit - zadaný email není platný.`), 'error', 5000
          );
          return;
        }

        this._subscribed.push(
          this._addressBookServ.createPerson(book_key, p).subscribe()
        );
      }
    );
  }

  // update or create persons - according to defined person_key
  updatePersons(book_key: number): void {
    // remove all persons with empty names
    this._newPersons = this._newPersons.filter(element => element.name);
    this._newPersons.forEach(
      p => {
        if (this.invalidPersonMobile(p)) {    
          this._notificationServ.alert(
            ($localize`Kontakt` + ' "' + p.name + '" ' +
            $localize`nelze uložit - zadané telefonní číslo je příliš dlouhé.`), 'error', 5000
          );
          return;
        }
        if (this.invalidPersonEmail(p)) {    
          this._notificationServ.alert(
            ($localize`Kontakt` + ' "' + p.name + '" ' +
            $localize`nelze uložit - zadaný email není platný.`), 'error', 5000
          );
          return;
        }

        if (p.person_key) {
          // update person (we have got person_key -> so it has been saved in database)
          this._subscribed.push(
            this._addressBookServ.updatePerson(book_key, p).subscribe()
          );
        }
        else {
          // create new
          this._subscribed.push(
            this._addressBookServ.createPerson(book_key, p).subscribe()
          );
        }
      }
    );


    // remove all persons with empty person_key(these were created and deleted only on client side)
    this._personsToRemove = this._personsToRemove.filter(
      p => p && p.person_key
    );
    this._personsToRemove.forEach(
      p => {
        // delete person
        this._subscribed.push(
          this._addressBookServ.deletePerson(book_key, p).subscribe()
        );
      }
    );
  }

  
  /****************************************************************/
  /* netOrder email/token */
  /****************************************************************/
  public netOrderToken: string = null;

  public netOrderEmailTo: any = '';
  public netOrderSubject: string = '';
  public netOrderText: string = '';
  public signatureEmail: string = '';
  private _personPhone: string = '';
  private _personEmail: string = '';

  // ngbTypeahead autocompleter contact
  // source: https://weblog.west-wind.com/posts/2019/Apr/08/Using-the-ngBootStrap-TypeAhead-Control-with-Dynamic-Data
  // on focus source: https://ng-bootstrap.github.io/#/components/typeahead/examples#focus
  focusNetOrderEmailTo$ = new Subject<string>();
  clickNetOrderEmailTo$ = new Subject<string>();
  @ViewChild('autocompleteNetOrderEmailTo', {static: false}) autocompleteNetOrderEmailTo: NgbTypeahead;
  
  autoCompleteNetOrderEmailTo = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focusNetOrderEmailTo$;
    const inputClick$ = this.clickNetOrderEmailTo$.pipe(filter(() => !this.autocompleteNetOrderEmailTo.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, inputClick$).pipe(
      switchMap( (search) => this._addressBookServ.getContacts(this._currentAddressBookItem.book_key, search))
    );
  }
  // Initially binds the string value and then after selecting an item by checking either for string or key/value object.
  inputFormatListValueNetOrderEmailTo(value: any) {
    if (value.email)
      return value.email;
    return value;
  }
  // these 2 methods are commonly used by both ngbTypeaheads
  // Used to format the result data from the lookup into the display and list values
  resultFormatListNetOrderEmailTo(value: any) {
    let result: string = '';
    result += value.email ? value.email : '';
    result += value.name ? ' (' + value.name + ')' : '';
    return result;
  }
  // save selected order_comp_book
  onSelectNetOrderEmailTo($event: any) {
    if ($event.item) {
      this.netOrderEmailTo = $event.item.email;
    }
  };

  // handler after emitting into contenteditable div 
  public afterTextEmailChange(event: any): void {
    this.netOrderText = event;
  }
  
  // handler after emitting into contenteditable div 
  public afterSignatureEmailChange(event: any): void {
    this.signatureEmail = event;
  }


  get netOrderLink(): string {
    if (this._currentAddressBookItem && this.netOrderToken) {
      return (
        'https://ext2.truckmanager.eu/#/ext-obligation?book_key=' + 
        this._currentAddressBookItem.book_key + '&token=' + this.netOrderToken
      );
    }
    return null;
  }

  generateNetOrderToken(): void {
    if (this._currentAddressBookItem) {
      this._subscribed.push(
        this._obligationServ.createNewObligationToken(this._currentAddressBookItem.book_key).subscribe(
          response => {
            if (response) {
              this.netOrderToken = response;
              this.initNetOrderEmail();
            }
          }
        )
      );
    }
  }

  deleteNetOrderToken(): void {
    if (this._currentAddressBookItem) {
      this._subscribed.push(
        this._addressBookServ.deleteNewObligationToken(this._currentAddressBookItem.book_key).subscribe(
          response => {
            if (response) {
              this.netOrderToken = null;
            }
          }
        )
      );
    }
  }

  initNetOrderEmail(): void {
    if (this._currentAddressBookItem && this._settingsDocs && this._settingsDocs.texts) {
      let country: string = this._currentAddressBookItem.country;
      if (this._settingsDocs.texts.net_order_mail_subject) {
        this.netOrderSubject = this._settingsDocs.texts.net_order_mail_subject[country];
        if (this.netOrderSubject) {
          this.netOrderSubject = this.netOrderSubject.replace(
            '@company/company', this._currentAddressBookItem.company
          );
        }
      }
      if (this._settingsDocs.texts.net_order_mail_text) {
        this.netOrderText = this._settingsDocs.texts.net_order_mail_text[country];
        if (this.netOrderText && this.netOrderToken) {
          this.netOrderText = this.netOrderText.replace('@net-objednavka', this.netOrderLink);
          this.netOrderText = this.netOrderText.replace('@net-order', this.netOrderLink);
        }
      }
      // init email signature
      if (this._currentAddressBookItem && this._settingsDocs && this._settingsDocs.texts && this._settingsDocs.texts.invoice_mail_signature) {
        this.signatureEmail = this._settingsDocs.texts.invoice_mail_signature[this._currentAddressBookItem.country];
        
        if (this.signatureEmail && this._company) {
          this.signatureEmail = this.signatureEmail.replace('@company/company', this._company.company);
          this.signatureEmail = this.signatureEmail.replace('@company/street', this._company.street);
          this.signatureEmail = this.signatureEmail.replace('@company/zip', this._company.zip);
          this.signatureEmail = this.signatureEmail.replace('@company/city', this._company.city);
          this.signatureEmail = this.signatureEmail.replace('@company/country', this._company.country);
        }
      }
    }

    if (this._currentAddressBookItem && this._company) {
      let persons: Array<any> = this._company.personsAsArray;
      persons.sort((a, b) => (a.importance > b.importance) ? 1 : -1);
      // set the most important person as contact on invoice
      let importance: number = persons[0].importance;
      
      let email: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'E');
      if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'S');
      if (!email) email = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'U');
      if (email) this._personEmail = email.value;
      
      let phone: any = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'G');
      if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'T');
      if (!phone) phone = this._company.contactsAsArray.find(x => x.importance == importance && x.type == 'F');
      if (phone) this._personPhone = phone.value;
    }
  }

  sendNetOrderEmail(): void {
    let contactTo: string = null;
    if (this.netOrderEmailTo && this.netOrderEmailTo.email) {
      contactTo = this.netOrderEmailTo.email;
    }
    else {
      contactTo = this.netOrderEmailTo;
    }

    // check if email exists in comp_book
    this._subscribed.push(
      // init contacts/persons
      this._addressBookServ.getContactsToComponent(this._currentAddressBookItem.book_key).subscribe(
        (response: any) => {
          if (response) {
            let persons: Array<Person> = this._addressBookServ.buildPersonsFromData(response);

            // possibly save person/email to user's address book 
            let findOrderPerson: Person = persons.find(p => p.email == contactTo);
            if (!findOrderPerson) {
              let newPerson = new Person();
              newPerson.importance = 1;
              newPerson.email = contactTo;
              if (newPerson.email && newPerson.email.includes('@')) {
                newPerson.name = newPerson.email.substring(0, newPerson.email.indexOf('@'));
              }
              
              this._subscribed.push(
                this._addressBookServ.createPerson(this._currentAddressBookItem.book_key, newPerson).subscribe()
              );
            }
          }
        }
      )
    );
    
    let emailObj: any = {
      from: 'info@truckmanager.eu',
      to: contactTo,
      // cc: ['info@truckmanager.eu'],
      bcc: 'jz@truckmanager.eu', 
      reply_to: 'info@truckmanager.eu',
      subject: this.netOrderSubject,
      body: this.initHtmlEmailBody(),
      content_type: 'text/html'
    };

    this._subscribed.push(
      this._emailingServ.sendEmail(emailObj).subscribe()
    );
  }

  
  private initHtmlEmailBody(): string {
    let html: string = 
    `<html>
    <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <title>` + 
    ((this._company.invoice_address && this._company.invoice_address.company) ? this._company.invoice_address.company : this._company.company) +
    `</title>
    </head>

    <body bgcolor="#f5f7f8" bottommargin="50" lang="cs" topmargin="30" style="font-family: Verdana, Geneva, Tahoma, sans-serif; font-size: 16px">
      <table cellpadding="0" cellspacing="0" bgcolor="#FFFFFF" width="780" align="center" style="box-shadow: 0px 0px 15px -5px grey">

        <!-- Hlavička - logo -->
        <tr>
          <td style="padding: 20px; font-size: 24px; font-weight: bold; background-color:` + 
          ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color2) 
          ? this._settingsDocs.presets.invoice_color2 : '#FF9900') + 
          `; color:` + 
          ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color1) 
          ? this._settingsDocs.presets.invoice_color1 : '#444444') + 
          `">
            ` + this._company.company + `
          </td>
        </tr>

        <!-- Barevná linka -->
        <tr style="height: 3px;" bgcolor="` + 
        ((this._settingsDocs.presets && this._settingsDocs.presets.invoice_color1) 
        ? this._settingsDocs.presets.invoice_color1 : '#444444') + 
        `">
          <td height="3px"></td>
        </tr>
        
        <!-- Předmět -->

        <!-- Text -->
        <tr>
          <td style="padding: 20px 20px 0px 20px; height: 76px;">` + this.netOrderText + `</td>
        </tr>

        <!-- Platební symboly -->

        <!-- Faktura -->

        <!-- Dokumenty -->

        <!-- Podpis -->			
        <tr>
          <td style="padding: 20px 20px 20px 20px;">
            <p>` + this.signatureEmail + `</p>
          </td>
        </tr>

        <!-- Kontakt -->
        <tr bgcolor="#444444">
          <td style="padding: 20px;">
            <font color="#C0C0C0">` +
              this._company.company + `<br>`+
              this._company.street + `<br>`+
              this._company.zip + ' ' + this._company.city + `<br>`+
              this._dictionary['contact'][this._currentAddressBookItem.country] + `<br>` +
              this._dictionary['email'][this._currentAddressBookItem.country] + ': ' + this._personEmail + `<br>`+
              this._dictionary['telephone'][this._currentAddressBookItem.country] + ': ' + this._personPhone +
            `</font>
          </td>
        </tr>
      </table>

      <table style="width: 100%">
        <tr>
          <td align="center" style="font-size:12px">
            <a href="https://truckmanager.eu" target="_blank">
              Tato faktura byla vystavena a odeslána z dopravního a spedičního systému TruckManager &amp; TruckAgenda
            </a>
          </td>
        </tr>
      </table>
    </body>
    </html>`;
    return html;
  }

  
  /************************************************/
  /* Routing */
  /************************************************/
  openAddressBookNewTab(): void {
    let queryParams: any = {
      reloadPossible: true
    }
    let url = this._router.serializeUrl(
      this._router.createUrlTree([{outlets: {left: 'ta2/search'}}], {queryParams: queryParams})
    );
    window.open('#' + url, '_blank');
  }
}
