<div *ngIf="(loadingVies || loadingAres) || simulationReloading" class="container-fluid">
  <div class="text-center">
    <div class="spinner-wrapper py-4">
      <div class="spinner-border text-warning" style="width:3rem; height:3rem;" role="status">
        <span class="sr-only"></span>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loadingVies && !loadingAres && !simulationReloading && !validViesResponse" class="container-fluid py-2">
  <div class="text-center">
    <h6 *ngIf="!viesCompanyFound" class="text-danger" i18n>
      Nepodařilo se vyhledat danou firmu (zkontrolujte DIČ).
    </h6>
  
    <h6 *ngIf="viesUnavailable" class="text-danger">
      <ng-container i18n>Služba</ng-container>
      <span> <a href="https://ec.europa.eu/taxation_customs/vies/#/vat-validation" target="_blank">VIES</a> </span>
      <ng-container i18n>není aktuálně dostupná.</ng-container>
    </h6>

    <!-- <div>
      <button class="btn btn-primary" (click)="validViesResponse=true">Zadat znovu</button>
    </div> -->
  </div>
</div>

<!-- && validViesResponse -->
<div *ngIf="!loadingVies && !loadingAres && !simulationReloading">
  <form [formGroup]="addressBookItemForm" (ngSubmit)="submitAddressBookItem()">
    <div class="col">
      <div class="row">
        <div class="col-12">
          <div class="d-flex bg-warning border rounded shadow my-2 py-1 pl-2">
            <h6 class="m-0 mr-auto">
              <span *ngIf="creationMode" i18n>Vytvoření firmy</span>
              <span *ngIf="!creationMode" i18n>Editace firmy</span>
            </h6>
            
            <div *ngIf="!enableAddressBookUpdate">
              <button class="btn btn-sm btn-primary" role="button" (click)="openAddressBookNewTab()" i18n>
                Upravit v adresáři
              </button>
            </div>

            <div class="red-check px-3">
              <div class="custom-control custom-control-inline custom-checkbox">
                <input class="custom-control-input" type="checkbox" id="blockedCheck" 
                formControlName="blocked"/>
                <label class="custom-control-label mb-0" for="blockedCheck" i18n>
                  Nespolehlivý zákazník
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div [class]="f.country.value == 'SK' ? 'col-sm-4' : 'col-sm-6'">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>IČ *</label>
            <input class="form-control form-control-sm" type="text" formControlName="cin" 
            [ngClass]="{'is-invalid': addressBookItemSubmitted && f.cin.errors}"/>
          </div>
        </div>
        <div [class]="f.country.value == 'SK' ? 'col-sm-4' : 'col-sm-6'">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>DIČ *</label>
            <div class="input-group">
              <input class="form-control form-control-sm" type="text" formControlName="tin" 
              [ngClass]="{'is-invalid': addressBookItemSubmitted && f.tin.errors}"/>
          
              <a class="btn btn-link input-group-append input-group-text" role="button"
              ngbTooltip="Vyhledat podle DIČ" container="body" placement="left" i18n-ngbTooltip
              (click)="findCompany()" href="javascript:void(0)">
                <img src="assets/icons-new/zoom_in.svg" height="17px"/>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="f.country.value == 'SK'" class="col-sm-4">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>IČ DPH</label>
            <input class="form-control form-control-sm" type="text" formControlName="cin_vat" 
            [ngClass]="{'is-invalid': addressBookItemSubmitted && f.cin_vat.errors}"/>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>Firma *</label>
            <input class="form-control form-control-sm" type="text" formControlName="company" 
            [ngClass]="{'is-invalid': addressBookItemSubmitted && f.company.errors}"/>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>Ulice</label>
            <input class="form-control form-control-sm" type="text" formControlName="street"/>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>Město *</label>
            <input class="form-control form-control-sm" type="text" formControlName="city" 
            [ngClass]="{'is-invalid': addressBookItemSubmitted && f.city.errors}"/>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>PSČ *</label>
            <input class="form-control form-control-sm" type="text" formControlName="zip" 
            [ngClass]="{'is-invalid': addressBookItemSubmitted && f.zip.errors}" maxlength="10"/>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group mb-1">
            <label class="normal-font-size mb-0" i18n>Stát *&nbsp;
              <a class="badge badge-pill badge-warning text-center"
              href="javascript:void(0)"
              [ngbPopover]="mpzListPopover"
              container="body" placement="top">?</a>
            </label>
            <ng-template #mpzListPopover>
              <div i18n>Je nutné zadat jednu z následujících povolených MPZ:</div>
              <div>AL, AND, ARM, AT, AZ, BE, BG, BIH, BY, CY, CZ, DE, DK, EE, EL, ES, FL, FR, FI, GB, GE, HU, HR, CH, 
                IT, IE, KZ, LU, LT, LV, MD, MNE, MT, NL, NMK, PT, PL, RO, RSM, RUS, SE, SK, SI, SRB, TR, UA
              </div>
              <!-- puvodne taky
                AL, AND, BIH, BY, F, FIN, FL, FO, 
                BGZ, GR, H, CH, I, IRL, IE, IS, KN, L, M, MC, MD,
                MK, MNE, N, P, RSM, RUS, S,
                SLO, SRB, TR, UA, V -->
            </ng-template>

            <select class="form-control form-control-sm" formControlName="country" 
            [ngClass]="{'is-invalid': addressBookItemSubmitted && f.country.errors}">
              <option [ngValue]="null" i18n>Zvolte stát</option>
              <option *ngFor="let country of COUNTRIES" [value]="country.mpz">
                {{country.name}}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <div role="tablist" id="accordion-inner">
            <!-- Kontakt -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-contact" 
                  href="#accordion-inner .item-contact" i18n>Kontakt</a>
                </h6>
              </div>
              <div id="collapseID2" class="collapse item-contact" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div *ngFor="let person of newPersons">
                    <div class="row">
                      <div class="col-sm-6">
                        <div class="form-group mb-1">
                          <label class="mb-1 text-nowrap">
                            <ng-container i18n>Příjmení*</ng-container> &nbsp;
                            <a class="badge badge-pill badge-warning text-center"
                            href="javascript:void(0)"
                            ngbPopover="Kontakty, kde nebude zadáno příjmení, nebudou uloženy."
                            container="body" placement="right" i18n-ngbPopover>?</a>
                          </label>
                          <div class="input-group">
                            <input type="text" class="form-control form-control-sm"
                            [(ngModel)]="person.name" [ngModelOptions]="{standalone: true}"/>
                            <div class="input-group-append">
                              <button class="btn btn-sm btn-danger" type="button"
                              ngbTooltip="Smazat celý řádek kontaktu" i18n-ngbTooltip
                              container="body" placement="left"
                              (click)="removeSelectedPerson(person)">X</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group mb-1">
                          <label class="mb-1" i18n>Funkce</label>
                          <select type="text" class="form-control form-control-sm"
                          [(ngModel)]="person.position" [ngModelOptions]="{standalone: true}">
                            <option [ngValue]="null"></option>
                            <option [value]="'M'" i18n>Jednatel/Vedoucí</option>
                            <option [value]="'I'" i18n>Účetní/Fakturant</option>
                            <option [value]="'D'" i18n>Dispečer</option>
                            <option [value]="'S'" i18n>Speditér</option>
                            <option [value]="'O'" i18n>Ostatní</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group mb-1">
                          <label class="mb-1" i18n>E-mail</label>
                          <input type="email" class="form-control form-control-sm"
                          [(ngModel)]="person.email" [ngModelOptions]="{standalone: true}"
                          (ngModelChange)="emailTruncate(person)"/>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group mb-1">
                          <label class="mb-1" i18n>Tel. (mobil)</label>
                          <input type="text" class="form-control form-control-sm"
                          [class.border-danger]="invalidPersonMobile(person)"
                          [(ngModel)]="person.mobile" [ngModelOptions]="{standalone: true}"/>
                        </div>
                      </div>
                      <div class="col-sm-4">
                        <div class="form-group mb-1">
                          <label class="mb-1" i18n>Tel. (pevná linka)</label>
                          <input type="text" class="form-control form-control-sm"
                          [(ngModel)]="person.phone" [ngModelOptions]="{standalone: true}"/>
                        </div>
                      </div>
                    </div>
                    <hr class="my-1" style="border-top: dashed 1px;"/>
                  </div>
                  
                  <div class="d-flex justify-content-end my-2">
                    <button class="btn btn-primary" type="button" 
                    (click)="addNewPerson()" i18n>Přidat kontakt</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- Bankovni spojeni -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-bank" 
                  href="#accordion-inner .item-bank" i18n>Bankovní spojení</a>
                </h6>
              </div>
              <div id="collapseID3" class="collapse item-bank" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Banka</label>
                        <input type="text" class="form-control form-control-sm" 
                        [(ngModel)]="currentAddressBookItem.bank" [ngModelOptions]="{standalone: true}"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Splatnost faktur</label>
                        <input type="number" class="form-control form-control-sm" 
                        placeholder="např. 60" min="0" [(ngModel)]="currentAddressBookItem.maturity"
                        [ngModelOptions]="{standalone: true}"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Číslo účtu</label>
                        <input type="text" class="form-control form-control-sm" formControlName="account"
                        [ngClass]="{'is-invalid': addressBookItemSubmitted && f.account.errors}"
                        maxlength="34"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0">SWIFT</label>
                        <input type="text" class="form-control form-control-sm" formControlName="swift" 
                        [ngClass]="{'is-invalid': addressBookItemSubmitted && f.swift.errors}"
                        maxlength="12"/>
                      </div>
                    </div>
                    <div class="col-sm-12">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0">IBAN</label>
                        <input type="text" class="form-control form-control-sm" formControlName="iban" 
                        [ngClass]="{'is-invalid': addressBookItemSubmitted && f.iban.errors}"
                        (keyup)="ibanFormatting()" maxlength="40"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Dalsi adresa -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" 
                  aria-controls="accordion-inner .item-address" 
                  href="#accordion-inner .item-address" i18n>Další adresa</a>
                </h6>
              </div>
              <div id="collapseID4" class="collapse item-address" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Firma</label>
                        <input class="form-control form-control-sm" type="text"
                        [(ngModel)]="currentAddressBookItem.company_invoice" 
                        [ngModelOptions]="{standalone: true}"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Ulice</label>
                        <input class="form-control form-control-sm" type="text"
                        [(ngModel)]="currentAddressBookItem.street_invoice" 
                        [ngModelOptions]="{standalone: true}"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Město</label>
                        <input class="form-control form-control-sm" type="text"
                        [(ngModel)]="currentAddressBookItem.city_invoice" 
                        [ngModelOptions]="{standalone: true}"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>PSČ</label>
                        <input class="form-control form-control-sm" type="text"
                        [(ngModel)]="currentAddressBookItem.zip_invoice" 
                        [ngModelOptions]="{standalone: true}" maxlength="10"/>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group mb-1">
                        <label class="normal-font-size mb-0" i18n>Stát&nbsp;
                          <a class="badge badge-pill badge-warning text-center"
                          href="javascript:void(0)"
                          [ngbPopover]="mpzListPopover"
                          container="body" placement="top">?</a>
                        </label>
                        <ng-template #mpzListPopover>
                          <div i18n>Je nutné zadat jednu z následujících povolených MPZ:</div>
                          <div>AL, AND, ARM, AT, AZ, BE, BG, BIH, BY, CY, CZ, DE, DK, EE, EL, ES, FL, FR, FI, GB, GE, HU, HR, CH, 
                            IT, IE, KZ, LU, LT, LV, MD, MNE, MT, NL, NMK, PT, PL, RO, RSM, RUS, SE, SK, SI, SRB, TR, UA
                          </div>
                          <!-- puvodne taky
                            AL, AND, BIH, BY, F, FIN, FL, FO, 
                            BGZ, GR, H, CH, I, IRL, IE, IS, KN, L, M, MC, MD,
                            MK, MNE, N, P, RSM, RUS, S,
                            SLO, SRB, TR, UA, V -->
                        </ng-template>
            
                        <select class="form-control form-control-sm"
                        [(ngModel)]="currentAddressBookItem.country_invoice" 
                        [ngModelOptions]="{standalone: true}">
                          <option [ngValue]="null" i18n>Zvolte stát</option>
                          <option *ngFor="let country of COUNTRIES" [value]="country.mpz">
                            {{country.name}}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Spedicni databanky -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-databank" 
                  href="#accordion-inner .item-databank" i18n>Spediční databanky</a>
                </h6>
              </div>
              <div id="collapseID5" class="collapse item-databank" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <p class="normal-font-size" i18n>Vložte ID firmy</p>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="width: 110px;">RAALTRANS</span>
                        </div>
                        <input class="form-control" type="text" disabled>
                      </div>
                    </div>
                    <div class="col-xl-12 mt-2">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="width: 110px;">TIMOCOM</span>
                        </div>
                        <input class="form-control" type="text" disabled>
                      </div>
                    </div>
                    <div class="col-xl-12 mt-2">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text" style="width: 110px;">TRANS.EU</span>
                        </div>
                        <input class="form-control" type="text" disabled>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Elektronická dokumentace -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-edoc" 
                  href="#accordion-inner .item-edoc" i18n>Elektronická dokumentace</a>
                </h6>
              </div>
              <div id="collapseID6" class="collapse item-edoc" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkEdocAgreement"
                        [(ngModel)]="currentAddressBookItem.edoc_required_agreement" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkEdocAgreement" i18n>
                          Souhlas
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkEdocItinerary"
                        [(ngModel)]="currentAddressBookItem.edoc_required_itinerary" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkEdocItinerary" i18n>
                          Status - dokončení nakl./vykl.
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkEdocDocuments"
                        [(ngModel)]="currentAddressBookItem.edoc_required_documents" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkEdocDocuments" i18n>
                          Dokumenty
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkEdocInvoice"
                        [(ngModel)]="currentAddressBookItem.edoc_required_invoice" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkEdocInvoice" i18n>
                          Faktura
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Zvlastni nastaveni -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-settings" 
                  href="#accordion-inner .item-settings" i18n>Zvláštní nastavení</a>
                </h6>
              </div>
              <div id="collapseID6" class="collapse item-settings" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkDocuments"
                        [(ngModel)]="currentAddressBookItem.original_documents" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkDocuments" i18n>
                          Zasílat originální dokumentaci poštou
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkSMS"
                        [(ngModel)]="currentAddressBookItem.notify_sms" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkSMS" i18n>
                          Zasílat informace o provedených nakl/vykl na SMS
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkEmail"
                        [(ngModel)]="currentAddressBookItem.notify_email" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkEmail" i18n>
                          Zasílat informace o provedených nakl/vykl na e-mail
                        </label>
                      </div>
                    </div>
                    <div class="col-xl-12">
                      <div class="custom-control custom-control-inline custom-switch">
                        <input class="custom-control-input" type="checkbox" id="checkTracking"
                        [(ngModel)]="currentAddressBookItem.customer_tracking" 
                        [ngModelOptions]="{standalone: true}"/>
                        <label class="custom-control-label" for="checkTracking" i18n>
                          Zasílat zákaznické sledování
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Vozovy park -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-cars" 
                  href="#accordion-inner .item-cars" i18n>Vozový park</a>
                </h6>
              </div>
              <div id="collapseID7" class="collapse item-cars" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="font-weight-bold" i18n>Typ vozidel</div>
                      <div *ngFor="let car_type of currentAddressBookItem.car_dest.types">
                        - {{currentAddressBookItem.car_dest.CAR_DEST_TYPES[car_type]}}
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="font-weight-bold" i18n>Velikost vozidel</div>
                      <div *ngFor="let car_size of currentAddressBookItem.car_dest.sizes">
                        - {{currentAddressBookItem.car_dest.CAR_DEST_SIZES[car_size]}}
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="font-weight-bold" i18n>Destinace / relace</div>
                      <div *ngFor="let country of currentAddressBookItem.car_dest.countries">
                        - {{currentAddressBookItem.car_dest.CAR_DEST_COUNTRIES[country]}}
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="font-weight-bold">
                        ADR: {{currentAddressBookItem.car_dest.adr}}
                      </div>
                      <div class="font-weight-bold">
                        <ng-container i18n>Tonáž</ng-container>:
                        {{currentAddressBookItem.car_dest.tonnage}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- netObjednavka -->
            <div class="card">
              <div class="card-header py-1 pl-2" role="tab">
                <h6 class="mb-0" style="font-size: 17px;">
                  <a data-toggle="collapse" aria-expanded="false" aria-controls="accordion-inner .item-net-order" 
                  href="#accordion-inner .item-net-order" i18n>net-Objednávka</a>
                </h6>
              </div>
              <div id="collapseID8" class="collapse item-net-order" role="tabpanel" data-parent="#accordion-inner">
                <div class="card-body py-1">
                  <div *ngIf="!netOrderToken" class="row">
                    <div class="col-12 my-2">
                      <button class="btn btn-success w-100" type="button" (click)="generateNetOrderToken()" i18n>
                        Vytvořit povolení
                      </button>
                    </div>
                  </div>
                  <div *ngIf="netOrderToken" class="row">
                    <div class="col-12">
                      <div class="form-group mb-1">
                        <label class="mb-1" i18n>Příjemce emailu</label>
                        <input class="form-control form-control-sm"
                        placeholder="Zvolte nebo napište"
                        [(ngModel)]="netOrderEmailTo" [ngModelOptions]="{standalone: true}"
                        (ngModelChange)="netOrderEmailTo = emailTruncate(netOrderEmailTo)"
                        [ngbTypeahead]="autoCompleteNetOrderEmailTo"
                        [resultFormatter]="resultFormatListNetOrderEmailTo"
                        [inputFormatter]="inputFormatListValueNetOrderEmailTo"
                        (selectItem)="onSelectNetOrderEmailTo($event);"
                        (focus)="focusNetOrderEmailTo$.next($event.target.value)"
                        (click)="clickNetOrderEmailTo$.next($event.target.value)"
                        #autocompleteNetOrderEmailTo="ngbTypeahead"/>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-1">
                        <label class="mb-1" i18n>Předmět emailu</label>
                        <input class="form-control form-control-sm" 
                        [(ngModel)]="netOrderSubject" [ngModelOptions]="{standalone: true}"/>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-1">
                        <label class="mb-1" i18n>Text emailu</label>
                        <div class="p-1 border rounded content-editable" style="height:120px; overflow: auto"
                        contenteditable="true"
                        [contenteditableHtml]="true"
                        [contenteditableModel]="netOrderText"
                        (contenteditableModelChange)="afterTextEmailChange($event)"></div>
                        <!-- <textarea class="form-control form-control-sm" rows="8"
                        [(ngModel)]="netOrderText" [ngModelOptions]="{standalone: true}">
                        </textarea> -->
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group mb-1">
                        <label class="mb-1" i18n>Váš podpis</label>
                        <div class="p-1 border rounded content-editable" style="height:100px; overflow: auto"
                        contenteditable="true"
                        [contenteditableHtml]="true"
                        [contenteditableModel]="signatureEmail"
                        (contenteditableModelChange)="afterSignatureEmailChange($event)"></div>
                      </div>
                    </div>

                    <div class="col-sm-4 my-2">
                      <button class="btn btn-success w-100" type="button" (click)="sendNetOrderEmail()" 
                      [disabled]="!netOrderEmailTo || !netOrderSubject || !netOrderText" i18n>
                        Odeslat povolení
                      </button>
                    </div>
                    <div class="col-sm-4 my-2">
                      <a class="btn btn-warning w-100" type="button" [href]="netOrderLink" target="_blank" i18n>
                        Náhled
                      </a>
                    </div>
                    <div class="col-sm-4 mt-2">
                      <button class="btn btn-danger w-100" type="button" (click)="deleteNetOrderToken()" i18n>
                        Odebrat povolení
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row my-2">
        <div class="col">
          <div class="d-flex justify-content-between">
            <div>
              <span class="text-danger" i18n>* Před uložením firmy zkontrolujte všechny zadané údaje</span>
            </div>
            <div>
              <button *ngIf="creationMode" class="btn btn-success ml-1" type="submit" i18n>
                Vytvořit
              </button>
              <button *ngIf="!creationMode" class="btn btn-primary ml-1" type="submit" i18n>
                Uložit změny
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- Modal for confirmation if findCompany option has been chosen -->
<div class="modal fade" role="dialog" tabindex="-1" id="confirmFindBookItemModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>Firma již existuje</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="py-3">
          <h5>
            <span i18n>Firma s DIČ</span><span>&nbsp;</span>
            <span><b>{{currentAddressBookItem.tin}}</b></span><span>&nbsp;</span>
            <span i18n>již v adresáři existuje.</span>
          </h5>
          <h5 i18n>Opravdu si přejete pokračovat?</h5>
        </div>

        <h6 class="text-bold mb-2" i18n>Nalezené firmy</h6>
        <div class="table-responsive normal-font-size">
          <table class="table table-sm">
          <thead class="text-bold">
            <tr>
              <th i18n>Firma</th>
              <th i18n>IČ</th>
              <th i18n>DIČ</th>
              <th i18n>Ulice</th>
              <th i18n>Město</th>
              <th i18n>PSČ</th>
              <th i18n>Stát</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of alreadyExistingItems">
            <td>{{item.company}}</td>
            <td>{{item.cin}}</td>
            <td>{{item.tin}}</td>
            <td>{{item.street}}</td>
            <td>{{item.city}}</td>
            <td>{{item.zip}}</td>
            <td>{{item.country}}</td>
            </tr>
          </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-lg btn-outline-secondary" type="button" data-dismiss="modal" i18n>
          Zrušit
        </button>
        <button class="btn btn-lg btn-success" type="button" 
        (click)="sendFindRequest()" data-dismiss="modal" i18n>
          Pokračovat
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal for confirmation if creationCompany option has been chosen -->
<div class="modal fade" role="dialog" tabindex="-1" id="confirmBookItemModal">
  <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-warning shadow">
        <h4 class="modal-title" i18n>Firma již existuje</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="py-3">
          <h5>
            <span i18n>Firma s DIČ</span><span>&nbsp;</span>
            <span><b>{{currentAddressBookItem.tin}}</b></span><span>&nbsp;</span>
            <span i18n>již v adresáři existuje.</span>
          </h5>
          <h5 i18n>Opravdu si přejete vytvořit firmu se zadanými údaji?</h5>
        </div>

        <h6 class="text-bold mb-2" i18n>Nalezené firmy</h6>
        <div class="table-responsive normal-font-size">
          <table class="table table-sm">
            <thead class="text-bold">
            <tr>
              <th i18n>Firma</th>
              <th i18n>IČ</th>
              <th i18n>DIČ</th>
              <th i18n>Ulice</th>
              <th i18n>Město</th>
              <th i18n>PSČ</th>
              <th i18n>Stát</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of alreadyExistingItems">
                <td>{{item.company}}</td>
                <td>{{item.cin}}</td>
                <td>{{item.tin}}</td>
                <td>{{item.street}}</td>
                <td>{{item.city}}</td>
                <td>{{item.zip}}</td>
                <td>{{item.country}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-lg btn-outline-secondary" type="button" data-dismiss="modal" i18n>
          Zrušit
        </button>
        <button class="btn btn-lg btn-success" type="button" 
        (click)="sendCreateRequest()" data-dismiss="modal" i18n>
          Vytvořit
        </button>
      </div>
    </div>
  </div>
</div>