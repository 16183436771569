import { ItineraryType } from "../config";
import { Itinerary } from "./itinerary.object";

export class ItineraryDiaryObligationCollection {

  get itineraries(): Array<Itinerary> {
    return this._itineraries;
  }
  set itineraries(value: Array<Itinerary>) {
    this._itineraries = value;
  }

  isIn(itinerary: Itinerary): boolean {
    return this._itineraries.indexOf(itinerary) > -1;
  }

  // array of obligation_keys that are included in yesterday,today,tomorrow
  // private _obligation_keys: Array<number> = [];
  // public get obligation_keys(): Array<number> {
  //   return this._obligation_keys;
  // }
  // public set obligation_keys(value: Array<number>) {
  //   this._obligation_keys = value;
  // }

  private _sortedItineraryHour: Array<any> = [];
  public get sortedItineraryHour(): Array<any> {
    return this._sortedItineraryHour;
  }
  
  private _sortedItineraryObligation: Array<any> = [];
  public get sortedItineraryObligation(): Array<any> {
    return this._sortedItineraryObligation;
  }

  constructor(
    private _itineraries: Array<Itinerary>,
    private _date: Date = new Date()
  ) {
    this.buildCollection();
  }
  
  // method for building whole collection again from itineraries array
  buildCollection(): void {
    let day_start: Date = new Date(this._date.getTime());
    day_start.setHours(0, 0, 0, 0);
    let day_end: Date = new Date(this._date.getTime());
    day_end.setHours(23, 59, 59, 999);

    let TYPES: Array<string> = [
      ItineraryType.LOADING, ItineraryType.UNLOADING,
      ItineraryType.WAREHOUSE, ItineraryType.WAREHOUSE_AUTO,
      ItineraryType.TRANSSHIPMENT, ItineraryType.CUSTOMS
    ];
    this._itineraries.forEach(
      it => {
        if (
          !it.arrival_time || !TYPES.includes(it.type) ||
          it.arrival_time.getTime() < day_start.getTime() ||
          it.arrival_time.getTime() > day_end.getTime()
        ) {
          // ignore and continue loop
          return true;
        }

        // create byHour collection
        let hour = it.arrival_time.getHours();
        this.pushToByDayCollection(it, hour);

        // create byObligation collection
        this.pushToByDayObligationCollection(it);

        // saving obligation key, then remove duplicates
        // this._obligation_keys.push(it.obligation_key);
      }
    );

    this.sortCollection();
  }

  private pushToByDayCollection(itinerary: Itinerary, hour: number) {
    // let lookup: Array<any>;
    // switch (true) {
    //   case itinerary.arrival_time < yesterday:
    //     lookup = this._byDay.yesterday;
    //     break;
    //   case itinerary.arrival_time > tomorrow:
    //     lookup = this._byDay.tomorrow;
    //     break;
    //   default:
    //     lookup = this._byDay.today;
    //     break;
    // }
    let found = false;
    this._sortedItineraryHour.forEach(
      byHour => {
        if (byHour.hour === hour) {
          byHour.itineraries.push(itinerary);
          found = true;
        }
      }
    );
    if (!found) {
      this._sortedItineraryHour.push({
        hour: hour,
        hourHuman: hour < 10 ? '0' + hour : hour,
        itineraries: [itinerary],
        first: itinerary // save first pushed itinerary
      });
    }
  }

  private pushToByDayObligationCollection(itinerary: Itinerary) {
    // let lookup: Array<any>;
    // switch (true) {
    //   case itinerary.arrival_time < yesterday:
    //     lookup = this._byDayObligation.yesterday;
    //     break;
    //   case itinerary.arrival_time > tomorrow:
    //     lookup = this._byDayObligation.tomorrow;
    //     break;
    //   default:
    //     lookup = this._byDayObligation.today;
    //     break;
    // }
    let found = false;
    this._sortedItineraryObligation.forEach(
      byObligation => {
        if (byObligation.obligation_key === itinerary.obligation_key) {
          byObligation.itineraries.push(itinerary);
          found = true;
        }
      }
    );
    if (!found) {
      this._sortedItineraryObligation.push({
        car_key: itinerary.car_key,
        obligation_key: itinerary.obligation_key,
        itineraries: [itinerary],
        first: itinerary // save first pushed itinerary
      });
    }
  }

  // method for building whole collection again from itineraries array
  /* buildCollection(): void {
    this._byDay = {
      yesterday: [],
      today: [],
      tomorrow: []
    };

    this._byDayObligation = {
      yesterday: [],
      today: [],
      tomorrow: []
    };

    let now = new Date();
    let yesterday = new Date();
    let tomorrow = new Date();
    tomorrow.setHours(23, 59, 59, 999);
    yesterday.setHours(0, 0, 0, 0);
    let day = 24 * 60 * 60 * 1000;

    this._itineraries.forEach(
      it => {
        if (
          !it.arrival_time ||
          (it.type != ItineraryType.LOADING && it.type != ItineraryType.UNLOADING && it.type != ItineraryType.WAREHOUSE && it.type != ItineraryType.WAREHOUSE_AUTO && it.type != ItineraryType.TRANSSHIPMENT) ||
          it.arrival_time.getTime() < (yesterday.getTime() - day) ||
          it.arrival_time.getTime() > (tomorrow.getTime() + day)
        ) {
          // ignore and continue loop
          return true;
        }

        // create byHour collection
        let hour = it.arrival_time.getHours();
        this.pushToByDayCollection(it, yesterday, tomorrow, hour);

        // create byObligation collection
        this.pushToByDayObligationCollection(it, yesterday, tomorrow);

        // saving obligation key, then remove duplicates
        // this._obligation_keys.push(it.obligation_key);
      }
    );

    // remove duplicates from obligation_keys array
    // this._obligation_keys = this._obligation_keys.filter(
    //   (item, index) => this._obligation_keys.indexOf(item) === index
    // );

    // sort and mark
    this.sortCollection();
  } */

  // sorting by arrival_time/number_plate
  sortCollection(): void {
    let itinerarySorting = (a: Itinerary, b: Itinerary) => {
      let result = 0;
      if (a.arrival_time > b.arrival_time) {
        result = 1;
      } else if (a.arrival_time < b.arrival_time) {
        result = -1;
      }
      return result;
    };

    // sorting for hours
    let sortingHour = (a, b) => {
      a.itineraries.sort(itinerarySorting);
      b.itineraries.sort(itinerarySorting);
      if (a.hour > b.hour) {
        return 1;
      } else if (a.hour < b.hour) {
        return -1;
      } else {
        return 0;
      }
    };
    this._sortedItineraryHour.sort(sortingHour);
    /*
    this._byDay.yesterday.sort(sortingHour);
    this._byDay.tomorrow.sort(sortingHour);
    this._byDay.today.sort(sortingHour);
    */

    // sorting for obligation
    let sortingObligation = (a, b) => {
      a.itineraries.sort(itinerarySorting);
      b.itineraries.sort(itinerarySorting);
      if (a.obligation_key > b.obligation_key) {
        return 1;
      } else if (a.obligation_key < b.obligation_key) {
        return -1;
      } else {
        return 0;
      }
    };
    this._sortedItineraryObligation.sort(sortingObligation);
    /*
    this._byDayObligation.yesterday.sort(sortingObligation);
    this._byDayObligation.tomorrow.sort(sortingObligation);
    this._byDayObligation.today.sort(sortingObligation);
    */

    // marking css
    this.markByHour();
  }


  /* Solution for sorting according to hours  */
  markByHour(): void {
    let counter;
    let marking = () => {
      counter = 0;
      return (a, index) => {
        a.itineraries.map(innerMarking());
      };
    };
    let innerMarking = () => {
      return (a) => {
        if (counter % 2 === 0) {
          a.css_class = 'even';
        } else {
          a.css_class = 'odd';
        }
        counter++;
      }
    };

    this._sortedItineraryHour.map(marking);
    /*
    this._byDay.yesterday.map(marking());
    this._byDay.today.map(marking());
    this._byDay.tomorrow.map(marking());
    */
  }


  /* Solution for sorting according to number plates */
  markByObligation(): void {
    let counter;
    let marking = () => {
      counter = 0;
      return (a, index) => {
        a.itineraries.map(innerMarking());
      };
    };
    let innerMarking = () => {
      return (a) => {
        if (counter % 2 === 0) {
          a.css_class = 'even';
        } else {
          a.css_class = 'odd';
        }
        counter++;
      }
    };

    this._sortedItineraryObligation.map(marking);
    /*
    this._byDayObligation.yesterday.map(marking());
    this._byDayObligation.today.map(marking());
    this._byDayObligation.tomorrow.map(marking());
    */
  }

  /*
  private _byDay: any = {
    yesterday: [],
    today: [],
    tomorrow: []
  };
  get byDay(): any {
    return this._byDay;
  }

  private pushToByDayCollection(itinerary: Itinerary, yesterday: Date, tomorrow: Date, hour: number) {
    let lookup: Array<any>;
    switch (true) {
      case itinerary.arrival_time < yesterday:
        lookup = this._byDay.yesterday;
        break;
      case itinerary.arrival_time > tomorrow:
        lookup = this._byDay.tomorrow;
        break;
      default:
        lookup = this._byDay.today;
        break;
    }
    let found = false;
    lookup.forEach(
      byHour => {
        if (byHour.hour === hour) {
          byHour.itineraries.push(itinerary);
          found = true;
        }
      }
    );
    if (!found) {
      lookup.push({
        hour: hour,
        hourHuman: hour < 10 ? '0' + hour : hour,
        itineraries: [itinerary],
        first: itinerary // save first pushed itinerary
      })
    }
  } */

    
  /* private _byDayObligation: any = {
    yesterday: [],
    today: [],
    tomorrow: []
  };
  get byDayObligation(): any {
    return this._byDayObligation;
  }

  private pushToByDayObligationCollection(itinerary: Itinerary, yesterday: Date, tomorrow: Date) {
    let lookup: Array<any>;
    switch (true) {
      case itinerary.arrival_time < yesterday:
        lookup = this._byDayObligation.yesterday;
        break;
      case itinerary.arrival_time > tomorrow:
        lookup = this._byDayObligation.tomorrow;
        break;
      default:
        lookup = this._byDayObligation.today;
        break;
    }
    let found = false;
    lookup.forEach(
      byObligation => {
        if (byObligation.obligation_key === itinerary.obligation_key) {
          byObligation.itineraries.push(itinerary);
          found = true;
        }
      }
    );
    if (!found) {
      lookup.push({
        car_key: itinerary.car_key,
        obligation_key: itinerary.obligation_key,
        itineraries: [itinerary],
        first: itinerary // save first pushed itinerary
      })
    }
  } */
}