import { AfterContentInit, AfterViewChecked, Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, Subscription } from "rxjs";
import { DestinationRoutesService } from "../../service/destination-routes.service";
import { GoogleMapsApiService } from "../../service/google-maps-api.service";
// import { VehicleService } from "../../service/vehicle.service";
import { VehicleNewService } from "src/app/service/vehicle-new.service";
import { StorageService } from "../../service/storage.service";
import { ManualTrackingTargetInterface } from "../../interface/manual-tracking-target.interface";
import { DestinationRouteDataInterface } from "../../interface/destination-route-data.interface";
// import { GoogleMapMarker } from "../../model/google-map-marker.object";
import { GoogleMapAdvMarker } from "src/app/model/google-map-adv-marker.object";
import { GeoPosition } from "../../model/geo-position.object";
import { Vehicle } from "../../model/vehicle.object";
import { DateTools } from "../../tools/DateTools";
import { Numbers } from "../../tools/Numbers";
import { ExternalApiRequestService } from "src/app/service/external-api-request.service";
import { ExternalApiRequest } from "src/app/model/external-api-request.object";

declare var google: any;

@Component({
  selector: 'vehicle-manual-tracking-configuration',
  templateUrl: './r-vehicle-manual-tracking-configuration.component.html',
  styleUrls: ['./r-vehicle-manual-tracking-configuration.component.css'],
  providers: [
    VehicleNewService
  ]
})
export class RVehicleManualTrackingConfigurationComponent implements AfterContentInit, AfterViewChecked {
  static STORAGE_KEY = 'vehicleManualTrackings';

  private _subscribed: Array<Subscription> = [];

  private carKey: number;
  private temporaryTrackingTarget: ManualTrackingTargetInterface;
  private temporaryTrackingWaypoints: Array<any> = [];
  private autocomplete: any;

  public address: string = '';
  @Input()
  set initialSearch(value: string) {
    this.address = value;
  }

  private _vehicle: Vehicle;
  get vehicle(): Vehicle {
    return this._vehicle;
  }
  @Input()
  set vehicle(vehicle: Vehicle) {
    this._vehicle = vehicle;
    if (vehicle.temporaryManualTracking.length) {
      let startMarker: GoogleMapAdvMarker = new GoogleMapAdvMarker();
      startMarker.position = vehicle.temporaryManualTracking[0].position;
      startMarker.icon = vehicle.temporaryManualTracking[0].icon;
      let endMarker: GoogleMapAdvMarker = new GoogleMapAdvMarker();
      endMarker.position = vehicle.temporaryManualTracking[1].position;
      endMarker.icon = vehicle.temporaryManualTracking[1].icon;
      this._markers = [endMarker, startMarker];
    } 
    else {
      this._markers = [this.vehicle.getCurrentPositionMarker()]
    }
  }

  private _targetTolerance: number = 2;
  get targetTolerance(): number {
    return this._targetTolerance;
  }
  set targetTolerance(value: number) {
    this._targetTolerance = value;
    if (this._vehicle.temporaryManualTrackingTarget) {
      this._vehicle.temporaryManualTrackingTarget.targetTolerance = this._targetTolerance;
    }
  }

  private _errorMessage: string;
  get errorMessage(): string {
    return this._errorMessage;
  }

  private _markers: Array<GoogleMapAdvMarker> = [];
  get markers(): Array<GoogleMapAdvMarker> {
    return this._markers;
  }
  
  get currentMarker(): GoogleMapAdvMarker {
    return this._markers.length ? this._markers[0] : null;
  }

  get destMarker(): GoogleMapAdvMarker {
    return (this._markers.length > 1) ? this._markers[1] : null;
  }

  private _createDirectionDestRoute: boolean = false;
  public get createDirectionDestRoute(): boolean {
    return this._createDirectionDestRoute;
  }

  private _rebuildMapSubject: Subject<boolean> = new Subject<boolean>();
  public get rebuildMapSubject(): Observable<boolean> {
    return this._rebuildMapSubject.asObservable();
  }

  private _mapProperties: any = {
    scrollwheel: true
  };
  get mapProperties(): any {
    return this._mapProperties;
  }

  private _loading: boolean = false;
  get loading(): boolean {
    return this._loading;
  }

  constructor(
    private _destinationRoutesService: DestinationRoutesService,
    private _externalApiRequestServ: ExternalApiRequestService,
    private _googleMapsService: GoogleMapsApiService,
    private _vehicleService: VehicleNewService,
    private _storage: StorageService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    // layout.isDashboardFullMode = true;
  }

  ngOnInit() {
    this._subscribed.push(
      this._route.params.subscribe(
        params => {
          this.carKey = parseInt(params['vehicleId']);
          if (params['coordinates']) {
            this.address = params['coordinates'];
          }

          if (!this.vehicle) {
            this._subscribed.push(
              this._vehicleService.getVehicleCache(this.carKey, true).subscribe(
                vehicle => {
                  if (vehicle && vehicle.car_key == this.carKey) {
                    if (!vehicle.latestServiceEventsLazyLoad) {
                      vehicle.latestServiceEventsLazyLoad = this._vehicleService.createLatestServiceEventsLazyload(vehicle);
                    }
                    if (!vehicle.trackingDataLazyload) {
                      vehicle.trackingDataLazyload = this._vehicleService.createVehicleTrackingEventsLazyload(vehicle);
                    }
                    this.vehicle = vehicle;

                    // reinit and rebuild
                    this.address = '';
                    if (this._markers.length == 2) {
                      // remove last marker (dest route marker)
                      this._markers = this._markers.splice(-1);
                      this._createDirectionDestRoute = false;
                    }
                    this._rebuildMapSubject.next(true);

                    // if (this.address) {
                    //   this.search();
                    // }
                  }
                }
              )
            );
          }
          else {
            this._vehicleService.getVehicleCache(this.carKey, true).subscribe()
          }
        }
      )
    )
  }

  ngOnDestroy() {
    this._subscribed.forEach(
      subscription => subscription.unsubscribe()
    );
    // this.layout.isDashboardFullMode = false;
  }

  ngAfterContentInit() {
    if (this.address.trim().length > 0 && this._vehicle instanceof Vehicle) {
      this.search();
    }
  }

  ngAfterViewChecked() {
    if (!this.autocomplete) {
      let input = document.getElementById('search-input');
      if (input instanceof HTMLInputElement) {
        this.createAutocomplete(input);
      }
    }
  }

  static createKey(vehicle: Vehicle): string {
    return RVehicleManualTrackingConfigurationComponent.STORAGE_KEY + '::' + vehicle.car_key;
  }

  get mapClick() {
    return (clickData) => {
      if (this._markers) {
        if (this._markers.length > 1) {
          this._markers[1].position = clickData.latLng;
          this._markers = [ this._markers[0], this._markers[1] ];
        } 
        else {
          // api request log call
          let api_request_log: ExternalApiRequest = new ExternalApiRequest();
          api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
          api_request_log.type = 'geocoding';
          api_request_log.descr = 'vehicle-manual-tracking-configuration';
          api_request_log.price = 0.005;
          this._externalApiRequestServ.createRequestLog(api_request_log);

          this._googleMapsService.geocode(clickData.latLng.lat() + ',' + clickData.latLng.lng(), 
            (result, status) => {
              // console.log(result, status);
              if (status === 'OK') {
                let closestMatch: string = GoogleMapsApiService.getLocationClosesMatch(result);
                if (!closestMatch) {
                  closestMatch = Numbers.round(clickData.latLng.lat(), 4).toString() + ',' + Numbers.round(clickData.latLng.lng(), 4).toString();
                }

                this.address = closestMatch;
                this.search();
              } 
              else {
                this.address = Numbers.round(clickData.latLng.lat(), 4).toString() + ',' + Numbers.round(clickData.latLng.lng(), 4).toString();
                this.search();
              }
            }
          );
        }
      }
    }
  }

  search() {
    if (!this.address.trim().length) {
      this._errorMessage = $localize`Adresa je povinná`;
    }
    this._errorMessage = null;
    this._markers = [this._vehicle.getCurrentPositionMarker()];
    this._loading = true;
    
    // api request log call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'geocoding';
    api_request_log.descr = 'vehicle-manual-tracking-configuration';
    api_request_log.price = 0.005;
    this._externalApiRequestServ.createRequestLog(api_request_log);

    this._googleMapsService.geocode(this.address, (results: any, status: string) => {
      if (status === 'OK') {
        let marker = new GoogleMapAdvMarker();
        marker.position = results[0].geometry.location;
        marker.icon = GoogleMapAdvMarker.icons['C'];

        let curMarker = new GoogleMapAdvMarker();
        curMarker.position = this._vehicle.getCurrentPositionMarker().position;
        curMarker.icon = this._vehicle.getCurrentPositionMarker().icon;

        this._markers = [curMarker, marker];
        // set flag for creating direction
        this._createDirectionDestRoute = true;
        this._rebuildMapSubject.next(true);

        this.temporaryTrackingTarget = {
          address: results[0].formatted_address,
          searchedAddress: this.address,
          distance: 0,
          duration: 0,
          durationReal: 0,
          targetReachedMessageSent: false,
          stops: 0,
          targetTolerance: this._targetTolerance,
          lat: marker.latitude, //.position.lat(),
          lng: marker.longitude, // position.lng()
        };
      } 
      else {
        this._errorMessage = $localize`Adresa nebyla nalezena mapovou službou`;
      }
      this._loading = false;
    });
  }

  createAutocomplete(input: any) {
    // only 3 basic fields
    const options: any = {
      fields: ["formatted_address", "geometry", "name"]
    };
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'autocomplete';
    api_request_log.descr = 'vehicle-manual-tracking-configuration';
    api_request_log.price = 0.00227;
    this._externalApiRequestServ.createRequestLog(api_request_log);

    this.autocomplete.addListener('place_changed', () => {
      // api request call
      let api_request_log: ExternalApiRequest = new ExternalApiRequest();
      api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
      api_request_log.type = 'place-details';
      api_request_log.descr = 'vehicle-manual-tracking-configuration';
      api_request_log.price = 0.004;
      this._externalApiRequestServ.createRequestLog(api_request_log);
      
      // place details
      let place = this.autocomplete.getPlace();
      if (!place.geometry) {
        return;
      }

      let marker = new GoogleMapAdvMarker();
      marker.position = place.geometry.location;
      marker.icon = GoogleMapAdvMarker.icons['C'];

      let curMarker = new GoogleMapAdvMarker();
      curMarker.position = this._vehicle.getCurrentPositionMarker().position;
      curMarker.icon = this._vehicle.getCurrentPositionMarker().icon;
      this._markers = [curMarker, marker];
      // set flag for creating direction
      this._createDirectionDestRoute = true;
      
      this.temporaryTrackingTarget = {
        address: input.value,
        searchedAddress: this.address,
        distance: 0,
        duration: 0,
        durationReal: 0,
        targetReachedMessageSent: false,
        stops: 0,
        targetTolerance: this._targetTolerance,
        lat: marker.latitude, // position.lat(),
        lng: marker.longitude // position.lng()
      };
      input.value = '';
    });
  }

  setVehicleTarget() {
    if (this._markers.length < 2) {
      return false;
    }

    let endPosition: GeoPosition = new GeoPosition();
    let waypoints: Array<GeoPosition> = this.temporaryTrackingWaypoints.map(
      (waypoint) => {
        let geoPosition = new GeoPosition();
        geoPosition.pos_gps = waypoint.location.lat() + ',' + waypoint.location.lng();
        return geoPosition;
      }
    );
    endPosition.pos_gps = this._markers[1].latitude + ',' + this._markers[1].longitude;

    this._destinationRoutesService.createNewRoute(
      this._vehicle,
      endPosition,
      waypoints,
      this._targetTolerance,
      this.temporaryTrackingTarget.duration,
      this.temporaryTrackingTarget.distance,
      this.temporaryTrackingTarget.searchedAddress
    ).subscribe(
      (data: DestinationRouteDataInterface) => {
        DestinationRoutesService.setReachDestinationToVehicle(
          this._vehicle,
          waypoints,
          this.temporaryTrackingTarget.duration,
          this.temporaryTrackingTarget.duration,
          endPosition,
          this.temporaryTrackingTarget.distance,
          this._targetTolerance,
          new Date(DateTools.isoFix(data.time_created).getTime()),
          this.temporaryTrackingTarget.searchedAddress,
          0,
          '#ff0000'
        );
        this._vehicle.destRouteChange.subscribe(
          () => {
            this._storage.setItem(
              RVehicleManualTrackingConfigurationComponent.createKey(this._vehicle),
              this._vehicle.getTemporaryManualTrackingDataToCache()
            );
          }
        );
      }
    );
  }

  directionsChange(manualTrackingDirectionRednered) {
    if (this.temporaryTrackingTarget) {
      let fullDuration = manualTrackingDirectionRednered.getDirections().routes[0].legs[0].duration.value;
      // speed_coef now used after GET request
      // if (!isNaN(this._vehicle.speed_coef)) {
      //   fullDuration = fullDuration / this._vehicle.speed_coef;
      // }
      this.temporaryTrackingWaypoints = manualTrackingDirectionRednered.getDirections().request.waypoints;
      this.temporaryTrackingTarget = {
        address: this.temporaryTrackingTarget.address,
        searchedAddress: this.temporaryTrackingTarget.searchedAddress,
        duration: fullDuration,
        durationReal: fullDuration,
        distance: manualTrackingDirectionRednered.getDirections().routes[0].legs[0].distance.value,
        targetReachedMessageSent: false,
        stops: this.temporaryTrackingTarget.stops,
        targetTolerance: this.temporaryTrackingTarget.targetTolerance,
        lat: this.temporaryTrackingTarget.lat,
        lng: this.temporaryTrackingTarget.lng
      };
    }
  }

}
