import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { StorageKeys } from 'src/app/config';
// import { GoogleMapMarker } from 'src/app/model/google-map-marker.object';
import { Itinerary } from 'src/app/model/itinerary.object';
import { TrackingEvent } from 'src/app/model/tracking-event.object';
import { Vehicle } from 'src/app/model/vehicle.object';
import { StorageService } from 'src/app/service/storage.service';
import { GoogleMapTools } from 'src/app/tools/GoogleMapTools';
import { ExternalApiRequestService } from 'src/app/service/external-api-request.service';
import { ExternalApiRequest } from 'src/app/model/external-api-request.object';
import { GoogleMapAdvMarker } from 'src/app/model/google-map-adv-marker.object';

declare var google: any;

@Component({
  selector: 'div.googleMapExpress',
  templateUrl: './google-map-express.component.html',
  styleUrls: ['./google-map-express.component.css']
})
export class GoogleMapExpressComponent implements OnInit {

  // usage in components - ta1-company-obligation, ta1-express-plan

  private _subscriptions: Array<Subscription> = [];
  
  private _map: any;
  private _markers: Array<GoogleMapAdvMarker> = [];
  private _markersMove: Array<GoogleMapAdvMarker> = [];
  private _infoWindows: Array<any> = [];
  private _polyLines: Array<any> = [];
  private _bounds: any;
  
  private _directionOrigin: any = null;
  private _directionDest: any = null;
  private _directionWaypoints: Array<any> = [];
  private _directionRendered: any = null;
  private _directionsService = new google.maps.DirectionsService();

  private _DEFAULT_ZOOM: number = 7;

  private _key: number;
  get key(): number {
    return this._key;
  }

  private _trafficLayerShow: boolean = true;
  private _trafficLayer: any = null;
  get trafficLayer(): any {
    return this._trafficLayer;
  }

  private _showHelp: boolean = true;
  public get showHelp(): boolean {
    return this._showHelp;
  }

  // inputs
  private _properties: any = { scrollwheel: true };
  @Input()
  set properties(properties: any) {
    this._properties = properties;
    if (this._properties && this._properties['zoom']) {
      this._DEFAULT_ZOOM = this._properties['zoom'];
    }
  }

  private _itinerary: Array<Itinerary> = [];
  @Input()
  set itinerary(value: Array<Itinerary>) {
    this._itinerary = value;
    this.initMarkers();
  }
  
  private _vehicle: Vehicle = null;
  @Input()
  public set vehicle(value: Vehicle) {
    this._vehicle = value;
  }

  private _evaluation: boolean = false;
  @Input()
  public set evaluation(value: boolean) {
    this._evaluation = value;
    if (this._evaluation) {
      this._showHelp = false;
    }
  }
  
  private _trackingEvents: Array<TrackingEvent> = [];
  @Input()
  public set trackingEvents(value: Array<TrackingEvent>) {
    this._trackingEvents = value;
    if (this._trackingEvents && this._trackingEvents.length) {
      this.createColorPolylinesFromTracking();
    }
  }

  private _buildMapOnOpen: boolean = false;
  public get buildMapOnOpen(): boolean {
    return this._buildMapOnOpen;
  }
  @Input()
  public set buildMapOnOpen(value: boolean) {
    this._buildMapOnOpen = value;
    if (this._buildMapOnOpen) {
      // add timeout for hiding help div
      window.setTimeout(
        () => {
          this._showHelp = false;
        }, 8000
      );
      
      this.buildData();
    }
  }
  
  private _homeMoving: boolean = true;
  @Input()
  public set homeMoving(value: boolean) {
    if (this._homeMoving != value) {
      this._homeMoving = value;
      // rebuild with/without home moving marker and polylines
      this.rebuildData(false);
    }
  }

  @Input() rebuildMap: Observable<boolean> = new Observable();
  
  // outputs
  private _itineraryMoved: EventEmitter<Array<Itinerary>> = new EventEmitter();
  @Output()
  public get itineraryMoved(): Observable<Array<Itinerary>> {
    return this._itineraryMoved.asObservable();
  }
  
  private _directionsChange: EventEmitter<any> = new EventEmitter();
  @Output()
  get directionsChange(): Observable<any> {
    return this._directionsChange.asObservable();
  }


  constructor(
    private _elRef: ElementRef,
    private _storageService: StorageService,
    private _externalApiRequestServ: ExternalApiRequestService
  ) { 
    this._key = Math.round((new Date()).getTime() / (Math.random() * 1024));
    // get traffic layer cookie
    let traffic: string = this._storageService.getItem(StorageKeys.map.traffic_layer, true);
    this._trafficLayerShow = (traffic == 'false') ? false : true;
  }

  ngOnInit(): void {
    if (this._evaluation) {
      this._firstBuilding = true;
    }

    // added event listener - parent could sent order to rebuild map
    this._subscriptions.push(
      this.rebuildMap.subscribe(
        (rebuild: boolean) => {
          if (rebuild) {
            this.rebuildData(false);
          }
        }
      )
    );

    // api request call
    let api_request_log: ExternalApiRequest = new ExternalApiRequest();
    api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
    api_request_log.type = 'maps-javascript';
    api_request_log.descr = 'google-map-express';
    api_request_log.price = 0.007;
    this._externalApiRequestServ.createRequestLog(api_request_log);
  }
  
  ngOnDestroy() {
    this.clearMarkers();
    this.clearData(true);

    this._subscriptions.forEach(
      subscription => subscription.unsubscribe()
    );
    this._subscriptions = [];
  }

  private clearData(dropMap: boolean) {
    // info windows
    this._infoWindows.forEach(
      infoWindow => {
        infoWindow.close();
        infoWindow.setMap(null);
      }
    );
    this._infoWindows = [];
    // polylines
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];
    this._bounds = null;
    // directions
    this._directionOrigin = null;
    this._directionDest = null;
    this._directionWaypoints = [];
    this.clearDirectionsRenderers();
    // drop map
    if (dropMap) {
      this._map = null;
    }
  }
  
  clearMarkers(): void {
    // markers
    this._markers.forEach(
      marker => {
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
          marker.infoWindow = null;
        }
        marker.clearEvents();
        marker.map = null;
      }
    );
    this._markers = [];
  }

  /*************************************************************/
  // Bulding methods
  /*************************************************************/
  // private _rebuildTimeout: number = null;
  // public get rebuildTimeout(): number {
  //   return this._rebuildTimeout;
  // }

  private _firstBuilding: boolean = true;
  public get firstBuilding(): boolean {
    return this._firstBuilding;
  }

  private _rebuilding: boolean = false;
  public get rebuilding(): boolean {
    return this._rebuilding;
  }
  
  private rebuildData(dropMap: boolean) {
    if (!this._rebuilding) {
      window.setTimeout(
        () => {
          this.clearData(dropMap);
          this.buildData();
        },
        1000
      )
    }
  }

  private buildData() {
    if (!this._map) {
      let container = this._elRef.nativeElement.children[0];
      if (this._properties) {
        this._properties.mapId = 'googleMap' + this._key;
      }
      this._map = new google.maps.Map(container, GoogleMapTools.getProperties(this._properties));

      // click to map - deactive possibly clicked markers
      this._map.addListener('click', (data) => {
        this.resetMarkersMove();
      });
    }

    // get markers from itinerary
    this.initMarkers();
    
    // markers and bounds
    if (this._markers.length) {
      // add default first marker with vehicle home stand
      if (this._homeMoving && this._vehicle && this._vehicle.home_stand) {
        let home1: GoogleMapAdvMarker = this.createHomeMarker(this._vehicle.home_stand.geoPosition, 0);
        home1.map = this._map;

        if (this._evaluation) {
          this._directionOrigin = { location: home1.position };
        }
        else {
          this._polyLines.push(
            new google.maps.Polyline({
              path: [
                home1.position,
                this._markers[0].position,
              ],
              geodesic: true,
              strokeColor: '#000000',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              zIndex: 1000,
              map: this._map
            })
          );
        }
      }


      this._bounds = new google.maps.LatLngBounds();
      this._markers.forEach(
        (marker: GoogleMapAdvMarker, index) => {
          // setup marker and bounds
          this.setMarker(marker);

          // specific vajiska feature - disabled bounds functions
          if (this._firstBuilding || !this._vehicle || this._vehicle.company_key != 3684) {
            this._bounds.extend(marker.position);
          }

          if (this._evaluation) {
            // push it waypoints array
            this._directionWaypoints.push(
              { location: marker.position }
            );
          }
          else {
            // draw blue lines
            if (this._markers[index + 1]) {
              this._polyLines.push(
                new google.maps.Polyline({
                  path: [
                    marker.position,
                    this._markers[index + 1].position,
                  ],
                  geodesic: true,
                  strokeColor: '#0000ff',
                  strokeOpacity: 1.0,
                  strokeWeight: 3,
                  zIndex: 1000,
                  map: this._map
                })
              );
            }
          }
        }
      );
      
      // add default last marker with vehicle home stand
      if (this._homeMoving && this._vehicle && this._vehicle.home_stand) {
        let lastIdx: number = this._markers.length + 1;
        let home2: GoogleMapAdvMarker = this.createHomeMarker(
          this._vehicle.home_stand.geoPosition, lastIdx
        );
        home2.map = this._map;
        
        if (this._evaluation) {
          this._directionDest = { location: home2.position };
        }
        else {
          this._polyLines.push(
            new google.maps.Polyline({
              path: [
                this._markers[this._markers.length - 1].position,
                home2.position
              ],
              geodesic: true,
              strokeColor: '#000000',
              strokeOpacity: 1.0,
              strokeWeight: 3,
              zIndex: 1000,
              map: this._map
            })
          );
        }
      }
    }

    if (this._evaluation && this._directionOrigin && this._directionDest) {
      this.renderDirections();
    }
    
    // obligation real tracking data
    if (this._trackingEvents && this._trackingEvents.length) {
      this.createColorPolylinesFromTracking();
    }

    if (this._map) {
      // specific vajiska feature - disabled bounds functions
      if (this._firstBuilding || !this._vehicle || this._vehicle.company_key != 3684) {
        if (this._bounds && this._markers.length > 1) {
          this._map.fitBounds(this._bounds);
        } 
        else {
          if (this._markers.length) {
            this._map.setCenter(this._markers[0].position);
          }
          this._map.setZoom(this._DEFAULT_ZOOM);
        }
      }

      // possibly draw traffic layer
      if (this._trafficLayerShow && !this._trafficLayer) {
        this.showTrafficLayer();
      }
    }
    
    this._firstBuilding = false;
    this._rebuilding = false;
  }

  
  /*************************************************************/
  // Markers methods
  /*************************************************************/
  private initMarkers(): void {
    if (this._itinerary) {
      // init markers
      this.clearMarkers();
      this._itinerary.forEach(
        it => {
          if (it.marker) {
            this._markers.push(it.marker);
          }
        }
      );
    }
  }

  private setMarker(marker: GoogleMapAdvMarker): void {
    marker.map = this._map;
    // set info window
    if (marker.infoWindowContent && !marker.infoWindow) {
      let infowindow: any;
      // https://stackoverflow.com/questions/57770749/
      infowindow = new google.maps.InfoWindow({ disableAutoPan: true });
      this._infoWindows.push(infowindow);
      marker.infoWindow = infowindow;
      infowindow.setContent(marker.infoWindowContent);
      infowindow.open(this._map, marker.getGoogleMarker());
    }
    
    if (!this._evaluation) {
      // behavior on marker clicked
      marker.addListener('click', () => {
        // close previous info window
        if (marker.infoWindow) {
          marker.infoWindow.close();
          marker.infoWindow.setMap(null);
        }

        // toggle
        marker.isActive = !marker.isActive;
        if (marker.isActive) {
          this._markersMove.push(marker);
          // let htmlContent: string = '<div style="font-weight:bold; margin-left:10px; padding:4px 6px; background:#e0a800; text-align:center; border-radius: 4px;">';
          // htmlContent += '<div>Vyměnit tento bod</div><div>(zvolte bod pro výměnu)</div>';
          // htmlContent += marker.number + '</div>';
          let htmlContent: string = '<div style="font-weight:bold; margin-left:10px; padding:4px 6px; background:#e0a800; text-align:center; border-radius: 4px;">';
          htmlContent += '< ' + marker.number + ' ></div>';
          marker.infoWindowContent = htmlContent;

          if (this._markersMove.length == 2) {
            // 2 markers clicked -> perform moving/appending second after first
            this.itineraryAppendAfter();
            this._markersMove = [];
          }
        }
        else {
          if (marker.isCurrentObligation) {
            let htmlContent: string = '<div style="font-weight:bold; margin-left:10px; padding:4px 6px; border-radius:4px; background-color:#0db9f0">';
            htmlContent += marker.number + '</div>';
            marker.infoWindowContent = htmlContent;
          }
          else {
            let htmlContent: string = '<div style="font-weight:bold; margin-left:10px;">';
            htmlContent += marker.number + '</div>';
            marker.infoWindowContent = htmlContent;
          }
          this._markersMove = [];
        }

        // show info window
        let infowindow: any;
        infowindow = new google.maps.InfoWindow({});
        marker.infoWindow = infowindow;
        this._infoWindows.push(marker.infoWindow);
        infowindow.setContent(marker.infoWindowContent);
        infowindow.open(this._map, marker.getGoogleMarker());
      });
    }
  }


  private createHomeMarker(location: any, idx: number): GoogleMapAdvMarker {
    let marker = new GoogleMapAdvMarker();
    marker.position = location;
    marker.draggable = false;
    marker.setData('type', 2);
    marker.setData('id', Math.random().toString(36).substring(7));
    marker.number = idx;
    marker.isCurrentObligation = false;
    marker.icon = 'assets/icon/icon-car-state/home_parking.svg';
    
    // let htmlContent: string = '<div style="font-weight:bold; margin-left:10px">';
    // htmlContent += marker.number + '</div>';
    // marker.infoWindowContent = htmlContent;
    // // set info window
    // if (marker.infoWindowContent && !marker.infoWindow) {
    //   let infowindow: any;
    //   infowindow = new google.maps.InfoWindow({});
    //   this._infoWindows.push(infowindow);
    //   marker.infoWindow = infowindow;
    //   infowindow.setContent(marker.infoWindowContent);
    //   infowindow.open(this._map, marker.getGoogleMarker());
    // }
    return marker;
  }

  // moving item in array using splice
  // source: https://dev.to/jalal246/moving-element-in-an-array-from-index-to-another-464b
  private move(inputArr: Array<Itinerary>, from: number, to: number): void {
    // remove
    let numberOfDeletedElm = 1;
    const elm = inputArr.splice(from, numberOfDeletedElm)[0];
    // add
    numberOfDeletedElm = 0;
    inputArr.splice(to, numberOfDeletedElm, elm);
  }
  
  // appending second itinerary after first
  itineraryAppendAfter(): void {
    if (this._markersMove.length == 2) {
      let idx1: number = this._markersMove[0].number - 1;
      let idx2: number = this._markersMove[1].number - 1;
      
      this.move(this._itinerary, idx2, idx1 + 1);

      // event emit
      this._itineraryMoved.emit(this._itinerary);
    }
  }

  resetMarkersMove(): void {
    if (this._markersMove) {
      this._markersMove.forEach(
        marker => {
          marker.isActive = false;
          // close previous info window
          if (marker.infoWindow) {
            marker.infoWindow.close();
            marker.infoWindow.setMap(null);
          }
          // show default info window
          if (marker.isCurrentObligation) {
            let htmlContent: string = '<div style="font-weight:bold; margin-left:10px; padding:4px 6px; border-radius:4px; background-color:#0db9f0">';
            htmlContent += marker.number + '</div>';
            marker.infoWindowContent = htmlContent;
          }
          else {
            let htmlContent: string = '<div style="font-weight:bold; margin-left:10px;">';
            htmlContent += marker.number + '</div>';
            marker.infoWindowContent = htmlContent;
          }
          let infowindow: any = new google.maps.InfoWindow({});
          marker.infoWindow = infowindow;
          this._infoWindows.push(marker.infoWindow);
          infowindow.setContent(marker.infoWindowContent);
          infowindow.open(this._map, marker.getGoogleMarker());
        }
      );
      this._markersMove = [];
    }
  }

  
  /*************************************************************/
  // Waypoints/directions methods
  /*************************************************************/
  private renderDirections(): void {
    let directionOptions: any;
    directionOptions = JSON.parse(JSON.stringify(GoogleMapTools.RendererOptions));

    this._directionRendered = new google.maps.DirectionsRenderer(directionOptions);
    this._directionRendered.setMap(this._map);
    this._directionRendered.addListener('directions_changed', () => {
      let directionChange: any = {
        rendered: this._directionRendered
      };
      // console.log(directionChange);
      this._directionsChange.emit(directionChange);
    });

    // render request
    let request = {
      origin: this._directionOrigin.location,
      destination: this._directionDest.location,
      waypoints: this._directionWaypoints,
      travelMode: google.maps.DirectionsTravelMode.DRIVING
    };
    this.callRequestOnDirectionsRender(this._directionRendered, request);
  }

  private _directionsRenderCalls: number = 0;
  private callRequestOnDirectionsRender(directionsRendered, request: any) {
    let isset = false;
    this._directionsRenderCalls++;
    window.setTimeout(
      () => {
        // api request call
        let api_request_log: ExternalApiRequest = new ExternalApiRequest();
        api_request_log.domain = 'https://maps.googleapis.com/maps/api/js';
        api_request_log.type = 'directions';
        api_request_log.descr = 'google-map-express';
        api_request_log.price = 0.005;
        this._externalApiRequestServ.createRequestLog(api_request_log);

        // google request
        this._directionsService.route(
          request,
          (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
              if (isset === false) {
                directionsRendered.setDirections(response);
                isset = true;
              }
            }
            this._directionsRenderCalls--;
          }
        );
      },
      1001 * this._directionsRenderCalls
    );
  }
  
  private clearDirectionsRenderers() {
    if (this._directionRendered) {
      this._directionRendered.setMap(null);
      this._directionRendered = null;
    }
  }

  
  /*************************************************************/
  // Traffic layer managing
  /*************************************************************/
  showTrafficLayer() {
    this._trafficLayer = new google.maps.TrafficLayer();
    this._trafficLayer.setMap(this._map);
  }

  toggleTrafficLayer() {
    if (!this._trafficLayer) {
      this._trafficLayer = new google.maps.TrafficLayer();
    }

    if (this._trafficLayer.getMap() == null){
      // traffic layer is disabled.. enable it
      this._trafficLayer.setMap(this._map);
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'true', true);
    }
    else {
      // traffic layer is enabled.. disable it
      this._trafficLayer.setMap(null);        
      this._storageService.setItem(StorageKeys.map.traffic_layer, 'false', true);     
    }
  }

  /*************************************************************/
  // Obligation events
  /*************************************************************/
  // method that creates polylines with given color
  createColorPolylinesFromTracking(): void {
    // polylines
    this._polyLines.forEach(
      polyLine => {
        polyLine.setMap(null);
      }
    );
    this._polyLines = [];

    // creating polylines to our map
    this._trackingEvents.forEach(
      (trackingData, index) => {
        if (this._trackingEvents[index + 1]) {
          // dark -> transit / red -> route
          let color: string = trackingData.cargo_status == 'E' ? '#444444' : '#ff0000';

          this._polyLines.push(
            new google.maps.Polyline({
              path: [
                trackingData.geoPos.googleLatLang.googleLtLn,
                this._trackingEvents[index + 1].geoPos.googleLatLang.googleLtLn,
              ],
              geodesic: true,
              strokeColor: color,
              strokeOpacity: 1.0,
              strokeWeight: 2,
              zIndex: 1000,
              map: this._map
            })
          );
        }
      }
    );
  }
}
